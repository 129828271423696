.posthero {
	text-align:center;
	@include breakpoint(small only) {
		padding-top:90px;
	}

	.HeroPostImg {
		width: 100%;
	}
	.LetsMeetImg {
		img {
			width: 100%;
		}
		p{
			background-color: #f4f6f6;
			padding: 20px;
		}
	}
}

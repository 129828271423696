#new-staff {
	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.3;
		padding: 30px 0;
		color: #595959;
		text-transform: capitalize;
		@include breakpoint(medium down) {
  		  font-size: 2.4rem;
	    }

	}
	p, a {
		line-height: 1.3;
		font-size: 20px;
		font-family: $body-font-family;
		color: #595959;
		@include breakpoint(xlarge down) {
			font-size: 16px;
		}
	}
	.Anotherlink {
		line-height: 1.3;
		font-weight: bold;
		font-family: $body-font-family;
		color: #595959;
		@include breakpoint(large only) {
			font-size: 14px;
		}
		&:hover {
			color: $primary-color;
		}
	}
	.new-staff-logo {
		display: block;
		margin: 100px auto;
		width: 30%;
		@include breakpoint(small down) {
			width: 60%;
		}
	}
	.hoverphoto {
		position: relative;

		@media (max-width: 1190px) {
			.person {
				display: none;
			}

			#key {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 5;
				width: 28%;
			}

			.instructions {
				display: none;
			}
		}

		@media (min-width: 1191px) {
			.person:hover, .person:focus {
				opacity: .75;
			}

			#key {
				display: none;
			}
		}

		#chantelle {
			position: absolute;
			top: 565px;
			left: 768px;
			z-index: 3;
			cursor: pointer;
		}

		#dasha {
			position: absolute;
			top: 578px;
			left: 548px;
			z-index: 3;
			cursor: pointer;
		}

		#ruthie {
			position: absolute;
			top: 850px;
			left: 600px;
			z-index: 3;
			cursor: pointer;
		}

		#rebecca {
			position: absolute;
			top: 900px;
			left: 397px;
			z-index: 4;
			cursor: pointer;
		}

		#steve {
			position: absolute;
			top: 883px;
			left: 722px;
			z-index: 4;
			cursor: pointer;
		}

		#beth {
			position: absolute;
			top: 798px;
			left: 195px;
			z-index: 4;
			cursor: pointer;
		}

		#kyle {
			position: absolute;
			top: 809px;
			left: 725px;
			z-index: 3;
			cursor: pointer;
		}

		#dave {
			position: absolute;
			top: 530px;
			left: 462px;
			z-index: 2;
			cursor: pointer;
		}

		#mike {
			position: absolute;
			top: 516px;
			left: 330px;
			z-index: 2;
			cursor: pointer;
		}

		#michael {
			position: absolute;
			top: 547px;
			left: 668px;
			z-index: 2;
			cursor: pointer;
		}

		#malik {
			position: absolute;
			top: 822px;
			left: 389px;
			z-index: 3;
			cursor: pointer;
		}

		#jennifer {
			position: absolute;
			top: 592px;
			left: 202px;
			z-index: 3;
			cursor: pointer;
		}
	}
		.directions {
		    text-transform: uppercase;
			line-height: 1.3;
			font-size: 20px;
		    width: 100%;
		    text-align: center;
		    color: #fb4c13;
			margin: 40px 10px;
			@include breakpoint(xlarge down) {
				font-size: 16px;
			}
			img {
				max-width: 3%;
				margin-top: -20px;

			}
	}
}

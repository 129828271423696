/*
* About Hero section style
*/
#about-hero {
		@include cover-background('../img/about-hero.png');

		h1 {
			color:white;
			font-size:2rem;
			@include breakpoint(small only) {
	  		  font-size: 1.5rem;
		    }
			@media (max-height: 420px) {
				font-size: 1.5rem;
			}
		}
		.HerotextAlign {
			text-align:left;
		}
		p {
			line-height: 1.75;
			font-size: 18px;
		}
}


#aboutText {
 padding:7rem 0;

	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.5;
		font-size:2rem;
		padding:0 0 30px;
		color: #595959;
		text-transform: capitalize;
		@include breakpoint(small only) {
  		  font-size: 1.5rem;
	    }
	}

	p {
		line-height: 1.75;
		font-size: 18px;
		font-family: $body-font-family;
		color: #595959;

	}

}

.r-tabs {
	padding-top:2rem;

	.r-tabs-nav {
		 margin: 0;
		 padding: 0;
 }
}

.r-tabs .r-tabs-tab {
    display: inline-block;
    margin: 0;
    list-style: none;
		width:25%;
		text-align:center;
		text-transform: uppercase;
		font-size:1.25rem;
		line-height: 1;
		font-weight:600;
		position:relative;
		border-left:1px solid white;

		a {
			display: block;
	  	padding:.85rem 0rem;
			color:#444444;
		}

		&:hover {
			background:#444444;
			a {
				color:white;
			}
		}
}

.r-tabs-nav .r-tabs-state-active {
	background:#444444;

	&:after {
    content:'';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-top: solid 10px #444444;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
}

	a {
		color:white;
	}
}
.r-tabs .r-tabs-panel {
    padding:3rem 0;
    display: none;
		color:#595959;

		border-top:1px solid #595959;
		margin-top:2rem;
}

.r-tabs .r-tabs-accordion-title {
    display: none;

}

.r-tabs .r-tabs-panel.r-tabs-state-active {
    display: block;
}

.FourTab {
	-webkit-column-count: 2; /* Chrome, Safari, Opera */
  -moz-column-count: 2; /* Firefox */
  column-count: 2;
}
/* Accordion responsive breakpoint */
@media only screen and (max-width: 480px) {
	.FourTab {
		-webkit-column-count: 1; /* Chrome, Safari, Opera */
	  -moz-column-count: 1; /* Firefox */
	  column-count: 1;
	}

    .r-tabs .r-tabs-nav {
        display: none;
    }

		.r-tabs .r-tabs-panel {
				border-top:none;
				margin-top:0;
				padding: 2rem 0;
		}
    .r-tabs .r-tabs-accordion-title {
			  border-bottom:1px solid #595959;
        display: block;
				text-align:center;
				text-transform: uppercase;
				font-size:1.25rem;
				line-height: 1;
				font-weight:600;
				position:relative;

				a {
          display: block;
			  	padding:.85rem 0rem;
					color:#444444;
				}

    }
   .r-tabs-accordion-title.r-tabs-state-active {
		background:#444444;

		&:after {
	    content:'';
	    position: absolute;
	    top: 100%;
	    left: 50%;
	    margin-left: -10px;
	    width: 0;
	    height: 0;
	    border-top: solid 10px #444444;
	    border-left: solid 10px transparent;
	    border-right: solid 10px transparent;
	}

		a {
			color:white;
		}
	}
}

#cliensLogos {
	//position: relative;
	background-image: url('../img/Clients-logos-background.jpg');
	background-size: cover;
	background-attachment: fixed;

	@include breakpoint(medium down) {
		background-size: auto 100%;
		background-attachment: scroll;
		background-position: center;

	}

	.clientlogoswrap {
		@include breakpoint(large up) {
					padding: 100px;
		}
		padding:10px;
	}
	.column, .columns {
		padding-right: 0.625rem!important;
		padding-left: 0.625rem!important;
	}
	.myspace {
		margin: 0!important;
		img {
			 -moz-transition: all 0.3s;
			 -webkit-transition: all 0.3s;
			 transition: all 0.3s;
			 @include breakpoint(medium down) {
				 padding:.5rem 0;
			 }
				&:hover {
					-moz-transform: scale(1.1);
					 -webkit-transform: scale(1.1);
					 transform: scale(1.1);
				}
		}
	}
	.clientlogos-load {
		width: 23px;
	}

	h4{
		font-family: 'Open Sans', serif;
		color: $white;
		font-weight: 900;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	#ClientMobile, #ClientMobileUp {
		padding-bottom: 40px!important;
		cursor: pointer;
	}

	.myhide {
		@include breakpoint(medium down) {
			display: none;
			&.open {
				display: block;
			}
		}
	}

	.hideClientMobile {
		@include hide-for(large);
	}
}

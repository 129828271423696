.cs-exposes-its-neck {
	//border-bottom: 3px solid $primary-color;
	background: $white;

		h1,h2,h3,h4,h5,h6 {
			font-family: $header-font-family;
			color: #595959;
			margin-bottom:0;
			padding-bottom:25px;
		}

		h1 {
			line-height: 1.3;
			padding-top:25px;
			font-weight: 900;
			text-transform: capitalize;

			@include breakpoint(large down) {
				font-size: 1.7rem;
				margin-bottom: 0;
			}
		}
		@media (max-width: 2000px) and (min-width: 1199px){
			h1{
				font-size: 2rem!important;
			}
		}

		p {
			color: #595959;
			line-height: 1.3;
			font-size: 20px;
			font-family: $body-font-family;
			@include breakpoint(medium down) {
			  font-size: 14px;
			}
		}
		@media (max-width: 2000px) and (min-width: 1024px){
			p {
				font-size: 16px!important;
			}
			// h3 {
			// 	font-size: 18px;
			// }
		}

		//ShareIcons
		.ShareIcons {
			width:50px;
			padding-right: 25px;
			padding-bottom: 25px;
			transition: all 1s ease;
			&:hover {
				transform: scale(1.1);
			}
		}

		.blogtext {
			margin-bottom: 150px;
			margin-top:50px;
			@include breakpoint(medium down) {
				margin-bottom: 50px;
			}
		}

		.boltnews {
			max-width: 50px;
		}


		.pagination {
			position: relative;
			margin-top: 100px;
			margin-bottom:0;
			background: #28acbe;
			@include breakpoint(small only) {
				padding: 30px 0;
			}
			@include breakpoint(medium down) {
				margin-top: 50px;
			}
			padding: 15px 0;
			font-family: $body-font-family;
			text-transform: uppercase;
			font-weight: 600;
			a {
				font-size: 16px;
				color: $white;
				@include breakpoint(large down) {
					font-size: 14px;
				}
				&::before, &::after{
					content:" ";
				}
				&:hover {
					background: transparent;
					color: #e6e04d;
				}
			}
			.current {
				font-size: 16px;
				display: inline-block!important; //comment this if pagination should be hide for samll screens
				@include breakpoint(large down) {
					font-size: 14px;
				}
				background: transparent;
				color: #e6e04d;
			}

			.pagination li {
				display: inline-block!important; //comment this if pagination should be hide for samll screens
			}
			.pagination-previous {
				@include vertical-align(15%, 50%);
			}
			.pagination-next {
				@include vertical-align(85%, 50%);
			}
			#BlogImgPrev, #BlogImgNext {
				width: 15px;
				margin-right: 10px;
				margin-left: 10px;
			}
		}

		.MobileShare {
			margin-top: 30px;
		}
		.gingerbread1 {
			padding-bottom: 2rem;
		}
		.gingerbread2 {
			padding-top: 1rem;
		}
}

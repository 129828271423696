
#xmas-slider-wrap {
	position: relative;
		.xmas-bxslider  {
			margin-left:0;
			.vertical {
				background:$black;
				// padding-right: 50px!important;
				img {
					height: 100vh;
    				margin: auto;
				}
			}
				.horizontal {
					min-height: 100vh;
					background-size: cover!important;
				}

		} //xmas-bxslider

		.bx-wrapper .bx-viewport {
		  left: 0;
		  border: none;
		  box-shadow: none;
		}

		ul, ul li {
		  margin: 0;
		  padding: 0;
		}
		#xmas-next {
			position: absolute;
		    top: 50%;
		    left: 95%;
		    margin-right: -95%;
		    -webkit-transform: translate(-95%,-50%);
		    -khtml-transform: translate(-95%,-50%);
		    transform: translate(-95%,-50%);
		    width: 60px;
			cursor: pointer;
			border:2px solid $primary-color;
			border-radius: 50%;
		}
		#xmas-prev {
			position: absolute;
		    top: 50%;
		    left: 5%;
		    margin-right: -5%;
		    -webkit-transform: translate(-5%,-50%);
		    -khtml-transform: translate(-5%,-50%);
		    transform: translate(-5%,-50%);
		    width: 60px;
			cursor: pointer;
			border:2px solid $primary-color;
			border-radius: 50%;
		}

		.dowloadimg {
			color: #000000;
			background-color: #f04f23;
			position: absolute;
			transition: all 0.5s ease;
			border: 1px solid #f04f23;
			@include breakpoint(small only) {
				@include vertical-align(50%, 95%);
			}
			@include breakpoint(medium up) {
				bottom: 20px;
				right: 20px;
			}

			P {
				font-family: $body-font-family;
				margin-bottom: 0;
				padding: 10px 50px;
				font-weight: 600;
			}
			&:hover {
				background-color: #ffffff;
				border-color: #000000;
			}

		}

} //#xmas-slider-wrap

.Mymasonry {
	border-top: 0.4px solid transparent; //need border for first video for Masonry should be in separate section. Otherwise poster is not showing
	img {
		width: 100%;
	}
	.madjack-social {
		@include breakpoint(medium down) {
			padding: 5px;
		}
	}

	#DistrBlock1,#AcanacBlock1, #AcanacBlock2, #AcanacBlock1-mobile, #AcanacBlock2-mobile, #DistrBlock1-mobile {
		.flex-video {
			margin-bottom: 0!important;
		}
	}
	#AcanacBlock2, #AcanacBlock2-mobile {
		.responsive-embed.widescreen, .flex-video.widescreen {
		    padding-bottom: 73%;
		}
	}
	#AcanacBlock1, #AcanacBlock2 {
		@include breakpoint(medium down) {
			display:none;
		}
	}
	#AcanacBlock1-mobile, #AcanacBlock2-mobile {
		@include breakpoint(large up) {
			display:none;
		}
	}
	#DistrBlock1 {
		@include breakpoint(medium down) {
			display:none;
		}
	}
	#DistrBlock1-mobile {
		@include breakpoint(large up) {
			display:none;
		}
	}
	#MadJackBlock {
		@include breakpoint(medium down) {
			display:none;
		}
	}
	#MadJackBlock-mobile {
		@include breakpoint(large up) {
			display:none;
		}
	}

	#MadJackBlockV0_new {
		/*@include breakpoint(medium down) {
			display:none;
		}*/
	}
	#MadJackBlock-mobile_new {
		@include breakpoint(large up) {
			display:none;
		}
	}



	// .test {
	// 	transition: all 1s ease;
	// 	&:hover {
	// 		width: 100vw;
	// 	}
	// }
	//need if we use text on image
	// .withtitle {
	// 	position: relative;
	// 	p {
	// 		font-family: $body-font-family;
	// 		text-transform: uppercase;
	// 		font-weight: 800;
	// 		font-size: 86px;
	// 		@include vertical-align(50%, 50%);
	// 		color: $white;
	// 		line-height: 1;
	// 	}
	// }
	.myplay {
		border:1px solid #fff;
		position: relative;
		cursor: pointer;
		.flex-video {
			margin-bottom: 0;
		}
		.playbtn {
			@include vertical-align(50%, 50%);
			width: 150px;
			z-index: 10;
			 opacity: 1;
			 transition: all 1s ease;
			 @include breakpoint(medium down) {
				 width: 80px;
			}
		}
	}
	.play_active {
		.playbtn {
			opacity: 0;
		}
	}
	.active {
		.playbtn {
			opacity: 0;
		}
	}

	.video-wrap {
		padding-top: 120px;
		padding-bottom: 120px;

		@include breakpoint(small down) {
			padding-top: 60px;
			padding-bottom: 60px;
	 }
	}
	video {
		    object-fit: inherit;
	}
	// #MadJackV {
	// 	left: 25%;
	//     width: 50%;
	//     height: 100%;
	// }
	// #MadJackBlock {
	// 	background-color: #232323;
	// 	.responsive-embed.widescreen, .flex-video.widescreen {
	// 	    padding-bottom: 79%;
	// 	}
	// }
	 #BelgianBlock1, #BelgianBlock2, #BelgianBlock31, #BelgianBlock4, #LindtBlock1, #LindtBlock2, #LindtBlock3, #LindtBlock5, #LindtBlock4, #LindtBlock6, #DistrBlock1, #DistrBlock1-mobile{
		.responsive-embed.widescreen, .flex-video.widescreen {
		    padding-bottom: 100%;
		}
	}
	 #MadJackBlockV0_new, #AF1Block {
			.responsive-embed.widescreen, .flex-video.widescreen {
					padding-bottom: 66.6%;
			}
		}

#w15KBlock2 {
	.responsive-embed.widescreen, .flex-video.widescreen {
			padding-bottom: 50.2%;
	}
}
#w15KBlock {
	.responsive-embed.widescreen, .flex-video.widescreen {
			padding-bottom: 56.9%;
	}

}
  #MadJackBlock-mobile_new {
		.flex-video.widescreen {
					padding-bottom: 66.6%;
				}
	}


	 #MadJackBlock01_new, #MadJackBlock2_new, #MadJackBlock3_new, #MadJackBlock4_new, #MadJackBlock5_new {
		.responsive-embed.widescreen, .flex-video.widescreen {
				padding-bottom: 150%;
		}
	}
	//!!!!!!!!!!!this border needs to be added because without it when you click on first video posters for other videos disappears
	#BelgianBlock1, #BelgianBlock2, #BelgianBlock31{
		border: 0.5px solid $white;
	}
	//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

	//!!!!!!!!!!!this border needs to be added because without it when you click on first video posters for other videos disappears
	#MadJackBlock2, #MadJackBlock3, #MadJackBlock4{
		border: 0.5px solid $white;
	}
	//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

	.sidePadding {
		padding-left: 1.5rem;
    padding-right: 1.5rem;
	}
	.topPadding {
		padding-top: 5rem;
    padding-bottom: 5rem;
	}

	@include breakpoint(large up) {
		.centered-row {
			display: flex;
			justify-content: center;
			text-align: center;
		}
		//Air Max Day
		.myleft {
			width: 40%;
		}
		.myright {
			width: 60%;
		}
		.myleft2 {
			width: 42.9%;
		}
		.myright2 {
			width: 57.1%;
		}
		//Hockey
		.myleft_h {
			width: 60.2%;
		}
		.myright_h {
			width: 39.8%;
		}
		.myleft_h2 {
			width: 42.9%;
		}
		.myright_h2 {
			width: 57.1%;
		}
		.myleft_h3 {
			width: 57.12%;
		}
		.myright_h3 {
			width: 42.88%;
		}
		//Gastown
		.myleft_g {
			width: 39.82%;
		}
		.myright_g {
			width: 60.18%;
			//margin-bottom:10px;
		}
		.myleft_g2 {
			width: 40.75%;
		}
		.myright_g2 {
			width: 59.25%;
		}
		//AF1
		.myleft_af {
			width: 57.15%;
		}
		.myright_af {
			width: 42.85%;
		}
		.myleft_af1 {
			width: 40%;
		}
		.myright_af1 {
			width: 60%;
		}
		//15K
		.myleft_15k {
			width: 60%;
		}
		.myright_15k {
			width: 40%;
		}
		.myleft_15k2 {
			width: 42.8%;
		}
		.myright_15k2 {
			width: 57.2%;
		}
		//BraHaus Nike
		.myleft_bra {
			width: 60%;
		}
		.myright_bra {
			width: 40%;
		}
		.myleft_bra1 {
			width: 42.77%;
		}
		.myright_bra1 {
			width: 57.23%;
		}
		.myleft_bra2 {
			width: 57.1%;
		}
		.myright_bra2 {
			width: 42.9%;
		}
		.myleft_nba {
			width: 42.77%;
		}
		.myright_nba {
			width: 57.23%;
		}
		.myleft_nba1 {
			width: 57.1%;
		}
		.myright_nba1 {
			width: 42.9%;
		}
		.myleft_nba2 {
			width: 37.56%;
		}
		.myright_nba2 {
			width: 62.44%;
		}

		.myleft_drew1 {
			width: 46.35%;
		}
		.myright_drew1 {
			width: 53.65%;
		}
		//Molson Carling
		.myleft_c {
			width: 60%;
		}
		.myright_c {
			width: 40%;
		}
		//Molson MadJack
		.myleft_m {
			width: 36%;
		}
		.myright_m {
			width: 64%;
			.flex-video {
				margin-bottom: 0;
			}
		}
		.myleft_m2 {
			width: 50.89%;
		}
		.myright_m2 {
			width: 49.11%;
		}
		.myleft_m3 {
			width: 63.92%;
		}
		.myright_m3 {
			width: 36.08%;
		}
		.myleft_m4 {
			width: 49.98%;
		}
		.myright_m4 {
			width: 49.98%;
		}
		// .myleft_b1 {
		// 	width: 23.33%;
		// }
		// .mycenter_b1 {
		// 	width: 33.33%;
		// }
		// .myright_b1 {
		// 	width: 23.33%;
		// }
		.myleft_b2 {
			width: 46.82%;
		}
		.myright_b2 {
			width: 53.18%;
		}
		.myleft_b3 {
			width: 63.3%;
		}
		.myright_b3 {
			width: 36.7%;
		}
		.myleft_b4 {
			width: 45.8%;
		}
		.myright_b4 {
			width: 54.2%;
		}

		//Molson MadJack News
		.myleft_m5 {
			width: 52.94%;
		}
		.myright_m5 {
			width: 23.53%;
		}

		//Bay
		.myleft_bay {
			width: 67.35%;
		}
		.myright_bay {
			width: 32.65%;
		}
		.myleft_bay1 {
			width: 42.8%;
		}
		.myright_bay1 {
			width: 57.2%;
		}
		.myleft_bay2 {
			width: 59.8%;
		}
		.myright_bay2 {
			width: 40.2%;
		}
		.myleft_bay3 {
			width: 42.85%;
		}
		.myright_bay3 {
			width: 57.15%;
		}
		.myleft_bay4 {
			width: 67.35%;
		}
		.myright_bay4 {
			width: 32.65%;
		}
		.myleft_bay5 {
			width: 67.1%;
		}
		.myright_bay5 {
			width: 32.9%;
		}
		//IKO
		.myleft_iko {
			width: 67.55%;
		}
		.myright_iko {
			width: 32.45%;
		}
		.myleft_iko1 {
			width: 32.4%;
		}
		.myright_iko1 {
			width: 67.6%;
		}

		//lindt
		.myleft_lindt1 {
			width: 45.07%;
		}
		.myright_lindt1 {
			width: 54.9%;
		}
		.myleft_lindt2 {
			width: 47.45%;
		}
		.myright_lindt2 {
			width: 52.55%;
		}
		.myleft_lindt3 {
			width: 33%;
		}
		.myright_lindt3 {
			width: 67%;
		}
		.myleft_lindt4 {
			width: 46.8%;
		}
		.myright_lindt4 {
			width: 53.2%;
		}
		//humber
		.myleft_humber1 {
			width:33.38%;
		}
		.myright_humber1 {
			width:66.62%;
		}
		.myleft_humber1-1, .myright_humber1-1 {
			width:33.35%
		}
		.mycenter_humber1-1 {
			width:33.30%
		}
		.myleft_humber2 {
			width:48.1%;
		}
		.myright_humber2 {
			width:51.9%;
		}
		.myleft_humber3 {
			width:32%;
		}
		.mycenter_humber3 {
			width:16.1%;
		}
		.myright_humber3 {
			width:51.9%;
		}
		.myleft_humber4 {
			 width:74.3%;
		}
		.myright_humber4 {
			width:25.7%;
		}
		.myleft_humber5 {
			 width:54.9%;
		}
		.myright_humber5 {
			width:45.1%;
		}
		//Distributel
		.myleft_dis1 {
			width:49.65%;
		}
		.myright_dis1 {
			width:50.35%;
		}
		//Acanac
		.myleft_ac {
			width: 54.7%;
		}
		.myright_ac {
			width:45.3%;
		}
		.myleft_ac1 {
			width: 57.70%;
		}
		.myright_ac1 {
			width:42.30%;
		}
		//Arterra
		.myleft_art {
			width: 27.7%;
		}
		.myright_art {
			width: 72.3%;
		}
		//HRH
		.myleft_hrh1 {
			width: 40.35%;
		}
		.myright_hrh1 {
			width: 59.65%;
		}
		.myleft_hrh2 {
			width: 44.8%;
		}
		.myright_hrh2 {
			width: 55.2%;
		}
		//KPMG
		.myleft_kpmg {
			width: 37.55%;
		}
		.mycenter_kpmg {
			width: 29.55%;
		}
		.myright_kpmg {
			width: 32.9%;
		}
		.myleft_deciem {
			width: 62.5%;
		}
		.myright_deciem {
			width: 37.5%;
		}
	}

	.pagination {
		position: relative;
		margin-bottom:0;
		margin-top: -3px;
		background: #e6e04d;
		@include breakpoint(small only) {
			padding: 30px 0;
		}
		padding: 55px 0;
		font-family: $body-font-family;
		text-transform: uppercase;
		font-weight: 600;
		a {
			font-size: 16px;
			color: #595959;
			@include breakpoint(large down) {
				font-size: 14px;
			}
			&::before, &::after{
				content:" ";
			}
			&:hover {
				background: transparent;
				color: $primary-color;
			}
		}
		.current {
			font-size: 16px;
			display: inline-block!important; //comment this if pagination should be hide for samll screens
			@include breakpoint(large down) {
				font-size: 14px;
			}
			background: transparent;
			color: $primary-color;
		}
		.portfolio {
			img {
				width: 45px;
			}
			@include breakpoint(small only) {
				display: inline-block!important; //comment this if pagination should be hide for samll screens
			}
		}
		.pagination li {
			display: inline-block!important; //comment this if pagination should be hide for samll screens
		}
		.pagination-previous {
			@include vertical-align(15%, 50%);
		}
		.pagination-next {
			@include vertical-align(85%, 50%);
		}
		#CaseImgPrev, #CaseImgNext {
			width: 15px;
			margin-right: 10px;
			margin-left: 10px;
		}
	}//pagination

	.molson_titles, .bay_titles, .lindt_titles, .humber_titles, .portfolio_titles {
		h1,h2,h3,h4,h5,h6 {
			font-family: $header-font-family;
		}
		h1 {
			line-height: 1.3;
			padding: 30px 0;
			color: #595959;
			text-transform: capitalize;
			@include breakpoint(medium down) {
			  font-size: 1.7rem;
			}
			span {
				text-transform: lowercase;
			}
		}
		@media (max-width: 2000px) and (min-width: 1024px){
			h1{
				font-size: 2rem!important;
			}
		}
		background-color: #e6e04d;
		padding: 20px;
	}

	.humber_subtitles {
		h1,h2,h3,h4,h5,h6 {
			font-family: $header-font-family;
			color: $white;
			margin-bottom: 0;
		}
		background-color: #f05022;
		padding: 10px 0;
		margin-top: 1px;
	}

}

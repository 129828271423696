
#works {
	 overflow-x: hidden;
	.figure {
		 position: relative;
		// float: left;
		text-align: center;
		overflow: hidden;
		cursor: pointer;



	}

	.figure img {
		position: relative;
		display: block;
		// min-height: 50%;
		// max-height: 101%;
		// max-width: 100%;

	}

	.figure .figcaption {
	//	position: relative;
		padding: 2em;
		color: #fff;
		text-transform: uppercase;
		font-size: 1.25em;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		img {
			//@include vertical-align(2%, 20%);
			min-height: 100%;
			/* Safari only override */
			_::-webkit-full-page-media, _:future, :root .safari_only {
			 min-height: 50%;
			}
		}
	}

	.figure .figcaption::before,
	.figure .figcaption::after {
		pointer-events: none;
	}

	.figure .figcaption,
	.figure .figcaption > a {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

		/* Anchor will cover the whole item by default */
		/* For some effects it will show as a button */
	.figure .figcaption > a {
		z-index: 1000;
		text-indent: 200%;
		white-space: nowrap;
		font-size: 0;
		opacity: 0;
	}



		/*---------------*/
		/***** Bubba *****/
		/*---------------*/

		.figure.effect-bubba {
			background: #000000;
		}

		.figure.effect-bubba img {
			opacity: 1;
			-webkit-transition: all 0.85s;
			transition: all 0.85s;
			transform: scale(1.02);
		}

		.figure.effect-bubba:hover img {
			opacity: 0.7;
			transform: scale(1);
		}

		.figure.effect-bubba .figcaption::before,
		.figure.effect-bubba .figcaption::after {
			position: absolute;
			top: 30px;
			right: 30px;
			bottom: 30px;
			left: 30px;
			content: '';
			opacity: 0;
			-webkit-transition: opacity 0.85s, -webkit-transform 0.85s;
			transition: opacity 0.85s, transform 0.85s;
		}

		.figure.effect-bubba .figcaption::before {
			border-top: 1px solid #fff;
			border-bottom: 1px solid #fff;
			-webkit-transform: scale(0,1);
			transform: scale(0,1);
		}

		.figure.effect-bubba .figcaption::after {
			border-right: 1px solid #fff;
			border-left: 1px solid #fff;
			-webkit-transform: scale(1,0);
			transform: scale(1,0);
		}

		.figure.effect-bubba:hover .figcaption::before,
		.figure.effect-bubba:hover .figcaption::after {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
		.notbuilt {
				cursor: default;
				background: #000000;
		}

}

.awards {
	position:absolute;
	top:0;
	right:60px;
	width: 150px;
	text-align: right;

	img {
		 width:50px;
		 margin-left:15px;
		 display:inline-block !important;
		 opacity: 1 !important;
	 }
}

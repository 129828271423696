/*
* Leadership section
*/

#leadership {
	position: relative;
	background-color: #595959;

	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.3;
		padding: 60px 0;
		color: #595959;
		text-transform: capitalize;
		@include breakpoint(small only) {
  		  font-size: 1.7rem;
	    }
		@include breakpoint(medium only) {
  		  font-size: 3rem;
	    }
		@include breakpoint(xlarge only) {
			font-size: 2.9rem;
		}
		@include breakpoint(xlarge down) {
			margin-bottom: 0;
		}
		@include breakpoint(large only) {
			font-size: 2rem;
		}
	}
	p {
		line-height: 1.3;
		font-family: $body-font-family;
		@include breakpoint(xlarge down) {
			//margin-bottom: 0.5rem;
			font-size:14px;
	    }
		@include breakpoint(large only) {
		  //font-size: 20px;
			font-size:12px;
			margin-bottom: 0.3rem;
	    }

			@include breakpoint(medium down) {
				font-size: 18px !important;
			}
	}
	/*
	@media (max-width: 2000px) and (min-width: 1441px){
		p {
			font-size: 14px;
		}
	}
	@media (min-width: 2001px){
		p {
			font-size: 20px;
		}
	}*/

	img {
		width:100%;
	}

	.LeadershipTitle {
		background-color: $white;
		@media (max-width: 2000px) and (min-width: 1024px){
			h1{
				font-size: 2rem!important;
			}
		}
	}
	.MyRow {
		position: relative;
	}
	// Overlay for Headshots
	.Sign-wrapper {
		  width: 100%;
		  height: 100%;
		  overflow: hidden;
		  position: relative;
		  border: 0;
		  
		  &:hover {
			//   img {
			// 	transform: scale(1.1);
			//  }
			.Sign-overlay-content {
				opacity: 1 !important;
				transform: translateY(0px);
				transition: transform 0.5s, opacity 0.1s !important;
				z-index: 100;
			  }
		  }
		  img {
			  transition: all 2s ease;
		  }
		.Sign-overlay-content {
			width: 100%;
			height: 24% !important;
			position: absolute;
			overflow: hidden;
			top: auto;
			bottom: 0;
			left: 0;
			opacity: 0;
			display: flex;
			align-items: center;
			justify-content: space-around;
			background: rgba(250,75,19,.6) !important;
			color: white;
			text-align: center;
			transform: translateY(100%);
			transition: transform 0.5s, opacity 0.1s 0.3s !important;

			 figcaption .leaderName {
				font-size: 20px;
				font-weight: bold;
			  }
		} //.Sing-overlay-content
	}	//overlay for headshots end

	.overlayLeadership {
	  background-color: rgba(230, 80, 32, 0.8);
	  display: none;
	  z-index: 10;

	  h1, p {
		  color: $white;
	  }
	  h1 {
		  padding: 15px 0;
		  span {
			  font-size: 16px;
			  font-family: $body-font-family;
			  text-transform: uppercase;
		  }
		  @include breakpoint(medium down) {
    		  padding: 60px 0 30px 0;
	  	   }

	  		@include breakpoint(small only) {
	    		  font-size: 1.5rem;
				  line-height: 1.3;
				  margin-right: 20px;
	  	    }
	  		@include breakpoint(medium only) {
	    		  font-size: 2.4rem;
				  line-height: 1.3;
	  	    }
	  		@include breakpoint(xlarge up) {
	  			font-size: 2.2rem;
	  		}
			@include breakpoint(xlarge down) {
	  			padding: 10px 0;
				margin-top: 20px;
				font-size: 1.8rem;
	  		}
	  		@include breakpoint(large only) {
	  			font-size: 1.8rem;
				margin-top: 0;
				line-height: 0.5;
	  		}
  	}
//leadership section for mobile
	  @include breakpoint(medium down) {
		  position: relative;
		  background-color: rgba(230, 80, 32, 1);
			width:100%;
	  }
//leadership section for mobile end
	  position: absolute;
	  top:0;
	  left:0;
	  height: 100%;
	  width: 75%;
	  animation: fadein 2s;
		-moz-animation: fadein 2s; /* Firefox */
		-webkit-animation: fadein 2s; /* Safari and Chrome */
		-o-animation: fadein 2s; /* Opera */
		@keyframes fadein {
		from {
			opacity:0;
		}
		to {
			opacity:1;
		}
		}
		@-moz-keyframes fadein { /* Firefox */
		from {
			opacity:0;
		}
		to {
			opacity:1;
		}
		}
		@-webkit-keyframes fadein { /* Safari and Chrome */
		from {
			opacity:0;
		}
		to {
			opacity:1;
		}
		}
		@-o-keyframes fadein { /* Opera */
		from {
			opacity:0;
		}
		to {
			opacity: 1;
		}
		}
	  .close-button {
		  right: 2%;
		  top: 30px;
		  height: 30px;
		  line-height: 0;
		  .Xclose {
  			max-width:30px;
			max-height: 30px;
			height: 100%;

  		}
	  }
	  .rightclose {
		  right: 27%;
	  }
	  .leadershipScl {
		  max-width: 40px;
		  padding-bottom: 30px;
		  margin-right: 10px;
		  @include breakpoint(large only) {
			  max-width: 20px;
  			padding-bottom: 0;
			margin-right: 5px;
  	    }
		@include breakpoint(small only) {
			max-width: 30px;
			margin-right: 5px;
	   }
	  }
	  @media (min-width: 1438px) and (max-width: 1530px){
		  .leadershipScl {
			  max-width: 25px;
		  }
		  h1 {
			  margin-top: 20px;
			  margin-bottom:0;
		  }
	  }

	  @media (max-width: 1390px) and (min-width: 1199px){
		  .leadershipScl {
			  max-width: 20px;
		  }
	  }

		  .Myorange {
			  width: 100%;
				height: 100%;
				position: absolute;
				overflow: hidden;
				bottom: 0;
				left: 0;

			  .BioText {
				 position: relative;
	    	 top: 50%;
				 left: 90%;
				 transform: translate(-90%,-50%);
			  }

			  .BioTextR {
				 position: relative;
				 top: 50%;
				 left: 10%;
				 transform: translate(-10%,-50%);
			  }
		  }
  }//overlayLeadership

	.rightOverlay {
		right:0 !important;
		left: auto;
	}

	//leadership section for mobile
	.JeffImg, .DaveRImg, .MannyImg, .WinfieldImg, .JennImg, .MarineImg, .JosieImg {
		position: relative;
		  #JeffLife, #DaveRLife, #MannyLife, #WinfieldLife, #JennLife, #MarineLife, #JosieLife {
		  	visibility: hidden;
		  	position: absolute;
		  	top: 0;
		  	left: 0;
		  	height: 100%;
		  }
	}
.MoreRow {
	background-color: $white;
	#LeadersMore {
		h4{
			font-family: 'Open Sans', serif;
			color: $primary-color;
			font-weight: 900;
			text-transform: uppercase;
			margin-bottom: 0;
		}
		padding: 20px 0!important;
		cursor: pointer;
	}
} //MoreRow
	.Leaders-load {
		width: 23px;
	}

	//leadership section for mobile end

	.code {
		width: 15%;
		@include breakpoint(medium down) {
			width: 35%;
	   }
	}

} //Leadership section end

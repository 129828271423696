.posthero {
	font-family: $body-font-family;
}
.posttext {
	//border-bottom: 3px solid $primary-color;
	background-image: -ms-linear-gradient(top, bottom, #ffffff 0%, #e9f7f8 100%);

	/* Mozilla Firefox */
	background-image: -moz-linear-gradient(top bottom, #ffffff 0%,  #e9f7f8 100%);

	/* Opera */
	background-image: -o-linear-gradient(top bottom, #ffffff 0%, #e9f7f8 100%);

	/* Webkit (Safari/Chrome 10) */
	background-image: -webkit-gradient(linear, top, bottom, color-stop(0, #ffffff), color-stop(100, #e9f7f8));

	/* Webkit (Chrome 11+) */
	background-image: -webkit-linear-gradient(top bottom, #ffffff 0%, #e9f7f8 100%);

	/* W3C Markup */
	background-image: linear-gradient(to bottom, #ffffff 0%, #e9f7f8 100%);

	@include breakpoint(medium down) {
		background: $white;
		padding-top:90px;
	}

		h1,h2,h3,h4,h5,h6 {
			font-family: $header-font-family;
			color: #595959;
			padding-bottom: 25px;
		}

		h1 {
			line-height: 1.3;
			padding: 25px 0;
			//color: $primary-color;
			text-transform: capitalize;
			@include breakpoint(large down) {
				font-size: 1.7rem;
				padding: 15px 0;
				margin-bottom: 0;
			}
		}
		@media (max-width: 2000px) and (min-width: 1199px){
			h1{
				font-size: 2rem!important;
			}
		}
		.sub {
			font-weight: 700;
			font-family: $body-font-family;
			color: #595959;
			@include breakpoint(large only) {
				font-size: 14px;
			}
		}
		/*h3 {
			@include breakpoint(large only) {
				font-size: 1.85rem;
			}

			padding-bottom:25px;
		}*/


		p, li {
			color: #595959;
			line-height: 1.5;
			font-size: 18px;
			font-family: $body-font-family;
			/*@include breakpoint(medium down) {
			  font-size: 18px;
			}*/
		}
		/*@media (max-width: 2000px) and (min-width: 1024px){
			p, li, a {
				font-size: 16px!important;
			}
			h3 {
				font-size: 18px;
			}
		}*/
		.topRow {
			font-weight: 700;
			margin-bottom: 0;
			padding: 100px 0 30px 0;

			@include breakpoint(small only) {
				padding-top: 50px;
			}
		}

		//ShareIcons
		.ShareIcons {
			width:50px;
			padding-left: 25px;
			transition: all 1s ease;
			&:hover {
				transform: scale(1.1);
			}
		}
    //Share Icon on left slides
		.ShareWrap {
			.ShareIcons {
					padding-right: 25px;
					padding-bottom: 25px;
					padding-left:0;
			}
		}
		.blogtext {
			margin-bottom: 150px;
			margin-top:50px;
			@include breakpoint(medium down) {
				margin-bottom: 50px;
			}
		}

		.boltnews {
			max-width: 50px;
			margin-bottom:100px;
			@include breakpoint(medium down) {
				margin-bottom: 50px;
			}
		}

		.blogquot {
			font-size: 20px;
			font-weight: 700;
			font-family: $header-font-family;
			padding: 30px;
		}


		.pagination {
			position: relative;
			//margin-top: 100px;
			margin-bottom:0;
			background: #28acbe;
			@include breakpoint(small only) {
				padding: 30px 0;
			}
			@include breakpoint(medium down) {
				//margin-top: 50px;
			}
			padding: 15px 0;
			font-family: $body-font-family;
			text-transform: uppercase;
			font-weight: 600;
			a {
				font-size: 16px;
				color: $white;
				@include breakpoint(large down) {
					font-size: 14px;
				}
				&::before, &::after{
					content:" ";
				}
				&:hover {
					background: transparent;
					color: #e6e04d;
				}
			}
			.current {
				font-size: 16px;
				display: inline-block!important; //comment this if pagination should be hide for samll screens
				@include breakpoint(large down) {
					font-size: 14px;
				}
				background: transparent;
				color: #e6e04d;
			}
			.portfolio {
				img {
					width: 25px;
				}
				@include breakpoint(small only) {
					display: inline-block!important; //comment this if pagination should be hide for samll screens
				}
			}
			.pagination li {
				display: inline-block!important; //comment this if pagination should be hide for samll screens
			}
			.pagination-previous {
				@include vertical-align(5%, 50%);
			}
			.pagination-next {
				@include vertical-align(95%, 50%);
			}
			#BlogImgPrev, #BlogImgNext {
				width: 15px;
				margin-right: 10px;
				margin-left: 10px;
			}
		}

		.Author {
			margin-bottom: 30px;
			span {
				text-transform: uppercase;
			}
		}

		.MobileShare {
			margin-top: 30px;
		}
		.mobileArrow {
			width: 35px;
		}

		.Anotherlink {
			font-weight: bold;
			color: #595959;
			&:hover {
				color: $primary-color;
			}
		}
		.postvideo {
			margin:30px 0;
		}

		.LetsMeetImg {
			img {
				width: 100%;
			}
			p{
				background-color: #f4f6f6;
				padding: 20px;
			}
		}
		.mypadding {
			padding-bottom: 80px;
		}
		.logo-bw {
			width: 350px;
			@include breakpoint(large only) {
				width: 250px;
			}
			@include breakpoint(medium down) {
				width: 150px;
			}
			margin-bottom: 50px;
		}
		.mypdf {
			img {
				max-width: 30px;
			}

		}
		&.afterhero {
			@include breakpoint(small only) {
			padding-top:0;
		}
	}

		//new staff post css

		.hoverphoto {
			position: relative;
			//margin-top: -120px;
			margin-bottom: 150px;
			@include breakpoint(medium down) {
				margin-bottom: 50px;
				//margin-top: -20px;
			}

			@media (max-width: 1190px) {
				.person {
					display: none;
				}

				#key {
					position: absolute;
					left: 0;
					bottom: 0;
					z-index: 5;
					width: 28%;
				}

				.instructions {
					display: none;
				}
			}

			@media (min-width: 1191px) {
				.person:hover, .person:focus {
					opacity: .75;
				}

				#key {
					display: none;
				}
			}

			#chantelle {
				position: absolute;
				top: 565px;
				left: 768px;
				z-index: 3;
				cursor: pointer;
			}

			#dasha {
				position: absolute;
				top: 578px;
				left: 548px;
				z-index: 3;
				cursor: pointer;
			}

			#ruthie {
				position: absolute;
				top: 850px;
				left: 600px;
				z-index: 3;
				cursor: pointer;
			}

			#rebecca {
				position: absolute;
				top: 900px;
				left: 397px;
				z-index: 4;
				cursor: pointer;
			}

			#steve {
				position: absolute;
				top: 883px;
				left: 722px;
				z-index: 4;
				cursor: pointer;
			}

			#beth {
				position: absolute;
				top: 798px;
				left: 195px;
				z-index: 4;
				cursor: pointer;
			}

			#kyle {
				position: absolute;
				top: 809px;
				left: 725px;
				z-index: 3;
				cursor: pointer;
			}

			#dave {
				position: absolute;
				top: 530px;
				left: 462px;
				z-index: 2;
				cursor: pointer;
			}

			#mike {
				position: absolute;
				top: 516px;
				left: 330px;
				z-index: 2;
				cursor: pointer;
			}

			#michael {
				position: absolute;
				top: 547px;
				left: 668px;
				z-index: 2;
				cursor: pointer;
			}

			#malik {
				position: absolute;
				top: 822px;
				left: 389px;
				z-index: 3;
				cursor: pointer;
			}

			#jennifer {
				position: absolute;
				top: 592px;
				left: 202px;
				z-index: 3;
				cursor: pointer;
			}
		}
				// img {
				// 	max-width: 3%;
				// 	margin-top: -20px;
				//
				// }
		//new staff post css end
}

.privacy-policy {
	//border-bottom: 3px solid $primary-color;
	background-image: -ms-linear-gradient(top, bottom, #ffffff 0%, #e9f7f8 100%);
	/* Mozilla Firefox */
	background-image: -moz-linear-gradient(top bottom, #ffffff 0%,  #e9f7f8 100%);
	/* Opera */
	background-image: -o-linear-gradient(top bottom, #ffffff 0%, #e9f7f8 100%);
	/* Webkit (Safari/Chrome 10) */
	background-image: -webkit-gradient(linear, top, bottom, color-stop(0, #ffffff), color-stop(100, #e9f7f8));
	/* Webkit (Chrome 11+) */
	background-image: -webkit-linear-gradient(top bottom, #ffffff 0%, #e9f7f8 100%);
	/* W3C Markup */
	background-image: linear-gradient(to bottom, #ffffff 0%, #e9f7f8 100%);}

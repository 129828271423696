/*
* Second (Central) section style
*/
#central {
	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}
	h1 {
		line-height: 1.3;
		padding: 0 0 15px 0;
		color: #595959;
		text-transform: capitalize;
		@include breakpoint(medium down) {
		  font-size: 1.7rem;
		}
	}
	@media (max-width: 2000px) and (min-width: 1024px){
		h1{
			font-size: 2rem!important;
		}
		p{
			font-size: 16px!important;
		}
	}
	p {
		line-height: 1.3;
		font-size: 18px;
		font-family: $body-font-family;
	}
    position: relative;
	// height: 90vh;
	background: $white;
	-moz-box-shadow:    0px 0 200px 50px rgba(0, 0, 0, 0.10);
   	-webkit-box-shadow: 0px 0 200px 50px rgba(0, 0, 0, 0.10);
   	box-shadow:         0px 0 200px 50px rgba(0, 0, 0, 0.10);

	.gallerybtn {
	   padding: 1em 3em;
	   line-height: 1.3;
	   font-size: 16px;
	   font-family: $body-font-family;
	   @include breakpoint(small only) {
		 font-size: 14px;
		 width: 100%;
		 //margin-top: -40px;
	   }
	   color: #595959;
	   text-transform: uppercase;
	   font-weight: 800;
	   border:1.5px solid #595959;
	   transition: all 0.5s ease;
	   &:hover {
		   background: #595959;
		   color: #ffffff;
	   }
	}
//slider for desktop
.sliderCentral {
	 height: 100% !important;
	 margin-left: 0;
	//background: #595959;/////!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

	li {
		 display: block;
		 overflow: hidden;
		 vertical-align: middle;

		 

		 #myVideo {
			position: absolute;
			height: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		  }
		  .video-wrap {
			position: relative; 
			padding-bottom: 112.5%; 
			height: 100%;
			overflow: hidden;
			width: auto;
		}	

		 .backgroungImg {
			 background-position: center;
			 height: 100%;
			 position: fixed;
			 @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
				 position: absolute!important;
			 }
			 left: 50%;
			 bottom: 0;

			 @include breakpoint(medium down) {
					 position:relative;
					 left:0;
			 }
		 }
		 #firstSlide29 {
			 background-image: url('../img/spotlight/SpinMaster_Spotlight.gif');
			 }
		 #firstSlide28 {
			 background-image: url('../img/spotlight/MillerGroup_Spotlight.gif');
			 }
 		 #firstSlide25 {
 			 background-image: url('../img/spotlight/Spotlight-hm.gif');
 		 	}
 		 #firstSlide24 {
 			 background-image: url('../img/spotlight/Spotlight-Deciem.gif');
 		 	}
 		 #firstSlide23 {
 			 background-image: url('../img/spotlight/Spotlight-Dior.gif');
 		 	}
  	#firstSlide22 {
  	 background-image: url('../img/spotlight/Arterra_Spotlight_New.gif');
  		 	}
 		#firstSlide21 {
 			 background-image: url('../img/spotlight/POD_Spotlight_New.gif');
 		 	}
  	#firstSlide20 {
 		 background-image: url('../img/spotlight/Bask_Spotlight.jpg');
  		}

	 #firstSlide19 {
	 background-image: url('../img/spotlight/TwoBears_Spotlight.jpg');
	 	}
 		 #firstSlide18 {
	 background-image: url('../img/spotlight/HRH_Spotlight.gif');
	 	}

			#firstSlide17 {
			 background-image: url('../img/spotlight/Refine_Spotlight.jpg');
			}

		 #firstSlide16 {
			 background-image: url('../img/spotlight/nike-giannis-zoomfreak-desktop.gif');
		 	}

		 #firstSlide15 {
		 	background-image: url('../img/spotlight/Spotlight-nikeadapt-desktop.jpg');
		 	}

		 #firstSlide14 {
			background-image: url('../img/spotlight/Spotlight-BrandWarriors-desktop.gif');
			}


		 	#firstSlide13 {
			 background-image: url('../img/spotlight/Canada-Goose-desktop.gif');
	 		 }

		 	#firstSlide12 {
		 			 background-image: url('../img/spotlight/scentral-station-desktop.gif');
		 			 /*background-position: central;
		 			 height: 100%;
		 			 position: fixed;
		 			 @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		 				 position: absolute!important;
		 			 }
		 			 left: 50%;
		 			 bottom: 0;*/
		 		 }
		 #firstSlide11 {
			 background-image: url('../img/spotlight/Distributel_Banners.gif');
			 /*background-position: 45% top;
			 height: 100%;
			 position: fixed;
			 @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
				 position: absolute!important;
			 }
			 left: 50%;
			 bottom: 0;*/
		 }
		 #firstSlide10 {
			  background-image: url('../img/spotlight/madjack_animation.gif');
		 		/*background-position: 45% top;
		 		height: 100%;
		 		position: fixed;

				@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
					position: absolute!important;
				}
				left: 50%;
				bottom: 0;*/
			}

		 #firstSlide9 {
			  background-image: url('../img/spotlight/Nonna_Outdoor.jpg');
		 		/*background-position: 45% top;
		 		height: 100%;
		 		position: fixed;

				@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
					position: absolute!important;
				}
				left: 50%;
				bottom: 0;*/
			}

		 	#firstSlide8 {
		 		background-image: url('../img/spotlight/Scout_Animation_Desktop.gif');
		 		/*background-position: 45% top;
		   	height: 100%;
		  	position: fixed;
		  	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
					position: absolute!important;
				}
				left: 50%;
				bottom: 0;*/
			}

		 #firstSlide7 {
			background-image: url('../img/spotlight/journey1.jpg');
			/*background-position: 45% top;
  		height: 100%;
 			position: fixed;
 			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
 			  position: absolute!important;
 			}
  			left: 50%;
  			bottom: 0;*/
		 }

		 #firstSlide6 {
			 background-image: url('../img/spotlight/tania.jpg');
  		/*height: 100%;
 			position: fixed;
 			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
 			  position: absolute!important;
 			}
  			left: 50%;
  			bottom: 0;*/
		 }
		 // @media (max-width: 2000px) and (min-width: 1024px){
			//  #gallerybtn{
			// 	 font-size: 16px!important;
			//  }
		 // }

		 #firstSlide4 {
 			background-image: url('../img/spotlight/nike-nba.jpg');
 			/*height: 100%;
			position: fixed;
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			  position: absolute!important;
			}
 			left: 50%;
 			bottom: 0;*/
 		}

		 #firstSlide3 {
 			background-image: url('../img/spotlight/Camo.jpg');
 			/*height: 100%;
			position: fixed;
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			  position: absolute!important;
			}
 			left: 50%;
 			bottom: 0;*/
 		}

		 #firstSlide2 {
 			background-image: url('../img/spotlight/Acanac_Screen.jpg');
 			/*height: 100%;
			position: fixed;
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			  position: absolute!important;
			}
 			left: 50%;
 			bottom: 0;*/
 		}

		 #firstSlide1 {
 		    background-image: url('../img/spotlight/Nike_Brahaus.jpg');
 			/*height: 100%;
 			position: fixed;
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			  position: absolute!important;
			}
 			left: 50%;
 			bottom: 0;*/
 		}
		#firstSlide {
			background-image: url('../img/spotlight/tarots_BM.jpg');
			/*height: 100%;
			position: fixed;
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			  position: absolute!important;
			}
			left: 50%;
			bottom: 0;*/
		}
		#secondSlide {
			background-image: url('../img/spotlight/HavanaNight.jpg');
			/*height: 100%;
			position: fixed;
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			  position: absolute!important;
			}
			left: 50%;
			bottom: 0;*/
		}
		#thirdSlide {
			background-image: url('../img/spotlight/Render_Cheers.jpg');
			/*height: 100%;
			position: fixed;
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			  position: absolute!important;
			}
			left: 50%;
			bottom: 0;*/
		}
		.spotlightwrap {
			position: relative;
			height: 100%;
			width: 100%;
		}
		.parentImg {
			.backgroungImg {
				background-repeat: no-repeat;
				background-position: center center;
				background-size: cover;
			} //.backgroungImg
		}
		.topRow {
			font-weight: 700;
			 padding-bottom: 30px;
			 @include breakpoint(medium down) {
	 	 			padding-bottom: 0;
	 		}
		}
		p {
			color: #28acbe;
		}
	}//li

	.textCenter {
		position: relative;
		padding:120px 0;
		// @include vertical-align(49%, 51%);

		@include breakpoint(large down)  {
					padding:100px 0;
		}

		@include breakpoint(medium down)  {
			padding:60px 0;
		}

		@include breakpoint(small only)  {
					padding:60px 0 10px;
				}
	}


}//slider end

//to place controls into appropriate place, controls, viewport and wrapper need to be styled after slider
.bx-wrapper .bx-controls-direction a {
	position: absolute;
   top: 89.5%;
   z-index: 51;
   -webkit-transform: translateY( -88%);
   -khtml-transform: translateY(-88%);
   -ms-transform: translateY(-88%);
   transform: translateY(-88%);
    padding-left: 4.01%;
   @include breakpoint(medium down) {
			top: 32%;
			padding-left: 40px;
	 }
	@include breakpoint(small only) {
		 top: 210px;
	}
}
.bx-prev {
	// left:18%;
	left:46%;
	height: 100px;
	-webkit-transform: translateX( -18%);
    -khtml-transform: translateX(-18%);
    -ms-transform: translateX(-18%);
    transform: translateX(-18%);
	// background: url('../img/ctl_controls_prev.svg') no-repeat;
	background: url('../img/controls_prev_spotlight.svg') no-repeat;
	background-color: rgba(0, 0, 0, 0.52);
	background-position-x: center;

	@include breakpoint(medium down) {
			position: absolute;
			height: 50px;
			//top: 50%;
			left: 20px;
			//margin-right: -5%;
			-webkit-transform: translate(-5%,-50%);
			-khtml-transform: translate(-5%,-50%);
			-ms-transform: translate(-5%,-50%);
			transform: translate(-5%,-50%);
			width: 30px;
			//background: rgba(0,0,0,.52);
			padding: 0;

		}


}
.bx-next {
	// right:68.5%;
	right:46%;
    height: 100px;
	-webkit-transform: translateX( -68%);
    -khtml-transform: translateX(-68%);
    -ms-transform: translateX(-68%);
    transform: translateX(-68%);
	// background: url('../img/ctl_controls_next.svg') no-repeat;
	background: url('../img/controls_next_spotlight.svg') no-repeat;

	background-color: rgba(0, 0, 0, 0.52);
	background-position-x: center;

@include breakpoint(medium down)  {
	position: absolute;
	 height: 50px;
//top: 50%;
right: 20px;
//margin-right: -95%;
-webkit-transform: translate(-95%,-50%);
-khtml-transform: translate(-95%,-50%);
-ms-transform: translate(-95%,-50%);
transform: translate(-95%,-50%);
width: 30px;
//background: rgba(0,0,0,.52);
padding: 0;
}
}
@media (max-width: 1024px) {
	.bx-wrapper .bx-controls-direction a {

	}
	.bx-prev {
		// left:15.5%;
		//left:44%;
	}
	.bx-next {
		// right:65.5%;
		//right:44%;
	}
}


.bx-wrapper .bx-pager {
	position: absolute;
	top: 93%;
	right: 26%;
	-webkit-transform: translate(-26%, -92%);
	-khtml-transform: translate(-26%, -92%);
	-ms-transform: translate(-26%, -92%);
	transform: translate(-26%, -92%);
	z-index:50;
		//left: 200px;
		.bx-default-pager a {
			background: transparent!important;
			border: 1px solid #666666!important;
			.active {
				background: #666666!important;
			}
		}
}

.bx-viewport, .bx-wrapper{
	position:relative;
	width:100%;
	// height:100% !important;
	// top:0;
	// left:0;
	border: none !important;
	margin-bottom: 0px;
}

.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto, .Iconsocial, .buttonWrap, .sliderCentral, .bx-controls-direction {
	@include breakpoint(large down) {
 			//display: none;
	}
}

// Mobile slider
.sliderCentralSmall {
	@include breakpoint(xlarge up) {
 			display: none;
	}
	margin-left: 0;
	p {
		color: #28acbe;
	}
	.topRow {
		padding-top: 40px;
    	padding-bottom: 40px;
		@include breakpoint(medium down) {
	 			padding-bottom: 0;
		}
	}
	.bottomRow {
		padding-bottom: 40px;
	}
} //.sliderCentralSmall

.sliderImage {
	position: relative;
	.Smallprev-cont {
		@include vertical-align(5%, 50%);
		width: 30px;
		background: rgba(0, 0, 0, 0.52);
		padding: 5px;
	}
	.Smallnext-cont {
		@include vertical-align(95%, 50%);
		width: 30px;
		background: rgba(0, 0, 0, 0.52);
		padding: 5px;
	}
}
// Mobile slider end
	.spotlight-word {
		position: relative;

		.Spotlight {
			width: 450px;
			padding-bottom: 20px;
			margin-top:-20px;
			@include breakpoint(medium down) {
		 			width: 200px;
			}
		}
		@media (max-width: 2000px) and (min-width: 1024px){
			.Spotlight {
				width: 380px!important;
			}
		}
		.under-spt {
			position: absolute;
			top: 20px;
			left: 0;
			z-index: -1;
		}
	} //.spotlight-word
	.spotlight-word-sm {
		position: relative;

		.Spotlight {
			width: 450px;
			padding-bottom: 20px;
			margin-top:-20px;
			@include breakpoint(medium down) {
		 			width: 200px;
			}
		}
		@media (max-width: 2000px) and (min-width: 1024px){
			.Spotlight {
				width: 380px!important;
			}
		}
		.under-spt {
			position: absolute;
			top: 60px;
			left: 0;
			z-index: -1;
		}
	} //.spotlight-word-sm
	// camo link
	#audio-text, #audio-text-sm, #audio-text-p, #audio-text-p-sm {
		font-weight:600;
		color: #28acbe;
		// margin-left: 25px;
		// &:before {
		// 	content:'';
		// 	background: url("../img/spotlight/radio_icon_1.svg") no-repeat;
		// 	height: 20px;
		// 	width: 25px;
		// 	margin-left:-25px;
		// 	margin-top:0;
		// 	position: absolute;
		// }
	}
	#camo-pause, #camo-pause-sm, #audio-text-p, #audio-text-p-sm {
		display: none;
	}
	.radio-icon {
		width: 30px;
		cursor: pointer;
	}
	#camo-pause-sm {
			margin-top:-100px;
	}
	#camo-play-sm {
			margin-top:-50px;
	}
	#camo-pause {
			margin-top:-14px;
	}
	#camo-play {
			margin-top:-7px;
	}

	#md-pause, #md-pause-sm {
		display: none;
	}
	.video-icon {
		width: 30px;
		cursor: pointer;
	}
	#md-pause-sm {
			margin-top:-5px;
	}
	#md-play-sm {
			margin-top:-5px;
	}
	#md-pause {
			margin-top:-6px;
	}
	#md-play {
			margin-top:-6px;
	}

	#spotlight-bay {
		position:relative;
		 // height: 100vh;
		width: 50vw;
		// @include breakpoint(xlarge down) {
		// 		height: 120vh;
		// }
		//IE style

		@include breakpoint(medium down) {
		width:100vw;
	}
		@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			.responsive-embed.widescreen, .flex-video.widescreen {
				  position: absolute!important;
				   left:0!important;
				   top:0!important;
				   right:0!important;
				   bottom:0!important;
				   width:100vw!important;
				   height:100vh!important;
				   padding:0;
			  }
			  #spotlight-bayV{
				   height: 130%!important;
			  }
		}
		.responsive-embed.widescreen, .flex-video.widescreen {
			// padding-bottom: 200%;
			// margin-bottom:-100px;
			// position: absolute;
			   top: 0%;
			  // left: 0%;
			  min-width: 100%;
			  min-height: 100%;
			  width: auto;
			  // height: auto;
			  z-index: 100;
			  height: 100%;
				position: fixed;
				left: 50%;
				bottom: 0;

@include breakpoint(medium down) {
	position: relative;
	left:0;
	padding-bottom:82%;
}


			  // transform: translate(-50%, -50%);
			  #spotlight-bayV{
				   width: auto;
				   height: 100%;
			  }
			  @media (min-width: 1600px) {
				  #spotlight-bayV{
    				   height: 105%;
    			  }
	  		}
		}
	}
	#spotlight-bay-mob {
		.responsive-embed.widescreen, .flex-video.widescreen {
			padding-bottom: 82%;
			margin-bottom:0;
			#spotlight-bayV-mob {
				top: -1%;
			}
		}
	}

}


#thingsWeDo {
	color: #595959;

	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}
	h1 {
		font-size:1.6rem;
		padding-bottom:1rem;
	}
	h2 {
		font-size:1.6rem;
		line-height: 1.8;
		padding:.5rem 0;

		@include breakpoint(small down) {
			font-size:1.25rem;
		}
		@media (max-height: 420px) {
			font-size:1.25rem;
		}
	}
	/*@media (max-width: 2000px) and (min-width: 1024px){
		h1{
			font-size: 2rem!important;
		}
		p{
			font-size: 16px!important;
		}
	}*/
	p {
		line-height: 1.8;
		font-size: 18px;
		font-family: $body-font-family;
		@include breakpoint(large only) {
			font-size: 18px;
		}
	}

	.thingsTxt {
		padding: 4rem 0;

		span {
			font-weight:bold;
			text-transform: uppercase;
		}
	}
	.orange {
		background:$primary-color;
		color:white !important;
	}

	.howWeWork {
		padding:6rem 0;
		background-image: url(../img/ArriveTogether-new.svg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 95% 95%;
	}

.thingsWrap {
	border-top:1px solid #595959;
	padding: 4rem 0 5rem;

	h1 {
			color:$primary-color;
		}

		@include breakpoint(medium down) {
			padding-bottom:0;
		}
	}
	.thingsBox {
		background: black;
		position:relative;
		margin: 10px;
		text-align:center;

		@include breakpoint(medium down) {
			margin: 0;
	   }

		&:hover {
			//background: $primary-color;
			-webkit-transition: all 1s ease-in-out;
			transition: all .8s ease-in-out;
			background-blend-mode: multiply;
			background-size: cover;
			opacity: .85;

			a {
				color: $primary-color !important;
				transition: all .8s ease-in-out;
			}

			img{
				//opacity: 0;
				transition: all 1s ease-in-out;
				}
			}
		.title {
			@include vertical-align(50%, 50%);
			font-weight:bold;
			color:white;
			font-size:1.55rem;
			line-height: 1.05;
			text-transform: uppercase;

			a {
				color: $white;
				&:hover {
					color: $primary-color;
				}
			}
		}
	}

	.branding{
		&:hover {
			//background-image: url(../img/hp-branding.png);
		}
	}
		.innovation{
			&:hover {
				//background-image: url(../img/hp-innovation.png);
			}
		}
			.physical{
				&:hover {
					//background-image: url(../img/hp-physical-exp.png);
				}
			}
				.digital{
					&:hover {
						//background-image: url(../img/hp-digital.png);
					}
				}
}

#instafeed{
  list-style:none;
	margin:0;

	img{
	  max-width:100%;
	  max-height:100%;
	}

	.insta-img {
		background-size: cover;
		position:relative;
	}
}

/*.instaMask {
	position:absolute;
	width:100%;
	height:100%;
}*/

//#central

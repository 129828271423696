#WhatArrive {
	margin-top: -20px;
	overflow-x: hidden;
		#WhatWeDo {
			@include cover-background('../img/WhatWeDo.jpg');

			position: relative;
			// background-attachment: fixed;
			//height:100%;
			h1,h2,h3,h4,h5,h6 {
				font-family: $header-font-family;
			}

			h1 {
				line-height: 1.3;
				padding: 30px 0;
				 color: #e6e04d;
				@include breakpoint(medium down) {
				  font-size: 1.7rem;
				}
			}
			p {
				line-height: 1.3;
				font-size: 18px;
				font-family: $body-font-family;
				color: $white;
				@include breakpoint(medium down) {
				  font-size: 14px;
				}
			}
			@media (max-width: 2000px) and (min-width: 1024px){
				h1{
					font-size: 2rem!important;
				}
				p{
					font-size: 16px!important;
				}
				a {
					font-size: 1rem;
				}
			}
			.topRow {
				font-weight: 700;
				font-size: 14px;
				margin-bottom: 0;
				// padding-top: 80px;
			}
			.whatimagewrap {
				position: relative;
				.whatImg {
					z-index: 0;
					@include breakpoint(large only) {
					  @include vertical-align(0%, -200%);
					  left: 20px;
				  }
				}
			} //whata image wrap

			.coffeeimagewrap {
				position: relative;
				.coffeeImg {
					z-index: 10;
					  left: -20px;
					  @include breakpoint(large only) {
	  					@include vertical-align(0%, -200%);

	  				}
				}
			} //what image wrap
			@media (min-width: 2001px) {
				.coffeeImg {
					@include vertical-align(0%, -100%);
				}
				.whatImg {
					@include vertical-align(0%, -100%);
				}
			}
			@media (max-width: 2000px) and (min-width: 1551px){
				.coffeeImg {
					@include vertical-align(0%, -105%);
				}
				.whatImg {
					@include vertical-align(0%, -105%);
				}
			}
			@media (max-width: 1550px) and (min-width: 1201px){
				.coffeeImg {
					@include vertical-align(0%, -145%);
				}
				.whatImg {
					@include vertical-align(0%, -145%);
				}
			}

			.TextAlign {
				padding:0 50px;
				@include breakpoint(medium down) {
					padding:0 15px;

				}
				.Myunderline {
					color: #e6e04d;
				}
				a{
					position: relative;
					font-family: $body-font-family;
					font-weight: 600;
					color: $white;
					font-size: 1.1rem;
					@include breakpoint(medium down) {
					  font-size: 14px;
					  line-height: 1.8rem;
					  font-weight: 300;
					}
					line-height: 2.2rem;
					transition: all 1.5s ease;
					&:hover {
						color: #e6e04d;
					}
				} // a
			}
			.SlideHeaderAlign {
				@include breakpoint(medium down) {
					padding: 80px 0 0 15px;
				}
				padding: 210px 50px 0 50px;
			}
			@media (max-width: 2000px) and (min-width: 1024px){
				.SlideHeaderAlign {
					padding: 160px 50px 0 50px!important;
				}
				#Work {
					margin: 60px 0 130px 0!important;
				}
			}
			.bx-wrapper {
				padding-bottom: 0;
				.bx-controls-direction a {
					margin-top: 80px!important;
					@include breakpoint(medium down) {
						display: none;
					}
				}
				.bx-pager {
					//bottom: 30px;
					@include vertical-align(-10%, 100%);
					left: -33%;
					@include breakpoint(large only) {
						left: -30%;
					}
					@include breakpoint(medium down) {
						display: none;
					}
				}
				.bx-pager.bx-default-pager a {
					background: transparent!important;
					border:1px solid $white!important;
					&.active {
						background: $white!important;
					}
				}

				.bx-viewport {
					overflow: visible!important;
				}
			} //bx-wrapper

			#Work {
				p {
					margin-bottom: 0;
					transition: all 0.5s ease;
				}
				@include breakpoint(medium down) {
					margin: 20px 0 50px 0;
				}
				margin: 60px 0 180px 0;
				padding: 0.85em 4em;
				font-family: $body-font-family;
				//color: $white;
				text-transform: uppercase;
				font-size: 14px;
				font-weight: 600;
				border:1.5px solid $white;
				transition: all 0.5s ease;
				&:hover {
					background: #ffffff;
					p{
						color: #595959;
					}


				}
			}

		}//WhatWeDo

		#ArriveTogether {
			@include cover-background('../img/arrivetogether.jpg');
			h1,h2,h3,h4,h5,h6 {
				font-family: $header-font-family;
			}

			h1 {
				line-height: 1.3;
				padding: 30px 0;
				color: #28acbe;
				text-transform: capitalize;
				@include breakpoint(medium down) {
				  font-size: 1.7rem;
				}
			}
			p {

				color: #595959;
				line-height: 1.3;
				font-size: 18px;
				font-family: $body-font-family;
				@include breakpoint(medium down) {
				  font-size: 14px;
				}
			}

			@media (max-width: 2000px) and (min-width: 1024px){
				h1{
					font-size: 2rem!important;
				}
				p{
					font-size: 16px!important;
				}
				.Arrive {
					width: 450px!important;
				}
			}

			.topRow {
				font-weight: 700;
				margin-bottom: 0;
				// padding-top: 80px;
			}
			.WhitetextAlign {
					@include breakpoint(medium down) {
						padding: 0;
					}
				padding:0 100px;
			}
			.HeadertextAlign {
				@include breakpoint(medium down) {
					padding: 0;
				}
				padding: 0 100px;
			}

			#toptracks {
				background-image: url('../img/toptracks.svg');
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				height: 100px;
				margin: 50px auto;
				@include breakpoint(medium down) {
					margin: 0 auto;
				}
			}
			#bottomtracks {
				background-image: url('../img/bottomtracks.svg');
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				height: 100px;
				margin: 50px auto;
				@include breakpoint(medium down) {
					margin: 0 auto;
				}
			}

			.Arrive {
				width: 550px;
				@include breakpoint(medium down) {
			 			width: 400px;
				}
			}

		}//Arrive togethe end
}

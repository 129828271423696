/*
* Mixins
*/
@mixin vertical-align($x, $y) {
 position: absolute;
 top: $y;
 left: $x;
 margin-right: -$x;
 -webkit-transform: translate(-$x, -$y);
 -khtml-transform: translate(-$x, -$y);
 -moz-transform: translate(-$x, -$y);
 -ms-transform: translate(-$x, -$y);
 transform: translate(-$x, -$y);
}

@mixin cover-background($img-uri) {
  background-image: url($img-uri);
  background-size: cover;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  background-position: center;
}

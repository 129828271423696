@font-face {
    font-family: "Trade Gothic LT W04 Light";
    src: url("../fonts/1462027/ab88c69c-d345-4c07-bf49-c0cc984095fa.eot?#iefix");
    src: url("../fonts/1462027/ab88c69c-d345-4c07-bf49-c0cc984095fa.eot?#iefix") format("eot"), url("../fonts/1462027/6de16bd6-1e13-400f-80e8-8f5c69d8a2b8.woff2") format("woff2"), url("../fonts/1462027/73c7abdc-45a3-42a3-a1af-f07d8a348917.woff") format("woff"), url("../fonts/1462027/73494c38-422a-4bae-9e2c-b86e7703c1b3.ttf") format("truetype"), url("../fonts/1462027/b84e9482-10dd-4d9d-8ef2-539fbc5eb441.svg#b84e9482-10dd-4d9d-8ef2-539fbc5eb441") format("svg")
}
@font-face {
    font-family: "Trade Gothic LT W04 Bold";
    src: url("../fonts/1462035/519a7aea-4569-4f88-92ce-6545742efb3b.eot?#iefix");
    src: url("../fonts/1462035/519a7aea-4569-4f88-92ce-6545742efb3b.eot?#iefix") format("eot"), url("../fonts/1462035/7cf07212-d52a-4cf9-9983-4942ecc34b0d.woff2") format("woff2"), url("../fonts/1462035/81ca1fb3-a435-4db8-b09c-f74e270eec60.woff") format("woff"), url("../fonts/1462035/28a5f1a0-5ec3-4aae-a58e-2453166d7918.ttf") format("truetype"), url("../fonts/1462035/11be2b3f-f734-422c-bdb7-a4f797bfa3f3.svg#11be2b3f-f734-422c-bdb7-a4f797bfa3f3") format("svg")
}
@font-face {
    font-family: "Trade Gothic LT W04 Bd_1463660";
    src: url("../fonts/1463660/b435f232-793c-46e0-a59b-17cc94c45d36.eot?#iefix");
    src: url("../fonts/1463660/b435f232-793c-46e0-a59b-17cc94c45d36.eot?#iefix") format("eot"), url("../fonts/1463660/a577e9b9-e095-472c-a1b1-11b207e5e9fc.woff2") format("woff2"), url("../fonts/1463660/9043fcee-4909-4a14-877e-9fdca9788e08.woff") format("woff"), url("../fonts/1463660/6f9ae21c-4b61-4107-b9bb-f463027dbec3.ttf") format("truetype"), url("../fonts/1463660/3325cb6b-5146-426b-9c48-11fea7a7ea3a.svg#3325cb6b-5146-426b-9c48-11fea7a7ea3a") format("svg")
}

.sxsw-central {
	background: url(../img/newsletter-sxsw/orange-bkg.png);
    min-height: 100vh;
    background-size: cover;

    @include breakpoint(small down) {
        height:auto;
    }

    .sxsw-title  {
        width:100%;
          font-size:3rem;
          padding:2rem 0;
          font-family: Trade Gothic LT W04 Bold;
          background:url(../img/newsletter-sxsw/white-title-bkg.png);
          background-size: cover;
          background-position: center bottom;
          background-repeat: no-repeat;
          text-align: center;
          color: $primary-color;
          position:fixed;
          z-index:100;

          @include breakpoint(small down) {
              font-size:1.5rem;
              padding:1rem 0;
              position:relative;
          }

          .blue {
              color:#00b5de;
          }
    }

    .sxsw-slider  li {
        padding:1em;
    }

    .sxsw-box {
        max-width:90rem;
        margin-top:3rem;
        background: url(../img/newsletter-sxsw/box-black-bkg.png) right bottom no-repeat;
        color:white;
        position:relative;

        @include breakpoint(small down) {
					//padding:1rem;
            background: url(../img/newsletter-sxsw/box-black-bkg-v.png) 0 bottom no-repeat;
        }

        .bx-controls-direction {
          display:none;
        }

        ul.ww-slider , ul.live-slider, ul.yt-slider {
          margin:0;

          li {
            padding:0;
          }

          img {
            width:100%;
          }

          .bx-controls-direction {
            display:none;
          }
        }

        .sxsw-img {
            img {  width:100%;
              }
            }


   .image-overlay-content .overlayText {
     top:70%;
   }

		.sxsw-text {
			padding:2rem 4rem;

			@include breakpoint(medium down) {
			padding:2rem 1rem 2rem;
			}

			p {
			font-size:1.15rem;
			line-height:1.25;
			}

          .yt-slider img, .ww-slider img,.live-slider img{
            width:100%;
          }
		}

        .bolt-btm {
          position:absolute;
          right:60px;
          bottom:50px;

          @include breakpoint(medium down) {
          display:none;
          }
        }

        .bolt-logo {
          img {
            margin:0 auto;
          }
        }
	}

    .sxswwrap{
        padding-top:14vh;
        width: 100vw;
        position: relative;
        overflow: hidden;

        @include breakpoint(small down) {
        padding-top:0;
        }

       .bx-wrapper {

         .bx-pager {
           @include breakpoint(medium down) {
             padding-top:0;
           }
         }

         .bx-pager.bx-default-pager a {
         border: 1px solid #000 !important;

                   @include breakpoint(medium down) {
                   border: 1px solid #fff !important;
                   margin: 0 8px;
                   }
       }

         .bx-pager.bx-default-pager a.active,
         .bx-pager.bx-default-pager a:hover {
           background:#000 !important;

                     @include breakpoint(medium down) {
                       background:#fff !important;
                     }
         }

         .bx-controls-direction a {
          width:42px;
          height:72px;

          @include breakpoint(small down) {
            top: 30px;
            width: 25px;
            height: 50px;
            background-size: 100%;
          }
        }
         .bx-next {
          background: url(../img/newsletter-sxsw/controls_next.png) no-repeat;
        }
         .bx-prev {
          background: url(../img/newsletter-sxsw/controls_prev.png) no-repeat;
        }
      }

      .overlay-fade-in .image-overlay-content {
        background:transparent;
      }

      .image-overlay-content {
        height: 100%;
      }

        @media screen and (min-width: 1024px){

            //@include vertical-align(50%, 50%);
        }

        .sxsw-coppy-center {
            img {
                margin:0 auto;
            }
        }

      .sxsw-slider {
          margin-left: 0;

          .sxsw-text {
              .sxsw-header {
                  padding-top:5rem;

                  img {
                      max-height:60vh;
                  }
              }
          }
        }

      }
}

.sxswwrap > .bx-wrapper > .bx-viewport {
    height: auto !important;
}
// sxsw-central end

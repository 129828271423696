#shopitems {
	h1,h2,h3,h4,h5,h6 {
		font-family: $body-font-family;
	}


	h2, h3, h4 {
		color: $white;
		text-transform: capitalize;
	}
	h2 {
		@include breakpoint(medium only) {
			font-size: 42px;
		}
		@include breakpoint(xlarge up) {
			font-size: 42px;
		}

		@include breakpoint(large only) {
			font-size: 28px;
		}
		@include breakpoint(medium only) {
			font-size: 24px;
		}
	}
	h4 {
		@include breakpoint(medium only) {
			font-size: 26px;
		}
		@include breakpoint(xlarge up) {
			font-size: 26px;
		}
		@include breakpoint(large only) {
			font-size: 18px;
		}
		@include breakpoint(medium only) {
			font-size: 16px;
		}
	}
	p {
		color: $white;
		line-height: 1.3;
		font-size: 20px;
		font-family: $body-font-family;
		@include breakpoint(large only) {
			font-size: 18px;
		}
		@include breakpoint(medium down) {
		  font-size: 14px;
		}
	}

		.columns {

		    padding: 30px!important;
			@include breakpoint(xlarge up) {
			  padding: 40px 30px 40px 30px!important;
			}
			@include breakpoint(medium only) {
			  padding: 25px 2.5px 25px 2.5px!important;
			}
			@include breakpoint(small only) {
			  padding: 50px 0 0 0!important;
			}
		}
		.main {
				@include breakpoint(medium only) {
				  padding-top: 5px!important;
				}
				@include breakpoint(small only) {
				  padding-top: 0!important;
				}
		}
		.bottom_item {
			position: relative;
			.myprice {
				width: 80%;
				@include vertical-align(50%, 50%);
					h2, h3, h4, p {
						text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
					}
				.rightcol {
					@include breakpoint(xlarge up) {
						margin-left:30px;
					}
				}
				.numberswrap {
					position: relative;
					margin-right: 25px;
					.number {
						position: relative;
						.dollarmark {
							position: absolute;
							top:0;
							left:0;
							font-size: 36px;
							@include breakpoint(large down) {
								font-size: 22px;
							}
							line-height: 1;
							font-weight: 600;
						}
						.dollars {
							position: absolute;
							top:0;
							left:25px;
							font-size: 70px;
							@include breakpoint(large down) {
								font-size: 50px;
								left: 10px;
							}
							line-height: 1;
							font-weight: 600;
						}
						.cent {
							position: absolute;
							top:0;
							left:110px;
							font-size: 36px;
							@include breakpoint(large down) {
								font-size: 22px;
								left:70px;
							}

							line-height: 1;
							font-weight: 600;
						}
						.plus {
							position: absolute;
							top:38px;
							left:110px;
							font-size: 14px;
							@include breakpoint(large down) {
								font-size: 10px;
								left:70px;
								top:22px;
							}
							line-height: 1;
							text-transform: uppercase;
						}
						.myvector {
							    padding: 0!important;
								position: absolute;
								@include breakpoint(large down) {
									left:0%;
								}
								@include breakpoint(large up) {
									top: 15%;;
								}
								@include breakpoint(medium down) {
									width: 150%;

								}
								@include breakpoint(xlarge up) {
									width: 120%;
								}

							top:10%;
							left:10%;
							max-width:200%;
							width: 100%;
							z-index: 1000;

						}

						@media (max-height: 400px) {
							.myvector {
								width: 100%;
							}
						}


					} //numbers

				} //numberswrap

				.myorder {
					.columns {
					    padding: 10px 10px 0 10px!important;
					}

					p{
						color: $black;
						background: $white;
						border-radius: 20px;
						padding: 5px;
						text-transform: uppercase;
						text-shadow: 4px 4px 6px rgba(0, 0, 0, 0);
						@include breakpoint(xlarge up) {
							font-size: 26px;
						}
						@include breakpoint(large only) {
							font-size: 16px;
						}
						@include breakpoint(medium only) {
							font-size: 16px;
						}
						font-weight: 600;
						box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
						transition: all 0.5s ease;
						&:hover {
							color: $white;
							background: #e7222d;
						}
					}

				}

			} //myprice
		} //bottom_item
		.Myitems {
			transition: all 0.5s ease-in-out;
			&:hover {
				box-shadow: 0 15px 25px rgba(0,0,0,0.3);
				// transform: scale(1.05);
			}
		}

	.legal {
		p {
			color: #595959;
			font-size: 14px;
			padding: 0 30px;
			@include breakpoint(medium down) {
				padding: 30px 30px 0 30px;
			}
		}
	}
}

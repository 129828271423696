footer {
	padding-top:40px;
	/*position: relative;
	background-color: $white;
	@include breakpoint(large up) {
		padding: 40px 0;
	}
	padding: 20px 0;
	.row {
		@include breakpoint(large up) {
			margin: 0 auto 40px auto;
		}
		margin: 0 auto 20px auto;
	}*/

	h1,h2,h3,h4,h5,h6 {
	}
	h3 {
		color: #595959;

		@include breakpoint(medium down) {
			font-size: 1.1875rem;
		}
	}
	@media (max-width: 2000px) and (min-width: 1024px){
		h1{
			font-size: 2rem!important;
		}
		p{
			font-size: 16px!important;
		}
		h3 {
			font-size: 1.6rem!important;
		}
		h4 {
			font-size: 1.4rem!important;
		}
		h5 {
			font-size: 1.1rem!important;
		}
	}
	h4 {
		color: $primary-color;
		font-weight: 700;

		@include breakpoint(medium down) {
			font-size: 1rem;
		}
	}
	p {
		font-family: $body-font-family;
		color: $primary-color;
		font-weight: 600;
		@include breakpoint(medium down) {
			font-size: 14px;
		}
	}
	.menu {
		padding-top:50px;
		text-transform: uppercase;
		li {
			@include breakpoint(small down) {
				display: block;
			}
			transition: all 1s ease;
			&:hover {
				@include breakpoint(xlarge up) {
					transform: scale(1.1);
				}
			}
		}
	}  //menu
	.Myemail {
		text-transform: uppercase;
		transition: all 1s ease;
		display: inline-block;
		color: $primary-color;
		font-weight:900;
		font-family: 'Open Sans', serif;
		&:hover {
			@include breakpoint(xlarge up) {
				transform: scale(1.1);
			}
		}
	}
	.IconsocialFooter {
		img {
			z-index: 100;
			// width: 45px;
    		// padding: 10px;
			width: 40px;
    		padding: 7px;
			transition: all 1s ease;
			&:hover {
				@include breakpoint(xlarge up) {
					transform: scale(1.1);
				}
			}
		}
	}

	.footer-arrow {
		width: 30px;
		@include breakpoint(xlarge down) {
			width: 23px;
		}
	}

	.Mytel {
		color: #595959;
		display: inline-block;
		transition: all 1s ease;
		&:hover {
			@include breakpoint(xlarge up) {
 			   transform: scale(1.1);
 		   }
		   color: $primary-color;
	   }
   	}

	.submit-btn {
				background-color: $white;
				border: 1px solid $primary-color;
				color:$primary-color;
				text-transform: uppercase;
				margin:auto;
				display: block;
				font-size: 16px;
				font-weight: 800;
				// padding: 0.4rem 0;
				width:100%;
				padding: 0.66rem 0;
				transition: all 0.5s ease;
				&:hover {
					background: $primary-color;
					color: $white;
				}
				@include breakpoint(small down) {
					font-size: 12px;
					padding: 0.5rem 0;
				}
			}

	#keep-in-touch {
		border: 1px solid $primary-color;
		border-right-width: 0;
		//box-shadow: 0;

		@include breakpoint(small down) {
			width: 98%;
			margin-left:2%;
			height:1.9rem;
		}
	}
	#keep-in-touch::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  @include breakpoint(small down) {
		  font-size: 12px!important;
	  }
	}
	#keep-in-touch::-moz-placeholder { /* Firefox 19+ */
		@include breakpoint(small down) {
  		  font-size: 12px!important;
  	  }
	}
	#keep-in-touch:-ms-input-placeholder { /* IE 10+ */
		@include breakpoint(small down) {
  		  font-size: 12px!important;
  	  }
	}
	#keep-in-touch:-moz-placeholder { /* Firefox 18- */
		@include breakpoint(small down) {
  		  font-size: 12px!important;
  	  }
	}
}

/* Get in Touch Form*/

#getinTouch {
  background:#929292;
  color:white;
  padding:110px 0;
  font-weight:600;

	@include breakpoint(large down) {
		padding:60px 15px;
	}

	.touchTitle, .cs-form-result-content {
		font-size:1.2rem;
	}

	p {
		padding-top:1rem;
		margin-bottom:0;
	}

  .touchBtn {
    margin-top:.85rem;

    .btnTwo  {
      display:block;
      max-width:210px;
      margin:0 auto;
      text-align:center;

      &:hover {
        background: white;
        border-color: white;
      }
    }
  }

  .formWrap {
    font-size:.95rem;
    font-weight:600;

    .fieldWrap {
      display:inline-block;
      padding-top:.85rem;
    }
		p {
		color:#cccccc;
		}

		a {
			color:$white;
			&:hover {
				color:#cccccc;
			}
		}

		.checkwrap {
			position: relative;
			padding-left: 2rem;
			margin-bottom: 12px;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			/* Hide the browser's default checkbox */
	input {
	  position: absolute;
	  opacity: 0;
	  cursor: pointer;
		left:0;
	  height: 0;
	  width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
	  position: absolute;
	  top: 0;
	  left: 0;
	  height: 25px;
	  width: 25px;
	  background-color: #eee;

		/* Create the checkmark/indicator (hidden when not checked) */
		&:after {
		  content: "";
		  position: absolute;
		  display: none;
		}

		/* Style the checkmark/indicator */
		&:after {
		  left: 8px;
		  top: 0;
		  width: 10px;
		  height: 20px;
		  border: solid white;
		  border-width: 0 5px 5px 0;
		  -webkit-transform: rotate(45deg);
		  -ms-transform: rotate(45deg);
		  transform: rotate(45deg);
		}
	}

	/* On mouse-over, add a grey background color */
	&:hover input ~ .checkmark {
	  background-color: #ccc;
	}

	/* When the checkbox is checked, add a blue background */
	input:checked ~ .checkmark {
	  background-color: $primary-color;

		/* Show the checkmark when checked */
		&:after {
		  display: block;
		}
	}
}

}
  .formBtm {
    background:#595959;
    color:white;
    width:200px;
    padding:.7rem .25rem 2px;
    border:none;
    border-bottom:1px solid white;
    margin-right:.25rem;
    color:#ddd;
    margin-bottom:.5rem;

    &:focus {
      outline:none;
    }
  }
}


#footer {
	padding:130px 0 50px;
	color:#595959;
	font-size:1rem;

	@include breakpoint(large down) {
		padding:70px 15px 30px;
	}
	@include breakpoint(small down) {
		text-align: center;
		padding:50px 0 ;
	}


  a:link, a:visited  {
    color:#595959;

		&:hover {
			color:$primary-color;
		}
  }

  .footerMenu {
    padding-top:30px;
    max-width:360px;
    font-weight:500;

		@include breakpoint(small down) {
			max-width:unset;
		}

		ul {
			margin:0;
			padding:0;
		}

    li {
      list-style:None;
      display:inline-block;
      width:49%;
      padding-bottom:48px;
    }
  }

 .footRight {
	 width:300px;
	 float:right;

	 @include breakpoint(small down) {
			 width:auto;
			 float:none;
	 }
 }
  .footerLogo{
    padding-bottom:30px;

    img {
      width:120px;
      height:101px;
      display:inline-block;
    }
  }

  .socialWrap  {
		width:300px;
		float:right;

		@include breakpoint(small down) {
				width:auto;
				float:none;
		}

    a {
      margin-right:4.5rem;

			@media (max-height: 420px) {
				margin-right:3.5rem;
			}


      &:last-child {
        margin-right:0;
      }
    }
    .fab {
      font-size:1.2rem;
    }

		@include breakpoint(small down) {
			padding-top:1rem;
		}
  }
  .footerTxt {
    font-weight:500;
    padding-bottom:40px;
  }

  .copyright {
    font-size:.85rem;
  }
}

input:-internal-autofill-selected {
	background-color:#929292 !important;
  -webkit-text-fill-color: white;
	font-size: 1.2rem;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
	border-bottom:1px solid #fff;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #929292 inset !important;
  transition: background-color 5000s ease-in-out 0s;
	font-size: 1.2rem;

}

	/**
	* Make the field a flex-container, reverse the order so label is on top.
	*/

	.btnTwo {
		display:inline-block;
		background:$primary-color;
		padding:.85rem 1.5rem;
		border-radius: 0px;
		border:1px solid $primary-color;
	  font-size:.9rem;
		color:white;
		cursor:pointer;
		font-weight:500;

		a {
			color:white;
				&:hover {
					color:$primary-color !important;
				}
		}	&:hover {
				background:white;
				color:$primary-color !important;
			}



		&:focus {
			outline:none;
		}
	}



		 label {
			letter-spacing: 0.05em;
			color:white;
		  font-weight:600;
		}
	.cs-form-field {
		display: flex;
		flex-flow: column-reverse;
		margin-bottom: 0;
		margin-right:6px;


	/**
	* Add a transition to the label and input.
	* I'm not even sure that touch-action: manipulation works on
	* inputs, but hey, it's new and cool and could remove the
	* pesky delay.
	*/
	 label,  input {
		transition: all 0.2s;
		touch-action: manipulation;
	}

	 input,  textarea {
		border: 0;
		border-bottom: 1px solid #ccc;
		font-family: inherit;
		-webkit-appearance: none;
		border-radius: 0;
		padding: .65rem 0;
		cursor: text;
		font-size:1.2rem;
		background-color: transparent;
		color:#fff;
		box-shadow: none;

		&:focus {
 		outline: 0;
 		border:none;
 		border-bottom: 1px solid #ff6d00;
 		background: transparent;
 		box-shadow: none;
	 	}
	}
	 textarea {
		 border: 1px solid #ccc;
		 padding: .65rem;

		 &:focus {
			 border: 1px solid #ff6d00;
		 }
	 }

	/**
	* Translate down and scale the label up to cover the placeholder,
	* when following an input (with placeholder-shown support).
	* Also make sure the label is only on one row, at max 2/3rds of the
	* field�to make sure it scales properly and doesn't wrap.
	*/
	 input:placeholder-shown + label {
		cursor: text;
		max-width: 66.66%;
		white-space: nowrap;
		overflow: hidden;
		font-weight:600;
		text-overflow: ellipsis;
		transform-origin: left bottom;
		transform: translate(0, 2.125rem) scale(1.05);
	}

}
	/**
	* By default, the placeholder should be transparent. Also, it should
	* inherit the transition.
	*/
	::-webkit-input-placeholder {
		opacity: 0;
		transition: inherit;
	}
	/**
	* Show the placeholder when the input is focused.
	*/
	.cs-form-field input:focus::-webkit-input-placeholder {
		opacity: 1;
	}
	/**
	* When the element is focused, remove the label transform.
	* Also, do this when the placeholder is _not_ shown, i.e. when
	* there's something in the input at all.
	*/
	.cs-form-field input:not(:placeholder-shown) + label,
	.cs-form-field input:focus + label {
		transform: translate(0, 0) scale(1);
		cursor: pointer;
	}

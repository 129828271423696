#mission {
	@include breakpoint(xlarge up) {
		@include cover-background('../img/missionbkg.jpg');
    	background-attachment: fixed;
    	background-position: bottom;
	}
	position: relative;
	@include breakpoint(large down) {
	  @include cover-background('../img/mission-mob.jpg');
	}

//	background-color: gray;

	.parallax {
		@include breakpoint(xlarge up) {
			background-attachment: fixed;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}
	.fondo1{
		background-image: url("../img/missionbkg1.png");


	}
	.fondo2{
		background-image: url("../img/missionbkg2.png");

	}

	.fondo3{
		background-image: url("../img/missionbkg3.png");
	}

	.fondo4{
		background-image: url("../img/missionbkg4.png");
		padding-bottom: 150px;

	}
	// .parallax-div2 {
	// 	@include cover-background('../img/missionbkg2.png');
	// 	background-attachment: fixed;
	// }
	// .parallax-div3 {
	// 	@include cover-background('../img/missionbkg3.png');
	// 	background-attachment: fixed;
	// }
	// .parallax-div4 {
	// 	@include cover-background('../img/missionbkg4.png');
	// 	background-attachment: fixed;
	// }
	//padding-top: 80px;
	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.3;
		padding: 0;
		text-transform: none;
		//color: $white;
		color: #595959;
    //text-shadow: 0px 1px 0px rgba(255,255,255,.5); /* 50% white from bottom */
		@include breakpoint(medium down) {
		  font-size: 1.7rem;
		}
	}
	@media (max-width: 2000px) and (min-width: 1024px){
		h1{
			font-size: 2rem!important;
		}
		p{
			font-size: 16px!important;
		}
	}
	p {
		line-height: 1.3;
		font-size: 18px;
		font-family: $body-font-family;
		@include breakpoint(large only) {
			font-size: 18px;
		}
		@include breakpoint(medium down) {
		  font-size: 14px;
		}
		color: $black;
	}
		//font-weight: bold;
		//color: $black;
    //text-shadow: 0px 1px 0px rgba(255,255,255,.7); /* 50% white from bottom */


	.MissiontextAlign {
			@include breakpoint(medium down) {
				padding: 0;
			}
		padding:0 100px;
	}
	.MissionHeader {
		#OurMission {
			max-width: 450px;
		}
		@include breakpoint(medium down) {
			padding: 50px 0;
		}
		padding: 200px 30px 50px;
	}

}

.holiday-window-displays {
	//border-bottom: 3px solid $primary-color;
	background: $white;

		h1,h2,h3,h4,h5,h6 {
			font-family: $header-font-family;
			color: #595959;
			margin-bottom:0;
			padding-bottom:25px;
		}

		h1 {
			line-height: 1.3;
			padding-top:25px;
			font-weight: 900;
			text-transform: capitalize;

			@include breakpoint(large down) {
				font-size: 1.7rem;
				margin-bottom: 0;
			}
		}
		@media (max-width: 2000px) and (min-width: 1199px){
			h1{
				font-size: 2rem!important;
			}
		}

		.sub {
			font-weight: 700;
			font-family: $body-font-family;
			color: #595959;
			/*@include breakpoint(large only) {
				font-size: 14px;
			}*/
		}
		h3 {
			font-size: 26px;
			font-weight: 900;
			padding-top:9px;
			margin-bottom:-16px;
			@include breakpoint(large down) {
				font-size: 1.1875rem;
			}
			span {
				font-weight: 300;
			}
		}
		p, li, a {
			color: #595959;
			line-height: 1.5;
			font-size: 18px;
			font-family: $body-font-family;
			/*@include breakpoint(medium down) {
			  font-size: 16px;
			}*/
		}
		/*@media (max-width: 2000px) and (min-width: 1024px){
			p, li, a {
				font-size: 16px!important;
			}
			 h3 {
			 	font-size: 18px;
			 }
		}*/

		//ShareIcons
		/*.ShareIcons {
			width:50px;
			padding-right: 25px;
			padding-bottom: 25px;
			transition: all 1s ease;
			&:hover {
				transform: scale(1.1);
			}
		}*/

		.blogtext {
			margin-bottom: 150px;
			@include breakpoint(medium down) {
				margin-bottom: 50px;
			}
		}

		.numbers {
			float: left;
			font-size: 5rem;
			line-height: 3rem;
			font-family: $header-font-family;
			padding-top: 16px;
			padding-right: 10px;
			font-weight: 900;
		}
		.first-img {
			margin-bottom:25px;
		}
		.second-img {
			margin-bottom:1rem;
		}
		.Anotherlink {
			font-weight: bold;
			color: #595959;
			&:hover {
				color: $primary-color;
			}
		}
		.font-type {
			font-family: $header-font-family;
			font-weight: 900!important;
		}
		.Saks-bxslider {
			 margin-left:0;
			li {
				margin-right:30px;
			}
		}
		.responsive-embed.widescreen, .flex-video.widescreen {
		    padding-bottom: 82%;
	   		margin-bottom: 0;
		}

}

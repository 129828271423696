/*
* Global style
*/
body, html {
	height: 100%;
	width:100%;
	position: relative;
	font-family: $body-font-family;
  //background-color: $white;
}
body {
	overflow-x: hidden;
}

h1 {
	font-size: 2.4rem;
	text-transform: capitalize;
	margin-bottom: 0;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $header-font-family;
}



//mobile menu style
	#CSMobileLogo {
		width: 80px;
	}
	.button_container {
	  position: absolute;
	  top: 45px;
	  right: 30px;
	  height: 27px;
	  width: 35px;
	  cursor: pointer;
	  z-index: 100;
	  transition: opacity .25s ease;
	  @include breakpoint(xlarge up) {
		  display: none;
	  }


	  &.active {
	    .top {
	      transform: translateY(11px) translateX(0) rotate(45deg);
	      background: #FFF;
	    }
	    .middle {
	      opacity: 0;
	      background: #FFF;
	    }

	    .bottom {
	      transform: translateY(-11px) translateX(0) rotate(-45deg);
	      background: #FFF;
	    }
	  }

		  span {
			  background: #FFF;
			  border: none;
			  height: 3px;
			  width: 100%;
			  position: absolute;
			  top: 0;
			  left: 0;
			  transition:  all .35s ease;
			  cursor: pointer;

			    &:nth-of-type(2) {
			      top: 11px;
			    }

			    &:nth-of-type(3) {
			      top: 22px;
			    }
		  }
	}

	.overlay {
	  position: absolute;
	  background: -webkit-linear-gradient(top left, #e04614 0%, #2fa2b4 100%);
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100vh;
	  opacity: 0;
	  visibility: hidden;
	  z-index: 50;
	  &.open {
	     opacity: 1;
	     visibility: visible;
	     height: 100vh;
	  }

	  @media (max-height:550px) {
		  nav{
			  height: 60%!important;
			  top: 35%!important;  //add this to value is subscribe is on the bottom
		  }
	  }
	  nav {
	    position: relative;
	    height: 70%;
	    //  top: 43%;
	     top:40%; //use this to value if subscribe is on the bottom
	    transform: translateY(-50%);
	  	font-family: $header-font-family;
		  font-weight: 300;
	    font-size: 30px;
	    text-align: center;
	    z-index: 100;

	  ul {
	    list-style: none;
	    padding: 0;
	    margin: 0 auto;
	    display: inline-block;
	    position: relative;
	    height: 90%;
		@include breakpoint(medium only) {
			width: 66.66667%;
		}

	    li {
	      display: block;
	      height: calc(100% / 8);
	      min-height: 30px;
	      position: relative;


	      a {
	        display: block;
	        position: relative;
	        color: #FFF;
	        text-decoration: none;
	        overflow: hidden;
		    	font-size: 22px;
		    	transition: all 1.5s ease;
	        &:hover:after,
	         &:focus:after,
	         &:active:after {
	           width: 100%;
	         }
		 }//a
	   }//li
	   h5 {
		   font-family: $header-font-family;
		   color:$white;
		   font-size: 1rem;
	   }
	   .submit-btn {
				   background-color: transparent;
				   border: 1px solid $white;
				   color:$white;
				   text-transform: uppercase;
				   margin:auto;
				   display: block;
				   font-size: 12px;
				   font-weight: 800;
				   // padding: 0.4rem 0;
				   width:100%;
				   padding: 0.56rem 0;
				   transition: color 1.5s ease;
				   &:hover {
					   // background: $primary-color;
					   color: $primary-color;
					   // border-color: $primary-color;
					   cursor: pointer;
				   }
			   }
	   #keep-in-touch {
		   border: 1px solid $white;
		   color: $white;
		   background-color: transparent;
		   border-right-width: 0;
		   box-shadow: 0;
		   height:1.999rem;
		   transition: color 0.5s ease;
		   width: 100%;
		   font-size: 12px;
	   }
	  } //ul
  	} //nav

	//social icons
	.IconsocialMobile {
		img {
			z-index: 100;
			// width: 40px;
    		// padding: 5px;
			width: 33px;
    		padding: 3px;
		}
		padding-top: 120px;
		#shop:before {
		    content: "|";
			color: $white;
		    background: $white; /* if line image is used, this is not necessary */
			width: 3px;
		    display: inline-block;
		    vertical-align: middle;
		    margin: 0 5px;
		}
	}

} //overaly

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

.bottomSpace {
	margin-bottom:100px;
}
.topSpace {
	margin-top:50px;
}
//mobile menu end

//--------------------------------------------------------------------------------------

//desktop menu style
// .button_containerDesk {
// 	// top: 15%;
// 	// width: 20px;
// 	&.active {
// 	  .top {
// 		transform: translateY(9px) translateX(0) rotate(45deg);
// 	  }
// 	  .bottom {
// 		transform: translateY(-9px) translateX(0) rotate(-45deg);
// 	  }
// 	}
// }
// .button_containerDesk span:nth-of-type(1) {
// 	top: 2px;
// }
// .button_containerDesk span:nth-of-type(3) {
// 	top: 20px;
// }

.topBar {
	position: fixed;
	top:0;
	width:100%;
	z-index: 100;
	padding-top:1rem;
	transition: top 0.3s;

	@include breakpoint(small only) {
		padding-bottom: 1rem;
		position: absolute;
		background: #333;
		}


	.topBtn {
		padding-top:.5rem;
	}

	.topWrap {
		padding-left:1.25rem;
		padding-right:1.25rem;
	}

	.navWrap {
		padding:1rem;
	}

		.buttonWrap {
			position:relative;
			//padding-top:.6rem;
			//height: 40px;
		    //width: 50px;
			//border: 2px solid transparent;
			//border-radius: 50%;
			z-index: 100;
			background-color: transparent;
		}

		.button_containerDesk {
	/*    position: absolute;
			height: 27px;
			width: 32px;
			top:12px;
			left:9px;
			cursor: pointer;
			-webkit-transition: opacity .25s ease;
			transition: opacity .25s ease;

*/
						&:hover {
						opacity: .7;
						}

						&.active {
						.top {
							transform: translateY(12px) translateX(0) rotate(45deg);
							background: #fff;
						}
						.middle {
							opacity: 0;
							background: #fff;
						}

						.bottom {
							transform: translateY(-12px) translateX(0) rotate(-45deg);
							background: #fff;
						}
						.hoverMenu  {
							opacity: 1;
						}
					}

			span {
			background: #fff;
			border: none;
			height: 4px;
			width: 48%;
			position: absolute;
			top: 18px;
			left: 17px;
			-webkit-transition: all .35s ease;
			transition: all .35s ease;
			cursor: pointer;
		}
	}
	.button_containerDesk span:nth-of-type(2) {
			top: 30px;
		}
	.button_containerDesk span:nth-of-type(3) {
			top: 42px;
		}

}


.logoImg {
    -webkit-transition: opacity .5s ease-in-out,-webkit-transform .5s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    width: 70px;
    height:64px;

}
.logoImg {
  fill:white;
}
.hoverMenu {
    -webkit-transition: opacity .5s ease-in-out,-webkit-transform .5s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height:64px;
    z-index: 50;
    opacity: 0;
    cursor: pointer;
}


#toggleDesk:hover #menuLogo {
    opacity: 0;
}
 #toggleDesk:hover #menuTrack {
     opacity: 0;
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
 }
  #toggleDesk:hover #logoHover {
  opacity: 1;
}

.icon-bolt {
  -webkit-mask: url(../../assets/img/bolt_tracks_wh.svg);
  mask: url(../../assets/img/bolt_tracks_wh.svg);
}


.icon {
  display: inline-block;
  width: 70px;
  height: 64px;
  background: white;
  -webkit-mask-size: cover;
          mask-size: cover;
}
.icon:hover,
.icon:focus {
  background: #ff6d00;
}




//buttomWrap
.buttonWrap {
	//position: fixed;
	//top: 5%;
	//left: 2.5%;
	//height: 50px;
	//width: 50px;
	//border: 2px solid transparent;
	//border-radius: 50%;
	//z-index: 100;
	//background-color: transparent;
}

//button
	.button_containerDesk {
		  //position: absolute;
		  //top: 22%;
		  //left: 21%;
		  //height: 27px;
		  //width: 25px;
		  //cursor: pointer;
		  //z-index: 100;
		  //transition: opacity .25s ease;


		  &:hover {
			opacity: .7;
		  }

		  &.active {
			.top {
			  transform: translateY(11px) translateX(0) rotate(45deg);
			  background: #fff;
			}
			.middle {
			  opacity: 0;
			  background: #fff;
			}

			.bottom {
			  transform: translateY(-11px) translateX(0) rotate(-45deg);
			  background: #fff;
			}
	  }

		  span {
			  background: #fff;
			  border: none;
			  height: 3px;
			  width: 100%;
			  position: absolute;
			  top: 0;
			  left: 0;
			  transition:  all .35s ease;
			  cursor: pointer;


				&:nth-of-type(2) {
				  top: 11px;
				}

				&:nth-of-type(3) {
				  top: 22px;
				}
		  }
	}//butoon end

//overlay
//
// #maindiv  {
//   position: fixed;
//   background-color: rgba(128, 128, 128, 0.5);
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   visibility: hidden;
//   z-index: 49;
//   border: 1px solid black;
// }


	.overlayDesk {
	  position: fixed;
	  /* IE10+ */
	  background-image: -ms-linear-gradient(308deg, #2fa2b4, #e04614);

	  /* Mozilla Firefox */
	  background-image: -moz-linear-gradient(308deg, #2fa2b4, #e04614);

	  /* Opera */
	  background-image: -o-linear-gradient(308deg, #2fa2b4, #e04614);

	  /* Webkit */
	  background-image: -webkit-linear-gradient(308deg, #2fa2b4, #e04614);

	  /* W3C Markup */
	  background-image: linear-gradient(308deg, #2fa2b4, #e04614);

	  background-size: 200% 200%;

	    -webkit-animation: AnimationName 5s ease infinite;
		-moz-animation: AnimationName 5s ease infinite;
		-o-animation: AnimationName 5s ease infinite;
		animation: AnimationName 5s ease infinite;

	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  opacity: 1;
	  visibility: hidden;
	  z-index: 99;

	  &.open {
		 opacity: 1;
		 visibility: visible;
		 height: 100%;
		 //social icons
		.SocialWhite, .SocialOrange {
			@include vertical-align(2%, 95%);
			width: 60px;
			left: 25px !important;

			img {
				margin-right: 0;
			}
			.shopDesk {

				&::after {
				   content: " ";
				   color: $white;
				   background: $white; /* if line image is used, this is not necessary */
				   width: 100%;
				   height:3px;
				   display: inline-block;
				   vertical-align: bottom;
			   }
			}
		}
		.SocialOrange {
			z-index: 99;
			opacity: 1;
		}
		.SocialWhite {
			z-index: 100;
			opacity: 1;
		}

		#facebook_d, #twitter_d, #instagram_d, #linkedin_d, #shopimg, #dribbble_d, #vimeo_d, #behance_d {
			-webkit-transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
		}
			#facebook_d {
					&:hover {
						opacity: 0;
					}
			}
			#twitter_d {
					&:hover {
						opacity: 0;
					}
			}
			#instagram_d {
					&:hover {
						opacity: 0;
					}
			}
			#linkedin_d {
					&:hover {
						opacity: 0;
					}
			}
			#shopimg {
				&:hover {
					opacity: 0;
				}
			}
			#dribbble_d {
				&:hover {
					opacity: 0;
				}
			}
			#vimeo_d {
				&:hover {
					opacity: 0;
				}
			}
			#behance_d {
				&:hover {
					opacity: 0;
				}
			}
			@media (min-width: 1921px) {
				.shopDesk {
					padding-bottom:0.2rem;
					&::after {
					//	margin-top: 0.8rem;
					//	margin: 0.8rem 0 0.4rem 0;
					}
				}
			}
			@media (max-width: 1920px) {
				.IconsocialOverlay {
					width: 60px;
					li > a {
						padding: 0.4rem 1rem!important;
					}
					.shopDesk {
						&::before {
						   margin: 0.2rem 0 0.8rem 0;
					   }
					}
				} //Iconsocial
		   }

	  } //&:open

	  #logo_d {

		  &:hover {
			  #MenuLogoDesk_tracks {
				  opacity:0;
				  -ms-transform: rotate(360deg); /* IE 9 */
				 -webkit-transform: rotate(360deg); /* Chrome, Safari, Opera */
				 transform: rotate(360deg);
			 }
			 #MenuLogoDesk {
				 opacity:0;
			 }
			 #bolt_flash_y {
				 opacity: 1;
			 }
			 #bolt_tracks_y {
				 opacity: 1;
				 -ms-transform: rotate(180deg); /* IE 9 */
				 -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
				 transform: rotate(180deg);
			 }
		 }
	 }
		  .logo_img {
			  -webkit-transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
			  position: absolute;
			  top: 5%;
			  right: 3%;
			  z-index:100;
			  width: 150px;
		  }
		  .bolt_y {
			  -webkit-transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
			  position: absolute;
			  top: 5%;
			  right: 3%;
			  width: 150px;
			  z-index:100;
			  opacity: 0;
		  }

	  @media (max-width: 2000px) and (min-width: 1024px) {
		  nav {
			  //font-size: 40px!important;

			  li {
				  min-height: 65px!important;
			  }
		 }
	  }
	  nav {
		position: relative;
		height: 70%;
		top: 57%;
		transform: translateY(-50%);
		font-family: $header-font-family;
		font-weight: 300;
		font-size: 50px;
		text-align: center;
		z-index: 100;
		max-width: 75rem;
		margin: 0 auto;

		@media (max-height: 550px) {
			font-size:30px;
		}



	  ul {
		list-style: none;
		padding: 0;
		margin: 0 auto;
		display: inline-block;
		position: relative;
		height: 100%;
		@include breakpoint(large up) {
			width: 50%;
		}

		li {
		  display: block;
		   height: calc(100% / 8);
		   min-height: 80px;
		   max-height: 120px;
		  position: relative;


			@include breakpoint(small) {
 		   min-height: unset;
 		   max-height: unset;
			}


		  a {
			display: block;
			position: relative;
			color: #FFF;
			text-decoration: none;
			overflow: hidden;
			transition: color 1.5s ease;

			@include breakpoint(small only) {
 		   font-size:25px;
			}

			&:hover {
				color: #e04614;
			}
			&:hover:after,
			 &:focus:after,
			 &:active:after {
			   width: 100%;
			 }

		  }
		}
		h5 {
			font-family: $header-font-family;
			color:$white;
			margin-top:10px;
		}
		.submit-btn {
					background-color: transparent;
					border: 1px solid $white;
					color:$white;
					text-transform: uppercase;
					margin:auto;
					display: block;
					font-size: 14px;
					font-weight: 800;
					// padding: 0.4rem 0;
					width:100%;
					padding: 0.71rem 0;
					transition: color 1.5s ease;
					&:hover {
						// background: $primary-color;
						color: $primary-color;
						// border-color: $primary-color;
						cursor: pointer;
					}
				}
		#keep-in-touch {
			border: 1px solid $white;
			color: $white;
			background-color: transparent;
			border-right-width: 0;
			box-shadow: 0;
			transition: color 0.5s ease;
		}
	 } //ul
   } //nav
  }   //overlayDesk end

  @-webkit-keyframes AnimationName {
      0%{background-position:50% 0%}
      50%{background-position:50% 100%}
      100%{background-position:50% 0%}
  }
  @-moz-keyframes AnimationName {
	  0%{background-position:50% 0%}
      50%{background-position:50% 100%}
      100%{background-position:50% 0%}
  }
   @-o-keyframes AnimationName {
	   0%{background-position:50% 0%}
       50%{background-position:50% 100%}
       100%{background-position:50% 0%}
  }
  @keyframes AnimationName {
	  0%{background-position:50% 0%}
      50%{background-position:50% 100%}
      100%{background-position:50% 0%}
  }



.bottomSpaceDesk{
	margin-bottom:100px;
}
.topSpaceDesk{
	margin-top:50px;
}//overlay end
//desktop menu end
//------------------------------------------------------------------------------------------------

// news section modal window style
	body.is-reveal-open {
		overflow: hidden;
		.revealwrap {
			display: block;


		}
	}
	.revealwrap {
		 position: fixed;
		 top: 0;
		 left: 0;
		 width: 100%;
		 display: none;
		.revealbox {
			position: relative;
			top: 0;
			left: 0;
			.WHclose {
				position: absolute;
				top: 0;
				right: 40px;
				font-size: 50px;
				color: #8a8a8a;
				@include breakpoint(medium down) {
					right: 0;
				}
			}
		}
	}

	.reveal-overlay {
		background-color: rgba(10, 10, 10, 0.81);

	}
	.WHvideo {
		max-width: 100%!important;
	}
	.WHslider {
		max-width: 70%!important;
		@include breakpoint(small only) {
			max-width: 100%!important;
		}
	}
	.reveal {
		border: 0;
		padding:0;
		height: 80vh!important;
		overflow-x: hidden;
		background-color: transparent;
		width: 100%!important;
		@include breakpoint(large up) {
			overflow-y: hidden;
		}

		img, video {

			@include breakpoint(large up) {
				height: 100%;
				margin: 0 auto;
			}
			@include breakpoint(medium down) {
				max-height: 50vh;
				margin: 50px auto;
			}
			//@include vertical-align(50%, 50%);
			// max-height: 100vh;
		}
		video {
			@include breakpoint(large up) {
				@include vertical-align(50%, 50%);
			}
			//max-height: 90vh;
		}
		ul {
			height: 100%;
			margin-left: 0;
		}
		li {
			img {
				@include breakpoint(large up) {
					height: 80vh;
				}
			}
		}
		.bx-wrapper .bx-pager {
				bottom: 10px;
				left: 0;
				z-index: 99;
		}
		.bx-wrapper .bx-pager.bx-default-pager a {
			background: #fefefe!important;
			border: 0!important;
			box-shadow: inset 3px 3px 4px rgba(0,0,0,0.7)!important;
			width: 12px;
			height: 12px;
			border-radius: 7px;
			z-index: 99;
			&:focus, &:hover {
				background: #fefefe!important;
			}
			&.active {
				background: $primary-color!important;
			}
		}
		.bx-wrapper .bx-controls-direction a {
			@include breakpoint(medium down) {
	  		  display: none;
	  	  }
		}

		.bx-wrapper {
			@include breakpoint(large up) {
				@include vertical-align(50%, 50%);
			}

		}

		h1,h2,h3,h4,h5,h6,p {
			font-family: $header-font-family;
		}
		h1 {
			color: $white;
			padding: 0;
			margin-bottom: 0;
			text-align: center;
			@include breakpoint(medium down) {
	  		  font-size:2rem;
	  	  }
		}
		p {
			color: $white;
			text-align: center;
		}
		@media (min-width: 1920px) {
			p {
				font-size: 20px!important;
			}
		}

		.myAudio {
			cursor: pointer;
		}


	}//reveal

//----------------------------------------

#quotes {
	padding: 80px 0;
	position: relative;
	background-color: #28acbe;

	h1,h2,h3,h4, {
		font-family: $header-font-family;
		color: $white;
	}
	h1 {
		text-transform: capitalize;
		@include breakpoint(medium down) {
		  font-size: 1.7rem;
		}
	}
	@media (max-width: 2000px) and (min-width: 1024px){
		h1{
			font-size: 2rem!important;
		}
		p{
			font-size: 16px!important;
		}
		h3 {
			font-size: 1.6rem!important;
		}
		.ClientsQts {
			height: 250px!important;
		}
	}
	h5,h6 {
		font-family: $body-font-family;
		color: $white;
	}
	h5 {
		margin-top: 2rem;
	}
	p {
		line-height: 1.3;
		font-size: 18px;
		font-family: $body-font-family;
		@include breakpoint(medium down) {
		  font-size: 14px;
		}
	}

	.bx-wrapper .bx-pager.bx-default-pager a:hover, .bx-wrapper .bx-pager.bx-default-pager a.active {
		    background: $white!important;
	}
	.bx-wrapper .bx-pager.bx-default-pager a {
	    background: transparent;
	    border: 1px solid white!important;
	}


	.bx-wrapper .bx-controls-direction a {
		@include breakpoint(medium down) {
			display:none;
		}
	}
	.bx-wrapper {
		img {
	    	margin: 2rem auto;
			width: 210px;
			@include breakpoint(medium down) {
				//width: 150px;
			}
		}
		#weedabix {
			width: 150px;
			@include breakpoint(medium down) {
				width: 100px;
			}
		}
		#monk {
			width: 150px;
			@include breakpoint(medium down) {
				width: 100px;
			}
		}

		@media (max-width: 2000px) and (min-width: 1024px){
			img {
				//width: 160px;
			}
			#weedabix, #monk {
				width: 110px;
			}
		}

		li {
			width: 100%!important;
			/*@include breakpoint(medium down) {
				width: 90%!important;
			}
			@include breakpoint(large up) {
				width: 100%!important;
			}*/
		}
	}
	.column, .columns {
	    padding-right: 0.9375rem!important;
	    padding-left: 0.9375rem!important;
	}
	.sliderQuotes {
		margin:0;
	}

	.ClientsQts {
		@include breakpoint(large up) {
			height: 300px;
			position: relative;
			.ClientsQtsCentr {
				@include vertical-align(50%, 50%);
			}
		}
	}
	.quotesLogo {
		@include breakpoint(large up) {
			height: 100px;
			position: relative;
			.quotesLogoCentr {
				@include vertical-align(50%, 80%);
			}
		}
	}
	.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto {
		/*left: 10px;*/
	}

	.quotes-twitter {
		display: inline;
		@include breakpoint(large up) {
			max-width: 3.5%;
		}
		@include breakpoint(medium down) {
			max-width: 10%;
		}
		margin: 0 5px 0 0!important;
	}

}

/*
* Partners section style
*/
#partners {
	position: relative;
	// height:100%;
	background-color: #e6e04d;
	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.3;
		padding: 10px 0;

		@include breakpoint(small only) {
  		  font-size: 2rem;
	    }
		@include breakpoint(medium only) {
  		  //font-size: 2.4rem;
	    }
		@include breakpoint(xlarge up) {
			//font-size: 1.8rem;
			//padding: 10px 0!important;
		}
		@include breakpoint(large only) {
			font-size: 1.8rem;
			//padding: 10px 0!important;
		}
	}
	p {
		line-height: 1.3;
		font-size: 18px;
		font-family: $body-font-family;

		@include breakpoint(xlarge only) {
			line-height: 1.2!important;
			font-size:16px;
		}

	  @include breakpoint(large only) {
			line-height: 1.2!important;
			font-size:14px;
		}

	}
	/*@media (max-width: 2000px) and (min-width: 1441px){
		p {
			font-size: 16px;
		}
	}*/

	a {
		color: $black;
	}

	img {
		width:100%;
	}
	.topRow {
		font-weight: 700;
		// padding-top: 80px;
	}
	.Myopacity {
		@include breakpoint(xlarge up) {
			position: absolute;
		    top: 45%;
		    left: 75%;
		    transform: translate(-50%, -47%);
			width: 50%;
		}
	}
	.textCenter {
		//padding: 100px 200px;
		width: 85%;
    margin: 0 auto;
		@include breakpoint(large up) {

		}

		@include breakpoint(large down) {
			padding: 40px 0px;
		}


	}

	// @media (max-width: 2000px) and (min-width: 1024px){
	// 	.textCenter {
	// 		h1{
	// 			font-size: 2rem!important;
	// 		}
	// 		p{
	// 			font-size: 16px!important;
	// 		}
	// 	}
	// }



	.columns {
		padding: 0;
	}

	.left {
		float: right;
	}

	.right {
		float: left;
	}

	//overlayPartner
			.overlayPartner {
			  background-color: #595959;
			  display: none;
			  z-index: 50;
			  animation: fadein 2s;
				-moz-animation: fadein 2s; /* Firefox */
				-webkit-animation: fadein 2s; /* Safari and Chrome */
				-o-animation: fadein 2s; /* Opera */
				@keyframes fadein {
				from {
					opacity:0;
				}
				to {
					opacity:1;
				}
				}
				@-moz-keyframes fadein { /* Firefox */
				from {
					opacity:0;
				}
				to {
					opacity:1;
				}
				}
				@-webkit-keyframes fadein { /* Safari and Chrome */
				from {
					opacity:0;
				}
				to {
					opacity:1;
				}
				}
				@-o-keyframes fadein { /* Opera */
				from {
					opacity:0;
				}
				to {
					opacity: 1;
				}
				}

			  .row {
				  padding:0;
				  margin:0;
			  }

			  &.open {
			     display: inline-block;
			    //  height: 100%;
			  }
			  h1 {
					font-size:1.5rem;
					color: $white;
			  }
			  p {
				  color: $white;
			  }
			  .image {
				  position: relative;
			  }
			  .Mytitle {
				  @include vertical-align(0, 100.2%);
				  background-color: rgba(0, 0, 0, 0.7);
				  width:100%;
				  height: 70px;
				  .namewrap {
					  position: relative;
					  top: 50%;
					  -webkit-transform: translateY(-50%);
					  -khtml-transform: translateY(-50%);
					  -ms-transform: translateY(-50%);
				       transform: translateY(-50%);
				  }
			  }
			  p {
				  font-size: 16px;

				  @include breakpoint(xlarge only) {
				  	font-size: 14px;
				 }
				 @include breakpoint(large only) {
				   font-size: 12px;
				   margin-bottom: 0.5rem!important;
			   }
			  }



			  @media (min-width: 1438px) and (max-width: 1530px){
				//   h1 {
				// 	  font-size: 2.2rem;
				//   }
				  p {
					  //font-size: 14px;
				  }
				  .partnerScl {
					  max-width: 30px;
				  }
			  }

			  @media (max-width: 1390px) and (min-width: 1199px){
				//   h1 {
				// 	  font-size: 1.8rem;
				//   }
				  p {
					  //font-size: 12px;
				  }
				  .partnerScl {
					  max-width: 20px;
				  }
				  .pos {
					    padding-top: 10px !important;
				  }
			  }

			  span {
				  color: #39aec3;
				  font-size: 16px;
				  font-family: $body-font-family;
			  }

	   }   //overlayPartner end

		//partner's name underline animation
		a {
		position: relative;
		&:after {
		  content: "";
		  position: absolute;
		  width: 100%;
		  height: 3px;
		  bottom: -5px;
		  left: 0;
		  background: $black;
		  visibility: hidden;
		  border-radius: 5px;
		  transform: scaleX(0);
		  transition: all 2s ease;
		}

		}
		//for jquery Partner's headshots hover with name undeline animation
		.changed {
		&:after {
		  visibility: visible;
		  transform: scaleX(1);
		}
		}
		//-----------------------------------
		#toggleNameJohn > a:hover:after {
		visibility: visible;
		transform: scaleX(1);
		}
		#toggleNameBrad > a:hover:after {
		visibility: visible;
		transform: scaleX(1);
		}
		#toggleNameDave > a:hover:after {
		visibility: visible;
		transform: scaleX(1);
		}
		#toggleNamePaul > a:hover:after {
		visibility: visible;
		transform: scaleX(1);
		}
		#toggleNameJoe > a:hover:after {
		visibility: visible;
		transform: scaleX(1);
		}
		#toggleNameteam > a:hover:after {
		visibility: visible;
		transform: scaleX(1);
		}
		//partner's name underline animation end

	   .HeadShots, #toggleNameJohn, #toggleNameBrad, #toggleNameDave, #toggleNamePaul, #toggleNameJoe {
		   cursor: pointer;
		   vertical-align: bottom;
	   }


	   .partnerScl {
		   max-width: 40px;
		   padding: 50% 0;
		//    padding-bottom: 20px;
		    margin-left: 30%;
		   @include breakpoint(large only) {
			   max-width: 20px;
    		//    padding-bottom: 0;
			   margin-right: 5px;
	   	  }
		  @include breakpoint(small only) {
			  max-width: 30px;
			  margin-right: 5px;
		 }
		 @include breakpoint(medium down) {
			 padding: 10px 0;
			 margin-left: 0;
		}
	   }

	//overlay close button
	//bio text position
	.pos {
		position: relative;
		padding-top: 30px!important;
		@include breakpoint(large only) {
		  padding-top: 10px!important;
	   }
	   @include breakpoint(xlarge only) {
		 padding-top: 20px!important;
	  }
	}

	.close-button {
		right: 0;
		top: 30px;
		height: 30px;
		line-height: 0;
		@include breakpoint(large only) {
		  top: 10px;
	    }
		@include breakpoint(xlarge only) {
		  top: 20px;
	    }
		.Xclose {
			max-width:30px;
			max-height: 30px;
			height: 100%;

		}
	}
	.partnership {
		margin-top: 25px;
		margin-bottom: 50px;
		@include breakpoint(large only) {
			margin-top: 15px;
    		margin-bottom: 40px;
	    }
	}

	// Overlay for Headshots
	.Sign-wrapper {
		  width: 100%;
		  height: 100%;
		  overflow: hidden;
		  position: relative;
		  border: 0;
		  &:hover {
			  img {
			  	transform: scale(1.1);
			 }
			  .Sign-overlay-content {
				  opacity: 0;
			  }
		  }
		  img {
			  transition: all 2s ease;
		  }
		.Sign-overlay-content {
			  background-color: rgba(0, 0, 0, 0.3);
			  width: 100%;
			  height: 100%;
			  position: absolute;
			  overflow: hidden;
			  bottom: 0;
			  left: 0;
			  opacity: 0;
			  transition: all 2s ease
		} //.Sing-overlay-content
	}
	//overlay for headshots end


	//Partners section for mobile end
	.JohnImg, .BradImg, .DaveImg, .PaulImg, .JoeImg {
		position: relative;
		#JohnLife, #BradLife, #DaveLife, #PaulLife, #JoeLife {
			visibility: hidden;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
		}
	}
	//Partners section for mobile end
	.MoreRow {
		background-color: $white;
		#PartnersMore {
			h4{
				font-family: 'Open Sans', serif;
				color: $primary-color;
				font-weight: 900;
				text-transform: uppercase;
				margin-bottom: 0;
			}
			padding: 20px 0!important;
			cursor: pointer;
		}
	} //MoreRow

	.Partners-load {
		width: 23px;
	}


}//#partners

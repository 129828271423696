
#CSnews {
			z-index:1;
			margin-bottom: 0px;
			//this code need for proper NEXT/PREV scrolling up
			margin-top: -1px;
			padding-top: 1px;
			//-----------------------------
			h1,h2,h3,h4,h5,h6 {
				font-family: $header-font-family;
			}

			h1 {
				line-height: 1.3;
				padding: 30px 0;
				color: $primary-color;
				text-transform: capitalize;
				@include breakpoint(large down) {
					font-size: 1.7rem;
					padding: 15px 0;
					margin-bottom: 0;
				}
			}
			@media (max-width: 2000px) and (min-width: 1199px){
				h1{
					font-size: 2rem!important;
				}
			}
			p {
				color: #595959;
				line-height: 1.3;
				font-size: 20px;
				font-family: $body-font-family;
				@include breakpoint(medium down) {
				  font-size: 18px;
				}
			}
			/*@media (max-width: 2000px) and (min-width: 1024px){
				p{
					font-size: 16px!important;
				}
			}*/
			.topRow {
				font-weight: 700;
				margin-bottom: 0;

				// padding-top: 80px;
			}

			.bordered {
				@include breakpoint(large up) {
					padding-bottom: 80px;
					border-bottom: 1px solid gray;
					max-width: 75rem;
					margin: 80px auto 0 auto;
				}
			}
			.lastbordered {
				@include breakpoint(large up) {
					margin: 80px auto 0 auto;
				}
			}

			//desktop style
			.newslist {
				//margin-top: 150px;
				position: relative;
					//cursor: pointer;
					height:100%;
					border: 30px solid $white;
					transition: box-shadow 0.3s ease-in-out;
					&:hover {
						@include breakpoint(xlarge up) {
							box-shadow: 0 5px 15px rgba(0,0,0,0.3);
							.NewslisrArrow {
								opacity: 1;
							}
						}
					}
				.NewslisrArrow {
					@include vertical-align(114.7%, 60%);
					opacity: 0;
					transition: opacity 0.3s ease-in-out;
				}
			} //newslistM

			//mobile style
			.newslistM {
				margin-bottom: 50px;
			}
			//Desktop Read MOre button
			.MoreCSNews {
				margin: 30px 0 0 0;
			    padding: 1em 4em;
			    font-family: $body-font-family;
			    color: $primary-color;
			    text-transform: uppercase;
			    font-size: 14px!important;
			    font-weight: 600;
			    border: 1.5px solid $primary-color;
			    transition: all 0.5s ease;
				&:hover {
					background: $primary-color;
					color: $white;
				}
			} //button end for desktop

			.MoreCSNewsM {
				font-family: $body-font-family;
				text-transform: uppercase;
			    font-size: 14px;
			    font-weight: 600;
				padding: 1em 3em;
			    margin: 0 0 3rem 0;
			}

			.pagination {
				position: relative;
				margin-top: 100px;
				margin-bottom:0;
				@include breakpoint(medium down) {
					margin-top: 0;
				}
				background: #e6e04d;
				@include breakpoint(small only) {
					padding: 30px 0;
				}
				padding: 15px 0;
				font-family: $body-font-family;
				text-transform: uppercase;
			    font-weight: 600;
				a {
					font-size: 16px;
					@include breakpoint(large down) {
						font-size: 14px;
					}
					&::before, &::after{
						content:" ";
					}
					&:hover {
						background: transparent;
						color: $primary-color;
					}
				}
				.current {
					font-size: 16px;
					display: inline-block!important; //comment this if pagination should be hide for samll screens
					@include breakpoint(large down) {
						font-size: 14px;
					}
					background: transparent;
					color: $primary-color;
				}
				.pagination li {
					display: inline-block!important; //comment this if pagination should be hide for samll screens
				}
				.pagination-previous {
					@include vertical-align(15%, 50%);
				}
				.pagination-next {
					@include vertical-align(85%, 50%);
				}

				#CSNewsImgPrev, #CSNewsImgNext {
					width: 15px;
					margin-right: 10px;
					margin-left: 10px;
				}
			}

			#secondblock, #thirdblock, #forthblock, #fifthblock, #sixthblock, #seventhblock, #eighthblock, #ninethblock {
				display: none;
			}
}

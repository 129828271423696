.career-starts-here {
	//border-bottom: 3px solid $primary-color;
	padding-top: 60px;
	@include breakpoint(large down) {
		padding-top:0;
	}
	.main-row {
		max-width:84rem;
		@include breakpoint(large down) {
			padding:20px;
		}
	}
	.applied-arts {
		width: 653px;
		@include breakpoint(large only) {
			width: 480px;
		}

		@include breakpoint(medium down) {
			width: 268px;
		}
	}

	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
		color: #595959;
		margin-bottom:0;
	}

	.my-header {
		line-height: 1.3;
		font-size: 3rem;
		font-weight: 900;
		padding: 25px 0;
		@include breakpoint(large only) {
			font-size: 2rem;
			padding: 15px 0;
			margin-bottom: 0;
		}
		@include breakpoint(medium down) {
			font-size: 1.4rem;
			padding: 15px 0;
			margin-bottom: 0;
		}
	}
	@media (max-width: 1300px) and (min-width: 1199px){
		.my-header {
			font-size: 2.6rem!important;
		}
		h4 {
			font-size: 1.4rem!important;
		}
		 .sub-header, .article-quot, .sub-sub-header {
			 font-size: 1.6rem!important;
		 }
		 .applied-arts {
	 		width: 590px;
		}
	}
	h4 {
			@include breakpoint(large down) {
				font-size: 1rem!important;
			}
	}
	p, a {
		color: #595959;
		line-height: 1.3;
		font-size: 20px;
		font-family: $body-font-family;

		@include breakpoint(medium down) {
		  font-size: 14px;
		}
	}
	@media (max-width: 1300px) and (min-width: 1024px){
		p, a {
			font-size: 16px;
		}
	}

	.columns {
		@include breakpoint(large up) {
			padding: 0 75px!important;
		}
	}
	.left-border {
		@include breakpoint(large up) {
			border-left: 2px solid #595959;
		}
	}
	.right-border {
		@include breakpoint(large up) {
			border-right: 2px solid #595959;
		}
	}
	.sub-header {
		@include breakpoint(large only) {
			font-size: 1.6rem;
		}
		@include breakpoint(medium down) {
			font-size: 1.2rem;
		}
		font-size: 2rem;
		font-weight: 900;

		@include breakpoint(large up) {
			padding:0 0 60px 0;
			margin-bottom:0;
		}
	}
	.article-quot {
		@include breakpoint(large only) {
			font-size: 1.6rem;
		}
		@include breakpoint(medium down) {
			font-size: 1.2rem;
		}
		font-size: 2rem;
		font-weight: 900;
		margin-top:20px;

		@include breakpoint(large up) {
			padding:60px 0 0 0;
			margin-bottom:0;
		}
	}
	.sub-sub-header {
		@include breakpoint(large only) {
			font-size: 1.6rem;
		}
		@include breakpoint(medium down) {
			font-size: 1.2rem;
		}
		font-size: 2rem;
		font-weight: 900;

		@include breakpoint(large up) {
			padding:44px 0 60px 0;
			margin-bottom:0;
		}
	}
	.article-text-start {
		margin-top:20px;
		@include breakpoint(large up) {
			margin-top: 60px;
		}
	}

	.pagination {
		position: relative;
		margin-top: 100px;
		margin-bottom:0;
		background: #28acbe;
		@include breakpoint(small only) {
			padding: 30px 0;
		}
		@include breakpoint(medium down) {
			margin-top: 50px;
		}
		padding: 15px 0;
		font-family: $body-font-family;
		text-transform: uppercase;
		font-weight: 600;
		a {
			font-size: 16px;
			color: $white;
			@include breakpoint(large down) {
				font-size: 14px;
			}
			&::before, &::after{
				content:" ";
			}
			&:hover {
				background: transparent;
				color: #e6e04d;
			}
		}
		.current {
			font-size: 16px;
			display: inline-block!important; //comment this if pagination should be hide for samll screens
			@include breakpoint(large down) {
				font-size: 14px;
			}
			background: transparent;
			color: #e6e04d;
		}
		.portfolio {
			img {
				width: 25px;
			}
			@include breakpoint(small only) {
				display: inline-block!important; //comment this if pagination should be hide for samll screens
			}
		}
		.pagination li {
			display: inline-block!important; //comment this if pagination should be hide for samll screens
		}
		.pagination-previous {
			@include vertical-align(15%, 50%);
		}
		.pagination-next {
			@include vertical-align(85%, 50%);
		}
		#BlogImgPrev, #BlogImgNext {
			width: 15px;
			margin-right: 10px;
			margin-left: 10px;
		}
	}
}

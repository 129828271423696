#airmax-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/nike/AirMax.jpg');
	}
	@include breakpoint(medium down) {
		@include cover-background('../img/work/individual/nike/AirMax_m.jpg');
	}

	p {
		color: #595959;
	}
}
#hockey-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/nike/Hockey.jpg');
	}
	@include breakpoint(medium down) {
		@include cover-background('../img/work/individual/nike/Hockey_m.jpg');
	}
	h1 {
		color: #d7271a;
	}
}

#gastown-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/nike/Gastown.jpg');
	}
	@include breakpoint(medium down) {
		@include cover-background('../img/work/individual/nike/Gastown_m.jpg');
	}
	h1, .topRow {
		color: #7a4b27;
	}
	p {
		color: #595959;
	}

}

#af1-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/nike/AF1.jpg');
	}
	@include breakpoint(medium down) {
		@include cover-background('../img/work/individual/nike/AF1_m.jpg');
	}


}

#w15k-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/nike/15K.jpg');
	}
	@include breakpoint(medium down) {
		@include cover-background('../img/work/individual/nike/15K_m.jpg');
	}
	h1 {
		color: #f6ff00;
	}


}
#brahaus-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/nike/BraHaus.jpg');
	}
	@include breakpoint(medium down) {
		background: #d5d5d5;
	}
	h1 {
		color: #000000;
	}
	p {
		color: #000000;
	}
}

#nba-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/nike/NBA.jpg');
	}
	@include breakpoint(medium down) {
		//background: #d5d5d5;
		@include cover-background('../img/work/individual/nike/NBA.jpg');
	}
	h1 {
		color: #ff0000;
	}

}


#uncledrew-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/nike/uncledrew.jpg');
	}
	@include breakpoint(medium down) {
		background: #f3efe7;
	}
	h1 {
		color: #f5823f;
	}
	svg {
		fill: #024682;
		width:30%;
	}
	p {
		color: #595959;
	}
}


#carling-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/molson/Carling.jpg');
	}
	@include breakpoint(medium down) {
		@include cover-background('../img/work/individual/molson/Carling_m.jpg');
	}
	h1 {
		color: #ff140f;
	}
}

#madjack-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/molson/MadJack.jpg');
	}
	@include breakpoint(medium down) {
		@include cover-background('../img/work/individual/molson/MadJack_m.jpg');
	}
	h1 {
		color: #fcb13e;
	}
}

#madjack-case-new {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/molson/MadJack-new.jpg');
	}
	@include breakpoint(medium down) {
		@include cover-background('../img/work/individual/molson/MadJack-new_m.jpg');
	}
	h1 {
		color: $black;
	}
	p {
		color: $black;
	}
	.flavour-stamp {
		position:absolute;
		top: 50%;right: 0;
	  margin-right: -5%;
	  -webkit-transform: translate(-10%,-50%);
	  -khtml-transform: translate(-10%,-50%);
	  -ms-transform: translate(-10%,-50%);
	  transform: translate(-10%,-50%);}
	}

#belgian-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/molson/Belgian.jpg');
	}
	@include breakpoint(medium down) {
		@include cover-background('../img/work/individual/molson/Belgian_m.jpg');
	}
	h1 {
		color: #e0c37f;
	}

}
#bay-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/bay/Bay.jpg');
	}
	@include breakpoint(medium down) {
		@include cover-background('../img/work/individual/bay/Bay_m.jpg');
	}
	h1 {
		color: #e0c37f;
	}
}

#hudsonsbay-case {
	@include cover-background('../img/work/individual/hudsonsbay/hudsonsbay.jpg');
	@include breakpoint(medium down) {
		background: #130042;
	}
}

#lindt-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/lindt/Lindt.jpg');
	}
	@include breakpoint(medium down) {
		@include cover-background('../img/work/individual/lindt/Lindt_m.jpg');
	}
	h1 {
		color: #e0c37f;
	}

}

#humber-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/humber/Humber.jpg');
	}
	@include breakpoint(medium down) {
		@include cover-background('../img/work/individual/humber/Humber_m.jpg');
	}
	h1 {
		color: #033691;
	}
	p {
		color: #282828;
	}
}

#iko-case {
	@include cover-background('../img/work/individual/iko/iko.jpg');
	@include breakpoint(medium down) {
		background: #8b8a90;
	}
}
#italpasta-case {
	@include cover-background('../img/work/individual/italpasta/italpasta.jpg');
	@include breakpoint(medium down) {
		background: $black;
	}

}
#bluejays-case {
	@include cover-background('../img/work/individual/bluejays/bluejays.jpg');
	@include breakpoint(medium down) {
		background:$black;
	}
}
#canadagoose-case {
	@include cover-background('../img/work/individual/canadagoose/canadagoose.jpg');
	@include breakpoint(medium down) {
		background:$black;
	}
}
#saintelizabeth-case {
	@include cover-background('../img/work/individual/saintelizabeth/saintelizabeth.jpg');
	@include breakpoint(medium down) {
		background: $white;
	}
	h1 {
		color: lighten($black, 31%);
	}
	p {
		color: lighten($black, 31%);
	}
}


#echelon-case {
	@include cover-background('../img/work/individual/echelon/echelon.jpg');
	@include breakpoint(medium down) {
		background:$black;
	}

}
#reinhart-case {
	@include cover-background('../img/work/individual/reinhart/reinhart.jpg');
	@include breakpoint(medium down) {
		background:#225b88;
	}
}
#acanac-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/acanac/Acanac.jpg');
	}
	@include breakpoint(medium down) {
		background: #090909;
	}
	h1 {
		color: #f9e600;
	}
}
#distributel-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/distributel/Distributel.gif');
	}
	@include breakpoint(medium down) {
		background: #004c7b;
	}
	h1 {
		color: #f7a51c;
	}
}
#humber-case {
	@include breakpoint(medium down) {
		height: 100vh!important;
	}
}


#arterra-pride {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/arterra/arterra-pride.jpg');
		background-color: #f2eff1;
		background-size: auto 50vw;
	  background-position: 90% center;
	}
	@include breakpoint(medium down) {
		background: #f2eff1;
	}
	h1, .topRow {
		color: #939598;
	}
	p {
		color: lighten($black, 31%);
	}

}

#arterra-bask {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/arterra/arterra-bask.jpg');
		background-color: #f2eff1;
		background-size: auto 50vw;
		background-position: 105% center;
	}
	@include breakpoint(medium down) {
		background: #f2eff1;
	}
	h1, .topRow {
		color: #939598;
	}
	p {
		color: lighten($black, 31%);
	}

}
#baruvida-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/baruvida/Baruvida-Tree.png');
		background-repeat: no-repeat;
    background-position: 90% center;
    background-color: #ffffff;
    background-size: 50vw auto;
	}
	@include breakpoint(medium down) {
		background: #ffffff	;
	}
	h1, .topRow {
		color: #939598;
	}
	p {
		color: lighten($black, 31%);
	}
}
#dior-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/dior/dior-case.jpg');
	}
	@include breakpoint(medium down) {
		background: #e6e1d8;
	}
	h1, .topRow {
		color: #000001;
	}
	p {
		color: lighten($black, 31%);
	}
}

#hrh-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/hrh/hrh-case.jpg');
		background-repeat: no-repeat;
    background-position: right center;
    background-color: #ffffff;
    background-size: 50vw auto;
	}
	@include breakpoint(medium down) {
		background: #ffffff;
	}
	h1, .topRow {
		color: #939598;
	}
	p {
		color: lighten($black, 31%);
	}
}

#hrhf-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/hrhf/hrhf-case.jpg');
	}
	@include breakpoint(medium down) {
		background: #ededed;
	}
	h1, .topRow {
		color: #939598;
	}
	p {
		color: lighten($black, 31%);
	}
}
#humbermeadow-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/humbermeadow/humbermeadow-case.jpg');

	}
	@include breakpoint(medium down) {
		background: #ffffff;
	}
	h1 {
		color: lighten($black, 49%);
	
	}
	p {
		color: lighten($black, 31%);
	}
	.img-to-idea {
		max-width: 258px;
	}
}
#humbermeadow-prll {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/humbermeadow/humbermeadow-10.jpg');
		height: 100vh;
		width: 100vw;
		background-attachment: fixed;
	}
}


#kpmg-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/kpmg/kpmg-case.jpg');

	}
	@include breakpoint(medium down) {
		background: #ffffff;
	}
	h1, .topRow {
		background: -webkit-linear-gradient(180deg, #1e49e2, #7214ea);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		white-space: break-spaces;
	}
	p {
		color: lighten($black, 31%);
	}
	.img-to-idea {
		max-width: 225px;
	}
}
#appficiency-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/appficiency/appficiency-case.jpg');

	}
	@include breakpoint(medium down) {
		//background: #ffffff;
		background: url(../img/work/individual/appficiency/appficiency-case.jpg) center no-repeat;
    background-size: cover;
	
	}    
    
	h1 {
		color: lighten($black, 55%);
		text-transform: uppercase;
	}
	 p {
		color: lighten($black, 31%);
	}
}
.appficiency-prll {
	@include cover-background('../img/work/individual/appficiency/appficiency-2.jpg');
}

#buddha-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/buddha/buddha-case.jpg');

	}
	@include breakpoint(medium down) {
		background: #1ba359;
	}    
    
	h1 {
		text-transform: uppercase;
	}
	h1, p {
		color: $white;
	}
	.img-to-idea {
		max-height: 205px;
	}
}
.buddha-brands {
	max-width: 600px;
	width: 70% !important;
	margin: 6% auto;
}
.buddha-icons {
	background: url(../img/work/individual/buddha/buddha-green-bkg.jpg) center 0 no-repeat #60c250;
	background-size: contain;
	padding: 30px 0;

	img {
	width: 70% !important;
	max-width: 230px;
	margin: 30px;
	}
}

#deciem-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/deciem/deciem-case.jpg');
	} 
	@include breakpoint(medium down) {
		background: #f2eff1;
	}    
    
	h1 {
		color: lighten($black, 55%);
		text-transform: uppercase;
	}
	 p {
		color: lighten($black, 31%);
	}
}

#twobears-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/twobears/twobears-case.jpg');
	} 
	@include breakpoint(medium down) {
		background: #e3f1f2;
	}    
    
	h1 {
		color: $black;
		text-transform: uppercase;
	}
	 p {
		color: $black;
	}
	.img-to-idea {
		max-height: 210px !important;
	}
}

#miller-case {
	@include breakpoint(large up) {
		@include cover-background('../img/work/individual/miller/miller-case.jpg');
	} 
	@include breakpoint(medium down) {
		//background: #e3f1f2;
	}    
    
	h1 {
		color: lighten($black, 50%);
		text-transform: uppercase;
	}
	 p {
		color: $black;
	}
	.img-to-idea {
		max-height: 210px !important;
	}
}
.miller-prll {
	@include cover-background('../img/work/individual/miller/miller-2.jpg');
}

.parrallel-bkg {
	@include breakpoint(large up) {
		height: 100vh;
		width: 100vw;
		background-attachment: fixed;
	}
}
.casestudy {
	position: relative;
	height: 100vh;
	width: 100vw;

	@include breakpoint(xlarge up) {
		background-attachment: fixed;
	}

	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.3;
		padding: 60px 0 30px;
		color:$white;
		text-transform: unset;
		font-size: 2.25rem;


		@include breakpoint(large up) {
			white-space: nowrap;
		}
		@include breakpoint(medium down) {
  		  font-size: 1.7rem;
	    }
	}
	p {
		line-height: 1.3;
		font-size: 18px;
		color:$white;
		font-family: $body-font-family;
		@include breakpoint(medium down) {
		  font-size: 16px;
		}
	}
	/*@media (max-width: 2000px) and (min-width: 1024px){
		h1{
			font-size: 2rem!important;
		}
		p{
			font-size: 16px!important;
		}
	}*/

	.topRow {
		font-weight: 700;
		color: $white;
	}
	.casestudytext {
		@include vertical-align(10%, 50%);
		span {
			font-weight: 700;
		}
	}
	.case-image {
		max-height: 40vh;
	}
	.img-to-idea {
		max-height:105px;
		max-width: 300px;

		@include breakpoint(small down) {
			max-width: 150px;
      max-height: 70px;
		}
	}

	#bay-to-idea {
		width: 60%;
	}
	.steliz-to-idea {
		width:50%;
	}
	.img-to-idea-h {
		width:200px;
	}
	.img-to-idea-h2 {
		width:250px;
	}
	.img-to-idea-h3 {
		width:300px;
	}
}

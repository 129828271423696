/*
* News section style
*/
#news {
	position: relative;
	background-color: $white;

	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.3;
		padding: 60px 0;
		color: #595959;
		text-transform: capitalize;
		@include breakpoint(medium only) {
  		  font-size: 1.7rem;
	    }
		@include breakpoint(small only) {
  		  font-size: 1.5rem;
	    }
	}
	p {
		//line-height: 1.2;
		//font-size: 16px;
		//font-family: $body-font-family;
		//@include breakpoint(medium down) {
		//  font-size: 14px;
		//}
	}


	img {
		width: 100%;
	}
	.NewsTitle {
			// background-color: #29acbe;
			background-color:$primary-color;
			h1 {
				color: $white;
			}
			@media (max-width: 2000px) and (min-width: 1024px){
				h1{
					font-size: 2rem!important;
				}
			}
	}

	.button {
		margin: 65px 0;
        padding: 1em 4em;
		font-family: 'Open Sans', serif;
		color: #595959;
		text-transform: uppercase;
		line-height: 1.3;
		font-size: 16px;
		@include breakpoint(medium down) {
		  font-size: 14px;
		}
		font-weight: 800;
		border:1.5px solid #595959;
		transition: all 0.5s ease;
		&:hover {
			background: #595959;
			color: #ffffff;
		}
	}
	.myLarge {
		display: none;
	}
	@media (max-width: 1215px) and (min-width: 1024px) {
		.myLarge {
			width: 50%!important;
		}
	}
	.notvisible {
		display: none;
	}
	.notvisibleMobile {
		display: none;
	}

	#moreMobile {
		h4{
			font-family: 'Open Sans', serif;
			color: $primary-color;
			font-weight: 900;
			text-transform: uppercase;
			margin-bottom: 0;
		}
		padding: 20px 0!important;
		cursor: pointer;
	}

	.news-load {
		width: 23px;
	}

}//#news

#CareersList {

	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
		color: #595959;
	}

	h1 {
		line-height: 1.3;
		padding: 25px 0;
		color: $primary-color;
		text-transform: capitalize;
		@include breakpoint(large down) {
			font-size: 1.7rem;
			padding: 15px 0;
			margin-bottom: 0;
		}
	}
	@media (max-width: 2000px) and (min-width: 1199px){
		h1{
			font-size: 2rem!important;
		}
	}
	p, li{
		color: #595959;
		line-height: 1.3;
		font-size: 20px;
		font-family: $body-font-family;
		@include breakpoint(medium down) {
  		  font-size: 18px;
	    }
	}
	
	ul.checklist {
		position: relative;
    list-style: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0;

		li {
		  margin-left: 35px !important;

			&:before {
			  content: '✓';
			  position: absolute;
			    left: 10px;
			}
		}
}

	/*@media (max-width: 2000px) and (min-width: 1024px){
		p, li {
			font-size: 16px!important;
		}
	}*/
	li {
		@include breakpoint(medium down) {
			margin: 0 0 10px 0;
		}
		margin: 0 0 10px 10px;
	}
	.applylink {
		margin: 30px 0 0 0;
		padding: 1em 4em;
		font-family: $body-font-family;
		color: $primary-color;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 600;
		border: 1.5px solid $primary-color;
		transition: all 0.5s ease;
		&:hover {
			background: $primary-color;
			color: $white;
		}

	}
	.emailus {
		color: $primary-color;
		display: inline-block;
		transition: transform 0.3s ease;
		&:hover {
			transform: scale(1.02);
		}
	}

	//ShareIcons
	.ShareIcons {
		width:50px;
		padding-left: 25px;
		@include breakpoint(medium down) {
			margin-left: -25px;
		}
		transition: all 1s ease;
		&:hover {
			transform: scale(1.1);
		}
	}

	.careerblock {
		padding: 150px 0;
		@include breakpoint(medium down) {
			padding: 50px 0;
		}
	}
	.topgap {
		margin-top: 40px;
	}
	.white {
		background-color: $white;
	}
	.gray {
		background-color: #f2f2f2;
	}

	.mobileArrow {
		width: 35px;
	}

}

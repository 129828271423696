/*
* Typography
*/
// @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');

/* libre-baskerville-regular - latin */
@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/libre-baskerville/libre-baskerville-v4-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Libre Baskerville'), local('LibreBaskerville-Regular'),
       url('../fonts/libre-baskerville/libre-baskerville-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/libre-baskerville/libre-baskerville-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/libre-baskerville/libre-baskerville-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/libre-baskerville/libre-baskerville-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/libre-baskerville/libre-baskerville-v4-latin-regular.svg#LibreBaskerville') format('svg'); /* Legacy iOS */
}
// @font-face{
// font-family:"Libre Baskerville";
// src:url("../fonts/1462027/ab88c69c-d345-4c07-bf49-c0cc984095fa.eot?#iefix");
// src:url("../fonts/1462027/ab88c69c-d345-4c07-bf49-c0cc984095fa.eot?#iefix") format("eot"),url("../fonts/1462027/6de16bd6-1e13-400f-80e8-8f5c69d8a2b8.woff2") format("woff2"),url("../fonts/1462027/73c7abdc-45a3-42a3-a1af-f07d8a348917.woff") format("woff"),url("../fonts/1462027/73494c38-422a-4bae-9e2c-b86e7703c1b3.ttf") format("truetype"),url("../fonts/1462027/b84e9482-10dd-4d9d-8ef2-539fbc5eb441.svg#b84e9482-10dd-4d9d-8ef2-539fbc5eb441") format("svg");
// }
/* libre-baskerville-700 - latin */
@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/libre-baskerville/libre-baskerville-v4-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Libre Baskerville Bold'), local('LibreBaskerville-Bold'),
       url('../fonts/libre-baskerville/libre-baskerville-v4-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/libre-baskerville/libre-baskerville-v4-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/libre-baskerville/libre-baskerville-v4-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/libre-baskerville/libre-baskerville-v4-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/libre-baskerville/libre-baskerville-v4-latin-700.svg#LibreBaskerville') format('svg'); /* Legacy iOS */
}

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans/open-sans-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
       url('../fonts/open-sans/open-sans-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans/open-sans-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans/open-sans-v14-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans/open-sans-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans/open-sans-v14-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans/open-sans-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../fonts/open-sans/open-sans-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans/open-sans-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans/open-sans-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans/open-sans-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans/open-sans-v14-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/open-sans/open-sans-v14-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
       url('../fonts/open-sans/open-sans-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans/open-sans-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans/open-sans-v14-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans/open-sans-v14-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans/open-sans-v14-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans/open-sans-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../fonts/open-sans/open-sans-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans/open-sans-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans/open-sans-v14-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans/open-sans-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans/open-sans-v14-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/open-sans/open-sans-v14-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
       url('../fonts/open-sans/open-sans-v14-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans/open-sans-v14-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans/open-sans-v14-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans/open-sans-v14-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans/open-sans-v14-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}

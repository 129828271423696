#contact {
	background: $primary-color;
		@include breakpoint(small only) {
			padding-top:90px;
		}
	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.3;
		padding: 30px 0;
		color: $white;
		text-transform: capitalize;
		@include breakpoint(medium down) {
  		  font-size: 2.4rem;
	    }

	}

	@media (max-width: 2000px) and (min-width: 1250px){
		h1{
			font-size: 2rem;
		}
	}
	@media (max-width: 1249px) and (min-width: 1024px){
		h1 {
			font-size: 1.8rem;
		}
	}

	p {
		line-height: 1.3;
		font-size: 20px;
		font-family: $body-font-family;
		color: $white;
		@include breakpoint(medium down) {
			font-size: 18px;
		}
	}
	a {
		color:$white;
		&:hover {
			color: $primary-color;
		}
	}
	/*@media (max-width: 2000px) and (min-width: 1024px){
		p, a{
			font-size: 16px;
		}
	}*/
	// @media (max-width: 2000px) and (min-width: 1441px){
	// 	p, a {
	// 		font-size: 16px;
	// 	}
	// }
	.topRow {
		font-weight: 700;
		padding-bottom: 0;
	}

	.ContacttextAlign {
		@include breakpoint(medium down) {
			padding: 50px 20px 30px 20px!important;
		}
		padding:50px 0 0 0;
	}
	.row{
		position: relative;
	}
	//social icons
	.Iconsocial {
		 @include vertical-align(2%, 95%);
		z-index: 1;
		width: 65px;
		img {
		   transition: all 1s ease;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
	@media (max-width: 1920px) {
		.Iconsocial {
			// width: 60px;
			li > a {
				padding: 0.4rem 1rem!important;
			}
		} //Iconsocial
  }

  .contactcareers {
	  margin: 30px 5% 30px 0;
	  @include breakpoint(medium down) {
		  margin: 10px 5% 10px 0;
	  }
	  padding: 1rem;
	  font-family: $body-font-family;
	  color: $white;
	  text-transform: uppercase;
	  font-size: 20px;
	  @include breakpoint(xlarge down) {
		  font-size: 14px;
	  }
	  font-weight: 600;
	  border: 1.5px solid #fefefe;
	  transition: all 0.5s ease;
	  &:hover {
		  background: $white;
		  color: $primary-color;
	  }
	  @media (max-width: 1440px) {
		  .xlarge-title {
			  display: none;
		  }

	  }
	   @media (min-width: 1441px) {
		   .normal-title {
 			  display: none;
 		  }
	  }
  }
  @media (max-width: 2000px) and (min-width: 1441px){
  	.contactcareers {
  		font-size: 16px;
  	}
  }

  // @media (max-width: 1260px) and (min-width: 1024px) {
  //   .contactcareers {
  //    font-size: 10px;
  //   }
  // }


//add space after Profit 500
  .Mypad {
	  margin-top: 20px;
  }

/*  .wrapGoogle {
	  position: relative;
	  .Googleimg {
		  @include vertical-align(0%, -60%);
		  top: 10%;
	  }
  }*/
  #profitImg {
	  padding-right: 10px;
  }

  .ProfitRow {
	  	@include breakpoint(medium down) {
	  		padding: 30px 20px 0 20px!important;
		}
  }
  .GoogleRow {
	  	@include breakpoint(medium down) {
	  		padding: 10px 20px 30px 20px!important;
				text-align: center;
		}
  }
  #undermap {
	  background: #595959;
	  margin-top:-7px;
	  @include breakpoint(medium down) {
		  text-align: center;
		  padding: 3rem 0!important;
	  }
	  padding: 3rem 3rem 2rem 3rem!important;
	  #CSaddress {
		  @include breakpoint(medium down) {
		  	line-height: 2.3;
		  }
	  }
  }

  #map {
  	height: 600px;
	@include breakpoint(medium down) {
	  height: 350px;
	}
	//margin-bottom: -150px;
  }

}

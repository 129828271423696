#trialrun {
	
	@include cover-background('../img/trialrun-hero.jpg');
	background-position: top;
	//background: $primary-color;

	align-items: center;
    justify-content: center;
	min-height: 960px;

	@media only screen and (min-width: 2000px) {
		min-height: 1260px;
	  }

	.HerotextAlign {
		margin-top: 36%;

		@include breakpoint(medium) {
			margin-top: 15%;
		}


	}

	h1 {
		color: $white;
	}

	.heroText {
		max-width: 600px;
		margin: 0 auto;

		@include breakpoint(medium down) {

			p {
				font-size: 18px;
			}
		}
	}
    @include breakpoint(medium down) {
		min-height: auto;

		&.pageHero {
			padding-bottom: 4rem;
		}
	}
}

#trialText {	
	@include cover-background ('../img/trialrun-bkg.jpg');
	position: relative;
	overflow-x: hidden;	
	padding: 3rem 0 2.5rem;
    @include breakpoint(xlarge up) {
	background-attachment: fixed;
	}
    .listWrap {
		max-width: 720px;
	}
   
	   h1,h2,h3,h4,h5,h6 {
		   font-family: $header-font-family;
	   }
   
	   h2 {
		   line-height: 1.5;
		   font-size:2rem;
		   padding:0 0 30px;
		   text-transform: capitalize;
		   color: #fff85b;
		   @include breakpoint(small only) {
			   font-size: 1.75rem;
		   }
	   }
   
	   p, li {
		   color: $white;   
		   margin-bottom: .5rem;
	   }   
   }
#howitWorks {
	padding: 3rem 0;

	h3 {
		font-size: 1.25rem;
		color: $primary-color;
		font-family: $body-font-family;
		font-weight: 600;
	}
	.workSteps {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: flex;
			align-items: stretch;
			margin: 1rem 0;
			padding: 0;

			p {
				background: #f7f7f7;
				border-radius: 0 8px 8px 0;
				padding: 0 2rem 0 1rem;
				margin: 10px 0;
				width: 100%;
				display: flex;
				align-items: center;
			}
			img {
				width: 180px;
			}
			
			@include breakpoint(small only) {
				
			align-items: flex-start;

				p {
					padding: .5rem 1rem;
					margin: 3px 0;
				}
				img {
					width: 80px;
				}
			}

		}

	}
}
#readyText {
	background: $primary-color;
	text-align: center;
	color: #fff85b;
	padding: 1.5rem;

	h2 {
		font-size: 2rem;
		@include breakpoint(small only) {
			font-size: 1.5rem;
		}
	}

}
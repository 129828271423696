.image-wrapper {
	  width: 100%;
	  height: 100%;
	  overflow: hidden;
	  position: relative;
	  h1 {
		//   padding: 60px 0 20px 0!important;
		  text-align: center;
		  text-transform: capitalize;
		  @include breakpoint(small down) {
			  text-align: left;
		  }
		}
		p {
			text-align: center;
		}
	  border: 0;
}

.image-overlay-content {
  width: 100%;
  cursor: pointer;

  @include breakpoint(medium down) {
  	height: 120px;
  }
  h1 {
	  line-height: 1.2!important;
	  padding: 0 20px!important;
	    @include breakpoint(medium down) {
		  font-size: 1.1rem!important;
		  @include vertical-align(1%, 50%);
	 }
  }
  @media (min-width: 1024px) and (max-width:1650px) {
	h1 {
	  font-size: 24px!important;
	}
  }
  @media (min-width: 1651px) and (max-width:2000px) {
	h1 {
	  font-size: 2.2rem!important;
		}
  }

  height: 100%;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  left: 0;

  .overlayText {
		color: $white!important;
  	position: relative;
    top: 50%;
    transform: translateY(-50%);

		p {
			font-size:1.25rem;
			line-height:1.5;

			@include breakpoint(xlarge only) {
				font-size: 1.05rem!important;
			}

			@include breakpoint(large down) {
				font-size: 14px!important;
			}
		}


  }
} //.image-overlay-content
.overlay-fade-in p {
  color: $white;
  opacity: 0;
  padding: 0 20px;
  @include breakpoint(medium down) {
	transition: all 0s linear;
  }
  transition: all 0.2s linear;
}
.overlay-fade-in img {
	@include breakpoint(medium down) {
  	  transition: all 0s linear;
    }
  transition: all 1s ease-in-out;
}
.overlay-fade-in .image-overlay-content {
	@include breakpoint(medium down) {
    	opacity: 0.9;
    }
	  opacity: 0;
	  background: #e04614;
	  @include breakpoint(large up) {
			  /* IE10+ */
			  background-image: -ms-linear-gradient(bottom left, #e04614 0%, #2fa2b4 100%);

			  /* Mozilla Firefox */
			  background-image: -moz-linear-gradient(bottom left, #e04614 0%,  #2fa2b4 100%);

			  /* Opera */
			  background-image: -o-linear-gradient(bottom left, #e04614 0%, #2fa2b4 100%);

			  /* Webkit (Safari/Chrome 10) */
			  background-image: -webkit-gradient(linear, left bottom, right top, color-stop(0, #e04614), color-stop(100, #2fa2b4));

			  /* Webkit (Chrome 11+) */
			  background-image: -webkit-linear-gradient(bottom left, #e04614 0%, #2fa2b4 100%);

			  /* W3C Markup */
			  background-image: linear-gradient(to top right, #e04614 0%, #2fa2b4 100%);
	 }
	  @include breakpoint(medium down) {
		  transition: all 0s ease-in-out;
	  }
	  transition: all 1s ease-in-out;
} //.overlay-fade-in .image-overlay-content end

.overlay-fade-in h1 {
	@include breakpoint(medium down) {
    	opacity: 1;
		transition: all 0s ease-in-out;
    }
  color: $white;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  	padding: 10px 20px!important;

  @include breakpoint(medium down) {
  	margin-right: 50px;
	text-align: left;
  }
} //.overlay-fade-in h1
.overlay-fade-in .Overlaybutton {
	display: inline-block;
    vertical-align: middle;
    padding: 0.85em 4em;
    -webkit-appearance: none;
    border-radius: 0;
    font-size: 0.9rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
	border: 1px solid #FFF;
	color: #FFF;
	font-family: $body-font-family;
	text-transform: uppercase;
	transition: all 0.5s ease;
	&:hover {
		background: #ffffff;
		color: $primary-color;
	}
}

.overlay-fade-in:hover img {
	@include breakpoint(large up) {
    	transform: scale(1.2);
    }
}
.overlay-fade-in:hover .image-overlay-content {
  opacity: 0.95;
}
.overlay-fade-in:hover h1, .overlay-fade-in p, .overlay-fade-in .Overlaybutton {
  opacity: 1;
}
.overlay-fade-in:hover p {
	@include breakpoint(medium down) {
    	transition-delay: 0s;
    }
  transition-delay: 0.1s;
}
.overlay-fade-in:hover .Overlaybutton {
	@include breakpoint(medium down) {
    	transition-delay: 0s;
    }
  transition-delay: 0.2s;
}

.NewsNextArrow {
	@include vertical-align(93%, 50%);
	width: 5%!important;
}
.readmore {
	text-align: center;
	margin: 30px 0;
}

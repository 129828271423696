#Culture {
	@include cover-background ('../img/Culture.jpg');
	position: relative;
	overflow-x: hidden;
	@include breakpoint(xlarge up) {
		background-attachment: fixed;
	}
	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.3;
		padding: 30px 0;
		text-transform: capitalize;
		@include breakpoint(medium down) {
		  font-size: 1.7rem;
		}
	}
	p {
		margin-bottom:0;
		line-height: 1.75;
		font-size: 18px;
		font-family: $body-font-family;

	}
	@media (max-width: 2000px) and (min-width: 1024px){
		h1{
			font-size: 2rem!important;
		}

		.Arrive {
			width: 450px!important;
		}
	}
	.cultureSliderWrap {
		@include breakpoint(xlarge up) {
			margin: 100px 100px;
		}

		@include breakpoint(large only) {
			margin: 80px 20px;
		}
		@include breakpoint(medium only) {
			margin: 80px 20px;
		}
		@include breakpoint(small only) {
			margin: 0 0 80px;
		}
		img {
			user-drag: none;
			user-select: none;
			-moz-user-select: none;
			-webkit-user-drag: none;
			-webkit-user-select: none;
			-ms-user-select: none;
			pointer-events: none;
		}
	}


	.cultureWrap {
	 	//padding: 200px;
		@include breakpoint(medium down) {
			margin-left: 30px;
			margin-top: 80px;
			height: 50vh;
		}
		@include breakpoint(small down) {
			height: unset;
		}
		@media (max-height: 420px) {
			height: unset;
		}

		height: 80vh;
		position: relative;
		color: $white;
		.topRow {
			font-weight: 700;
		    margin-bottom: 0;
		}
		.culturetext {
			 @include vertical-align(50%, 40%);

			 @include breakpoint(small only) {
				 position: relative !important;
	 			 @include vertical-align(0%, 0%);
			}

			@media (max-height: 420px) {
				position: relative !important;
				@include vertical-align(0, 0);
			}
		}
		// @media (max-width: 1441px) {
		// 	.culturetext {
		// 		@include vertical-align(50%, 50%);
		// 	}
		// }
	}

	#careers {
		margin: 30px 0;
	    padding: 1em 4em;
	    font-family: $body-font-family;
	    color: #ffffff;
	    text-transform: uppercase;
	    font-size: 14px;
	    font-weight: 600;
	    border: 1.5px solid #ffffff;
		transition: all 0.5s ease;
		&:hover {
			background: #ffffff;
			color: #595959;
		}
	}

	.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto {
		bottom: 40px;
		left: 0;
		@include breakpoint(large only) {
			left: -20px;
		}
		@include breakpoint(medium only) {
			left: -20px;
		}
		@include breakpoint(small only) {
			left: -10px;
		}
	}
	.bx-pager.bx-default-pager a {
		background: transparent!important;
		border:1px solid $white!important;
		&.active {
			background: $white!important;
		}
	}
	.bx-wrapper {
		    padding: 1.25rem;
		img {

			  width: 100%;
		}
	}
	ul {
		margin-left: 0;
	}

}


.case-study-list {
	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.3;
		padding: 30px 0;
		color: $white;
		text-transform: capitalize;
		@include breakpoint(medium down) {
  		  font-size: 1.7rem;
	    }
		@include breakpoint(xlarge only) {
			font-size: 2.9rem;
		}
	}
	p {

		color: $white;
		line-height: 1.3;
		font-size: 20px;
		font-family: $body-font-family;
		@include breakpoint(large only) {
			font-size: 18px;
		}
		@include breakpoint(medium down) {
		  font-size: 14px;
		}
	}

	.figure {
		position: relative;
		//float: left;
		text-align: center;
		overflow: hidden;
		cursor: pointer;
		@include breakpoint(medium down) {
		  max-height: 150px;
		  width: 100%;
		}
	}

	.figure img {
		position: relative;
		display: block;
		// min-height: 50%;
		// max-height: 101%;
		// max-width: 100%;
    // min-width: 100%;
		@include breakpoint(medium down) {
		  margin-top: -15%;
		}

	}

	.figure .figcaption {
		padding: 2em;
		color: #fff;
		text-transform: capitalize;
		font-size: 1.25em;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		img {
			//@include vertical-align(2%, 20%);
			min-height: 100%;
			/* Safari only override */
			_::-webkit-full-page-media, _:future, :root .safari_only {
			 min-height: 50%;
			}
		}
	}

	.figure .figcaption::before,
	.figure .figcaption::after {
		pointer-events: none;
	}

	.figure .figcaption
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}


		/*---------------*/
		/***** Bubba *****/
		/*---------------*/

		.figure.effect-bubba {
			background: #000000;
			h1 {
		  	 padding: 0 20px!important;
			  line-height: 1.3;
			  @include vertical-align(50%, 35%);
		    }
		    @media (min-width: 1500px) and (max-width:1920px) {
			  	h1 {
			  	  font-size: 26px!important;
			  	}
		    }
			  @media (min-width: 1370px) and (max-width:1499px) {
			  	h1 {
			  	  font-size: 24px!important;
			  	}
		    }
		  	@media (min-width: 1024px) and (max-width:1369px) {
			  	h1 {
			  	  font-size: 16px!important;
			  	}
		    }
		  	@media (max-width: 1024px) {
			  	h1 {
			  	  @include vertical-align(50%, 45%);
			  	}
		    }

				p {

    				margin-bottom: 0;
					font-family: $body-font-family;
				    text-transform: uppercase;
				    font-size: 14px;
				    font-weight: 600;

				}

			@media (max-width: 2000px) and (min-width:1800px) {
				p {
				  font-size: 12px!important;
				}
			}
			@media (max-width: 1799px) and (min-width:1000px) {
				p {
				  font-size: 10px!important;

				}
				.worksview {
					padding: 0.7em 2em!important;
				}
			}

			.worksview {
				@include vertical-align(50%, 65%);
				color: $white;
			    padding: 1em 3em;
			    border: 1.5px solid #ffffff;
				transition: all 0.5s ease;
				&:hover {
					background: #ffffff;
					border: 1.5px solid #ffffff;
					color: #595959;
				}
			}
		}

		.figure.effect-bubba img {
			opacity: 0.3;
			-webkit-transition: all 0.85s;
			transition: all 0.85s;
			transform: scale(1.02);
		}

		.figure.effect-bubba:hover img {
			opacity: 0.7;
			transform: scale(1);
		}

		.figure.effect-bubba .figcaption::before,
		.figure.effect-bubba .figcaption::after {
			position: absolute;
			top: 30px;
			right: 30px;
			bottom: 30px;
			left: 30px;
			content: '';
			opacity: 0;
			-webkit-transition: opacity 0.85s, -webkit-transform 0.85s;
			transition: opacity 0.85s, transform 0.85s;
		}

		.figure.effect-bubba .figcaption::before {
			border-top: 1px solid #fff;
			border-bottom: 1px solid #fff;
			-webkit-transform: scale(0,1);
			transform: scale(0,1);
		}

		.figure.effect-bubba .figcaption::after {
			border-right: 1px solid #fff;
			border-left: 1px solid #fff;
			-webkit-transform: scale(1,0);
			transform: scale(1,0);
		}

		.figure.effect-bubba:hover .figcaption::before,
		.figure.effect-bubba:hover .figcaption::after {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
		@include breakpoint(medium down) {
			.figure.effect-bubba:hover .figcaption::before,
			.figure.effect-bubba:hover .figcaption::after {
				opacity: 0;
			}
		}
		.active {
				cursor: default;
				background: #000000;
		}


}

//nile page thumb sliders

.nike-list {
	@include breakpoint(large up) {
		.case-list-slider {
			margin-left:0;
		}
		.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto {
			bottom: 100%!important;
			left: 0!important;
			z-index: 90!important;
			background: #e6e04d!important;
		}
		.bx-wrapper .bx-pager {
			padding: 0 0 7px 0!important;
		}
		.bx-wrapper .bx-pager.bx-default-pager a {
			border-color: #000000!important;
		}
		.bx-wrapper .bx-pager.bx-default-pager a:hover, .bx-wrapper .bx-pager.bx-default-pager a.active {
			background-color: #000000!important;
		}
	}
}

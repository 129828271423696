//About page 4th section style
#TakeLook {
	overflow-x: hidden;
	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}
	h1 {
		text-transform: capitalize;
		line-height: 1.3;
		@include breakpoint(medium down) {
  		  font-size: 1.5rem;
	    }
		@include breakpoint(large only) {
			font-size: 1.6rem;
		}
	}
	@media (max-width: 1700px) and (min-width:1199px) {
		h1 {
		  font-size: 2rem!important;
		}
	}

	.figure {
		position: relative;
		//float: left;
		text-align: center;
		overflow: hidden;
		cursor: pointer;
		@include breakpoint(small only) {
		  max-height: 180px;
		  width: 100%;

			img {
				 margin-top: -20%;
			}
		}
	}

	.figure img {
		position: relative;
		display: block;
		// min-height: 50%;
		// max-height: 101%;
		// max-width: 100%;
    	// min-width: 100%;
	}

	.figure .figcaption {
		padding: 2em;
		color: #fff;
		text-transform: capitalize;
		font-size: 1.25em;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		img {
			//@include vertical-align(2%, 20%);
			min-height: 100%;
			/* Safari only override */
			_::-webkit-full-page-media, _:future, :root .safari_only {
			 min-height: 50%;
			}
		}
	}

	.figure .figcaption::before,
	.figure .figcaption::after {
		pointer-events: none;
	}

	.figure .figcaption
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}


		/*---------------*/
		/***** Bubba *****/
		/*---------------*/

		.figure.effect-bubba {
			background: #000000;
			h1 {
		  	  padding: 0 20px!important;
			  @include breakpoint(large up) {
			  	@include vertical-align(50%, 35%);
			  }
			  @include breakpoint(medium down) {
			  	@include vertical-align(50%, 50%);
			  }
			}


				p {
					@include vertical-align(50%, 65%);
					color: $white;
				    padding: 1em 3em;
				    border: 1.5px solid #ffffff;
					transition: all 0.5s ease;
					font-size: 18px;
					@include breakpoint(medium down) {
					  font-size: 14px;
					}
					font-weight: 600;
					text-transform: uppercase;
					font-family: $body-font-family;
					&:hover {
						background: #ffffff;
						border: 1.5px solid #ffffff;
						color: #595959;

					}//hover
			}//p
			@media (max-width: 2000px) and (min-width: 1024px){
				p{
					font-size: 16px!important;
				}
			}
		}//effect-bubba

		.figure.effect-bubba img {
			opacity: 0.3;
			-webkit-transition: all 0.85s;
			transition: all 0.85s;
			transform: scale(1.02);
		}

		.figure.effect-bubba:hover img {
			opacity: 0.7;
			transform: scale(1);
		}

		.figure.effect-bubba .figcaption::before,
		.figure.effect-bubba .figcaption::after {
			position: absolute;
			top: 30px;
			right: 30px;
			bottom: 30px;
			left: 30px;
			content: '';
			opacity: 0;
			-webkit-transition: opacity 0.85s, -webkit-transform 0.85s;
			transition: opacity 0.85s, transform 0.85s;
		}

		.figure.effect-bubba .figcaption::before {
			border-top: 1px solid #fff;
			border-bottom: 1px solid #fff;
			-webkit-transform: scale(0,1);
			transform: scale(0,1);
		}

		.figure.effect-bubba .figcaption::after {
			border-right: 1px solid #fff;
			border-left: 1px solid #fff;
			-webkit-transform: scale(1,0);
			transform: scale(1,0);
		}

		.figure.effect-bubba:hover .figcaption::before,
		.figure.effect-bubba:hover .figcaption::after {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
		@include breakpoint(small only) {
			.figure.effect-bubba:hover .figcaption::before,
			.figure.effect-bubba:hover .figcaption::after {
				opacity: 0;
			}
		}
}

#OurNeighbourhood {
	@include cover-background('../img/Neighbourhood.jpg');

	h1 span {
		white-space: nowrap;
	}
}
/*
#OurNeighbourhood {
	position: relative;
	height: 100%;
	width: 100%;
	//background-position: left;
	z-index:1;

	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.3;
		padding: 0px 0 30px;
		color: #e6e04d;
		text-transform: capitalize;
		@include breakpoint(medium down) {
  		  font-size: 1.7rem;
	    }
			span {
				white-space: nowrap;
			}
	}
	p {
		color: $white;
		line-height: 1.3;
		font-size: 20px;
		font-family: $body-font-family;

		@include breakpoint(medium down) {
  		  font-size:18px;
	    }

	}
	@media (max-width: 2000px) and (min-width: 1024px){
		h1{
			font-size: 2rem!important;
		}
		p{
			font-size: 16px!important;
		}
	}
	.topRow {
		font-weight: 700;
		margin-bottom: 0;
		@include breakpoint(large down) {
			font-size: 14px;
		}
		// padding-top: 80px;
	}

	.HerotextAlign {
		@include vertical-align(50%, 50%);
	}
	//scrolling to the next section

    .OurNbhscrollicon {
 	   @include vertical-align(50%, 95%);
 	   z-index:5;
 	   position: absolute;
 	    width: 20px;
 	    -webkit-animation: fadeInOut 2s infinite;
    }
    @-webkit-keyframes fadeInOut {
 	 from { opacity: 0.2; }
 	 50% { opacity: 1.0; }
 	 to { opacity: 0.2; }
  }

	  //social icons
	  .Iconsocial {
		  @include vertical-align(2%, 95%);
		  z-index: 1;
		  width: 65px;
		  img {
			 transition: all 1s ease;
			  &:hover {
				  transform: scale(1.1);
			  }
		  }
	  }
	  @media (max-width: 1920px) {
		  .Iconsocial {
			  // width: 60px;
			  li > a {
				  padding: 0.4rem 1rem!important;
			  }
		  } //Iconsocial
	}
}
*/

/*
* Hero section style
*/
#hero {
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;

	@include breakpoint(small only) {
		//height: 86%;
	}

	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		// line-height: 1.3;
		font-size: 2rem;
		color: $white;
		text-transform: capitalize;

		span {
			white-space: nowrap;
		}
		@include breakpoint(medium down) {
		  font-size: 1.7rem;
		}
	}
	/*@media (max-width: 2000px) and (min-width: 1024px){
		h1{
			font-size: 2rem!important;
		}
		p{
			font-size: 16px!important;
		}
	}*/

	// @media (max-width: 1699px) and (min-width: 1024px) {
	// 	h1 {
	// 		font-size: 1.7rem;
	// 	}
	// }
	@include cover-background('../img/hero-img.jpg');
	z-index:1;
	#video-dots {
		background-image: url('../img/dot-pattern.png');
		background-repeat: repeat;
		height: 100%;
		width: 100%;
		position: absolute;
		top:0;
		left:0;
		z-index: 1;
	}
	/* =============================================================================
	  VIDEO
	============================================================================= */
	video {
	  margin: 0;
	  padding: 0;

	}
	.videoHero {
		@include vertical-align(53%, 50%);
		min-width: 100vw;
		min-height: 100vh;
	}


	//Central Station Logo
	.CTRlogo {
		visibility: hidden;
		@include vertical-align(50%, 48%);
		// width: 450px;
		width: 28%;
		z-index:5;
		@include breakpoint(small only) {
	  	@include vertical-align(50%, 36%);
			width: 200px !important;
		}
			@media (max-height: 420px) {
				width: 160px!important;
				@include vertical-align(50%, 36%);
			}

	}



	/*@media (max-width: 1800px) and (min-width: 641px){
		.CTRlogo {
			width: 450px;
		}
	}*/
	@media (max-height: 1010px) and (min-height: 401px){
		.CTRlogo {
			/*max-width: 50%;*/
		}
	}
	.wrapper {
		z-index:5;
		width:100%;
		/*@include breakpoint(xlarge down) {
		   width:100%;
	   }*/

	}

	/*@media (max-width: 2500px) and (min-width: 1800px){
		.wrapper {
			width:60%;
		}
	}

	@media (max-width: 1799px) and (min-width: 1200px){
		.wrapper {
			width:90%;
		}
	}
	@media (max-height: 700px){
		.wrapper {
		}
	}*/

	@media (max-height: 420px) {

		.wrapper {
			@include vertical-align(51%, 80%);
			h1 {
				font-size:1.5rem;
			}
		}
	}

	@media (min-height: 421px) {
		//Text slider wrapper
		.wrapper {
			@include vertical-align(51%, 87%);
			//  width:65%;
		}
	}

	// @media (max-width: 1699px) and (min-width: 1024px) {
	// 	.wrapper {
	// 		 width:100%;
	// 	}
	// }

}//hero section end

 //scrolling to the next section
.scrollDown {
	@include vertical-align(50%, 95%);
	z-index:5;
	position: absolute;
	width: 20px;
	-webkit-animation: fadeInOut 2s infinite;
}
@-webkit-keyframes fadeInOut {
	from { opacity: 0.2; }
	50% { opacity: 1.0; }
	to { opacity: 0.2; }
}



.pageHero {
	position: relative;
	height: 100%;
	width: 100%;
	overflow-x: hidden;
	z-index:1;

	@include breakpoint(small only) {
		height: unset;
		padding-top:10rem;
		padding-bottom:7rem;
	}

	@media (max-height: 420px) {
		height: unset;
		padding-top:5rem;
		padding-bottom:5rem;
	}


	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.5;
		//font-size:2rem;
		padding:0 0 30px;
		color: #e6e04d;
		text-transform: unset;
		@include breakpoint(small only) {
  		  font-size: 1.7rem;
	    }
		@media (max-height: 420px) {
			font-size: 1.7rem;
		}
	}

	p {
		line-height: 1.3;
		font-size: 20px;
		font-family: $body-font-family;
		color: $white;
	}

	.topRow {
		font-weight: 700;
		margin-bottom: 0;
		// padding-top: 80px;
	}

	.HerotextAlign {
		width: 100%;
		text-align:center;
		@include vertical-align(50%, 50%);
		@include breakpoint(small only) {
    		position: relative !important;
					@include vertical-align(0, 0);
		}
		@media (max-height: 420px) {
			position: relative !important;
			@include vertical-align(0, 0);
		}
	}
	//scrolling to the next section

    .Aboutscrollicon {
 	   @include vertical-align(50%, 95%);
 	   z-index:5;
 	   position: absolute;
 	    width: 20px;
 	    -webkit-animation: fadeInOut 2s infinite;
    }
    @-webkit-keyframes fadeInOut {
 	 from { opacity: 0.2; }
 	 50% { opacity: 1.0; }
 	 to { opacity: 0.2; }
  }

	  //social icons
	  .Iconsocial {
		  @include vertical-align(2%, 95%);
		  z-index: 1;
		  width: 65px;

		  img {
			 transition: all 1s ease;
			  &:hover {
				  transform: scale(1.1);
			  }
		  }
	  }
	  @media (max-width: 1920px) {
		  .Iconsocial {
			  // width: 60px;
			  li > a {
				  padding: 0.4rem 1rem!important;
			  }
		  } //Iconsocial
	}
}

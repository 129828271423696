.tattoo-central {
			.square-left {
				position: absolute;
				width: 150px;
				height: 150px;
				background: $white;
				top: 0;
				left:2%;
				z-index: 1001;
				@include breakpoint(medium down) {
				  width: 100%;
				  height: 50px;
				  left:0;
				}
				.bolt-wrap {
					position:relative;
					height:100%;
					.bolt{
						@include vertical-align(50%, 50%);
						@include breakpoint(medium down) {
							width: 50px;
						}
					}
				}
			}

			@media (max-width: 2100px) and (min-width: 1800px){
				.square-left {
					width: 120px;
					height: 120px;
				}
			}
			@media (max-width: 1800px) and (min-width: 1500px){
				.square-left {
					width: 100px;
					height: 100px;
				}
			}
			@media (max-width: 1500px) and (min-width: 1024px){
				.square-left {
					width: 70px;
					height: 70px;
				}
				.bolt {
					width:60%;
				}
			}

			.tattoowrap{
				width: 100vw;
				height: 100vh;
				position: relative;
				background: #d7d7d7;
				overflow: hidden;
				.tattoo-slider {
					height: 100vh;
					margin-left: 0;
					#dave-r-right-large {
						background-image: url(../img/newsletter-landing/DaveR_01.jpg);
					}
					#dave-r-right {
						background-image: url(../img/newsletter-landing/DaveR_02.jpg);
					}
					#dave-r-left {
						background-image: url(../img/newsletter-landing/DaveR_03.jpg);
					}
					#dave-m-right-large {
						background-image: url(../img/newsletter-landing/DaveM_01.jpg);
					}
					#dave-m-right {
						background-image: url(../img/newsletter-landing/DaveM_03.jpg);
					}
					#dave-m-left {
						background-image: url(../img/newsletter-landing/DaveM_02.jpg);
					}

					#steve-st-large {
						background-image: url(../img/newsletter-landing/Steve_01.jpg);
					}
					#steve-st {
						background-image: url(../img/newsletter-landing/Steve_02.jpg);
					}
					#kaitlyn-large {
						background-image: url(../img/newsletter-landing/Kaitlyn_01.jpg);
					}
					#kaitlyn {
						background-image: url(../img/newsletter-landing/Kaitlyn_02.jpg);
					}
					#kevin-large {
						background-image: url(../img/newsletter-landing/Kevin_01.jpg);
					}
					#kevin {
						background-image: url(../img/newsletter-landing/Kevin_02.jpg);
					}

					.tattoo-right-img {
						height: 100vh;
						width: 45vw;
						position: absolute;
						right:0;
						top:0;
						background-size: cover;
						background-repeat: no-repeat;
						background-position: top center;

					}
					.tattoo-text {
						height: 100vh;
						width: 55vw;
						position: absolute;
						left:0;
						top:0;
						.tattoo-copy {
							height: 50vh;
							position: relative;
							.tattoo-coppy-center {
								@include vertical-align(50%, 50%);
							}
							h1,h2,h3,h4,h5,h6 {
								font-family: $header-font-family;
							}

							h1 {
								line-height: 1.3;
								padding: 0 0 30px 0; //30px 0
								color: #28acbe;
								text-transform: capitalize;
								@include breakpoint(large down) {
						  		  font-size: 1.3rem;
								  padding: 20px 0;
							    }
							}

							p {
								line-height: 1.3;//1.2
								font-size: 20px; //18px
								font-family: $body-font-family;
								color: #595959;
								@include breakpoint(large down) {
								  font-size: 12px;
								}
							}

							@media (max-width: 2000px) and (min-width: 1199px){
								h1{
									font-size: 1.8rem;
									padding: 0 0 10px 0;//20px 0 10px 0
								}
								p{
									font-size: 16px;
								}
							}
						}
								.tattoo-left-img {
									position: relative;
									height: 50vh;
									.tattoo-right {
										height: 50vh;
										width: 50%;
										position: absolute;
										right:0;
										top:0;
										background-size: cover;
										background-repeat: no-repeat;
										background-position: top center;

									}
									.tattoo-left {
										height: 50vh;
										width: 50%;
										position: absolute;
										left:0;
										top:0;
										background-size: cover;
										background-repeat: no-repeat;
										background-position: top center;

									}
									.tattoo-left-one {
										height: 50vh;
										width: 100%;
										position: absolute;
										left:0;
										top:0;
										background-size: cover;
										background-repeat: no-repeat;
										background-position: top center;
									}
								}
					}
					li {
						height: 100vh!important;
					}
				}
				.bx-wrapper, .bx-viewport {
					height: 100vh!important;
				}

				.outer-left {
				  width: 1px;
				  height: 100%;
				  margin: 0 2%;
				  position: absolute;
				  overflow: hidden;
				}
				.inner-left {
					  position: absolute;
					  width: 100%;
					  height: 100vh;
					  background: $white;
					  top: 0;
					  /* box-shadow: 0px 0px 30px 20px grey; */
					  z-index: 1000;
				}

				.outer-right {
				  width: 1px;
				  height: 100%;
				  margin: 0 98%;
				  position: absolute;
				  overflow: hidden;
				}
				.inner-right {
					  position: absolute;
					  width: 100%;
					  height: 100vh;
					  background: $white;
					  top: 0;
					  /* box-shadow: 0px 0px 30px 20px grey; */
					  z-index: 1000;
				}
				.square-right {
					position: absolute;
					width: 150px;
					height: 150px;
					background: $white;
					top: 0;
					right:2%;
					z-index: 1001;
					.controls-wrap {
						position:relative;
						height:100%;
						.tattoo-prev-cont {
							@include vertical-align(15%, 35%);
							cursor:pointer;
						}
						.tattoo-next-cont {
							@include vertical-align(85%, 35%);
							cursor:pointer;
						}
						#tattoo-pager {
							@include vertical-align(50%, 75%);
						    // bottom:-80%;
							// width: 100%;
						    // height:100%;
							// position:absolute;
						}
						#tattoo-pager ul {
						    list-style: none;
							margin-left:0;
							margin-bottom:0;
						}
						#tattoo-pager li {
						    display:inline-block;
						}
						#tattoo-pager ul a {
							background: transparent;
						    border: 1px solid #666666;
						    text-indent: -9999px;
						    display: block;
						    width: 10px;
						    height: 10px;
						    margin: 0 3px;
						    outline: 0;
						    border-radius: 5px;
							&:hover{
								background-color: #28acbe;
								border: 1px solid #28acbe;
							}
						}
						.active {
							background-color: #28acbe!important;
							border: 1px solid #28acbe!important;
						}
					}
				}
				@media (max-width: 2100px) and (min-width: 1800px){
					.square-right {
						width: 120px;
						height: 120px;
					}
				}
				@media (max-width: 1800px) and (min-width: 1500px){
					.square-right {
						width: 100px;
						height: 100px;
					}
					.tattoo-next-cont, .tattoo-prev-cont {
						width:30%;
					}
					#tattoo-pager ul a {
						margin: 0 2px!important;
					}
				}
				@media (max-width: 1500px) and (min-width: 1024px){
					.square-right {
						width: 70px;
						height: 70px;
					}
					.tattoo-next-cont, .tattoo-prev-cont {
						width:30%;
					}
					#tattoo-pager ul a {
						margin: 0!important;
						width: 6px!important;//8px
						height: 6px!important;//8px
						border-radius: 4px!important;
						margin: 0 2px!important;
					}
				}
			} //.tattoowrap end
		.tattoowrap-mobile {
			background: #d7d7d7;
			.tattoo-text-mobile {
				position:relative;
				padding-bottom:20px;
				// height:620px;
				height:200px;
				.tattoo-copy-mobile {
						@include vertical-align(50%, 50%);
						z-index:5;
				}
				.tattoo-mobile-prev {
					@include vertical-align(5%, 50%);
					cursor:pointer;
					width:30px;
					 margin-top: 30px;
					// margin-top: 40px;
					z-index:6;
				}
				.tattoo-mobile-next {
					@include vertical-align(95%, 50%);
					cursor:pointer;
					width:30px;
					margin-top: 30px;
					// margin-top: 40px;
					z-index:6;
				}
				h1,h2,h3,h4,h5,h6 {
					font-family: $header-font-family;
				}

				h1 {
					padding: 80px 20% 20px 20%;
					color: #28acbe;
					font-size: 1.2rem;
					text-transform: capitalize;
					// @include breakpoint(large down) {
					//   font-size: 1.3rem;
					//   padding: 20px 0;
					// }
				}

				p {
					font-size: 14px;
					font-family: $body-font-family;
					color: #595959;
					padding: 0 20%;
					margin-bottom: 10px;
					// @include breakpoint(large down) {
					//   font-size: 12px;
					// }
				}
			}
			ul {
				margin-left: 0;
				img {
					width:100%;
				}
			}
			.tattoo-descr-mobile {
				padding:30px 5% 30px 5%;
				p{
					margin-bottom:0;
				}
			}
		} //.tattoowrap-mobile end
}// tattoo-central end

#Error404 {
	position: relative;
	height: 100%;
	width: 100%;
	 @include cover-background('../img/error_bkg.jpg');

		 .HerotextAlign {
			 @include breakpoint(medium up) {
     				@include vertical-align(50%, 41%);
			}
			@include breakpoint(small only) {
				  @include vertical-align(50%, 5%);
			}
     	}
     	.HerotextAlignbtm {
			@include breakpoint(medium up) {
				   @include vertical-align(50%, 98%);
		   }
		   @include breakpoint(small only) {
				  @include vertical-align(50%, 95%);
		  }
     	}


	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
		color: $white;
	}
	h1 {
		line-height: 1;
		@include breakpoint(medium only) {
  		  font-size: 1.7rem;
	    }
		@include breakpoint(small only) {
  		  font-size: 1.5rem;
	    }
	}
	p {
		font-family: $body-font-family;
		color: $white;
		font-size: 20px;
		@include breakpoint(medium up) {
  		  padding: 20px 0;
	    }

		margin-bottom: 0;
		@include breakpoint(medium down) {
			font-size: 14px;
		}
	}
	.Iconsocial404 {
		@include breakpoint(medium up) {
			padding-top: 20px;
		}
		img {
			z-index: 100;
			// width: 45px;
			// padding: 10px;
			width: 40px;
			padding: 7px;
			transition: all 1s ease;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
	.Mytel {
		color: $white;
		display: inline-block;
		transition: all 1s ease;
		&:hover {
		   transform: scale(1.1);
	   }
   	}

	.errorbtns {
		margin: 30px 5% 30px 0;
		padding: 1rem;
		font-family: $body-font-family;
		color: $white;
		text-transform: uppercase;
		font-size: 20px;
		@include breakpoint(xlarge down) {
			font-size: 14px;
		}
		@include breakpoint(small only) {
			margin: 10px auto 0 auto;
		}
		font-weight: 600;
		border: 1.5px solid #fefefe;
		transition: all 0.5s ease;
		&:hover {
			background: $white;
			color: $primary-color;
		}
	}
	@media (max-width: 2000px) and (min-width: 1441px){
	  .contactcareers {
		  font-size: 16px;
	  }
	}

	@media (max-width: 1260px) and (min-width: 1024px) {
		 .contactcareers {
			 font-size: 10px;
		 }
	 }
}

#NeighbourhoodImages {
	p {
		margin-bottom: 0;
		color: #595959;
		line-height: 1.3;
		font-size: 20px;
		font-family: $body-font-family;
		@include breakpoint(medium down) {
		  font-size: 18px;
		}
	}

	/*@media (max-width: 2000px) and (min-width: 1024px){
		p{
			font-size: 16px!important;
		}
	}*/
	@include breakpoint(medium up) {
		margin-top: 80px;
		.columns {
			padding: 20px 10px 0 10px!important;
		}
	}
	@include breakpoint(small only) {
		.columns {
			padding: 0!important;
			.MyDescription {
				margin: 0 auto;
				padding: 25px 0;
			}
		}
	}
	.MyDescription {
		margin: 50px auto;
	}
}

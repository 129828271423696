#signup {
	//  padding: 30px 0;
	 @include breakpoint(large down) {
		 padding: 0 10px;
		 @include cover-background('../img/newsletter-signup/background-signup-m.jpg');
	}
	// max-width: 75rem;
	margin:0 auto;
	.row {
		    max-width: 45rem;
	}
	// background-image: url(../img/newsletter-signup/background-signup.jpg);
	// background-size: cover;
	// background-repeat: no-repeat;
	// background-position: top center;
	@include cover-background('../img/newsletter-signup/background-signup-m.jpg');
	#mc_embed_signup{
		background:transparent;
		clear:left;
		font-family: $header-font-family;
	}
	#mc_embed_signup form {
		    padding: 0;
	}
	#mc_embed_signup .mc-field-group {
		width:100%;
		padding-bottom:0;
	}
	.first-name {
		padding-right:10px;
		@include breakpoint(large down) {
   		 	padding-right:0;
   		}
	}
	.last-name {
		padding-left:10px;
		@include breakpoint(large down) {
   		 	padding-left:0;
   		}
	}
	input {
		margin: 0 0 5px;
		// border-color:$white!important;
		// border: 0!important;
		text-indent: 5px!important;
	}
	#mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error {
		    border: 2px solid #e85c41!important;
	}
	#mc_embed_signup .button {
		    margin: 25px 0 0 0;
			background-color: $primary-color;
			padding: 0;
			width:100%;
			border: 1px solid $primary-color!important;
			font-family: $body-font-family;
			font-weight: 700;
			font-size: 12px;
			&:hover {
				color: $primary-color;
				background-color: transparent;
				border: 1px solid $primary-color!important;
			}
	}
	#mc_embed_signup div#mce-responses {
		margin:0!important;
		padding:0!important;
	}
	#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
		// margin: 0;
    	padding: 5px 0!important;
		font-size: 16px!important;
		background-color: transparent!important;
	}
	#mc_embed_signup #mce-success-response, #mc_embed_signup #mce-error-response {
		font-size: 22px!important;
		margin: 0!important;
    	padding: 5px 0!important;
	}
	.signup-introduction {
		padding:0 0 10px 0!important;
	}
	.signup-header {
		img{
			padding-top:50px;
			width:100%;
		}
		h6 {
			font-family: $header-font-family;
			font-weight: 600;
			padding-right:10px;
		}
		.top-row {
			padding-top:10px;
			padding-bottom:10px;
		}

	}
	.cs-info {
		p {
			font-family: $body-font-family;
			// color: $primary-color;
			font-weight: 700;
			font-size: 12px;
		}
		margin:30px 0;
	}
}

#OfficeImages {
	@include breakpoint(medium up) {
		padding-top: 80px;
		padding-bottom:80px;
		
		.columns {
			padding: 20px 10px 0 10px!important;
		}
	}
	@include breakpoint(small only) {
		.columns {
			padding: 0!important;
		}
	}
}

#airmaxhero {
	@include cover-background('../img/work/individual/nike/AirMaxHero.jpg');
	@include breakpoint(large up) {
		margin-bottom: 27px;
	}
}
#hockeyhero {
	@include cover-background('../img/work/individual/nike/HockeyHero.jpg');
	@include breakpoint(large up) {
		margin-bottom: 27px;
	}
}
#gastownhero {
	@include cover-background('../img/work/individual/nike/GastownHero.jpg');
	@include breakpoint(large up) {
		margin-bottom: 27px;
	}
}
#af1hero {
	@include cover-background('../img/work/individual/nike/AF1Hero.jpg');
	@include breakpoint(large up) {
		margin-bottom: 27px;
	}
}
#w15khero {
	@include cover-background('../img/work/individual/nike/15KHero.jpg');
	@include breakpoint(large up) {
		margin-bottom: 27px;
	}
}
#brahaushero {
	@include cover-background('../img/work/individual/nike/BraHausHero.jpg');
	@include breakpoint(large up) {
		margin-bottom: 27px;
	}
}
#nbahero {
	@include cover-background('../img/work/individual/nike/NBAHero.jpg');
	@include breakpoint(large up) {
		margin-bottom: 27px;
	}
}
#uncledrewhero {
	@include cover-background('../img/work/individual/nike/UncleDrewHero.jpg');
	@include breakpoint(large up) {
		margin-bottom: 27px;
	}
}
#carlinghero {
	@include cover-background('../img/work/individual/molson/CarlingHero.jpg');

}
#madjackhero {
	@include cover-background('../img/work/individual/molson/MadJackHero.jpg');
}
#madjackhero-new {
	@include cover-background('../img/work/individual/molson/MadJackHero-new.jpg');
}
#belgianhero {
	@include cover-background('../img/work/individual/molson/BelgianHero.jpg');

}
#bayhero {
	@include cover-background('../img/work/individual/bay/BayHero.jpg');
}
#hudsonsbayhero {
	@include cover-background('../img/work/individual/hudsonsbay/hudsonsbayHero.jpg');
}
#ikohero {
	@include cover-background('../img/work/individual/iko/ikoHero.jpg');
}
#italpastahero {
	@include cover-background('../img/work/individual/italpasta/italpastaHero.jpg');
}

#bluejayshero {
	@include cover-background('../img/work/individual/bluejays/bluejaysHero.jpg');
}

#canadagoosehero {
	@include cover-background('../img/work/individual/canadagoose/canadagooseHero.jpg');
}
#echelonhero {
	@include cover-background('../img/work/individual/echelon/echelonHero.jpg');
	background-position: left center;
}
#reinharthero {
	@include cover-background('../img/work/individual/reinhart/reinhartHero.jpg');
}
#saintelizabethhero {
	@include cover-background('../img/work/individual/saintelizabeth/saintelizabethHero.jpg');
}
#lindthero {
	@include cover-background('../img/work/individual/lindt/LindtHero.jpg');
}
#humberhero {
	@include cover-background('../img/work/individual/humber/HumberHero.jpg');
}
#acanachero {
	@include cover-background('../img/work/individual/acanac/AcanacHero.jpg');
	#acanac-hero-logo {
		max-width:70%!important;
	}
}
#distributelhero {
	@include cover-background('../img/work/individual/distributel/DistributelHero.jpg');
	#distr-hero-logo {
		max-width:75%!important;
	}
}

#arterrapridehero {
	@include cover-background('../img/work/individual/arterra/ArterraPrideHero.jpg');
}

#arterrabaskhero {
	@include cover-background('../img/work/individual/arterra/ArterraBaskHero.jpg');
}
#baruvidahero {
	@include cover-background('../img/work/individual/baruvida/BaruvidaHero.jpg');
}
#diorhero {
	@include cover-background('../img/work/individual/dior/DiorHero.jpg');
}
#hrhhero {
	@include cover-background('../img/work/individual/humber/HRHHero.jpg');
}
#hrhfhero {
	@include cover-background('../img/work/individual/humber/hrhfHero.jpg');
}
#kpmghero {
	@include cover-background('../img/work/individual/kpmg/kpmgHero.jpg');
	background-position: right center;
}
#humbermeadowhero {
	@include cover-background('../img/work/individual/humbermeadow/humbermeadowhero.jpg');
}
#appficiencyhero {
	@include cover-background('../img/work/individual/appficiency/appficiencyhero.jpg');
}

#buddhahero {
	@include cover-background('../img/work/individual/buddha/buddhahero.jpg');
	.client-logo {
		max-width:395px !important;
	}
}

#deciemhero {
	@include cover-background('../img/work/individual/deciem/deciemhero.jpg');
	.client-logo {
		max-width:460px !important;
	}
}

#twobearshero {
	@include cover-background('../img/work/individual/twobears/twobearshero.jpg');
	.client-logo {
		max-width:340px !important;
	}
}

#millerhero {
	@include cover-background('../img/work/individual/miller/millerhero.jpg');
	.client-logo {
		max-width:390px !important;
	}
}

.case-study-hero {
	position: relative;
	height: 100%;
	width: 100%;
	z-index:1;

	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.3;
		padding: 30px 0;
		color: #595959;
		text-transform: capitalize;
		@include breakpoint(medium down) {
  		  font-size: 1.7rem;
	    }
		@include breakpoint(xlarge only) {
			font-size: 2.9rem;
		}
	}
	p {
		color: #595959;
		line-height: 1.3;
		font-size: 20px;
		font-family: $body-font-family;
		@include breakpoint(large only) {
			font-size: 18px;
		}
		@include breakpoint(medium down) {
		  font-size: 14px;
		}
	}

	.HerotextAlign {
		@include vertical-align(50%, 50%);

		.client-logo {
			width: 60vw;
			max-width: 570px;
		}
	}
	 //scrolling to the next section

  .scrollDown  {
			@include vertical-align(50%, 95%);
 	    z-index:5;
 	    position: absolute;
 	    width: 20px;
 	    -webkit-animation: fadeInOut 2s infinite;
		 }
     @-webkit-keyframes fadeInOut {
			 from { opacity: 0.2; }
			 50% { opacity: 1.0; }
			 to { opacity: 0.2; }
			}

	  //social icons
	  .Iconsocial {
		  @include vertical-align(2%, 95%);
		  z-index: 1;
		  width: 65px;
		  img {
			 transition: all 1s ease;
			  &:hover {
				  transform: scale(1.1);
			  }
		  }
	  }
	  @media (max-width: 1920px) {
		  .Iconsocial {
			  // width: 60px;
			  li > a {
				  padding: 0.4rem 1rem!important;
			  }
		  } //Iconsocial
	}
}

@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
   ========================================================================== */
/**
 * 1. Change the default font family in all browsers (opinionated).
 * 2. Correct the line height in all browsers.
 * 3. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
figcaption,
figure {
  display: block; }

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Add the correct display in IE.
 */
main {
  display: block; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */ }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible; }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
   */ }

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto; }

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE, and Firefox.
 */
details {
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/*
 * Add the correct display in IE 9-.
 */
menu {
  display: block; }

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }

[data-whatinput='mouse'] button {
  outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }

.row::before, .row::after {
  display: table;
  content: ' '; }

.row::after {
  clear: both; }

.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

@media print, screen and (min-width: 40em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem; } }

@media print, screen and (min-width: 64em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem; } }

.row .row.collapse {
  margin-right: 0;
  margin-left: 0; }

.row.expanded {
  max-width: none; }

.row.expanded .row {
  margin-right: auto;
  margin-left: auto; }

.row:not(.expanded) .row {
  max-width: none; }

.row.gutter-small > .column, .row.gutter-small > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.row.gutter-medium > .column, .row.gutter-medium > .columns {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

@media print, screen and (min-width: 40em) {
  .column, .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

.column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
  float: right; }

.column.end:last-child:last-child, .end.columns:last-child:last-child {
  float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.3333333333%; }

.small-push-1 {
  position: relative;
  left: 8.3333333333%; }

.small-pull-1 {
  position: relative;
  left: -8.3333333333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.6666666667%; }

.small-push-2 {
  position: relative;
  left: 16.6666666667%; }

.small-pull-2 {
  position: relative;
  left: -16.6666666667%; }

.small-offset-1 {
  margin-left: 8.3333333333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.6666666667%; }

.small-4 {
  width: 33.3333333333%; }

.small-push-4 {
  position: relative;
  left: 33.3333333333%; }

.small-pull-4 {
  position: relative;
  left: -33.3333333333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.6666666667%; }

.small-push-5 {
  position: relative;
  left: 41.6666666667%; }

.small-pull-5 {
  position: relative;
  left: -41.6666666667%; }

.small-offset-4 {
  margin-left: 33.3333333333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.6666666667%; }

.small-7 {
  width: 58.3333333333%; }

.small-push-7 {
  position: relative;
  left: 58.3333333333%; }

.small-pull-7 {
  position: relative;
  left: -58.3333333333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.6666666667%; }

.small-push-8 {
  position: relative;
  left: 66.6666666667%; }

.small-pull-8 {
  position: relative;
  left: -66.6666666667%; }

.small-offset-7 {
  margin-left: 58.3333333333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.6666666667%; }

.small-10 {
  width: 83.3333333333%; }

.small-push-10 {
  position: relative;
  left: 83.3333333333%; }

.small-pull-10 {
  position: relative;
  left: -83.3333333333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.6666666667%; }

.small-push-11 {
  position: relative;
  left: 91.6666666667%; }

.small-pull-11 {
  position: relative;
  left: -91.6666666667%; }

.small-offset-10 {
  margin-left: 83.3333333333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.6666666667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }

.small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
  clear: none; }

.small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
  clear: both; }

.small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
  float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }

.small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
  clear: none; }

.small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
  clear: both; }

.small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
  float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.3333333333%; }

.small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
  clear: none; }

.small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
  clear: both; }

.small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
  float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }

.small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
  clear: none; }

.small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
  clear: both; }

.small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
  float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }

.small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
  clear: none; }

.small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
  clear: both; }

.small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
  float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.6666666667%; }

.small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
  clear: none; }

.small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
  clear: both; }

.small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
  float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.2857142857%; }

.small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
  clear: none; }

.small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
  clear: both; }

.small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
  float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }

.small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
  clear: none; }

.small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
  clear: both; }

.small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
  float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }

.small-centered, .small-centered:last-child:not(:first-child) {
  float: none;
  clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.3333333333%; }
  .medium-push-1 {
    position: relative;
    left: 8.3333333333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.3333333333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.6666666667%; }
  .medium-push-2 {
    position: relative;
    left: 16.6666666667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.6666666667%; }
  .medium-offset-1 {
    margin-left: 8.3333333333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.6666666667%; }
  .medium-4 {
    width: 33.3333333333%; }
  .medium-push-4 {
    position: relative;
    left: 33.3333333333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.3333333333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.6666666667%; }
  .medium-push-5 {
    position: relative;
    left: 41.6666666667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.6666666667%; }
  .medium-offset-4 {
    margin-left: 33.3333333333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.6666666667%; }
  .medium-7 {
    width: 58.3333333333%; }
  .medium-push-7 {
    position: relative;
    left: 58.3333333333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.3333333333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.6666666667%; }
  .medium-push-8 {
    position: relative;
    left: 66.6666666667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.6666666667%; }
  .medium-offset-7 {
    margin-left: 58.3333333333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.6666666667%; }
  .medium-10 {
    width: 83.3333333333%; }
  .medium-push-10 {
    position: relative;
    left: 83.3333333333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.3333333333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.6666666667%; }
  .medium-push-11 {
    position: relative;
    left: 91.6666666667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.6666666667%; }
  .medium-offset-10 {
    margin-left: 83.3333333333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.6666666667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
  .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
    float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
  .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
    float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.3333333333%; }
  .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
    float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
  .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
    float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
  .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
    float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.6666666667%; }
  .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
    float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.2857142857%; }
  .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
    float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
  .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
    float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
  .medium-centered, .medium-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.3333333333%; }
  .large-push-1 {
    position: relative;
    left: 8.3333333333%; }
  .large-pull-1 {
    position: relative;
    left: -8.3333333333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.6666666667%; }
  .large-push-2 {
    position: relative;
    left: 16.6666666667%; }
  .large-pull-2 {
    position: relative;
    left: -16.6666666667%; }
  .large-offset-1 {
    margin-left: 8.3333333333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.6666666667%; }
  .large-4 {
    width: 33.3333333333%; }
  .large-push-4 {
    position: relative;
    left: 33.3333333333%; }
  .large-pull-4 {
    position: relative;
    left: -33.3333333333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.6666666667%; }
  .large-push-5 {
    position: relative;
    left: 41.6666666667%; }
  .large-pull-5 {
    position: relative;
    left: -41.6666666667%; }
  .large-offset-4 {
    margin-left: 33.3333333333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.6666666667%; }
  .large-7 {
    width: 58.3333333333%; }
  .large-push-7 {
    position: relative;
    left: 58.3333333333%; }
  .large-pull-7 {
    position: relative;
    left: -58.3333333333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.6666666667%; }
  .large-push-8 {
    position: relative;
    left: 66.6666666667%; }
  .large-pull-8 {
    position: relative;
    left: -66.6666666667%; }
  .large-offset-7 {
    margin-left: 58.3333333333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.6666666667%; }
  .large-10 {
    width: 83.3333333333%; }
  .large-push-10 {
    position: relative;
    left: 83.3333333333%; }
  .large-pull-10 {
    position: relative;
    left: -83.3333333333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.6666666667%; }
  .large-push-11 {
    position: relative;
    left: 91.6666666667%; }
  .large-pull-11 {
    position: relative;
    left: -91.6666666667%; }
  .large-offset-10 {
    margin-left: 83.3333333333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.6666666667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
  .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
    float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
  .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
    float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.3333333333%; }
  .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
    float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
  .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
    float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
  .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
    float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.6666666667%; }
  .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
    float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.2857142857%; }
  .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
    float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
  .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
    float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
  .large-centered, .large-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }

.column-block > :last-child {
  margin-bottom: 0; }

@media print, screen and (min-width: 40em) {
  .column-block {
    margin-bottom: 1.875rem; }
  .column-block > :last-child {
    margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }

h1 small, .h1 small,
h2 small, .h2 small,
h3 small, .h3 small,
h4 small, .h4 small,
h5 small, .h5 small,
h6 small, .h6 small {
  line-height: 0;
  color: #cacaca; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #1779ba;
  text-decoration: none;
  cursor: pointer; }

a:hover, a:focus {
  color: #1468a0; }

a img {
  border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }

dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }

blockquote, blockquote p {
  line-height: 1.6;
  color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }

cite:before {
  content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }

p + .stat {
  margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }

[type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
textarea:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fefefe;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }

textarea[rows] {
  height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }

[type='checkbox'] + label[for],
[type='radio'] + label[for] {
  cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }

label.middle {
  margin: 0 0 1rem;
  padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem; }

.input-group > :first-child {
  border-radius: 0 0 0 0; }

.input-group > :last-child > * {
  border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%; }

.input-group-label:first-child {
  border-right: 0; }

.input-group-label:last-child {
  border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 2.5rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%; }

.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  height: 2.5rem;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem; }

.input-group .input-group-button {
  display: table-cell; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }

.fieldset legend {
  margin: 0;
  margin-left: -0.1875rem;
  padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

@media screen and (min-width: 0\0) {
  select {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }

select:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fefefe;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

select:disabled {
  background-color: #e6e6e6;
  cursor: not-allowed; }

select::-ms-expand {
  display: none; }

select[multiple] {
  height: auto;
  background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea; }

.is-invalid-input:not(:focus)::placeholder {
  color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }

.form-error.is-visible {
  display: block; }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #1779ba;
  color: #fefefe; }

[data-whatinput='mouse'] .button {
  outline: 0; }

.button:hover, .button:focus {
  background-color: #14679e;
  color: #fefefe; }

.button.tiny {
  font-size: 0.6rem; }

.button.small {
  font-size: 0.75rem; }

.button.large {
  font-size: 1.25rem; }

.button.expanded {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0; }

.button.primary {
  background-color: #1779ba;
  color: #fefefe; }

.button.primary:hover, .button.primary:focus {
  background-color: #126195;
  color: #fefefe; }

.button.secondary {
  background-color: #767676;
  color: #fefefe; }

.button.secondary:hover, .button.secondary:focus {
  background-color: #5e5e5e;
  color: #fefefe; }

.button.success {
  background-color: #3adb76;
  color: #0a0a0a; }

.button.success:hover, .button.success:focus {
  background-color: #22bb5b;
  color: #0a0a0a; }

.button.warning {
  background-color: #ffae00;
  color: #0a0a0a; }

.button.warning:hover, .button.warning:focus {
  background-color: #cc8b00;
  color: #0a0a0a; }

.button.alert {
  background-color: #cc4b37;
  color: #fefefe; }

.button.alert:hover, .button.alert:focus {
  background-color: #a53b2a;
  color: #fefefe; }

.button.disabled, .button[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
  background-color: #1779ba;
  color: #fefefe; }

.button.disabled.primary, .button[disabled].primary {
  opacity: 0.25;
  cursor: not-allowed; }

.button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
  background-color: #1779ba;
  color: #fefefe; }

.button.disabled.secondary, .button[disabled].secondary {
  opacity: 0.25;
  cursor: not-allowed; }

.button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #767676;
  color: #fefefe; }

.button.disabled.success, .button[disabled].success {
  opacity: 0.25;
  cursor: not-allowed; }

.button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #3adb76;
  color: #0a0a0a; }

.button.disabled.warning, .button[disabled].warning {
  opacity: 0.25;
  cursor: not-allowed; }

.button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #ffae00;
  color: #0a0a0a; }

.button.disabled.alert, .button[disabled].alert {
  opacity: 0.25;
  cursor: not-allowed; }

.button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #cc4b37;
  color: #fefefe; }

.button.hollow {
  border: 1px solid #1779ba;
  color: #1779ba; }

.button.hollow, .button.hollow:hover, .button.hollow:focus {
  background-color: transparent; }

.button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
  background-color: transparent; }

.button.hollow:hover, .button.hollow:focus {
  border-color: #0c3d5d;
  color: #0c3d5d; }

.button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
  border: 1px solid #1779ba;
  color: #1779ba; }

.button.hollow.primary {
  border: 1px solid #1779ba;
  color: #1779ba; }

.button.hollow.primary:hover, .button.hollow.primary:focus {
  border-color: #0c3d5d;
  color: #0c3d5d; }

.button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
  border: 1px solid #1779ba;
  color: #1779ba; }

.button.hollow.secondary {
  border: 1px solid #767676;
  color: #767676; }

.button.hollow.secondary:hover, .button.hollow.secondary:focus {
  border-color: #3b3b3b;
  color: #3b3b3b; }

.button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
  border: 1px solid #767676;
  color: #767676; }

.button.hollow.success {
  border: 1px solid #3adb76;
  color: #3adb76; }

.button.hollow.success:hover, .button.hollow.success:focus {
  border-color: #157539;
  color: #157539; }

.button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76; }

.button.hollow.warning {
  border: 1px solid #ffae00;
  color: #ffae00; }

.button.hollow.warning:hover, .button.hollow.warning:focus {
  border-color: #805700;
  color: #805700; }

.button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00; }

.button.hollow.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37; }

.button.hollow.alert:hover, .button.hollow.alert:focus {
  border-color: #67251a;
  color: #67251a; }

.button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37; }

.button.clear {
  border: 1px solid #1779ba;
  color: #1779ba; }

.button.clear, .button.clear:hover, .button.clear:focus {
  background-color: transparent; }

.button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
  background-color: transparent; }

.button.clear:hover, .button.clear:focus {
  border-color: #0c3d5d;
  color: #0c3d5d; }

.button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border: 1px solid #1779ba;
  color: #1779ba; }

.button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border-color: transparent; }

.button.clear.primary {
  border: 1px solid #1779ba;
  color: #1779ba; }

.button.clear.primary:hover, .button.clear.primary:focus {
  border-color: #0c3d5d;
  color: #0c3d5d; }

.button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border: 1px solid #1779ba;
  color: #1779ba; }

.button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border-color: transparent; }

.button.clear.secondary {
  border: 1px solid #767676;
  color: #767676; }

.button.clear.secondary:hover, .button.clear.secondary:focus {
  border-color: #3b3b3b;
  color: #3b3b3b; }

.button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border: 1px solid #767676;
  color: #767676; }

.button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border-color: transparent; }

.button.clear.success {
  border: 1px solid #3adb76;
  color: #3adb76; }

.button.clear.success:hover, .button.clear.success:focus {
  border-color: #157539;
  color: #157539; }

.button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76; }

.button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border-color: transparent; }

.button.clear.warning {
  border: 1px solid #ffae00;
  color: #ffae00; }

.button.clear.warning:hover, .button.clear.warning:focus {
  border-color: #805700;
  color: #805700; }

.button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00; }

.button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border-color: transparent; }

.button.clear.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37; }

.button.clear.alert:hover, .button.clear.alert:focus {
  border-color: #67251a;
  color: #67251a; }

.button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37; }

.button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border-color: transparent; }

.button.dropdown::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4em;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #fefefe transparent transparent;
  position: relative;
  top: 0.4em;
  display: inline-block;
  float: right;
  margin-left: 1em; }

.button.dropdown.hollow::after {
  border-top-color: #1779ba; }

.button.dropdown.hollow.primary::after {
  border-top-color: #1779ba; }

.button.dropdown.hollow.secondary::after {
  border-top-color: #767676; }

.button.dropdown.hollow.success::after {
  border-top-color: #3adb76; }

.button.dropdown.hollow.warning::after {
  border-top-color: #ffae00; }

.button.dropdown.hollow.alert::after {
  border-top-color: #cc4b37; }

.button.arrow-only::after {
  top: -0.1em;
  float: none;
  margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }

.accordion[disabled] .accordion-title {
  cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba; }

:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0 0 0 0; }

.accordion-title:hover, .accordion-title:focus {
  background-color: #e6e6e6; }

.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  content: '+'; }

.is-active > .accordion-title::before {
  content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a; }

:last-child > .accordion-content:last-child {
  border-bottom: 1px solid #e6e6e6; }

.accordion-menu li {
  width: 100%; }

.accordion-menu a {
  padding: 0.7rem 1rem; }

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem; }

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem; }

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 1rem; }

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  left: auto;
  right: 1rem; }

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem; }

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.is-accordion-submenu-parent {
  position: relative; }

.has-submenu-toggle > a {
  margin-right: 40px; }

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px; }

.submenu-toggle::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  top: 0;
  bottom: 0;
  margin: auto; }

.submenu-toggle[aria-expanded='true']::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%; }

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #1779ba;
  color: #fefefe; }

.badge.primary {
  background: #1779ba;
  color: #fefefe; }

.badge.secondary {
  background: #767676;
  color: #fefefe; }

.badge.success {
  background: #3adb76;
  color: #0a0a0a; }

.badge.warning {
  background: #ffae00;
  color: #0a0a0a; }

.badge.alert {
  background: #cc4b37;
  color: #fefefe; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }

.breadcrumbs::before, .breadcrumbs::after {
  display: table;
  content: ' '; }

.breadcrumbs::after {
  clear: both; }

.breadcrumbs li {
  float: left;
  font-size: 0.6875rem;
  color: #0a0a0a;
  cursor: default;
  text-transform: uppercase; }

.breadcrumbs li:not(:last-child)::after {
  position: relative;
  margin: 0 0.75rem;
  opacity: 1;
  content: "/";
  color: #cacaca; }

.breadcrumbs a {
  color: #1779ba; }

.breadcrumbs a:hover {
  text-decoration: underline; }

.breadcrumbs .disabled {
  color: #cacaca;
  cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  font-size: 0; }

.button-group::before, .button-group::after {
  display: table;
  content: ' '; }

.button-group::after {
  clear: both; }

.button-group .button {
  margin: 0;
  margin-right: 1px;
  margin-bottom: 1px;
  font-size: 0.9rem; }

.button-group .button:last-child {
  margin-right: 0; }

.button-group.tiny .button {
  font-size: 0.6rem; }

.button-group.small .button {
  font-size: 0.75rem; }

.button-group.large .button {
  font-size: 1.25rem; }

.button-group.expanded {
  margin-right: -1px; }

.button-group.expanded::before, .button-group.expanded::after {
  display: none; }

.button-group.expanded .button:first-child:last-child {
  width: 100%; }

.button-group.expanded .button:first-child:nth-last-child(2), .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button {
  display: inline-block;
  width: calc(50% - 1px);
  margin-right: 1px; }

.button-group.expanded .button:first-child:nth-last-child(2):last-child, .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child {
  margin-right: -6px; }

.button-group.expanded .button:first-child:nth-last-child(3), .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button {
  display: inline-block;
  width: calc(33.3333333333% - 1px);
  margin-right: 1px; }

.button-group.expanded .button:first-child:nth-last-child(3):last-child, .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button:last-child {
  margin-right: -6px; }

.button-group.expanded .button:first-child:nth-last-child(4), .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button {
  display: inline-block;
  width: calc(25% - 1px);
  margin-right: 1px; }

.button-group.expanded .button:first-child:nth-last-child(4):last-child, .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button:last-child {
  margin-right: -6px; }

.button-group.expanded .button:first-child:nth-last-child(5), .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button {
  display: inline-block;
  width: calc(20% - 1px);
  margin-right: 1px; }

.button-group.expanded .button:first-child:nth-last-child(5):last-child, .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button:last-child {
  margin-right: -6px; }

.button-group.expanded .button:first-child:nth-last-child(6), .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button {
  display: inline-block;
  width: calc(16.6666666667% - 1px);
  margin-right: 1px; }

.button-group.expanded .button:first-child:nth-last-child(6):last-child, .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button:last-child {
  margin-right: -6px; }

.button-group.primary .button {
  background-color: #1779ba;
  color: #fefefe; }

.button-group.primary .button:hover, .button-group.primary .button:focus {
  background-color: #126195;
  color: #fefefe; }

.button-group.secondary .button {
  background-color: #767676;
  color: #fefefe; }

.button-group.secondary .button:hover, .button-group.secondary .button:focus {
  background-color: #5e5e5e;
  color: #fefefe; }

.button-group.success .button {
  background-color: #3adb76;
  color: #0a0a0a; }

.button-group.success .button:hover, .button-group.success .button:focus {
  background-color: #22bb5b;
  color: #0a0a0a; }

.button-group.warning .button {
  background-color: #ffae00;
  color: #0a0a0a; }

.button-group.warning .button:hover, .button-group.warning .button:focus {
  background-color: #cc8b00;
  color: #0a0a0a; }

.button-group.alert .button {
  background-color: #cc4b37;
  color: #fefefe; }

.button-group.alert .button:hover, .button-group.alert .button:focus {
  background-color: #a53b2a;
  color: #fefefe; }

.button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
  width: 100%; }

.button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
  margin-bottom: 0; }

@media print, screen and (min-width: 40em) {
  .button-group.stacked-for-small .button {
    width: auto;
    margin-bottom: 0; } }

@media print, screen and (min-width: 64em) {
  .button-group.stacked-for-medium .button {
    width: auto;
    margin-bottom: 0; } }

@media screen and (max-width: 39.9375em) {
  .button-group.stacked-for-small.expanded {
    display: block; }
  .button-group.stacked-for-small.expanded .button {
    display: block;
    margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a; }

.callout > :first-child {
  margin-top: 0; }

.callout > :last-child {
  margin-bottom: 0; }

.callout.primary {
  background-color: #d7ecfa;
  color: #0a0a0a; }

.callout.secondary {
  background-color: #eaeaea;
  color: #0a0a0a; }

.callout.success {
  background-color: #e1faea;
  color: #0a0a0a; }

.callout.warning {
  background-color: #fff3d9;
  color: #0a0a0a; }

.callout.alert {
  background-color: #f7e4e1;
  color: #0a0a0a; }

.callout.small {
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem; }

.callout.large {
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem; }

.card {
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a; }

.card > :last-child {
  margin-bottom: 0; }

.card-divider {
  padding: 1rem;
  background: #e6e6e6; }

.card-divider > :last-child {
  margin-bottom: 0; }

.card-section {
  padding: 1rem; }

.card-section > :last-child {
  margin-bottom: 0; }

.card-image {
  min-height: 1px; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }

[data-whatinput='mouse'] .close-button {
  outline: 0; }

.close-button:hover, .close-button:focus {
  color: #0a0a0a; }

.close-button.small {
  right: 0.66rem;
  top: 0.33em;
  font-size: 1.5em;
  line-height: 1; }

.close-button, .close-button.medium {
  right: 1rem;
  top: 0.5rem;
  font-size: 2em;
  line-height: 1; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative; }

[data-whatinput='mouse'] .menu li {
  outline: 0; }

.menu a,
.menu .button {
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: 0.7rem 1rem; }

.menu input,
.menu select,
.menu a,
.menu button {
  margin-bottom: 0; }

.menu input {
  display: inline-block; }

.menu li, .menu.horizontal li {
  display: inline-block; }

.menu.vertical li {
  display: block; }

.menu.expanded {
  display: table;
  width: 100%; }

.menu.expanded > li {
  display: table-cell;
  vertical-align: middle; }

.menu.simple li + li {
  margin-left: 1rem; }

.menu.simple a {
  padding: 0; }

@media print, screen and (min-width: 40em) {
  .menu.medium-horizontal li {
    display: inline-block; }
  .menu.medium-vertical li {
    display: block; }
  .menu.medium-expanded {
    display: table;
    width: 100%; }
  .menu.medium-expanded > li {
    display: table-cell;
    vertical-align: middle; }
  .menu.medium-simple {
    display: table;
    width: 100%; }
  .menu.medium-simple > li {
    display: table-cell;
    vertical-align: middle; } }

@media print, screen and (min-width: 64em) {
  .menu.large-horizontal li {
    display: inline-block; }
  .menu.large-vertical li {
    display: block; }
  .menu.large-expanded {
    display: table;
    width: 100%; }
  .menu.large-expanded > li {
    display: table-cell;
    vertical-align: middle; }
  .menu.large-simple {
    display: table;
    width: 100%; }
  .menu.large-simple > li {
    display: table-cell;
    vertical-align: middle; } }

.menu.nested {
  margin-right: 0;
  margin-left: 1rem; }

.menu.icons img,
.menu.icons i,
.menu.icons svg {
  vertical-align: middle; }

.menu.icons img + span,
.menu.icons i + span,
.menu.icons svg + span {
  vertical-align: middle; }

.menu.icon-top img,
.menu.icon-top i,
.menu.icon-top svg, .menu.icon-right img,
.menu.icon-right i,
.menu.icon-right svg, .menu.icon-bottom img,
.menu.icon-bottom i,
.menu.icon-bottom svg, .menu.icon-left img,
.menu.icon-left i,
.menu.icon-left svg {
  vertical-align: middle; }

.menu.icon-top img + span,
.menu.icon-top i + span,
.menu.icon-top svg + span, .menu.icon-right img + span,
.menu.icon-right i + span,
.menu.icon-right svg + span, .menu.icon-bottom img + span,
.menu.icon-bottom i + span,
.menu.icon-bottom svg + span, .menu.icon-left img + span,
.menu.icon-left i + span,
.menu.icon-left svg + span {
  vertical-align: middle; }

.menu.icon-left li a img,
.menu.icon-left li a i,
.menu.icon-left li a svg {
  margin-right: 0.25rem;
  display: inline-block; }

.menu.icon-right li a img,
.menu.icon-right li a i,
.menu.icon-right li a svg {
  margin-left: 0.25rem;
  display: inline-block; }

.menu.icon-top li a {
  text-align: center; }

.menu.icon-top li a img,
.menu.icon-top li a i,
.menu.icon-top li a svg {
  display: block;
  margin: 0 auto 0.25rem; }

.menu.icon-bottom li a {
  text-align: center; }

.menu.icon-bottom li a img,
.menu.icon-bottom li a i,
.menu.icon-bottom li a svg {
  display: block;
  margin: 0.25rem auto 0; }

.menu .is-active > a {
  background: #1779ba;
  color: #fefefe; }

.menu .active > a {
  background: #1779ba;
  color: #fefefe; }

.menu.align-left {
  text-align: left; }

.menu.align-right {
  text-align: right; }

.menu.align-right .submenu li {
  text-align: left; }

.menu.align-right.vertical .submenu li {
  text-align: right; }

.menu.align-right .nested {
  margin-right: 1rem;
  margin-left: 0; }

.menu.align-center {
  text-align: center; }

.menu.align-center .submenu li {
  text-align: left; }

.menu .menu-text {
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit; }

.menu-centered > .menu {
  text-align: center; }

.menu-centered > .menu .submenu li {
  text-align: left; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }

.menu-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #fefefe;
  box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
  content: ''; }

.menu-icon:hover::after {
  background: #cacaca;
  box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }

.menu-icon.dark::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #0a0a0a;
  box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
  content: ''; }

.menu-icon.dark:hover::after {
  background: #8a8a8a;
  box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }

.is-drilldown li {
  display: block; }

.is-drilldown.animate-height {
  transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fefefe; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear; }

.drilldown .is-drilldown-submenu.is-active {
  z-index: 1;
  display: block;
  transform: translateX(-100%); }

.drilldown .is-drilldown-submenu.is-closing {
  transform: translateX(100%); }

.drilldown .is-drilldown-submenu a {
  padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }

.drilldown .is-drilldown-submenu-parent > a::after {
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem; }

.dropdown-pane.is-opening {
  display: block; }

.dropdown-pane.is-open {
  visibility: visible;
  display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #1779ba; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }

.is-dropdown-menu.vertical.align-right {
  float: right; }

.is-dropdown-submenu-parent {
  position: relative; }

.is-dropdown-submenu-parent a::after {
  position: absolute;
  top: 50%;
  right: 5px;
  left: auto;
  margin-top: -6px; }

.is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
  top: 100%;
  left: auto; }

.is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto; }

.is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe; }

.dropdown .is-dropdown-submenu a {
  padding: 0.7rem 1rem; }

.is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
  right: 14px; }

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

.is-dropdown-submenu .is-dropdown-submenu {
  margin-top: -1px; }

.is-dropdown-submenu > li {
  width: 100%; }

.is-dropdown-submenu.js-dropdown-active {
  display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }

.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed,
.responsive-embed video,
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.responsive-embed.widescreen,
.flex-video.widescreen {
  padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #1779ba;
  color: #fefefe; }

.label.primary {
  background: #1779ba;
  color: #fefefe; }

.label.secondary {
  background: #767676;
  color: #fefefe; }

.label.success {
  background: #3adb76;
  color: #0a0a0a; }

.label.warning {
  background: #ffae00;
  color: #0a0a0a; }

.label.alert {
  background: #cc4b37;
  color: #fefefe; }

.media-object {
  display: block;
  margin-bottom: 1rem; }

.media-object img {
  max-width: none; }

@media screen and (max-width: 39.9375em) {
  .media-object.stack-for-small .media-object-section {
    padding: 0;
    padding-bottom: 1rem;
    display: block; }
  .media-object.stack-for-small .media-object-section img {
    width: 100%; } }

.media-object-section {
  display: table-cell;
  vertical-align: top; }

.media-object-section:first-child {
  padding-right: 1rem; }

.media-object-section:last-child:not(:nth-child(2)) {
  padding-left: 1rem; }

.media-object-section > :last-child {
  margin-bottom: 0; }

.media-object-section.middle {
  vertical-align: middle; }

.media-object-section.bottom {
  vertical-align: bottom; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }

.js-off-canvas-overlay.is-visible {
  opacity: 1;
  visibility: visible; }

.js-off-canvas-overlay.is-closable {
  cursor: pointer; }

.js-off-canvas-overlay.is-overlay-absolute {
  position: absolute; }

.js-off-canvas-overlay.is-overlay-fixed {
  position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }

[data-whatinput='mouse'] .off-canvas {
  outline: 0; }

.off-canvas.is-transition-push {
  z-index: 12; }

.off-canvas.is-closed {
  visibility: hidden; }

.off-canvas.is-transition-overlap {
  z-index: 13; }

.off-canvas.is-transition-overlap.is-open {
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }

.off-canvas.is-open {
  transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }

[data-whatinput='mouse'] .off-canvas-absolute {
  outline: 0; }

.off-canvas-absolute.is-transition-push {
  z-index: 12; }

.off-canvas-absolute.is-closed {
  visibility: hidden; }

.off-canvas-absolute.is-transition-overlap {
  z-index: 13; }

.off-canvas-absolute.is-transition-overlap.is-open {
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }

.off-canvas-absolute.is-open {
  transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(-250px); }

.off-canvas-content .off-canvas.position-left {
  transform: translateX(-250px); }

.off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
  transform: translate(0, 0); }

.off-canvas-content.is-open-left.has-transition-push {
  transform: translateX(250px); }

.position-left.is-transition-push {
  box-shadow: inset -13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(250px); }

.off-canvas-content .off-canvas.position-right {
  transform: translateX(250px); }

.off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
  transform: translate(0, 0); }

.off-canvas-content.is-open-right.has-transition-push {
  transform: translateX(-250px); }

.position-right.is-transition-push {
  box-shadow: inset 13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px); }

.off-canvas-content .off-canvas.position-top {
  transform: translateY(-250px); }

.off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
  transform: translate(0, 0); }

.off-canvas-content.is-open-top.has-transition-push {
  transform: translateY(250px); }

.position-top.is-transition-push {
  box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px); }

.off-canvas-content .off-canvas.position-bottom {
  transform: translateY(250px); }

.off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
  transform: translate(0, 0); }

.off-canvas-content.is-open-bottom.has-transition-push {
  transform: translateY(-250px); }

.position-bottom.is-transition-push {
  box-shadow: inset 0 13px 20px -13px rgba(10, 10, 10, 0.25); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }

.off-canvas-content.has-transition-push {
  transform: translate(0, 0); }

.off-canvas-content .off-canvas.is-open {
  transform: translate(0, 0); }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
  .position-left.reveal-for-medium .close-button {
    display: none; }
  .off-canvas-content .position-left.reveal-for-medium {
    transform: none; }
  .off-canvas-content.has-reveal-left {
    margin-left: 250px; }
  .position-left.reveal-for-medium ~ .off-canvas-content {
    margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
  .position-right.reveal-for-medium .close-button {
    display: none; }
  .off-canvas-content .position-right.reveal-for-medium {
    transform: none; }
  .off-canvas-content.has-reveal-right {
    margin-right: 250px; }
  .position-right.reveal-for-medium ~ .off-canvas-content {
    margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
  .position-top.reveal-for-medium .close-button {
    display: none; }
  .off-canvas-content .position-top.reveal-for-medium {
    transform: none; }
  .off-canvas-content.has-reveal-top {
    margin-top: 250px; }
  .position-top.reveal-for-medium ~ .off-canvas-content {
    margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
  .position-bottom.reveal-for-medium .close-button {
    display: none; }
  .off-canvas-content .position-bottom.reveal-for-medium {
    transform: none; }
  .off-canvas-content.has-reveal-bottom {
    margin-bottom: 250px; }
  .position-bottom.reveal-for-medium ~ .off-canvas-content {
    margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
  .position-left.reveal-for-large .close-button {
    display: none; }
  .off-canvas-content .position-left.reveal-for-large {
    transform: none; }
  .off-canvas-content.has-reveal-left {
    margin-left: 250px; }
  .position-left.reveal-for-large ~ .off-canvas-content {
    margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
  .position-right.reveal-for-large .close-button {
    display: none; }
  .off-canvas-content .position-right.reveal-for-large {
    transform: none; }
  .off-canvas-content.has-reveal-right {
    margin-right: 250px; }
  .position-right.reveal-for-large ~ .off-canvas-content {
    margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
  .position-top.reveal-for-large .close-button {
    display: none; }
  .off-canvas-content .position-top.reveal-for-large {
    transform: none; }
  .off-canvas-content.has-reveal-top {
    margin-top: 250px; }
  .position-top.reveal-for-large ~ .off-canvas-content {
    margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
  .position-bottom.reveal-for-large .close-button {
    display: none; }
  .off-canvas-content .position-bottom.reveal-for-large {
    transform: none; }
  .off-canvas-content.has-reveal-bottom {
    margin-bottom: 250px; }
  .position-bottom.reveal-for-large ~ .off-canvas-content {
    margin-bottom: 250px; } }

@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
  .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
    box-shadow: none;
    transform: none; }
  .off-canvas.in-canvas-for-medium .close-button {
    display: none; } }

@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
  .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
    box-shadow: none;
    transform: none; }
  .off-canvas.in-canvas-for-large .close-button {
    display: none; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }

.orbit-slide.no-motionui.is-active {
  top: 0;
  left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #fefefe; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe; }

[data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
  outline: 0; }

.orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
  background-color: rgba(10, 10, 10, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }

[data-whatinput='mouse'] .orbit-bullets {
  outline: 0; }

.orbit-bullets button {
  width: 1.2rem;
  height: 1.2rem;
  margin: 0.1rem;
  border-radius: 50%;
  background-color: #cacaca; }

.orbit-bullets button:hover {
  background-color: #8a8a8a; }

.orbit-bullets button.is-active {
  background-color: #8a8a8a; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }

.pagination::before, .pagination::after {
  display: table;
  content: ' '; }

.pagination::after {
  clear: both; }

.pagination li {
  margin-right: 0.0625rem;
  border-radius: 0;
  font-size: 0.875rem;
  display: none; }

.pagination li:last-child, .pagination li:first-child {
  display: inline-block; }

@media print, screen and (min-width: 40em) {
  .pagination li {
    display: inline-block; } }

.pagination a,
.pagination button {
  display: block;
  padding: 0.1875rem 0.625rem;
  border-radius: 0;
  color: #0a0a0a; }

.pagination a:hover,
.pagination button:hover {
  background: #e6e6e6; }

.pagination .current {
  padding: 0.1875rem 0.625rem;
  background: #1779ba;
  color: #fefefe;
  cursor: default; }

.pagination .disabled {
  padding: 0.1875rem 0.625rem;
  color: #cacaca;
  cursor: not-allowed; }

.pagination .disabled:hover {
  background: transparent; }

.pagination .ellipsis::after {
  padding: 0.1875rem 0.625rem;
  content: '\2026';
  color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }

.progress.primary .progress-meter {
  background-color: #1779ba; }

.progress.secondary .progress-meter {
  background-color: #767676; }

.progress.success .progress-meter {
  background-color: #3adb76; }

.progress.warning .progress-meter {
  background-color: #ffae00; }

.progress.alert .progress-meter {
  background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #1779ba; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }

.slider-fill.is-dragging {
  transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #1779ba;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }

[data-whatinput='mouse'] .slider-handle {
  outline: 0; }

.slider-handle:hover {
  background-color: #14679e; }

.slider-handle.is-dragging {
  transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }

.slider.vertical .slider-fill {
  top: 0;
  width: 0.5rem;
  max-height: 100%; }

.slider.vertical .slider-handle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1.4rem;
  height: 1.4rem;
  transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%; }

.sticky.is-stuck.is-at-top {
  top: 0; }

.sticky.is-stuck.is-at-bottom {
  bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }

.sticky.is-anchored.is-at-bottom {
  bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }

[data-whatinput='mouse'] .reveal {
  outline: 0; }

@media print, screen and (min-width: 40em) {
  .reveal {
    min-height: 0; } }

.reveal .column, .reveal .columns {
  min-width: 0; }

.reveal > :last-child {
  margin-bottom: 0; }

@media print, screen and (min-width: 40em) {
  .reveal {
    width: 600px;
    max-width: 75rem; } }

.reveal.collapse {
  padding: 0; }

@media print, screen and (min-width: 40em) {
  .reveal.tiny {
    width: 30%;
    max-width: 75rem; } }

@media print, screen and (min-width: 40em) {
  .reveal.small {
    width: 50%;
    max-width: 75rem; } }

@media print, screen and (min-width: 40em) {
  .reveal.large {
    width: 90%;
    max-width: 75rem; } }

.reveal.full {
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  height: 100vh;
  min-height: 100vh;
  margin-left: 0;
  border: 0;
  border-radius: 0; }

@media screen and (max-width: 39.9375em) {
  .reveal {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; } }

.reveal.without-overlay {
  position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fefefe;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }

input + .switch-paddle {
  margin: 0; }

.switch-paddle::after {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  transform: translate3d(0, 0, 0);
  border-radius: 0;
  background: #fefefe;
  transition: all 0.25s ease-out;
  content: ''; }

input:checked ~ .switch-paddle {
  background: #1779ba; }

input:checked ~ .switch-paddle::after {
  left: 2.25rem; }

[data-whatinput='mouse'] input:focus ~ .switch-paddle {
  outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }

input:checked + label > .switch-active {
  display: block; }

.switch-inactive {
  right: 15%; }

input:checked + label > .switch-inactive {
  display: none; }

.switch.tiny {
  height: 1.5rem; }

.switch.tiny .switch-paddle {
  width: 3rem;
  height: 1.5rem;
  font-size: 0.625rem; }

.switch.tiny .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1rem;
  height: 1rem; }

.switch.tiny input:checked ~ .switch-paddle::after {
  left: 1.75rem; }

.switch.small {
  height: 1.75rem; }

.switch.small .switch-paddle {
  width: 3.5rem;
  height: 1.75rem;
  font-size: 0.75rem; }

.switch.small .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1.25rem;
  height: 1.25rem; }

.switch.small input:checked ~ .switch-paddle::after {
  left: 2rem; }

.switch.large {
  height: 2.5rem; }

.switch.large .switch-paddle {
  width: 5rem;
  height: 2.5rem;
  font-size: 1rem; }

.switch.large .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 2rem;
  height: 2rem; }

.switch.large input:checked ~ .switch-paddle::after {
  left: 2.75rem; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }

thead,
tbody,
tfoot {
  border: 1px solid #f1f1f1;
  background-color: #fefefe; }

caption {
  padding: 0.5rem 0.625rem 0.625rem;
  font-weight: bold; }

thead {
  background: #f8f8f8;
  color: #0a0a0a; }

tfoot {
  background: #f1f1f1;
  color: #0a0a0a; }

thead tr,
tfoot tr {
  background: transparent; }

thead th,
thead td,
tfoot th,
tfoot td {
  padding: 0.5rem 0.625rem 0.625rem;
  font-weight: bold;
  text-align: left; }

tbody th,
tbody td {
  padding: 0.5rem 0.625rem 0.625rem; }

tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: #f1f1f1; }

table.unstriped tbody {
  background-color: #fefefe; }

table.unstriped tbody tr {
  border-bottom: 0;
  border-bottom: 1px solid #f1f1f1;
  background-color: #fefefe; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f3f3f3; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec; }

.table-scroll {
  overflow-x: auto; }

.table-scroll table {
  width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none; }

.tabs::before, .tabs::after {
  display: table;
  content: ' '; }

.tabs::after {
  clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }

.tabs.simple > li > a:hover {
  background: transparent; }

.tabs.primary {
  background: #1779ba; }

.tabs.primary > li > a {
  color: #fefefe; }

.tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
  background: #1673b1; }

.tabs-title {
  float: left; }

.tabs-title > a {
  display: block;
  padding: 1.25rem 1.5rem;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba; }

.tabs-title > a:hover {
  background: #fefefe;
  color: #1468a0; }

.tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
  background: #e6e6e6;
  color: #1779ba; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }

.tabs-panel.is-active {
  display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }

a.thumbnail:hover, a.thumbnail:focus {
  box-shadow: 0 0 6px 1px rgba(23, 121, 186, 0.5); }

a.thumbnail image {
  box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe; }

.title-bar::before, .title-bar::after {
  display: table;
  content: ' '; }

.title-bar::after {
  clear: both; }

.title-bar .menu-icon {
  margin-left: 0.25rem;
  margin-right: 0.25rem; }

.title-bar-left {
  float: left; }

.title-bar-right {
  float: right;
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe; }

.tooltip::before {
  position: absolute; }

.tooltip.bottom::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #0a0a0a;
  bottom: 100%; }

.tooltip.bottom.align-center::before {
  left: 50%;
  transform: translateX(-50%); }

.tooltip.top::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0a0a0a transparent transparent;
  top: 100%;
  bottom: auto; }

.tooltip.top.align-center::before {
  left: 50%;
  transform: translateX(-50%); }

.tooltip.left::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0a0a0a;
  left: 100%; }

.tooltip.left.align-center::before {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%); }

.tooltip.right::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0a0a0a transparent transparent;
  right: 100%;
  left: auto; }

.tooltip.right.align-center::before {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%); }

.tooltip.align-top::before {
  bottom: auto;
  top: 10%; }

.tooltip.align-bottom::before {
  bottom: 10%;
  top: auto; }

.tooltip.align-left::before {
  left: 10%;
  right: auto; }

.tooltip.align-right::before {
  left: auto;
  right: 10%; }

.top-bar {
  padding: 0.5rem; }

.top-bar::before, .top-bar::after {
  display: table;
  content: ' '; }

.top-bar::after {
  clear: both; }

.top-bar,
.top-bar ul {
  background-color: #e6e6e6; }

.top-bar input {
  max-width: 200px;
  margin-right: 1rem; }

.top-bar .input-group-field {
  width: 100%;
  margin-right: 0; }

.top-bar input.button {
  width: auto; }

.top-bar .top-bar-left,
.top-bar .top-bar-right {
  width: 100%; }

@media print, screen and (min-width: 40em) {
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    width: auto; } }

@media screen and (max-width: 63.9375em) {
  .top-bar.stacked-for-medium .top-bar-left,
  .top-bar.stacked-for-medium .top-bar-right {
    width: 100%; } }

@media screen and (max-width: 74.9375em) {
  .top-bar.stacked-for-large .top-bar-left,
  .top-bar.stacked-for-large .top-bar-right {
    width: 100%; } }

.top-bar-title {
  display: inline-block;
  float: left;
  padding: 0.5rem 1rem 0.5rem 0; }

.top-bar-title .menu-icon {
  bottom: 2px; }

.top-bar-left {
  float: left; }

.top-bar-right {
  float: right; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }

@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important; } }

@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }

@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important; } }

@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

/**
 * Foundation for Sites
 * Version 6.7.3
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/*
* Mixins
*/
/*
* Typography
*/
/* libre-baskerville-regular - latin */
@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/libre-baskerville/libre-baskerville-v4-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Libre Baskerville"), local("LibreBaskerville-Regular"), url("../fonts/libre-baskerville/libre-baskerville-v4-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/libre-baskerville/libre-baskerville-v4-latin-regular.woff2") format("woff2"), url("../fonts/libre-baskerville/libre-baskerville-v4-latin-regular.woff") format("woff"), url("../fonts/libre-baskerville/libre-baskerville-v4-latin-regular.ttf") format("truetype"), url("../fonts/libre-baskerville/libre-baskerville-v4-latin-regular.svg#LibreBaskerville") format("svg");
  /* Legacy iOS */ }

/* libre-baskerville-700 - latin */
@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/libre-baskerville/libre-baskerville-v4-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Libre Baskerville Bold"), local("LibreBaskerville-Bold"), url("../fonts/libre-baskerville/libre-baskerville-v4-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/libre-baskerville/libre-baskerville-v4-latin-700.woff2") format("woff2"), url("../fonts/libre-baskerville/libre-baskerville-v4-latin-700.woff") format("woff"), url("../fonts/libre-baskerville/libre-baskerville-v4-latin-700.ttf") format("truetype"), url("../fonts/libre-baskerville/libre-baskerville-v4-latin-700.svg#LibreBaskerville") format("svg");
  /* Legacy iOS */ }

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/open-sans/open-sans-v14-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Light"), local("OpenSans-Light"), url("../fonts/open-sans/open-sans-v14-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/open-sans-v14-latin-300.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v14-latin-300.woff") format("woff"), url("../fonts/open-sans/open-sans-v14-latin-300.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v14-latin-300.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/open-sans/open-sans-v14-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("../fonts/open-sans/open-sans-v14-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/open-sans-v14-latin-regular.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v14-latin-regular.woff") format("woff"), url("../fonts/open-sans/open-sans-v14-latin-regular.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v14-latin-regular.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/open-sans/open-sans-v14-latin-600.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url("../fonts/open-sans/open-sans-v14-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/open-sans-v14-latin-600.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v14-latin-600.woff") format("woff"), url("../fonts/open-sans/open-sans-v14-latin-600.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v14-latin-600.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/open-sans/open-sans-v14-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../fonts/open-sans/open-sans-v14-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/open-sans-v14-latin-700.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v14-latin-700.woff") format("woff"), url("../fonts/open-sans/open-sans-v14-latin-700.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v14-latin-700.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/open-sans/open-sans-v14-latin-800.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"), url("../fonts/open-sans/open-sans-v14-latin-800.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/open-sans-v14-latin-800.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v14-latin-800.woff") format("woff"), url("../fonts/open-sans/open-sans-v14-latin-800.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v14-latin-800.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/*
* Global style
*/
body, html {
  height: 100%;
  width: 100%;
  position: relative;
  font-family: "Open Sans", sans-serif, Arial; }

body {
  overflow-x: hidden; }

h1 {
  font-size: 2.4rem;
  text-transform: capitalize;
  margin-bottom: 0; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Libre Baskerville", serif; }

#CSMobileLogo {
  width: 80px; }

.button_container {
  position: absolute;
  top: 45px;
  right: 30px;
  height: 27px;
  width: 35px;
  cursor: pointer;
  z-index: 100;
  transition: opacity .25s ease; }
  @media screen and (min-width: 75em) {
    .button_container {
      display: none; } }
  .button_container.active .top {
    transform: translateY(11px) translateX(0) rotate(45deg);
    background: #FFF; }
  .button_container.active .middle {
    opacity: 0;
    background: #FFF; }
  .button_container.active .bottom {
    transform: translateY(-11px) translateX(0) rotate(-45deg);
    background: #FFF; }
  .button_container span {
    background: #FFF;
    border: none;
    height: 3px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .35s ease;
    cursor: pointer; }
    .button_container span:nth-of-type(2) {
      top: 11px; }
    .button_container span:nth-of-type(3) {
      top: 22px; }

.overlay {
  position: absolute;
  background: -webkit-linear-gradient(top left, #e04614 0%, #2fa2b4 100%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  z-index: 50; }
  .overlay.open {
    opacity: 1;
    visibility: visible;
    height: 100vh; }
  @media (max-height: 550px) {
    .overlay nav {
      height: 60% !important;
      top: 35% !important; } }
  .overlay nav {
    position: relative;
    height: 70%;
    top: 40%;
    transform: translateY(-50%);
    font-family: "Libre Baskerville", serif;
    font-weight: 300;
    font-size: 30px;
    text-align: center;
    z-index: 100; }
    .overlay nav ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      display: inline-block;
      position: relative;
      height: 90%; }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        .overlay nav ul {
          width: 66.66667%; } }
      .overlay nav ul li {
        display: block;
        height: calc(100% / 8);
        min-height: 30px;
        position: relative; }
        .overlay nav ul li a {
          display: block;
          position: relative;
          color: #FFF;
          text-decoration: none;
          overflow: hidden;
          font-size: 22px;
          transition: all 1.5s ease; }
          .overlay nav ul li a:hover:after, .overlay nav ul li a:focus:after, .overlay nav ul li a:active:after {
            width: 100%; }
      .overlay nav ul h5 {
        font-family: "Libre Baskerville", serif;
        color: #fefefe;
        font-size: 1rem; }
      .overlay nav ul .submit-btn {
        background-color: transparent;
        border: 1px solid #fefefe;
        color: #fefefe;
        text-transform: uppercase;
        margin: auto;
        display: block;
        font-size: 12px;
        font-weight: 800;
        width: 100%;
        padding: 0.56rem 0;
        transition: color 1.5s ease; }
        .overlay nav ul .submit-btn:hover {
          color: #fa4b13;
          cursor: pointer; }
      .overlay nav ul #keep-in-touch {
        border: 1px solid #fefefe;
        color: #fefefe;
        background-color: transparent;
        border-right-width: 0;
        box-shadow: 0;
        height: 1.999rem;
        transition: color 0.5s ease;
        width: 100%;
        font-size: 12px; }
  .overlay .IconsocialMobile {
    padding-top: 120px; }
    .overlay .IconsocialMobile img {
      z-index: 100;
      width: 33px;
      padding: 3px; }
    .overlay .IconsocialMobile #shop:before {
      content: "|";
      color: #fefefe;
      background: #fefefe;
      /* if line image is used, this is not necessary */
      width: 3px;
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px; }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%; }
  100% {
    opacity: 1;
    left: 0; } }

.bottomSpace {
  margin-bottom: 100px; }

.topSpace {
  margin-top: 50px; }

.topBar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  padding-top: 1rem;
  transition: top 0.3s; }
  @media print, screen and (max-width: 39.99875em) {
    .topBar {
      padding-bottom: 1rem;
      position: absolute;
      background: #333; } }
  .topBar .topBtn {
    padding-top: .5rem; }
  .topBar .topWrap {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .topBar .navWrap {
    padding: 1rem; }
  .topBar .buttonWrap {
    position: relative;
    z-index: 100;
    background-color: transparent; }
  .topBar .button_containerDesk {
    /*    position: absolute;
			height: 27px;
			width: 32px;
			top:12px;
			left:9px;
			cursor: pointer;
			-webkit-transition: opacity .25s ease;
			transition: opacity .25s ease;

*/ }
    .topBar .button_containerDesk:hover {
      opacity: .7; }
    .topBar .button_containerDesk.active .top {
      transform: translateY(12px) translateX(0) rotate(45deg);
      background: #fff; }
    .topBar .button_containerDesk.active .middle {
      opacity: 0;
      background: #fff; }
    .topBar .button_containerDesk.active .bottom {
      transform: translateY(-12px) translateX(0) rotate(-45deg);
      background: #fff; }
    .topBar .button_containerDesk.active .hoverMenu {
      opacity: 1; }
    .topBar .button_containerDesk span {
      background: #fff;
      border: none;
      height: 4px;
      width: 48%;
      position: absolute;
      top: 18px;
      left: 17px;
      -webkit-transition: all .35s ease;
      transition: all .35s ease;
      cursor: pointer; }
  .topBar .button_containerDesk span:nth-of-type(2) {
    top: 30px; }
  .topBar .button_containerDesk span:nth-of-type(3) {
    top: 42px; }

.logoImg {
  -webkit-transition: opacity .5s ease-in-out,-webkit-transform .5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  width: 70px;
  height: 64px; }

.logoImg {
  fill: white; }

.hoverMenu {
  -webkit-transition: opacity .5s ease-in-out,-webkit-transform .5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 64px;
  z-index: 50;
  opacity: 0;
  cursor: pointer; }

#toggleDesk:hover #menuLogo {
  opacity: 0; }

#toggleDesk:hover #menuTrack {
  opacity: 0;
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg); }

#toggleDesk:hover #logoHover {
  opacity: 1; }

.icon-bolt {
  -webkit-mask: url(../../assets/img/bolt_tracks_wh.svg);
  mask: url(../../assets/img/bolt_tracks_wh.svg); }

.icon {
  display: inline-block;
  width: 70px;
  height: 64px;
  background: white;
  -webkit-mask-size: cover;
  mask-size: cover; }

.icon:hover,
.icon:focus {
  background: #ff6d00; }

.button_containerDesk:hover {
  opacity: .7; }

.button_containerDesk.active .top {
  transform: translateY(11px) translateX(0) rotate(45deg);
  background: #fff; }

.button_containerDesk.active .middle {
  opacity: 0;
  background: #fff; }

.button_containerDesk.active .bottom {
  transform: translateY(-11px) translateX(0) rotate(-45deg);
  background: #fff; }

.button_containerDesk span {
  background: #fff;
  border: none;
  height: 3px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .35s ease;
  cursor: pointer; }
  .button_containerDesk span:nth-of-type(2) {
    top: 11px; }
  .button_containerDesk span:nth-of-type(3) {
    top: 22px; }

.overlayDesk {
  position: fixed;
  /* IE10+ */
  background-image: -ms-linear-gradient(308deg, #2fa2b4, #e04614);
  /* Mozilla Firefox */
  background-image: -moz-linear-gradient(308deg, #2fa2b4, #e04614);
  /* Opera */
  background-image: -o-linear-gradient(308deg, #2fa2b4, #e04614);
  /* Webkit */
  background-image: -webkit-linear-gradient(308deg, #2fa2b4, #e04614);
  /* W3C Markup */
  background-image: linear-gradient(308deg, #2fa2b4, #e04614);
  background-size: 200% 200%;
  -webkit-animation: AnimationName 5s ease infinite;
  -moz-animation: AnimationName 5s ease infinite;
  -o-animation: AnimationName 5s ease infinite;
  animation: AnimationName 5s ease infinite;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: hidden;
  z-index: 99; }
  .overlayDesk.open {
    opacity: 1;
    visibility: visible;
    height: 100%; }
    .overlayDesk.open .SocialWhite, .overlayDesk.open .SocialOrange {
      position: absolute;
      top: 95%;
      left: 2%;
      margin-right: -2%;
      -webkit-transform: translate(-2%, -95%);
      -khtml-transform: translate(-2%, -95%);
      -moz-transform: translate(-2%, -95%);
      -ms-transform: translate(-2%, -95%);
      transform: translate(-2%, -95%);
      width: 60px;
      left: 25px !important; }
      .overlayDesk.open .SocialWhite img, .overlayDesk.open .SocialOrange img {
        margin-right: 0; }
      .overlayDesk.open .SocialWhite .shopDesk::after, .overlayDesk.open .SocialOrange .shopDesk::after {
        content: " ";
        color: #fefefe;
        background: #fefefe;
        /* if line image is used, this is not necessary */
        width: 100%;
        height: 3px;
        display: inline-block;
        vertical-align: bottom; }
    .overlayDesk.open .SocialOrange {
      z-index: 99;
      opacity: 1; }
    .overlayDesk.open .SocialWhite {
      z-index: 100;
      opacity: 1; }
    .overlayDesk.open #facebook_d, .overlayDesk.open #twitter_d, .overlayDesk.open #instagram_d, .overlayDesk.open #linkedin_d, .overlayDesk.open #shopimg, .overlayDesk.open #dribbble_d, .overlayDesk.open #vimeo_d, .overlayDesk.open #behance_d {
      -webkit-transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out; }
    .overlayDesk.open #facebook_d:hover {
      opacity: 0; }
    .overlayDesk.open #twitter_d:hover {
      opacity: 0; }
    .overlayDesk.open #instagram_d:hover {
      opacity: 0; }
    .overlayDesk.open #linkedin_d:hover {
      opacity: 0; }
    .overlayDesk.open #shopimg:hover {
      opacity: 0; }
    .overlayDesk.open #dribbble_d:hover {
      opacity: 0; }
    .overlayDesk.open #vimeo_d:hover {
      opacity: 0; }
    .overlayDesk.open #behance_d:hover {
      opacity: 0; }
    @media (min-width: 1921px) {
      .overlayDesk.open .shopDesk {
        padding-bottom: 0.2rem; } }
    @media (max-width: 1920px) {
      .overlayDesk.open .IconsocialOverlay {
        width: 60px; }
        .overlayDesk.open .IconsocialOverlay li > a {
          padding: 0.4rem 1rem !important; }
        .overlayDesk.open .IconsocialOverlay .shopDesk::before {
          margin: 0.2rem 0 0.8rem 0; } }
  .overlayDesk #logo_d:hover #MenuLogoDesk_tracks {
    opacity: 0;
    -ms-transform: rotate(360deg);
    /* IE 9 */
    -webkit-transform: rotate(360deg);
    /* Chrome, Safari, Opera */
    transform: rotate(360deg); }
  .overlayDesk #logo_d:hover #MenuLogoDesk {
    opacity: 0; }
  .overlayDesk #logo_d:hover #bolt_flash_y {
    opacity: 1; }
  .overlayDesk #logo_d:hover #bolt_tracks_y {
    opacity: 1;
    -ms-transform: rotate(180deg);
    /* IE 9 */
    -webkit-transform: rotate(180deg);
    /* Chrome, Safari, Opera */
    transform: rotate(180deg); }
  .overlayDesk .logo_img {
    -webkit-transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    position: absolute;
    top: 5%;
    right: 3%;
    z-index: 100;
    width: 150px; }
  .overlayDesk .bolt_y {
    -webkit-transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    position: absolute;
    top: 5%;
    right: 3%;
    width: 150px;
    z-index: 100;
    opacity: 0; }
  @media (max-width: 2000px) and (min-width: 1024px) {
    .overlayDesk nav li {
      min-height: 65px !important; } }
  .overlayDesk nav {
    position: relative;
    height: 70%;
    top: 57%;
    transform: translateY(-50%);
    font-family: "Libre Baskerville", serif;
    font-weight: 300;
    font-size: 50px;
    text-align: center;
    z-index: 100;
    max-width: 75rem;
    margin: 0 auto; }
    @media (max-height: 550px) {
      .overlayDesk nav {
        font-size: 30px; } }
    .overlayDesk nav ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      display: inline-block;
      position: relative;
      height: 100%; }
      @media print, screen and (min-width: 64em) {
        .overlayDesk nav ul {
          width: 50%; } }
      .overlayDesk nav ul li {
        display: block;
        height: calc(100% / 8);
        min-height: 80px;
        max-height: 120px;
        position: relative;
        min-height: unset;
        max-height: unset; }
        .overlayDesk nav ul li a {
          display: block;
          position: relative;
          color: #FFF;
          text-decoration: none;
          overflow: hidden;
          transition: color 1.5s ease; }
          @media print, screen and (max-width: 39.99875em) {
            .overlayDesk nav ul li a {
              font-size: 25px; } }
          .overlayDesk nav ul li a:hover {
            color: #e04614; }
          .overlayDesk nav ul li a:hover:after, .overlayDesk nav ul li a:focus:after, .overlayDesk nav ul li a:active:after {
            width: 100%; }
      .overlayDesk nav ul h5 {
        font-family: "Libre Baskerville", serif;
        color: #fefefe;
        margin-top: 10px; }
      .overlayDesk nav ul .submit-btn {
        background-color: transparent;
        border: 1px solid #fefefe;
        color: #fefefe;
        text-transform: uppercase;
        margin: auto;
        display: block;
        font-size: 14px;
        font-weight: 800;
        width: 100%;
        padding: 0.71rem 0;
        transition: color 1.5s ease; }
        .overlayDesk nav ul .submit-btn:hover {
          color: #fa4b13;
          cursor: pointer; }
      .overlayDesk nav ul #keep-in-touch {
        border: 1px solid #fefefe;
        color: #fefefe;
        background-color: transparent;
        border-right-width: 0;
        box-shadow: 0;
        transition: color 0.5s ease; }

@-webkit-keyframes AnimationName {
  0% {
    background-position: 50% 0%; }
  50% {
    background-position: 50% 100%; }
  100% {
    background-position: 50% 0%; } }

@-moz-keyframes AnimationName {
  0% {
    background-position: 50% 0%; }
  50% {
    background-position: 50% 100%; }
  100% {
    background-position: 50% 0%; } }

@-o-keyframes AnimationName {
  0% {
    background-position: 50% 0%; }
  50% {
    background-position: 50% 100%; }
  100% {
    background-position: 50% 0%; } }

@keyframes AnimationName {
  0% {
    background-position: 50% 0%; }
  50% {
    background-position: 50% 100%; }
  100% {
    background-position: 50% 0%; } }

.bottomSpaceDesk {
  margin-bottom: 100px; }

.topSpaceDesk {
  margin-top: 50px; }

body.is-reveal-open {
  overflow: hidden; }
  body.is-reveal-open .revealwrap {
    display: block; }

.revealwrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: none; }
  .revealwrap .revealbox {
    position: relative;
    top: 0;
    left: 0; }
    .revealwrap .revealbox .WHclose {
      position: absolute;
      top: 0;
      right: 40px;
      font-size: 50px;
      color: #8a8a8a; }
      @media print, screen and (max-width: 63.99875em) {
        .revealwrap .revealbox .WHclose {
          right: 0; } }

.reveal-overlay {
  background-color: rgba(10, 10, 10, 0.81); }

.WHvideo {
  max-width: 100% !important; }

.WHslider {
  max-width: 70% !important; }
  @media print, screen and (max-width: 39.99875em) {
    .WHslider {
      max-width: 100% !important; } }

.reveal {
  border: 0;
  padding: 0;
  height: 80vh !important;
  overflow-x: hidden;
  background-color: transparent;
  width: 100% !important; }
  @media print, screen and (min-width: 64em) {
    .reveal {
      overflow-y: hidden; } }
  @media print, screen and (min-width: 64em) {
    .reveal img, .reveal video {
      height: 100%;
      margin: 0 auto; } }
  @media print, screen and (max-width: 63.99875em) {
    .reveal img, .reveal video {
      max-height: 50vh;
      margin: 50px auto; } }
  @media print, screen and (min-width: 64em) {
    .reveal video {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -50%);
      -khtml-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); } }
  .reveal ul {
    height: 100%;
    margin-left: 0; }
  @media print, screen and (min-width: 64em) {
    .reveal li img {
      height: 80vh; } }
  .reveal .bx-wrapper .bx-pager {
    bottom: 10px;
    left: 0;
    z-index: 99; }
  .reveal .bx-wrapper .bx-pager.bx-default-pager a {
    background: #fefefe !important;
    border: 0 !important;
    box-shadow: inset 3px 3px 4px rgba(0, 0, 0, 0.7) !important;
    width: 12px;
    height: 12px;
    border-radius: 7px;
    z-index: 99; }
    .reveal .bx-wrapper .bx-pager.bx-default-pager a:focus, .reveal .bx-wrapper .bx-pager.bx-default-pager a:hover {
      background: #fefefe !important; }
    .reveal .bx-wrapper .bx-pager.bx-default-pager a.active {
      background: #fa4b13 !important; }
  @media print, screen and (max-width: 63.99875em) {
    .reveal .bx-wrapper .bx-controls-direction a {
      display: none; } }
  @media print, screen and (min-width: 64em) {
    .reveal .bx-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -50%);
      -khtml-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); } }
  .reveal h1, .reveal h2, .reveal h3, .reveal h4, .reveal h5, .reveal h6, .reveal p {
    font-family: "Libre Baskerville", serif; }
  .reveal h1 {
    color: #fefefe;
    padding: 0;
    margin-bottom: 0;
    text-align: center; }
    @media print, screen and (max-width: 63.99875em) {
      .reveal h1 {
        font-size: 2rem; } }
  .reveal p {
    color: #fefefe;
    text-align: center; }
  @media (min-width: 1920px) {
    .reveal p {
      font-size: 20px !important; } }
  .reveal .myAudio {
    cursor: pointer; }

/*
* Hero section style
*/
#hero {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  /*@media (max-width: 2000px) and (min-width: 1024px){
		h1{
			font-size: 2rem!important;
		}
		p{
			font-size: 16px!important;
		}
	}*/
  background-image: url("../img/hero-img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  /* =============================================================================
	  VIDEO
	============================================================================= */
  /*@media (max-width: 1800px) and (min-width: 641px){
		.CTRlogo {
			width: 450px;
		}
	}*/
  /*@media (max-width: 2500px) and (min-width: 1800px){
		.wrapper {
			width:60%;
		}
	}

	@media (max-width: 1799px) and (min-width: 1200px){
		.wrapper {
			width:90%;
		}
	}
	@media (max-height: 700px){
		.wrapper {
		}
	}*/ }
  #hero h1, #hero h2, #hero h3, #hero h4, #hero h5, #hero h6 {
    font-family: "Libre Baskerville", serif; }
  #hero h1 {
    font-size: 2rem;
    color: #fefefe;
    text-transform: capitalize; }
    #hero h1 span {
      white-space: nowrap; }
    @media print, screen and (max-width: 63.99875em) {
      #hero h1 {
        font-size: 1.7rem; } }
  #hero #video-dots {
    background-image: url("../img/dot-pattern.png");
    background-repeat: repeat;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
  #hero video {
    margin: 0;
    padding: 0; }
  #hero .videoHero {
    position: absolute;
    top: 50%;
    left: 53%;
    margin-right: -53%;
    -webkit-transform: translate(-53%, -50%);
    -khtml-transform: translate(-53%, -50%);
    -moz-transform: translate(-53%, -50%);
    -ms-transform: translate(-53%, -50%);
    transform: translate(-53%, -50%);
    min-width: 100vw;
    min-height: 100vh; }
  #hero .CTRlogo {
    visibility: hidden;
    position: absolute;
    top: 48%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -48%);
    -khtml-transform: translate(-50%, -48%);
    -moz-transform: translate(-50%, -48%);
    -ms-transform: translate(-50%, -48%);
    transform: translate(-50%, -48%);
    width: 28%;
    z-index: 5; }
    @media print, screen and (max-width: 39.99875em) {
      #hero .CTRlogo {
        position: absolute;
        top: 36%;
        left: 50%;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -36%);
        -khtml-transform: translate(-50%, -36%);
        -moz-transform: translate(-50%, -36%);
        -ms-transform: translate(-50%, -36%);
        transform: translate(-50%, -36%);
        width: 200px !important; } }
    @media (max-height: 420px) {
      #hero .CTRlogo {
        width: 160px !important;
        position: absolute;
        top: 36%;
        left: 50%;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -36%);
        -khtml-transform: translate(-50%, -36%);
        -moz-transform: translate(-50%, -36%);
        -ms-transform: translate(-50%, -36%);
        transform: translate(-50%, -36%); } }
  @media (max-height: 1010px) and (min-height: 401px) {
    #hero .CTRlogo {
      /*max-width: 50%;*/ } }
  #hero .wrapper {
    z-index: 5;
    width: 100%;
    /*@include breakpoint(xlarge down) {
		   width:100%;
	   }*/ }
  @media (max-height: 420px) {
    #hero .wrapper {
      position: absolute;
      top: 80%;
      left: 51%;
      margin-right: -51%;
      -webkit-transform: translate(-51%, -80%);
      -khtml-transform: translate(-51%, -80%);
      -moz-transform: translate(-51%, -80%);
      -ms-transform: translate(-51%, -80%);
      transform: translate(-51%, -80%); }
      #hero .wrapper h1 {
        font-size: 1.5rem; } }
  @media (min-height: 421px) {
    #hero .wrapper {
      position: absolute;
      top: 87%;
      left: 51%;
      margin-right: -51%;
      -webkit-transform: translate(-51%, -87%);
      -khtml-transform: translate(-51%, -87%);
      -moz-transform: translate(-51%, -87%);
      -ms-transform: translate(-51%, -87%);
      transform: translate(-51%, -87%); } }

.scrollDown {
  position: absolute;
  top: 95%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -95%);
  -khtml-transform: translate(-50%, -95%);
  -moz-transform: translate(-50%, -95%);
  -ms-transform: translate(-50%, -95%);
  transform: translate(-50%, -95%);
  z-index: 5;
  position: absolute;
  width: 20px;
  -webkit-animation: fadeInOut 2s infinite; }

@-webkit-keyframes fadeInOut {
  from {
    opacity: 0.2; }
  50% {
    opacity: 1.0; }
  to {
    opacity: 0.2; } }

.pageHero {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  z-index: 1; }
  @media print, screen and (max-width: 39.99875em) {
    .pageHero {
      height: unset;
      padding-top: 10rem;
      padding-bottom: 7rem; } }
  @media (max-height: 420px) {
    .pageHero {
      height: unset;
      padding-top: 5rem;
      padding-bottom: 5rem; } }
  .pageHero h1, .pageHero h2, .pageHero h3, .pageHero h4, .pageHero h5, .pageHero h6 {
    font-family: "Libre Baskerville", serif; }
  .pageHero h1 {
    line-height: 1.5;
    padding: 0 0 30px;
    color: #e6e04d;
    text-transform: unset; }
    @media print, screen and (max-width: 39.99875em) {
      .pageHero h1 {
        font-size: 1.7rem; } }
    @media (max-height: 420px) {
      .pageHero h1 {
        font-size: 1.7rem; } }
  .pageHero p {
    line-height: 1.3;
    font-size: 20px;
    font-family: "Open Sans", sans-serif, Arial;
    color: #fefefe; }
  .pageHero .topRow {
    font-weight: 700;
    margin-bottom: 0; }
  .pageHero .HerotextAlign {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
    -khtml-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    @media print, screen and (max-width: 39.99875em) {
      .pageHero .HerotextAlign {
        position: relative !important;
        position: absolute;
        top: 0;
        left: 0;
        margin-right: 0;
        -webkit-transform: translate(0, 0);
        -khtml-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0); } }
    @media (max-height: 420px) {
      .pageHero .HerotextAlign {
        position: relative !important;
        position: absolute;
        top: 0;
        left: 0;
        margin-right: 0;
        -webkit-transform: translate(0, 0);
        -khtml-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0); } }
  .pageHero .Aboutscrollicon {
    position: absolute;
    top: 95%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -95%);
    -khtml-transform: translate(-50%, -95%);
    -moz-transform: translate(-50%, -95%);
    -ms-transform: translate(-50%, -95%);
    transform: translate(-50%, -95%);
    z-index: 5;
    position: absolute;
    width: 20px;
    -webkit-animation: fadeInOut 2s infinite; }

@-webkit-keyframes fadeInOut {
  from {
    opacity: 0.2; }
  50% {
    opacity: 1.0; }
  to {
    opacity: 0.2; } }
  .pageHero .Iconsocial {
    position: absolute;
    top: 95%;
    left: 2%;
    margin-right: -2%;
    -webkit-transform: translate(-2%, -95%);
    -khtml-transform: translate(-2%, -95%);
    -moz-transform: translate(-2%, -95%);
    -ms-transform: translate(-2%, -95%);
    transform: translate(-2%, -95%);
    z-index: 1;
    width: 65px; }
    .pageHero .Iconsocial img {
      transition: all 1s ease; }
      .pageHero .Iconsocial img:hover {
        transform: scale(1.1); }
  @media (max-width: 1920px) {
    .pageHero .Iconsocial li > a {
      padding: 0.4rem 1rem !important; } }

#peoplehero {
  background-image: url("../img/peoplehero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

/*
.peopleHero {
	position: relative;
	height: 100%;
	width: 100%;
	z-index:1;

	@include breakpoint(medium down){
		min-height:640px;
	}

	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.3;
		padding: 30px 0;
		color: #e6e04d;
		text-transform: capitalize;
		@include breakpoint(medium down) {
  		  font-size: 1.7rem;
	    }
	}
	p {

		line-height: 1.3;
		font-size: 20px;
		font-family: $body-font-family;
		color: $white;
		//@include breakpoint(medium down) {
		//  font-size: 14px;
		//}
	}

	@media (max-width: 2000px) and (min-width: 1024px){
		h1{
			font-size: 2rem!important;
		}
		p{
			font-size: 16px!important;
		}
	}
	.topRow {
		font-weight: 700;
		margin-bottom: 0;
		// padding-top: 80px;
	}

	.HerotextAlign {
		text-align:center;
		@include vertical-align(50%, 50%);
	}
	//scrolling to the next section

    .Peoplescrollicon {
 	   @include vertical-align(50%, 95%);
 	   z-index:5;
 	   position: absolute;
 	    width: 20px;
 	    -webkit-animation: fadeInOut 2s infinite;
    }
    @-webkit-keyframes fadeInOut {
 	 from { opacity: 0.2; }
 	 50% { opacity: 1.0; }
 	 to { opacity: 0.2; }
  }

	  //social icons
	  .Iconsocial {
		  @include vertical-align(2%, 95%);
		  z-index: 1;
		  width: 65px;

		  img {
			 transition: all 1s ease;
			  &:hover {
				  transform: scale(1.1);
			  }
		  }
	  }
	  @media (max-width: 1920px) {
		  .Iconsocial {
			  // width: 60px;
			  li > a {
				  padding: 0.4rem 1rem!important;
			  }
		  } //Iconsocial
	}

	#PeopleTextIntro {
		margin-top: 40px;
	}
}
*/
/** VARIABLES
===================================*/
/** RESET AND LAYOUT
===================================*/
.bx-wrapper {
  position: relative;
  margin-bottom: 0;
  padding: 0;
  *zoom: 1;
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.bx-wrapper img {
  max-width: 100%;
  display: block; }

.bxslider {
  margin: 0;
  padding: 0; }

ul.bxslider {
  list-style: none; }

.bx-viewport {
  /*fix other elements on the page moving (on Chrome)*/
  -webkit-transform: translatez(0); }

/** THEME
===================================*/
.bx-wrapper {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: 0;
  border: 0;
  background: transparent; }

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: -30px;
  width: 100%;
  left: 10px; }

/* LOADER */
.bx-wrapper .bx-loading {
  min-height: 50px;
  background: url("../img/bx_loader.gif") center center no-repeat #ffffff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000; }

/* PAGER */
.bx-wrapper .bx-pager {
  text-align: center;
  font-size: .85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
  padding-top: 20px; }

.bx-wrapper .bx-pager.bx-default-pager a {
  background: transparent !important;
  border: 1px solid #666666 !important;
  text-indent: -9999px;
  display: block;
  width: 12px;
  height: 12px;
  margin: 0 5px;
  outline: 0;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px; }

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
  background: #666666 !important; }

.bx-wrapper .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  vertical-align: bottom;
  *zoom: 1;
  *display: inline; }

.bx-wrapper .bx-pager-item {
  font-size: 0;
  line-height: 0; }

/* DIRECTION CONTROLS (NEXT / PREV) */
.bx-wrapper .bx-prev {
  left: 25px;
  background: url("../img/controls_prev.svg") no-repeat; }

.bx-wrapper .bx-prev:hover,
.bx-wrapper .bx-prev:focus {
  background-position: 0 0; }

.bx-wrapper .bx-next {
  right: 25px;
  background: url("../img/controls_next.svg") no-repeat; }

.bx-wrapper .bx-next:hover,
.bx-wrapper .bx-next:focus {
  background-position: 0 0; }

.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 50; }

.bx-wrapper .bx-controls-direction a.disabled {
  display: none; }

/* AUTO CONTROLS (START / STOP) */
.bx-wrapper .bx-controls-auto {
  text-align: center; }

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active,
.bx-wrapper .bx-controls-auto .bx-start:focus {
  background-position: -86px 0; }

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active,
.bx-wrapper .bx-controls-auto .bx-stop:focus {
  background-position: -86px -33px; }

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%; }

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px; }

/* IMAGE CAPTIONS */
.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666;
  background: rgba(80, 80, 80, 0.75);
  width: 100%; }

.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: .85em;
  padding: 10px; }

/*
* Second (Central) section style
*/
#central {
  position: relative;
  background: #fefefe;
  -moz-box-shadow: 0px 0 200px 50px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0 200px 50px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0 200px 50px rgba(0, 0, 0, 0.1); }
  #central h1, #central h2, #central h3, #central h4, #central h5, #central h6 {
    font-family: "Libre Baskerville", serif; }
  #central h1 {
    line-height: 1.3;
    padding: 0 0 15px 0;
    color: #595959;
    text-transform: capitalize; }
    @media print, screen and (max-width: 63.99875em) {
      #central h1 {
        font-size: 1.7rem; } }
  @media (max-width: 2000px) and (min-width: 1024px) {
    #central h1 {
      font-size: 2rem !important; }
    #central p {
      font-size: 16px !important; } }
  #central p {
    line-height: 1.3;
    font-size: 18px;
    font-family: "Open Sans", sans-serif, Arial; }
  #central .gallerybtn {
    padding: 1em 3em;
    line-height: 1.3;
    font-size: 16px;
    font-family: "Open Sans", sans-serif, Arial;
    color: #595959;
    text-transform: uppercase;
    font-weight: 800;
    border: 1.5px solid #595959;
    transition: all 0.5s ease; }
    @media print, screen and (max-width: 39.99875em) {
      #central .gallerybtn {
        font-size: 14px;
        width: 100%; } }
    #central .gallerybtn:hover {
      background: #595959;
      color: #ffffff; }
  #central .sliderCentral {
    height: 100% !important;
    margin-left: 0; }
    #central .sliderCentral li {
      display: block;
      overflow: hidden;
      vertical-align: middle; }
      #central .sliderCentral li #myVideo {
        position: absolute;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      #central .sliderCentral li .video-wrap {
        position: relative;
        padding-bottom: 112.5%;
        height: 100%;
        overflow: hidden;
        width: auto; }
      #central .sliderCentral li .backgroungImg {
        background-position: center;
        height: 100%;
        position: fixed;
        left: 50%;
        bottom: 0; }
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
          #central .sliderCentral li .backgroungImg {
            position: absolute !important; } }
        @media print, screen and (max-width: 63.99875em) {
          #central .sliderCentral li .backgroungImg {
            position: relative;
            left: 0; } }
      #central .sliderCentral li #firstSlide29 {
        background-image: url("../img/spotlight/SpinMaster_Spotlight.gif"); }
      #central .sliderCentral li #firstSlide28 {
        background-image: url("../img/spotlight/MillerGroup_Spotlight.gif"); }
      #central .sliderCentral li #firstSlide25 {
        background-image: url("../img/spotlight/Spotlight-hm.gif"); }
      #central .sliderCentral li #firstSlide24 {
        background-image: url("../img/spotlight/Spotlight-Deciem.gif"); }
      #central .sliderCentral li #firstSlide23 {
        background-image: url("../img/spotlight/Spotlight-Dior.gif"); }
      #central .sliderCentral li #firstSlide22 {
        background-image: url("../img/spotlight/Arterra_Spotlight_New.gif"); }
      #central .sliderCentral li #firstSlide21 {
        background-image: url("../img/spotlight/POD_Spotlight_New.gif"); }
      #central .sliderCentral li #firstSlide20 {
        background-image: url("../img/spotlight/Bask_Spotlight.jpg"); }
      #central .sliderCentral li #firstSlide19 {
        background-image: url("../img/spotlight/TwoBears_Spotlight.jpg"); }
      #central .sliderCentral li #firstSlide18 {
        background-image: url("../img/spotlight/HRH_Spotlight.gif"); }
      #central .sliderCentral li #firstSlide17 {
        background-image: url("../img/spotlight/Refine_Spotlight.jpg"); }
      #central .sliderCentral li #firstSlide16 {
        background-image: url("../img/spotlight/nike-giannis-zoomfreak-desktop.gif"); }
      #central .sliderCentral li #firstSlide15 {
        background-image: url("../img/spotlight/Spotlight-nikeadapt-desktop.jpg"); }
      #central .sliderCentral li #firstSlide14 {
        background-image: url("../img/spotlight/Spotlight-BrandWarriors-desktop.gif"); }
      #central .sliderCentral li #firstSlide13 {
        background-image: url("../img/spotlight/Canada-Goose-desktop.gif"); }
      #central .sliderCentral li #firstSlide12 {
        background-image: url("../img/spotlight/scentral-station-desktop.gif");
        /*background-position: central;
		 			 height: 100%;
		 			 position: fixed;
		 			 @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		 				 position: absolute!important;
		 			 }
		 			 left: 50%;
		 			 bottom: 0;*/ }
      #central .sliderCentral li #firstSlide11 {
        background-image: url("../img/spotlight/Distributel_Banners.gif");
        /*background-position: 45% top;
			 height: 100%;
			 position: fixed;
			 @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
				 position: absolute!important;
			 }
			 left: 50%;
			 bottom: 0;*/ }
      #central .sliderCentral li #firstSlide10 {
        background-image: url("../img/spotlight/madjack_animation.gif");
        /*background-position: 45% top;
		 		height: 100%;
		 		position: fixed;

				@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
					position: absolute!important;
				}
				left: 50%;
				bottom: 0;*/ }
      #central .sliderCentral li #firstSlide9 {
        background-image: url("../img/spotlight/Nonna_Outdoor.jpg");
        /*background-position: 45% top;
		 		height: 100%;
		 		position: fixed;

				@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
					position: absolute!important;
				}
				left: 50%;
				bottom: 0;*/ }
      #central .sliderCentral li #firstSlide8 {
        background-image: url("../img/spotlight/Scout_Animation_Desktop.gif");
        /*background-position: 45% top;
		   	height: 100%;
		  	position: fixed;
		  	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
					position: absolute!important;
				}
				left: 50%;
				bottom: 0;*/ }
      #central .sliderCentral li #firstSlide7 {
        background-image: url("../img/spotlight/journey1.jpg");
        /*background-position: 45% top;
  		height: 100%;
 			position: fixed;
 			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
 			  position: absolute!important;
 			}
  			left: 50%;
  			bottom: 0;*/ }
      #central .sliderCentral li #firstSlide6 {
        background-image: url("../img/spotlight/tania.jpg");
        /*height: 100%;
 			position: fixed;
 			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
 			  position: absolute!important;
 			}
  			left: 50%;
  			bottom: 0;*/ }
      #central .sliderCentral li #firstSlide4 {
        background-image: url("../img/spotlight/nike-nba.jpg");
        /*height: 100%;
			position: fixed;
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			  position: absolute!important;
			}
 			left: 50%;
 			bottom: 0;*/ }
      #central .sliderCentral li #firstSlide3 {
        background-image: url("../img/spotlight/Camo.jpg");
        /*height: 100%;
			position: fixed;
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			  position: absolute!important;
			}
 			left: 50%;
 			bottom: 0;*/ }
      #central .sliderCentral li #firstSlide2 {
        background-image: url("../img/spotlight/Acanac_Screen.jpg");
        /*height: 100%;
			position: fixed;
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			  position: absolute!important;
			}
 			left: 50%;
 			bottom: 0;*/ }
      #central .sliderCentral li #firstSlide1 {
        background-image: url("../img/spotlight/Nike_Brahaus.jpg");
        /*height: 100%;
 			position: fixed;
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			  position: absolute!important;
			}
 			left: 50%;
 			bottom: 0;*/ }
      #central .sliderCentral li #firstSlide {
        background-image: url("../img/spotlight/tarots_BM.jpg");
        /*height: 100%;
			position: fixed;
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			  position: absolute!important;
			}
			left: 50%;
			bottom: 0;*/ }
      #central .sliderCentral li #secondSlide {
        background-image: url("../img/spotlight/HavanaNight.jpg");
        /*height: 100%;
			position: fixed;
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			  position: absolute!important;
			}
			left: 50%;
			bottom: 0;*/ }
      #central .sliderCentral li #thirdSlide {
        background-image: url("../img/spotlight/Render_Cheers.jpg");
        /*height: 100%;
			position: fixed;
			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			  position: absolute!important;
			}
			left: 50%;
			bottom: 0;*/ }
      #central .sliderCentral li .spotlightwrap {
        position: relative;
        height: 100%;
        width: 100%; }
      #central .sliderCentral li .parentImg .backgroungImg {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover; }
      #central .sliderCentral li .topRow {
        font-weight: 700;
        padding-bottom: 30px; }
        @media print, screen and (max-width: 63.99875em) {
          #central .sliderCentral li .topRow {
            padding-bottom: 0; } }
      #central .sliderCentral li p {
        color: #28acbe; }
    #central .sliderCentral .textCenter {
      position: relative;
      padding: 120px 0; }
      @media print, screen and (max-width: 74.99875em) {
        #central .sliderCentral .textCenter {
          padding: 100px 0; } }
      @media print, screen and (max-width: 63.99875em) {
        #central .sliderCentral .textCenter {
          padding: 60px 0; } }
      @media print, screen and (max-width: 39.99875em) {
        #central .sliderCentral .textCenter {
          padding: 60px 0 10px; } }
  #central .bx-wrapper .bx-controls-direction a {
    position: absolute;
    top: 89.5%;
    z-index: 51;
    -webkit-transform: translateY(-88%);
    -khtml-transform: translateY(-88%);
    -ms-transform: translateY(-88%);
    transform: translateY(-88%);
    padding-left: 4.01%; }
    @media print, screen and (max-width: 63.99875em) {
      #central .bx-wrapper .bx-controls-direction a {
        top: 32%;
        padding-left: 40px; } }
    @media print, screen and (max-width: 39.99875em) {
      #central .bx-wrapper .bx-controls-direction a {
        top: 210px; } }
  #central .bx-prev {
    left: 46%;
    height: 100px;
    -webkit-transform: translateX(-18%);
    -khtml-transform: translateX(-18%);
    -ms-transform: translateX(-18%);
    transform: translateX(-18%);
    background: url("../img/controls_prev_spotlight.svg") no-repeat;
    background-color: rgba(0, 0, 0, 0.52);
    background-position-x: center; }
    @media print, screen and (max-width: 63.99875em) {
      #central .bx-prev {
        position: absolute;
        height: 50px;
        left: 20px;
        -webkit-transform: translate(-5%, -50%);
        -khtml-transform: translate(-5%, -50%);
        -ms-transform: translate(-5%, -50%);
        transform: translate(-5%, -50%);
        width: 30px;
        padding: 0; } }
  #central .bx-next {
    right: 46%;
    height: 100px;
    -webkit-transform: translateX(-68%);
    -khtml-transform: translateX(-68%);
    -ms-transform: translateX(-68%);
    transform: translateX(-68%);
    background: url("../img/controls_next_spotlight.svg") no-repeat;
    background-color: rgba(0, 0, 0, 0.52);
    background-position-x: center; }
    @media print, screen and (max-width: 63.99875em) {
      #central .bx-next {
        position: absolute;
        height: 50px;
        right: 20px;
        -webkit-transform: translate(-95%, -50%);
        -khtml-transform: translate(-95%, -50%);
        -ms-transform: translate(-95%, -50%);
        transform: translate(-95%, -50%);
        width: 30px;
        padding: 0; } }
  #central .bx-wrapper .bx-pager {
    position: absolute;
    top: 93%;
    right: 26%;
    -webkit-transform: translate(-26%, -92%);
    -khtml-transform: translate(-26%, -92%);
    -ms-transform: translate(-26%, -92%);
    transform: translate(-26%, -92%);
    z-index: 50; }
    #central .bx-wrapper .bx-pager .bx-default-pager a {
      background: transparent !important;
      border: 1px solid #666666 !important; }
      #central .bx-wrapper .bx-pager .bx-default-pager a .active {
        background: #666666 !important; }
  #central .bx-viewport, #central .bx-wrapper {
    position: relative;
    width: 100%;
    border: none !important;
    margin-bottom: 0px; }
  #central .sliderCentralSmall {
    margin-left: 0; }
    @media screen and (min-width: 75em) {
      #central .sliderCentralSmall {
        display: none; } }
    #central .sliderCentralSmall p {
      color: #28acbe; }
    #central .sliderCentralSmall .topRow {
      padding-top: 40px;
      padding-bottom: 40px; }
      @media print, screen and (max-width: 63.99875em) {
        #central .sliderCentralSmall .topRow {
          padding-bottom: 0; } }
    #central .sliderCentralSmall .bottomRow {
      padding-bottom: 40px; }
  #central .sliderImage {
    position: relative; }
    #central .sliderImage .Smallprev-cont {
      position: absolute;
      top: 50%;
      left: 5%;
      margin-right: -5%;
      -webkit-transform: translate(-5%, -50%);
      -khtml-transform: translate(-5%, -50%);
      -moz-transform: translate(-5%, -50%);
      -ms-transform: translate(-5%, -50%);
      transform: translate(-5%, -50%);
      width: 30px;
      background: rgba(0, 0, 0, 0.52);
      padding: 5px; }
    #central .sliderImage .Smallnext-cont {
      position: absolute;
      top: 50%;
      left: 95%;
      margin-right: -95%;
      -webkit-transform: translate(-95%, -50%);
      -khtml-transform: translate(-95%, -50%);
      -moz-transform: translate(-95%, -50%);
      -ms-transform: translate(-95%, -50%);
      transform: translate(-95%, -50%);
      width: 30px;
      background: rgba(0, 0, 0, 0.52);
      padding: 5px; }
  #central .spotlight-word {
    position: relative; }
    #central .spotlight-word .Spotlight {
      width: 450px;
      padding-bottom: 20px;
      margin-top: -20px; }
      @media print, screen and (max-width: 63.99875em) {
        #central .spotlight-word .Spotlight {
          width: 200px; } }
    @media (max-width: 2000px) and (min-width: 1024px) {
      #central .spotlight-word .Spotlight {
        width: 380px !important; } }
    #central .spotlight-word .under-spt {
      position: absolute;
      top: 20px;
      left: 0;
      z-index: -1; }
  #central .spotlight-word-sm {
    position: relative; }
    #central .spotlight-word-sm .Spotlight {
      width: 450px;
      padding-bottom: 20px;
      margin-top: -20px; }
      @media print, screen and (max-width: 63.99875em) {
        #central .spotlight-word-sm .Spotlight {
          width: 200px; } }
    @media (max-width: 2000px) and (min-width: 1024px) {
      #central .spotlight-word-sm .Spotlight {
        width: 380px !important; } }
    #central .spotlight-word-sm .under-spt {
      position: absolute;
      top: 60px;
      left: 0;
      z-index: -1; }
  #central #audio-text, #central #audio-text-sm, #central #audio-text-p, #central #audio-text-p-sm {
    font-weight: 600;
    color: #28acbe; }
  #central #camo-pause, #central #camo-pause-sm, #central #audio-text-p, #central #audio-text-p-sm {
    display: none; }
  #central .radio-icon {
    width: 30px;
    cursor: pointer; }
  #central #camo-pause-sm {
    margin-top: -100px; }
  #central #camo-play-sm {
    margin-top: -50px; }
  #central #camo-pause {
    margin-top: -14px; }
  #central #camo-play {
    margin-top: -7px; }
  #central #md-pause, #central #md-pause-sm {
    display: none; }
  #central .video-icon {
    width: 30px;
    cursor: pointer; }
  #central #md-pause-sm {
    margin-top: -5px; }
  #central #md-play-sm {
    margin-top: -5px; }
  #central #md-pause {
    margin-top: -6px; }
  #central #md-play {
    margin-top: -6px; }
  #central #spotlight-bay {
    position: relative;
    width: 50vw; }
    @media print, screen and (max-width: 63.99875em) {
      #central #spotlight-bay {
        width: 100vw; } }
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      #central #spotlight-bay .responsive-embed.widescreen, #central #spotlight-bay .flex-video.widescreen {
        position: absolute !important;
        left: 0 !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        width: 100vw !important;
        height: 100vh !important;
        padding: 0; }
      #central #spotlight-bay #spotlight-bayV {
        height: 130% !important; } }
    #central #spotlight-bay .responsive-embed.widescreen, #central #spotlight-bay .flex-video.widescreen {
      top: 0%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      z-index: 100;
      height: 100%;
      position: fixed;
      left: 50%;
      bottom: 0; }
      @media print, screen and (max-width: 63.99875em) {
        #central #spotlight-bay .responsive-embed.widescreen, #central #spotlight-bay .flex-video.widescreen {
          position: relative;
          left: 0;
          padding-bottom: 82%; } }
      #central #spotlight-bay .responsive-embed.widescreen #spotlight-bayV, #central #spotlight-bay .flex-video.widescreen #spotlight-bayV {
        width: auto;
        height: 100%; }
      @media (min-width: 1600px) {
        #central #spotlight-bay .responsive-embed.widescreen #spotlight-bayV, #central #spotlight-bay .flex-video.widescreen #spotlight-bayV {
          height: 105%; } }
  #central #spotlight-bay-mob .responsive-embed.widescreen, #central #spotlight-bay-mob .flex-video.widescreen {
    padding-bottom: 82%;
    margin-bottom: 0; }
    #central #spotlight-bay-mob .responsive-embed.widescreen #spotlight-bayV-mob, #central #spotlight-bay-mob .flex-video.widescreen #spotlight-bayV-mob {
      top: -1%; }

#thingsWeDo {
  color: #595959;
  /*@media (max-width: 2000px) and (min-width: 1024px){
		h1{
			font-size: 2rem!important;
		}
		p{
			font-size: 16px!important;
		}
	}*/ }
  #thingsWeDo h1, #thingsWeDo h2, #thingsWeDo h3, #thingsWeDo h4, #thingsWeDo h5, #thingsWeDo h6 {
    font-family: "Libre Baskerville", serif; }
  #thingsWeDo h1 {
    font-size: 1.6rem;
    padding-bottom: 1rem; }
  #thingsWeDo h2 {
    font-size: 1.6rem;
    line-height: 1.8;
    padding: .5rem 0; }
    @media print, screen and (max-width: 39.99875em) {
      #thingsWeDo h2 {
        font-size: 1.25rem; } }
    @media (max-height: 420px) {
      #thingsWeDo h2 {
        font-size: 1.25rem; } }
  #thingsWeDo p {
    line-height: 1.8;
    font-size: 18px;
    font-family: "Open Sans", sans-serif, Arial; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      #thingsWeDo p {
        font-size: 18px; } }
  #thingsWeDo .thingsTxt {
    padding: 4rem 0; }
    #thingsWeDo .thingsTxt span {
      font-weight: bold;
      text-transform: uppercase; }
  #thingsWeDo .orange {
    background: #fa4b13;
    color: white !important; }
  #thingsWeDo .howWeWork {
    padding: 6rem 0;
    background-image: url(../img/ArriveTogether-new.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 95% 95%; }
  #thingsWeDo .thingsWrap {
    border-top: 1px solid #595959;
    padding: 4rem 0 5rem; }
    #thingsWeDo .thingsWrap h1 {
      color: #fa4b13; }
    @media print, screen and (max-width: 63.99875em) {
      #thingsWeDo .thingsWrap {
        padding-bottom: 0; } }
  #thingsWeDo .thingsBox {
    background: black;
    position: relative;
    margin: 10px;
    text-align: center; }
    @media print, screen and (max-width: 63.99875em) {
      #thingsWeDo .thingsBox {
        margin: 0; } }
    #thingsWeDo .thingsBox:hover {
      -webkit-transition: all 1s ease-in-out;
      transition: all .8s ease-in-out;
      background-blend-mode: multiply;
      background-size: cover;
      opacity: .85; }
      #thingsWeDo .thingsBox:hover a {
        color: #fa4b13 !important;
        transition: all .8s ease-in-out; }
      #thingsWeDo .thingsBox:hover img {
        transition: all 1s ease-in-out; }
    #thingsWeDo .thingsBox .title {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -50%);
      -khtml-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-weight: bold;
      color: white;
      font-size: 1.55rem;
      line-height: 1.05;
      text-transform: uppercase; }
      #thingsWeDo .thingsBox .title a {
        color: #fefefe; }
        #thingsWeDo .thingsBox .title a:hover {
          color: #fa4b13; }

#instafeed {
  list-style: none;
  margin: 0; }
  #instafeed img {
    max-width: 100%;
    max-height: 100%; }
  #instafeed .insta-img {
    background-size: cover;
    position: relative; }

/*.instaMask {
	position:absolute;
	width:100%;
	height:100%;
}*/
/*
* Partners section style
*/
#partners {
  position: relative;
  background-color: #e6e04d;
  /*@media (max-width: 2000px) and (min-width: 1441px){
		p {
			font-size: 16px;
		}
	}*/ }
  #partners h1, #partners h2, #partners h3, #partners h4, #partners h5, #partners h6 {
    font-family: "Libre Baskerville", serif; }
  #partners h1 {
    line-height: 1.3;
    padding: 10px 0; }
    @media print, screen and (max-width: 39.99875em) {
      #partners h1 {
        font-size: 2rem; } }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      #partners h1 {
        font-size: 1.8rem; } }
  #partners p {
    line-height: 1.3;
    font-size: 18px;
    font-family: "Open Sans", sans-serif, Arial; }
    @media screen and (min-width: 75em) and (max-width: 89.99875em) {
      #partners p {
        line-height: 1.2 !important;
        font-size: 16px; } }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      #partners p {
        line-height: 1.2 !important;
        font-size: 14px; } }
  #partners a {
    color: #0a0a0a; }
  #partners img {
    width: 100%; }
  #partners .topRow {
    font-weight: 700; }
  @media screen and (min-width: 75em) {
    #partners .Myopacity {
      position: absolute;
      top: 45%;
      left: 75%;
      transform: translate(-50%, -47%);
      width: 50%; } }
  #partners .textCenter {
    width: 85%;
    margin: 0 auto; }
    @media print, screen and (max-width: 74.99875em) {
      #partners .textCenter {
        padding: 40px 0px; } }
  #partners .columns {
    padding: 0; }
  #partners .left {
    float: right; }
  #partners .right {
    float: left; }
  #partners .overlayPartner {
    background-color: #595959;
    display: none;
    z-index: 50;
    animation: fadein 2s;
    -moz-animation: fadein 2s;
    /* Firefox */
    -webkit-animation: fadein 2s;
    /* Safari and Chrome */
    -o-animation: fadein 2s;
    /* Opera */ }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
    #partners .overlayPartner .row {
      padding: 0;
      margin: 0; }
    #partners .overlayPartner.open {
      display: inline-block; }
    #partners .overlayPartner h1 {
      font-size: 1.5rem;
      color: #fefefe; }
    #partners .overlayPartner p {
      color: #fefefe; }
    #partners .overlayPartner .image {
      position: relative; }
    #partners .overlayPartner .Mytitle {
      position: absolute;
      top: 100.2%;
      left: 0;
      margin-right: 0;
      -webkit-transform: translate(0, -100.2%);
      -khtml-transform: translate(0, -100.2%);
      -moz-transform: translate(0, -100.2%);
      -ms-transform: translate(0, -100.2%);
      transform: translate(0, -100.2%);
      background-color: rgba(0, 0, 0, 0.7);
      width: 100%;
      height: 70px; }
      #partners .overlayPartner .Mytitle .namewrap {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -khtml-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%); }
    #partners .overlayPartner p {
      font-size: 16px; }
      @media screen and (min-width: 75em) and (max-width: 89.99875em) {
        #partners .overlayPartner p {
          font-size: 14px; } }
      @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
        #partners .overlayPartner p {
          font-size: 12px;
          margin-bottom: 0.5rem !important; } }
    @media (min-width: 1438px) and (max-width: 1530px) {
      #partners .overlayPartner .partnerScl {
        max-width: 30px; } }
    @media (max-width: 1390px) and (min-width: 1199px) {
      #partners .overlayPartner .partnerScl {
        max-width: 20px; }
      #partners .overlayPartner .pos {
        padding-top: 10px !important; } }
    #partners .overlayPartner span {
      color: #39aec3;
      font-size: 16px;
      font-family: "Open Sans", sans-serif, Arial; }
  #partners a {
    position: relative; }
    #partners a:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -5px;
      left: 0;
      background: #0a0a0a;
      visibility: hidden;
      border-radius: 5px;
      transform: scaleX(0);
      transition: all 2s ease; }
  #partners .changed:after {
    visibility: visible;
    transform: scaleX(1); }
  #partners #toggleNameJohn > a:hover:after {
    visibility: visible;
    transform: scaleX(1); }
  #partners #toggleNameBrad > a:hover:after {
    visibility: visible;
    transform: scaleX(1); }
  #partners #toggleNameDave > a:hover:after {
    visibility: visible;
    transform: scaleX(1); }
  #partners #toggleNamePaul > a:hover:after {
    visibility: visible;
    transform: scaleX(1); }
  #partners #toggleNameJoe > a:hover:after {
    visibility: visible;
    transform: scaleX(1); }
  #partners #toggleNameteam > a:hover:after {
    visibility: visible;
    transform: scaleX(1); }
  #partners .HeadShots, #partners #toggleNameJohn, #partners #toggleNameBrad, #partners #toggleNameDave, #partners #toggleNamePaul, #partners #toggleNameJoe {
    cursor: pointer;
    vertical-align: bottom; }
  #partners .partnerScl {
    max-width: 40px;
    padding: 50% 0;
    margin-left: 30%; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      #partners .partnerScl {
        max-width: 20px;
        margin-right: 5px; } }
    @media print, screen and (max-width: 39.99875em) {
      #partners .partnerScl {
        max-width: 30px;
        margin-right: 5px; } }
    @media print, screen and (max-width: 63.99875em) {
      #partners .partnerScl {
        padding: 10px 0;
        margin-left: 0; } }
  #partners .pos {
    position: relative;
    padding-top: 30px !important; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      #partners .pos {
        padding-top: 10px !important; } }
    @media screen and (min-width: 75em) and (max-width: 89.99875em) {
      #partners .pos {
        padding-top: 20px !important; } }
  #partners .close-button {
    right: 0;
    top: 30px;
    height: 30px;
    line-height: 0; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      #partners .close-button {
        top: 10px; } }
    @media screen and (min-width: 75em) and (max-width: 89.99875em) {
      #partners .close-button {
        top: 20px; } }
    #partners .close-button .Xclose {
      max-width: 30px;
      max-height: 30px;
      height: 100%; }
  #partners .partnership {
    margin-top: 25px;
    margin-bottom: 50px; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      #partners .partnership {
        margin-top: 15px;
        margin-bottom: 40px; } }
  #partners .Sign-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    border: 0; }
    #partners .Sign-wrapper:hover img {
      transform: scale(1.1); }
    #partners .Sign-wrapper:hover .Sign-overlay-content {
      opacity: 0; }
    #partners .Sign-wrapper img {
      transition: all 2s ease; }
    #partners .Sign-wrapper .Sign-overlay-content {
      background-color: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      position: absolute;
      overflow: hidden;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: all 2s ease; }
  #partners .JohnImg, #partners .BradImg, #partners .DaveImg, #partners .PaulImg, #partners .JoeImg {
    position: relative; }
    #partners .JohnImg #JohnLife, #partners .JohnImg #BradLife, #partners .JohnImg #DaveLife, #partners .JohnImg #PaulLife, #partners .JohnImg #JoeLife, #partners .BradImg #JohnLife, #partners .BradImg #BradLife, #partners .BradImg #DaveLife, #partners .BradImg #PaulLife, #partners .BradImg #JoeLife, #partners .DaveImg #JohnLife, #partners .DaveImg #BradLife, #partners .DaveImg #DaveLife, #partners .DaveImg #PaulLife, #partners .DaveImg #JoeLife, #partners .PaulImg #JohnLife, #partners .PaulImg #BradLife, #partners .PaulImg #DaveLife, #partners .PaulImg #PaulLife, #partners .PaulImg #JoeLife, #partners .JoeImg #JohnLife, #partners .JoeImg #BradLife, #partners .JoeImg #DaveLife, #partners .JoeImg #PaulLife, #partners .JoeImg #JoeLife {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%; }
  #partners .MoreRow {
    background-color: #fefefe; }
    #partners .MoreRow #PartnersMore {
      padding: 20px 0 !important;
      cursor: pointer; }
      #partners .MoreRow #PartnersMore h4 {
        font-family: 'Open Sans', serif;
        color: #fa4b13;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 0; }
  #partners .Partners-load {
    width: 23px; }

/*
* Leadership section
*/
#leadership {
  position: relative;
  background-color: #595959;
  /*
	@media (max-width: 2000px) and (min-width: 1441px){
		p {
			font-size: 14px;
		}
	}
	@media (min-width: 2001px){
		p {
			font-size: 20px;
		}
	}*/ }
  #leadership h1, #leadership h2, #leadership h3, #leadership h4, #leadership h5, #leadership h6 {
    font-family: "Libre Baskerville", serif; }
  #leadership h1 {
    line-height: 1.3;
    padding: 60px 0;
    color: #595959;
    text-transform: capitalize; }
    @media print, screen and (max-width: 39.99875em) {
      #leadership h1 {
        font-size: 1.7rem; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      #leadership h1 {
        font-size: 3rem; } }
    @media screen and (min-width: 75em) and (max-width: 89.99875em) {
      #leadership h1 {
        font-size: 2.9rem; } }
    @media print, screen and (max-width: 89.99875em) {
      #leadership h1 {
        margin-bottom: 0; } }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      #leadership h1 {
        font-size: 2rem; } }
  #leadership p {
    line-height: 1.3;
    font-family: "Open Sans", sans-serif, Arial; }
    @media print, screen and (max-width: 89.99875em) {
      #leadership p {
        font-size: 14px; } }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      #leadership p {
        font-size: 12px;
        margin-bottom: 0.3rem; } }
    @media print, screen and (max-width: 63.99875em) {
      #leadership p {
        font-size: 18px !important; } }
  #leadership img {
    width: 100%; }
  #leadership .LeadershipTitle {
    background-color: #fefefe; }
    @media (max-width: 2000px) and (min-width: 1024px) {
      #leadership .LeadershipTitle h1 {
        font-size: 2rem !important; } }
  #leadership .MyRow {
    position: relative; }
  #leadership .Sign-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    border: 0; }
    #leadership .Sign-wrapper:hover .Sign-overlay-content {
      opacity: 1 !important;
      transform: translateY(0px);
      transition: transform 0.5s, opacity 0.1s !important;
      z-index: 100; }
    #leadership .Sign-wrapper img {
      transition: all 2s ease; }
    #leadership .Sign-wrapper .Sign-overlay-content {
      width: 100%;
      height: 24% !important;
      position: absolute;
      overflow: hidden;
      top: auto;
      bottom: 0;
      left: 0;
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: rgba(250, 75, 19, 0.6) !important;
      color: white;
      text-align: center;
      transform: translateY(100%);
      transition: transform 0.5s, opacity 0.1s 0.3s !important; }
      #leadership .Sign-wrapper .Sign-overlay-content figcaption .leaderName {
        font-size: 20px;
        font-weight: bold; }
  #leadership .overlayLeadership {
    background-color: rgba(230, 80, 32, 0.8);
    display: none;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 75%;
    animation: fadein 2s;
    -moz-animation: fadein 2s;
    /* Firefox */
    -webkit-animation: fadein 2s;
    /* Safari and Chrome */
    -o-animation: fadein 2s;
    /* Opera */ }
    #leadership .overlayLeadership h1, #leadership .overlayLeadership p {
      color: #fefefe; }
    #leadership .overlayLeadership h1 {
      padding: 15px 0; }
      #leadership .overlayLeadership h1 span {
        font-size: 16px;
        font-family: "Open Sans", sans-serif, Arial;
        text-transform: uppercase; }
      @media print, screen and (max-width: 63.99875em) {
        #leadership .overlayLeadership h1 {
          padding: 60px 0 30px 0; } }
      @media print, screen and (max-width: 39.99875em) {
        #leadership .overlayLeadership h1 {
          font-size: 1.5rem;
          line-height: 1.3;
          margin-right: 20px; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        #leadership .overlayLeadership h1 {
          font-size: 2.4rem;
          line-height: 1.3; } }
      @media screen and (min-width: 75em) {
        #leadership .overlayLeadership h1 {
          font-size: 2.2rem; } }
      @media print, screen and (max-width: 89.99875em) {
        #leadership .overlayLeadership h1 {
          padding: 10px 0;
          margin-top: 20px;
          font-size: 1.8rem; } }
      @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
        #leadership .overlayLeadership h1 {
          font-size: 1.8rem;
          margin-top: 0;
          line-height: 0.5; } }
    @media print, screen and (max-width: 63.99875em) {
      #leadership .overlayLeadership {
        position: relative;
        background-color: #e65020;
        width: 100%; } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
    #leadership .overlayLeadership .close-button {
      right: 2%;
      top: 30px;
      height: 30px;
      line-height: 0; }
      #leadership .overlayLeadership .close-button .Xclose {
        max-width: 30px;
        max-height: 30px;
        height: 100%; }
    #leadership .overlayLeadership .rightclose {
      right: 27%; }
    #leadership .overlayLeadership .leadershipScl {
      max-width: 40px;
      padding-bottom: 30px;
      margin-right: 10px; }
      @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
        #leadership .overlayLeadership .leadershipScl {
          max-width: 20px;
          padding-bottom: 0;
          margin-right: 5px; } }
      @media print, screen and (max-width: 39.99875em) {
        #leadership .overlayLeadership .leadershipScl {
          max-width: 30px;
          margin-right: 5px; } }
    @media (min-width: 1438px) and (max-width: 1530px) {
      #leadership .overlayLeadership .leadershipScl {
        max-width: 25px; }
      #leadership .overlayLeadership h1 {
        margin-top: 20px;
        margin-bottom: 0; } }
    @media (max-width: 1390px) and (min-width: 1199px) {
      #leadership .overlayLeadership .leadershipScl {
        max-width: 20px; } }
    #leadership .overlayLeadership .Myorange {
      width: 100%;
      height: 100%;
      position: absolute;
      overflow: hidden;
      bottom: 0;
      left: 0; }
      #leadership .overlayLeadership .Myorange .BioText {
        position: relative;
        top: 50%;
        left: 90%;
        transform: translate(-90%, -50%); }
      #leadership .overlayLeadership .Myorange .BioTextR {
        position: relative;
        top: 50%;
        left: 10%;
        transform: translate(-10%, -50%); }
  #leadership .rightOverlay {
    right: 0 !important;
    left: auto; }
  #leadership .JeffImg, #leadership .DaveRImg, #leadership .MannyImg, #leadership .WinfieldImg, #leadership .JennImg, #leadership .MarineImg, #leadership .JosieImg {
    position: relative; }
    #leadership .JeffImg #JeffLife, #leadership .JeffImg #DaveRLife, #leadership .JeffImg #MannyLife, #leadership .JeffImg #WinfieldLife, #leadership .JeffImg #JennLife, #leadership .JeffImg #MarineLife, #leadership .JeffImg #JosieLife, #leadership .DaveRImg #JeffLife, #leadership .DaveRImg #DaveRLife, #leadership .DaveRImg #MannyLife, #leadership .DaveRImg #WinfieldLife, #leadership .DaveRImg #JennLife, #leadership .DaveRImg #MarineLife, #leadership .DaveRImg #JosieLife, #leadership .MannyImg #JeffLife, #leadership .MannyImg #DaveRLife, #leadership .MannyImg #MannyLife, #leadership .MannyImg #WinfieldLife, #leadership .MannyImg #JennLife, #leadership .MannyImg #MarineLife, #leadership .MannyImg #JosieLife, #leadership .WinfieldImg #JeffLife, #leadership .WinfieldImg #DaveRLife, #leadership .WinfieldImg #MannyLife, #leadership .WinfieldImg #WinfieldLife, #leadership .WinfieldImg #JennLife, #leadership .WinfieldImg #MarineLife, #leadership .WinfieldImg #JosieLife, #leadership .JennImg #JeffLife, #leadership .JennImg #DaveRLife, #leadership .JennImg #MannyLife, #leadership .JennImg #WinfieldLife, #leadership .JennImg #JennLife, #leadership .JennImg #MarineLife, #leadership .JennImg #JosieLife, #leadership .MarineImg #JeffLife, #leadership .MarineImg #DaveRLife, #leadership .MarineImg #MannyLife, #leadership .MarineImg #WinfieldLife, #leadership .MarineImg #JennLife, #leadership .MarineImg #MarineLife, #leadership .MarineImg #JosieLife, #leadership .JosieImg #JeffLife, #leadership .JosieImg #DaveRLife, #leadership .JosieImg #MannyLife, #leadership .JosieImg #WinfieldLife, #leadership .JosieImg #JennLife, #leadership .JosieImg #MarineLife, #leadership .JosieImg #JosieLife {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%; }
  #leadership .MoreRow {
    background-color: #fefefe; }
    #leadership .MoreRow #LeadersMore {
      padding: 20px 0 !important;
      cursor: pointer; }
      #leadership .MoreRow #LeadersMore h4 {
        font-family: 'Open Sans', serif;
        color: #fa4b13;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 0; }
  #leadership .Leaders-load {
    width: 23px; }
  #leadership .code {
    width: 15%; }
    @media print, screen and (max-width: 63.99875em) {
      #leadership .code {
        width: 35%; } }

/*
* News section style
*/
#news {
  position: relative;
  background-color: #fefefe; }
  #news h1, #news h2, #news h3, #news h4, #news h5, #news h6 {
    font-family: "Libre Baskerville", serif; }
  #news h1 {
    line-height: 1.3;
    padding: 60px 0;
    color: #595959;
    text-transform: capitalize; }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      #news h1 {
        font-size: 1.7rem; } }
    @media print, screen and (max-width: 39.99875em) {
      #news h1 {
        font-size: 1.5rem; } }
  #news img {
    width: 100%; }
  #news .NewsTitle {
    background-color: #fa4b13; }
    #news .NewsTitle h1 {
      color: #fefefe; }
    @media (max-width: 2000px) and (min-width: 1024px) {
      #news .NewsTitle h1 {
        font-size: 2rem !important; } }
  #news .button {
    margin: 65px 0;
    padding: 1em 4em;
    font-family: 'Open Sans', serif;
    color: #595959;
    text-transform: uppercase;
    line-height: 1.3;
    font-size: 16px;
    font-weight: 800;
    border: 1.5px solid #595959;
    transition: all 0.5s ease; }
    @media print, screen and (max-width: 63.99875em) {
      #news .button {
        font-size: 14px; } }
    #news .button:hover {
      background: #595959;
      color: #ffffff; }
  #news .myLarge {
    display: none; }
  @media (max-width: 1215px) and (min-width: 1024px) {
    #news .myLarge {
      width: 50% !important; } }
  #news .notvisible {
    display: none; }
  #news .notvisibleMobile {
    display: none; }
  #news #moreMobile {
    padding: 20px 0 !important;
    cursor: pointer; }
    #news #moreMobile h4 {
      font-family: 'Open Sans', serif;
      color: #fa4b13;
      font-weight: 900;
      text-transform: uppercase;
      margin-bottom: 0; }
  #news .news-load {
    width: 23px; }

.image-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  border: 0; }
  .image-wrapper h1 {
    text-align: center;
    text-transform: capitalize; }
    @media print, screen and (max-width: 39.99875em) {
      .image-wrapper h1 {
        text-align: left; } }
  .image-wrapper p {
    text-align: center; }

.image-overlay-content {
  width: 100%;
  cursor: pointer;
  height: 100%;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  left: 0; }
  @media print, screen and (max-width: 63.99875em) {
    .image-overlay-content {
      height: 120px; } }
  .image-overlay-content h1 {
    line-height: 1.2 !important;
    padding: 0 20px !important; }
    @media print, screen and (max-width: 63.99875em) {
      .image-overlay-content h1 {
        font-size: 1.1rem !important;
        position: absolute;
        top: 50%;
        left: 1%;
        margin-right: -1%;
        -webkit-transform: translate(-1%, -50%);
        -khtml-transform: translate(-1%, -50%);
        -moz-transform: translate(-1%, -50%);
        -ms-transform: translate(-1%, -50%);
        transform: translate(-1%, -50%); } }
  @media (min-width: 1024px) and (max-width: 1650px) {
    .image-overlay-content h1 {
      font-size: 24px !important; } }
  @media (min-width: 1651px) and (max-width: 2000px) {
    .image-overlay-content h1 {
      font-size: 2.2rem !important; } }
  .image-overlay-content .overlayText {
    color: #fefefe !important;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
    .image-overlay-content .overlayText p {
      font-size: 1.25rem;
      line-height: 1.5; }
      @media screen and (min-width: 75em) and (max-width: 89.99875em) {
        .image-overlay-content .overlayText p {
          font-size: 1.05rem !important; } }
      @media print, screen and (max-width: 74.99875em) {
        .image-overlay-content .overlayText p {
          font-size: 14px !important; } }

.overlay-fade-in p {
  color: #fefefe;
  opacity: 0;
  padding: 0 20px;
  transition: all 0.2s linear; }
  @media print, screen and (max-width: 63.99875em) {
    .overlay-fade-in p {
      transition: all 0s linear; } }

.overlay-fade-in img {
  transition: all 1s ease-in-out; }
  @media print, screen and (max-width: 63.99875em) {
    .overlay-fade-in img {
      transition: all 0s linear; } }

.overlay-fade-in .image-overlay-content {
  opacity: 0;
  background: #e04614;
  transition: all 1s ease-in-out; }
  @media print, screen and (max-width: 63.99875em) {
    .overlay-fade-in .image-overlay-content {
      opacity: 0.9; } }
  @media print, screen and (min-width: 64em) {
    .overlay-fade-in .image-overlay-content {
      /* IE10+ */
      background-image: -ms-linear-gradient(bottom left, #e04614 0%, #2fa2b4 100%);
      /* Mozilla Firefox */
      background-image: -moz-linear-gradient(bottom left, #e04614 0%, #2fa2b4 100%);
      /* Opera */
      background-image: -o-linear-gradient(bottom left, #e04614 0%, #2fa2b4 100%);
      /* Webkit (Safari/Chrome 10) */
      background-image: -webkit-gradient(linear, left bottom, right top, color-stop(0, #e04614), color-stop(100, #2fa2b4));
      /* Webkit (Chrome 11+) */
      background-image: -webkit-linear-gradient(bottom left, #e04614 0%, #2fa2b4 100%);
      /* W3C Markup */
      background-image: linear-gradient(to top right, #e04614 0%, #2fa2b4 100%); } }
  @media print, screen and (max-width: 63.99875em) {
    .overlay-fade-in .image-overlay-content {
      transition: all 0s ease-in-out; } }

.overlay-fade-in h1 {
  color: #fefefe;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  padding: 10px 20px !important; }
  @media print, screen and (max-width: 63.99875em) {
    .overlay-fade-in h1 {
      opacity: 1;
      transition: all 0s ease-in-out; } }
  @media print, screen and (max-width: 63.99875em) {
    .overlay-fade-in h1 {
      margin-right: 50px;
      text-align: left; } }

.overlay-fade-in .Overlaybutton {
  display: inline-block;
  vertical-align: middle;
  padding: 0.85em 4em;
  -webkit-appearance: none;
  border-radius: 0;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #FFF;
  color: #FFF;
  font-family: "Open Sans", sans-serif, Arial;
  text-transform: uppercase;
  transition: all 0.5s ease; }
  .overlay-fade-in .Overlaybutton:hover {
    background: #ffffff;
    color: #fa4b13; }

@media print, screen and (min-width: 64em) {
  .overlay-fade-in:hover img {
    transform: scale(1.2); } }

.overlay-fade-in:hover .image-overlay-content {
  opacity: 0.95; }

.overlay-fade-in:hover h1, .overlay-fade-in p, .overlay-fade-in .Overlaybutton {
  opacity: 1; }

.overlay-fade-in:hover p {
  transition-delay: 0.1s; }
  @media print, screen and (max-width: 63.99875em) {
    .overlay-fade-in:hover p {
      transition-delay: 0s; } }

.overlay-fade-in:hover .Overlaybutton {
  transition-delay: 0.2s; }
  @media print, screen and (max-width: 63.99875em) {
    .overlay-fade-in:hover .Overlaybutton {
      transition-delay: 0s; } }

.NewsNextArrow {
  position: absolute;
  top: 50%;
  left: 93%;
  margin-right: -93%;
  -webkit-transform: translate(-93%, -50%);
  -khtml-transform: translate(-93%, -50%);
  -moz-transform: translate(-93%, -50%);
  -ms-transform: translate(-93%, -50%);
  transform: translate(-93%, -50%);
  width: 5% !important; }

.readmore {
  text-align: center;
  margin: 30px 0; }

#quotes {
  padding: 80px 0;
  position: relative;
  background-color: #28acbe; }
  #quotes h1, #quotes h2, #quotes h3, #quotes h4 {
    font-family: "Libre Baskerville", serif;
    color: #fefefe; }
  #quotes h1 {
    text-transform: capitalize; }
    @media print, screen and (max-width: 63.99875em) {
      #quotes h1 {
        font-size: 1.7rem; } }
  @media (max-width: 2000px) and (min-width: 1024px) {
    #quotes h1 {
      font-size: 2rem !important; }
    #quotes p {
      font-size: 16px !important; }
    #quotes h3 {
      font-size: 1.6rem !important; }
    #quotes .ClientsQts {
      height: 250px !important; } }
  #quotes h5, #quotes h6 {
    font-family: "Open Sans", sans-serif, Arial;
    color: #fefefe; }
  #quotes h5 {
    margin-top: 2rem; }
  #quotes p {
    line-height: 1.3;
    font-size: 18px;
    font-family: "Open Sans", sans-serif, Arial; }
    @media print, screen and (max-width: 63.99875em) {
      #quotes p {
        font-size: 14px; } }
  #quotes .bx-wrapper .bx-pager.bx-default-pager a:hover, #quotes .bx-wrapper .bx-pager.bx-default-pager a.active {
    background: #fefefe !important; }
  #quotes .bx-wrapper .bx-pager.bx-default-pager a {
    background: transparent;
    border: 1px solid white !important; }
  @media print, screen and (max-width: 63.99875em) {
    #quotes .bx-wrapper .bx-controls-direction a {
      display: none; } }
  #quotes .bx-wrapper img {
    margin: 2rem auto;
    width: 210px; }
  #quotes .bx-wrapper #weedabix {
    width: 150px; }
    @media print, screen and (max-width: 63.99875em) {
      #quotes .bx-wrapper #weedabix {
        width: 100px; } }
  #quotes .bx-wrapper #monk {
    width: 150px; }
    @media print, screen and (max-width: 63.99875em) {
      #quotes .bx-wrapper #monk {
        width: 100px; } }
  @media (max-width: 2000px) and (min-width: 1024px) {
    #quotes .bx-wrapper #weedabix, #quotes .bx-wrapper #monk {
      width: 110px; } }
  #quotes .bx-wrapper li {
    width: 100% !important;
    /*@include breakpoint(medium down) {
				width: 90%!important;
			}
			@include breakpoint(large up) {
				width: 100%!important;
			}*/ }
  #quotes .column, #quotes .columns {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important; }
  #quotes .sliderQuotes {
    margin: 0; }
  @media print, screen and (min-width: 64em) {
    #quotes .ClientsQts {
      height: 300px;
      position: relative; }
      #quotes .ClientsQts .ClientsQtsCentr {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -50%);
        -khtml-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); } }
  @media print, screen and (min-width: 64em) {
    #quotes .quotesLogo {
      height: 100px;
      position: relative; }
      #quotes .quotesLogo .quotesLogoCentr {
        position: absolute;
        top: 80%;
        left: 50%;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -80%);
        -khtml-transform: translate(-50%, -80%);
        -moz-transform: translate(-50%, -80%);
        -ms-transform: translate(-50%, -80%);
        transform: translate(-50%, -80%); } }
  #quotes .bx-wrapper .bx-pager, #quotes .bx-wrapper .bx-controls-auto {
    /*left: 10px;*/ }
  #quotes .quotes-twitter {
    display: inline;
    margin: 0 5px 0 0 !important; }
    @media print, screen and (min-width: 64em) {
      #quotes .quotes-twitter {
        max-width: 3.5%; } }
    @media print, screen and (max-width: 63.99875em) {
      #quotes .quotes-twitter {
        max-width: 10%; } }

#cliensLogos {
  background-image: url("../img/Clients-logos-background.jpg");
  background-size: cover;
  background-attachment: fixed; }
  @media print, screen and (max-width: 63.99875em) {
    #cliensLogos {
      background-size: auto 100%;
      background-attachment: scroll;
      background-position: center; } }
  #cliensLogos .clientlogoswrap {
    padding: 10px; }
    @media print, screen and (min-width: 64em) {
      #cliensLogos .clientlogoswrap {
        padding: 100px; } }
  #cliensLogos .column, #cliensLogos .columns {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important; }
  #cliensLogos .myspace {
    margin: 0 !important; }
    #cliensLogos .myspace img {
      -moz-transition: all 0.3s;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; }
      @media print, screen and (max-width: 63.99875em) {
        #cliensLogos .myspace img {
          padding: .5rem 0; } }
      #cliensLogos .myspace img:hover {
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        transform: scale(1.1); }
  #cliensLogos .clientlogos-load {
    width: 23px; }
  #cliensLogos h4 {
    font-family: 'Open Sans', serif;
    color: #fefefe;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 0; }
  #cliensLogos #ClientMobile, #cliensLogos #ClientMobileUp {
    padding-bottom: 40px !important;
    cursor: pointer; }
  @media print, screen and (max-width: 63.99875em) {
    #cliensLogos .myhide {
      display: none; }
      #cliensLogos .myhide.open {
        display: block; } }
  @media print, screen and (min-width: 64em) {
    #cliensLogos .hideClientMobile {
      display: none !important; } }

/*
* About Hero section style
*/
#about-hero {
  background-image: url("../img/about-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  #about-hero h1 {
    color: white;
    font-size: 2rem; }
    @media print, screen and (max-width: 39.99875em) {
      #about-hero h1 {
        font-size: 1.5rem; } }
    @media (max-height: 420px) {
      #about-hero h1 {
        font-size: 1.5rem; } }
  #about-hero .HerotextAlign {
    text-align: left; }
  #about-hero p {
    line-height: 1.75;
    font-size: 18px; }

#aboutText {
  padding: 7rem 0; }
  #aboutText h1, #aboutText h2, #aboutText h3, #aboutText h4, #aboutText h5, #aboutText h6 {
    font-family: "Libre Baskerville", serif; }
  #aboutText h1 {
    line-height: 1.5;
    font-size: 2rem;
    padding: 0 0 30px;
    color: #595959;
    text-transform: capitalize; }
    @media print, screen and (max-width: 39.99875em) {
      #aboutText h1 {
        font-size: 1.5rem; } }
  #aboutText p {
    line-height: 1.75;
    font-size: 18px;
    font-family: "Open Sans", sans-serif, Arial;
    color: #595959; }

.r-tabs {
  padding-top: 2rem; }
  .r-tabs .r-tabs-nav {
    margin: 0;
    padding: 0; }

.r-tabs .r-tabs-tab {
  display: inline-block;
  margin: 0;
  list-style: none;
  width: 25%;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.25rem;
  line-height: 1;
  font-weight: 600;
  position: relative;
  border-left: 1px solid white; }
  .r-tabs .r-tabs-tab a {
    display: block;
    padding: .85rem 0rem;
    color: #444444; }
  .r-tabs .r-tabs-tab:hover {
    background: #444444; }
    .r-tabs .r-tabs-tab:hover a {
      color: white; }

.r-tabs-nav .r-tabs-state-active {
  background: #444444; }
  .r-tabs-nav .r-tabs-state-active:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-top: solid 10px #444444;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent; }
  .r-tabs-nav .r-tabs-state-active a {
    color: white; }

.r-tabs .r-tabs-panel {
  padding: 3rem 0;
  display: none;
  color: #595959;
  border-top: 1px solid #595959;
  margin-top: 2rem; }

.r-tabs .r-tabs-accordion-title {
  display: none; }

.r-tabs .r-tabs-panel.r-tabs-state-active {
  display: block; }

.FourTab {
  -webkit-column-count: 2;
  /* Chrome, Safari, Opera */
  -moz-column-count: 2;
  /* Firefox */
  column-count: 2; }

/* Accordion responsive breakpoint */
@media only screen and (max-width: 480px) {
  .FourTab {
    -webkit-column-count: 1;
    /* Chrome, Safari, Opera */
    -moz-column-count: 1;
    /* Firefox */
    column-count: 1; }
  .r-tabs .r-tabs-nav {
    display: none; }
  .r-tabs .r-tabs-panel {
    border-top: none;
    margin-top: 0;
    padding: 2rem 0; }
  .r-tabs .r-tabs-accordion-title {
    border-bottom: 1px solid #595959;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.25rem;
    line-height: 1;
    font-weight: 600;
    position: relative; }
    .r-tabs .r-tabs-accordion-title a {
      display: block;
      padding: .85rem 0rem;
      color: #444444; }
  .r-tabs-accordion-title.r-tabs-state-active {
    background: #444444; }
    .r-tabs-accordion-title.r-tabs-state-active:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -10px;
      width: 0;
      height: 0;
      border-top: solid 10px #444444;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent; }
    .r-tabs-accordion-title.r-tabs-state-active a {
      color: white; } }

#WhatArrive {
  margin-top: -20px;
  overflow-x: hidden; }
  #WhatArrive #WhatWeDo {
    background-image: url("../img/WhatWeDo.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative; }
    #WhatArrive #WhatWeDo h1, #WhatArrive #WhatWeDo h2, #WhatArrive #WhatWeDo h3, #WhatArrive #WhatWeDo h4, #WhatArrive #WhatWeDo h5, #WhatArrive #WhatWeDo h6 {
      font-family: "Libre Baskerville", serif; }
    #WhatArrive #WhatWeDo h1 {
      line-height: 1.3;
      padding: 30px 0;
      color: #e6e04d; }
      @media print, screen and (max-width: 63.99875em) {
        #WhatArrive #WhatWeDo h1 {
          font-size: 1.7rem; } }
    #WhatArrive #WhatWeDo p {
      line-height: 1.3;
      font-size: 18px;
      font-family: "Open Sans", sans-serif, Arial;
      color: #fefefe; }
      @media print, screen and (max-width: 63.99875em) {
        #WhatArrive #WhatWeDo p {
          font-size: 14px; } }
    @media (max-width: 2000px) and (min-width: 1024px) {
      #WhatArrive #WhatWeDo h1 {
        font-size: 2rem !important; }
      #WhatArrive #WhatWeDo p {
        font-size: 16px !important; }
      #WhatArrive #WhatWeDo a {
        font-size: 1rem; } }
    #WhatArrive #WhatWeDo .topRow {
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 0; }
    #WhatArrive #WhatWeDo .whatimagewrap {
      position: relative; }
      #WhatArrive #WhatWeDo .whatimagewrap .whatImg {
        z-index: 0; }
        @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
          #WhatArrive #WhatWeDo .whatimagewrap .whatImg {
            position: absolute;
            top: -200%;
            left: 0%;
            margin-right: 0%;
            -webkit-transform: translate(0%, 200%);
            -khtml-transform: translate(0%, 200%);
            -moz-transform: translate(0%, 200%);
            -ms-transform: translate(0%, 200%);
            transform: translate(0%, 200%);
            left: 20px; } }
    #WhatArrive #WhatWeDo .coffeeimagewrap {
      position: relative; }
      #WhatArrive #WhatWeDo .coffeeimagewrap .coffeeImg {
        z-index: 10;
        left: -20px; }
        @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
          #WhatArrive #WhatWeDo .coffeeimagewrap .coffeeImg {
            position: absolute;
            top: -200%;
            left: 0%;
            margin-right: 0%;
            -webkit-transform: translate(0%, 200%);
            -khtml-transform: translate(0%, 200%);
            -moz-transform: translate(0%, 200%);
            -ms-transform: translate(0%, 200%);
            transform: translate(0%, 200%); } }
    @media (min-width: 2001px) {
      #WhatArrive #WhatWeDo .coffeeImg {
        position: absolute;
        top: -100%;
        left: 0%;
        margin-right: 0%;
        -webkit-transform: translate(0%, 100%);
        -khtml-transform: translate(0%, 100%);
        -moz-transform: translate(0%, 100%);
        -ms-transform: translate(0%, 100%);
        transform: translate(0%, 100%); }
      #WhatArrive #WhatWeDo .whatImg {
        position: absolute;
        top: -100%;
        left: 0%;
        margin-right: 0%;
        -webkit-transform: translate(0%, 100%);
        -khtml-transform: translate(0%, 100%);
        -moz-transform: translate(0%, 100%);
        -ms-transform: translate(0%, 100%);
        transform: translate(0%, 100%); } }
    @media (max-width: 2000px) and (min-width: 1551px) {
      #WhatArrive #WhatWeDo .coffeeImg {
        position: absolute;
        top: -105%;
        left: 0%;
        margin-right: 0%;
        -webkit-transform: translate(0%, 105%);
        -khtml-transform: translate(0%, 105%);
        -moz-transform: translate(0%, 105%);
        -ms-transform: translate(0%, 105%);
        transform: translate(0%, 105%); }
      #WhatArrive #WhatWeDo .whatImg {
        position: absolute;
        top: -105%;
        left: 0%;
        margin-right: 0%;
        -webkit-transform: translate(0%, 105%);
        -khtml-transform: translate(0%, 105%);
        -moz-transform: translate(0%, 105%);
        -ms-transform: translate(0%, 105%);
        transform: translate(0%, 105%); } }
    @media (max-width: 1550px) and (min-width: 1201px) {
      #WhatArrive #WhatWeDo .coffeeImg {
        position: absolute;
        top: -145%;
        left: 0%;
        margin-right: 0%;
        -webkit-transform: translate(0%, 145%);
        -khtml-transform: translate(0%, 145%);
        -moz-transform: translate(0%, 145%);
        -ms-transform: translate(0%, 145%);
        transform: translate(0%, 145%); }
      #WhatArrive #WhatWeDo .whatImg {
        position: absolute;
        top: -145%;
        left: 0%;
        margin-right: 0%;
        -webkit-transform: translate(0%, 145%);
        -khtml-transform: translate(0%, 145%);
        -moz-transform: translate(0%, 145%);
        -ms-transform: translate(0%, 145%);
        transform: translate(0%, 145%); } }
    #WhatArrive #WhatWeDo .TextAlign {
      padding: 0 50px; }
      @media print, screen and (max-width: 63.99875em) {
        #WhatArrive #WhatWeDo .TextAlign {
          padding: 0 15px; } }
      #WhatArrive #WhatWeDo .TextAlign .Myunderline {
        color: #e6e04d; }
      #WhatArrive #WhatWeDo .TextAlign a {
        position: relative;
        font-family: "Open Sans", sans-serif, Arial;
        font-weight: 600;
        color: #fefefe;
        font-size: 1.1rem;
        line-height: 2.2rem;
        transition: all 1.5s ease; }
        @media print, screen and (max-width: 63.99875em) {
          #WhatArrive #WhatWeDo .TextAlign a {
            font-size: 14px;
            line-height: 1.8rem;
            font-weight: 300; } }
        #WhatArrive #WhatWeDo .TextAlign a:hover {
          color: #e6e04d; }
    #WhatArrive #WhatWeDo .SlideHeaderAlign {
      padding: 210px 50px 0 50px; }
      @media print, screen and (max-width: 63.99875em) {
        #WhatArrive #WhatWeDo .SlideHeaderAlign {
          padding: 80px 0 0 15px; } }
    @media (max-width: 2000px) and (min-width: 1024px) {
      #WhatArrive #WhatWeDo .SlideHeaderAlign {
        padding: 160px 50px 0 50px !important; }
      #WhatArrive #WhatWeDo #Work {
        margin: 60px 0 130px 0 !important; } }
    #WhatArrive #WhatWeDo .bx-wrapper {
      padding-bottom: 0; }
      #WhatArrive #WhatWeDo .bx-wrapper .bx-controls-direction a {
        margin-top: 80px !important; }
        @media print, screen and (max-width: 63.99875em) {
          #WhatArrive #WhatWeDo .bx-wrapper .bx-controls-direction a {
            display: none; } }
      #WhatArrive #WhatWeDo .bx-wrapper .bx-pager {
        position: absolute;
        top: 100%;
        left: -10%;
        margin-right: 10%;
        -webkit-transform: translate(10%, -100%);
        -khtml-transform: translate(10%, -100%);
        -moz-transform: translate(10%, -100%);
        -ms-transform: translate(10%, -100%);
        transform: translate(10%, -100%);
        left: -33%; }
        @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
          #WhatArrive #WhatWeDo .bx-wrapper .bx-pager {
            left: -30%; } }
        @media print, screen and (max-width: 63.99875em) {
          #WhatArrive #WhatWeDo .bx-wrapper .bx-pager {
            display: none; } }
      #WhatArrive #WhatWeDo .bx-wrapper .bx-pager.bx-default-pager a {
        background: transparent !important;
        border: 1px solid #fefefe !important; }
        #WhatArrive #WhatWeDo .bx-wrapper .bx-pager.bx-default-pager a.active {
          background: #fefefe !important; }
      #WhatArrive #WhatWeDo .bx-wrapper .bx-viewport {
        overflow: visible !important; }
    #WhatArrive #WhatWeDo #Work {
      margin: 60px 0 180px 0;
      padding: 0.85em 4em;
      font-family: "Open Sans", sans-serif, Arial;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      border: 1.5px solid #fefefe;
      transition: all 0.5s ease; }
      #WhatArrive #WhatWeDo #Work p {
        margin-bottom: 0;
        transition: all 0.5s ease; }
      @media print, screen and (max-width: 63.99875em) {
        #WhatArrive #WhatWeDo #Work {
          margin: 20px 0 50px 0; } }
      #WhatArrive #WhatWeDo #Work:hover {
        background: #ffffff; }
        #WhatArrive #WhatWeDo #Work:hover p {
          color: #595959; }
  #WhatArrive #ArriveTogether {
    background-image: url("../img/arrivetogether.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    #WhatArrive #ArriveTogether h1, #WhatArrive #ArriveTogether h2, #WhatArrive #ArriveTogether h3, #WhatArrive #ArriveTogether h4, #WhatArrive #ArriveTogether h5, #WhatArrive #ArriveTogether h6 {
      font-family: "Libre Baskerville", serif; }
    #WhatArrive #ArriveTogether h1 {
      line-height: 1.3;
      padding: 30px 0;
      color: #28acbe;
      text-transform: capitalize; }
      @media print, screen and (max-width: 63.99875em) {
        #WhatArrive #ArriveTogether h1 {
          font-size: 1.7rem; } }
    #WhatArrive #ArriveTogether p {
      color: #595959;
      line-height: 1.3;
      font-size: 18px;
      font-family: "Open Sans", sans-serif, Arial; }
      @media print, screen and (max-width: 63.99875em) {
        #WhatArrive #ArriveTogether p {
          font-size: 14px; } }
    @media (max-width: 2000px) and (min-width: 1024px) {
      #WhatArrive #ArriveTogether h1 {
        font-size: 2rem !important; }
      #WhatArrive #ArriveTogether p {
        font-size: 16px !important; }
      #WhatArrive #ArriveTogether .Arrive {
        width: 450px !important; } }
    #WhatArrive #ArriveTogether .topRow {
      font-weight: 700;
      margin-bottom: 0; }
    #WhatArrive #ArriveTogether .WhitetextAlign {
      padding: 0 100px; }
      @media print, screen and (max-width: 63.99875em) {
        #WhatArrive #ArriveTogether .WhitetextAlign {
          padding: 0; } }
    #WhatArrive #ArriveTogether .HeadertextAlign {
      padding: 0 100px; }
      @media print, screen and (max-width: 63.99875em) {
        #WhatArrive #ArriveTogether .HeadertextAlign {
          padding: 0; } }
    #WhatArrive #ArriveTogether #toptracks {
      background-image: url("../img/toptracks.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 100px;
      margin: 50px auto; }
      @media print, screen and (max-width: 63.99875em) {
        #WhatArrive #ArriveTogether #toptracks {
          margin: 0 auto; } }
    #WhatArrive #ArriveTogether #bottomtracks {
      background-image: url("../img/bottomtracks.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 100px;
      margin: 50px auto; }
      @media print, screen and (max-width: 63.99875em) {
        #WhatArrive #ArriveTogether #bottomtracks {
          margin: 0 auto; } }
    #WhatArrive #ArriveTogether .Arrive {
      width: 550px; }
      @media print, screen and (max-width: 63.99875em) {
        #WhatArrive #ArriveTogether .Arrive {
          width: 400px; } }

#orangeMobile {
  background-color: #f04e24;
  width: 100%;
  height: 120px;
  position: relative; }
  #orangeMobile #MenuMobilelogo {
    position: absolute;
    top: 20px;
    left: 30px;
    width: 100px; }

#TakeLook {
  overflow-x: hidden;
  /*---------------*/
  /***** Bubba *****/
  /*---------------*/ }
  #TakeLook h1, #TakeLook h2, #TakeLook h3, #TakeLook h4, #TakeLook h5, #TakeLook h6 {
    font-family: "Libre Baskerville", serif; }
  #TakeLook h1 {
    text-transform: capitalize;
    line-height: 1.3; }
    @media print, screen and (max-width: 63.99875em) {
      #TakeLook h1 {
        font-size: 1.5rem; } }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      #TakeLook h1 {
        font-size: 1.6rem; } }
  @media (max-width: 1700px) and (min-width: 1199px) {
    #TakeLook h1 {
      font-size: 2rem !important; } }
  #TakeLook .figure {
    position: relative;
    text-align: center;
    overflow: hidden;
    cursor: pointer; }
    @media print, screen and (max-width: 39.99875em) {
      #TakeLook .figure {
        max-height: 180px;
        width: 100%; }
        #TakeLook .figure img {
          margin-top: -20%; } }
  #TakeLook .figure img {
    position: relative;
    display: block; }
  #TakeLook .figure .figcaption {
    padding: 2em;
    color: #fff;
    text-transform: capitalize;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }
    #TakeLook .figure .figcaption img {
      min-height: 100%;
      /* Safari only override */ }
      #TakeLook .figure .figcaption img _::-webkit-full-page-media, #TakeLook .figure .figcaption img _:future, #TakeLook .figure .figcaption img :root .safari_only {
        min-height: 50%; }
  #TakeLook .figure .figcaption::before,
  #TakeLook .figure .figcaption::after {
    pointer-events: none; }
  #TakeLook .figure .figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  #TakeLook .figure.effect-bubba {
    background: #000000; }
    #TakeLook .figure.effect-bubba h1 {
      padding: 0 20px !important; }
      @media print, screen and (min-width: 64em) {
        #TakeLook .figure.effect-bubba h1 {
          position: absolute;
          top: 35%;
          left: 50%;
          margin-right: -50%;
          -webkit-transform: translate(-50%, -35%);
          -khtml-transform: translate(-50%, -35%);
          -moz-transform: translate(-50%, -35%);
          -ms-transform: translate(-50%, -35%);
          transform: translate(-50%, -35%); } }
      @media print, screen and (max-width: 63.99875em) {
        #TakeLook .figure.effect-bubba h1 {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-right: -50%;
          -webkit-transform: translate(-50%, -50%);
          -khtml-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); } }
    #TakeLook .figure.effect-bubba p {
      position: absolute;
      top: 65%;
      left: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -65%);
      -khtml-transform: translate(-50%, -65%);
      -moz-transform: translate(-50%, -65%);
      -ms-transform: translate(-50%, -65%);
      transform: translate(-50%, -65%);
      color: #fefefe;
      padding: 1em 3em;
      border: 1.5px solid #ffffff;
      transition: all 0.5s ease;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      font-family: "Open Sans", sans-serif, Arial; }
      @media print, screen and (max-width: 63.99875em) {
        #TakeLook .figure.effect-bubba p {
          font-size: 14px; } }
      #TakeLook .figure.effect-bubba p:hover {
        background: #ffffff;
        border: 1.5px solid #ffffff;
        color: #595959; }
    @media (max-width: 2000px) and (min-width: 1024px) {
      #TakeLook .figure.effect-bubba p {
        font-size: 16px !important; } }
  #TakeLook .figure.effect-bubba img {
    opacity: 0.3;
    -webkit-transition: all 0.85s;
    transition: all 0.85s;
    transform: scale(1.02); }
  #TakeLook .figure.effect-bubba:hover img {
    opacity: 0.7;
    transform: scale(1); }
  #TakeLook .figure.effect-bubba .figcaption::before,
  #TakeLook .figure.effect-bubba .figcaption::after {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.85s, -webkit-transform 0.85s;
    transition: opacity 0.85s, transform 0.85s; }
  #TakeLook .figure.effect-bubba .figcaption::before {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1); }
  #TakeLook .figure.effect-bubba .figcaption::after {
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0); }
  #TakeLook .figure.effect-bubba:hover .figcaption::before,
  #TakeLook .figure.effect-bubba:hover .figcaption::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); }
  @media print, screen and (max-width: 39.99875em) {
    #TakeLook .figure.effect-bubba:hover .figcaption::before,
    #TakeLook .figure.effect-bubba:hover .figcaption::after {
      opacity: 0; } }

#Culture {
  background-image: url("../img/Culture.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow-x: hidden; }
  @media screen and (min-width: 75em) {
    #Culture {
      background-attachment: fixed; } }
  #Culture h1, #Culture h2, #Culture h3, #Culture h4, #Culture h5, #Culture h6 {
    font-family: "Libre Baskerville", serif; }
  #Culture h1 {
    line-height: 1.3;
    padding: 30px 0;
    text-transform: capitalize; }
    @media print, screen and (max-width: 63.99875em) {
      #Culture h1 {
        font-size: 1.7rem; } }
  #Culture p {
    margin-bottom: 0;
    line-height: 1.75;
    font-size: 18px;
    font-family: "Open Sans", sans-serif, Arial; }
  @media (max-width: 2000px) and (min-width: 1024px) {
    #Culture h1 {
      font-size: 2rem !important; }
    #Culture .Arrive {
      width: 450px !important; } }
  @media screen and (min-width: 75em) {
    #Culture .cultureSliderWrap {
      margin: 100px 100px; } }
  @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
    #Culture .cultureSliderWrap {
      margin: 80px 20px; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    #Culture .cultureSliderWrap {
      margin: 80px 20px; } }
  @media print, screen and (max-width: 39.99875em) {
    #Culture .cultureSliderWrap {
      margin: 0 0 80px; } }
  #Culture .cultureSliderWrap img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    pointer-events: none; }
  #Culture .cultureWrap {
    height: 80vh;
    position: relative;
    color: #fefefe; }
    @media print, screen and (max-width: 63.99875em) {
      #Culture .cultureWrap {
        margin-left: 30px;
        margin-top: 80px;
        height: 50vh; } }
    @media print, screen and (max-width: 39.99875em) {
      #Culture .cultureWrap {
        height: unset; } }
    @media (max-height: 420px) {
      #Culture .cultureWrap {
        height: unset; } }
    #Culture .cultureWrap .topRow {
      font-weight: 700;
      margin-bottom: 0; }
    #Culture .cultureWrap .culturetext {
      position: absolute;
      top: 40%;
      left: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -40%);
      -khtml-transform: translate(-50%, -40%);
      -moz-transform: translate(-50%, -40%);
      -ms-transform: translate(-50%, -40%);
      transform: translate(-50%, -40%); }
      @media print, screen and (max-width: 39.99875em) {
        #Culture .cultureWrap .culturetext {
          position: relative !important;
          position: absolute;
          top: 0%;
          left: 0%;
          margin-right: 0%;
          -webkit-transform: translate(0%, 0%);
          -khtml-transform: translate(0%, 0%);
          -moz-transform: translate(0%, 0%);
          -ms-transform: translate(0%, 0%);
          transform: translate(0%, 0%); } }
      @media (max-height: 420px) {
        #Culture .cultureWrap .culturetext {
          position: relative !important;
          position: absolute;
          top: 0;
          left: 0;
          margin-right: 0;
          -webkit-transform: translate(0, 0);
          -khtml-transform: translate(0, 0);
          -moz-transform: translate(0, 0);
          -ms-transform: translate(0, 0);
          transform: translate(0, 0); } }
  #Culture #careers {
    margin: 30px 0;
    padding: 1em 4em;
    font-family: "Open Sans", sans-serif, Arial;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    border: 1.5px solid #ffffff;
    transition: all 0.5s ease; }
    #Culture #careers:hover {
      background: #ffffff;
      color: #595959; }
  #Culture .bx-wrapper .bx-pager, #Culture .bx-wrapper .bx-controls-auto {
    bottom: 40px;
    left: 0; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      #Culture .bx-wrapper .bx-pager, #Culture .bx-wrapper .bx-controls-auto {
        left: -20px; } }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      #Culture .bx-wrapper .bx-pager, #Culture .bx-wrapper .bx-controls-auto {
        left: -20px; } }
    @media print, screen and (max-width: 39.99875em) {
      #Culture .bx-wrapper .bx-pager, #Culture .bx-wrapper .bx-controls-auto {
        left: -10px; } }
  #Culture .bx-pager.bx-default-pager a {
    background: transparent !important;
    border: 1px solid #fefefe !important; }
    #Culture .bx-pager.bx-default-pager a.active {
      background: #fefefe !important; }
  #Culture .bx-wrapper {
    padding: 1.25rem; }
    #Culture .bx-wrapper img {
      width: 100%; }
  #Culture ul {
    margin-left: 0; }

#CSnewshero {
  background-image: url("../img/CSnews.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

/*
.CSnewshero {
	position: relative;
	height: 100%;
	width: 100%;
	z-index:1;

	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.3;
		padding: 30px 0;
		color: #e6e04d;
		text-transform: capitalize;
		@include breakpoint(medium down) {
  		  font-size: 1.7rem;
	    }
	}
	p {
		color: $white;
		line-height: 1.3;
		font-size: 20px;
		font-family: $body-font-family;
		@include breakpoint(medium down) {
		  font-size: 18px;
		}
	}

	@media (max-width: 2000px) and (min-width: 1024px){
		h1{
			font-size: 2rem!important;
		}
		p{
			font-size: 16px!important;
		}
	}
	.topRow {
		font-weight: 700;
		margin-bottom: 0;
		// padding-top: 80px;
	}

	.HerotextAlign {
		@include vertical-align(50%, 50%);
	}
	//scrolling to the next section

    .CSnewsscrollicon {
 	   @include vertical-align(50%, 95%);
 	   z-index:5;
 	   position: absolute;
 	    width: 20px;
 	    -webkit-animation: fadeInOut 2s infinite;
    }
    @-webkit-keyframes fadeInOut {
 	 from { opacity: 0.2; }
 	 50% { opacity: 1.0; }
 	 to { opacity: 0.2; }
  }

	  //social icons
	  .Iconsocial {
		  @include vertical-align(2%, 95%);
		  z-index: 1;
		  width: 65px;
		  img {
			 transition: all 1s ease;
			  &:hover {
				  transform: scale(1.1);
			  }
		  }
	  }
	  @media (max-width: 1920px) {
		  .Iconsocial {
			  // width: 60px;
			  li > a {
				  padding: 0.4rem 1rem!important;
			  }
		  } //Iconsocial
	}

}
*/
#CSnews {
  z-index: 1;
  margin-bottom: 0px;
  margin-top: -1px;
  padding-top: 1px;
  /*@media (max-width: 2000px) and (min-width: 1024px){
				p{
					font-size: 16px!important;
				}
			}*/ }
  #CSnews h1, #CSnews h2, #CSnews h3, #CSnews h4, #CSnews h5, #CSnews h6 {
    font-family: "Libre Baskerville", serif; }
  #CSnews h1 {
    line-height: 1.3;
    padding: 30px 0;
    color: #fa4b13;
    text-transform: capitalize; }
    @media print, screen and (max-width: 74.99875em) {
      #CSnews h1 {
        font-size: 1.7rem;
        padding: 15px 0;
        margin-bottom: 0; } }
  @media (max-width: 2000px) and (min-width: 1199px) {
    #CSnews h1 {
      font-size: 2rem !important; } }
  #CSnews p {
    color: #595959;
    line-height: 1.3;
    font-size: 20px;
    font-family: "Open Sans", sans-serif, Arial; }
    @media print, screen and (max-width: 63.99875em) {
      #CSnews p {
        font-size: 18px; } }
  #CSnews .topRow {
    font-weight: 700;
    margin-bottom: 0; }
  @media print, screen and (min-width: 64em) {
    #CSnews .bordered {
      padding-bottom: 80px;
      border-bottom: 1px solid gray;
      max-width: 75rem;
      margin: 80px auto 0 auto; } }
  @media print, screen and (min-width: 64em) {
    #CSnews .lastbordered {
      margin: 80px auto 0 auto; } }
  #CSnews .newslist {
    position: relative;
    height: 100%;
    border: 30px solid #fefefe;
    transition: box-shadow 0.3s ease-in-out; }
    @media screen and (min-width: 75em) {
      #CSnews .newslist:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); }
        #CSnews .newslist:hover .NewslisrArrow {
          opacity: 1; } }
    #CSnews .newslist .NewslisrArrow {
      position: absolute;
      top: 60%;
      left: 114.7%;
      margin-right: -114.7%;
      -webkit-transform: translate(-114.7%, -60%);
      -khtml-transform: translate(-114.7%, -60%);
      -moz-transform: translate(-114.7%, -60%);
      -ms-transform: translate(-114.7%, -60%);
      transform: translate(-114.7%, -60%);
      opacity: 0;
      transition: opacity 0.3s ease-in-out; }
  #CSnews .newslistM {
    margin-bottom: 50px; }
  #CSnews .MoreCSNews {
    margin: 30px 0 0 0;
    padding: 1em 4em;
    font-family: "Open Sans", sans-serif, Arial;
    color: #fa4b13;
    text-transform: uppercase;
    font-size: 14px !important;
    font-weight: 600;
    border: 1.5px solid #fa4b13;
    transition: all 0.5s ease; }
    #CSnews .MoreCSNews:hover {
      background: #fa4b13;
      color: #fefefe; }
  #CSnews .MoreCSNewsM {
    font-family: "Open Sans", sans-serif, Arial;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding: 1em 3em;
    margin: 0 0 3rem 0; }
  #CSnews .pagination {
    position: relative;
    margin-top: 100px;
    margin-bottom: 0;
    background: #e6e04d;
    padding: 15px 0;
    font-family: "Open Sans", sans-serif, Arial;
    text-transform: uppercase;
    font-weight: 600; }
    @media print, screen and (max-width: 63.99875em) {
      #CSnews .pagination {
        margin-top: 0; } }
    @media print, screen and (max-width: 39.99875em) {
      #CSnews .pagination {
        padding: 30px 0; } }
    #CSnews .pagination a {
      font-size: 16px; }
      @media print, screen and (max-width: 74.99875em) {
        #CSnews .pagination a {
          font-size: 14px; } }
      #CSnews .pagination a::before, #CSnews .pagination a::after {
        content: " "; }
      #CSnews .pagination a:hover {
        background: transparent;
        color: #fa4b13; }
    #CSnews .pagination .current {
      font-size: 16px;
      display: inline-block !important;
      background: transparent;
      color: #fa4b13; }
      @media print, screen and (max-width: 74.99875em) {
        #CSnews .pagination .current {
          font-size: 14px; } }
    #CSnews .pagination .pagination li {
      display: inline-block !important; }
    #CSnews .pagination .pagination-previous {
      position: absolute;
      top: 50%;
      left: 15%;
      margin-right: -15%;
      -webkit-transform: translate(-15%, -50%);
      -khtml-transform: translate(-15%, -50%);
      -moz-transform: translate(-15%, -50%);
      -ms-transform: translate(-15%, -50%);
      transform: translate(-15%, -50%); }
    #CSnews .pagination .pagination-next {
      position: absolute;
      top: 50%;
      left: 85%;
      margin-right: -85%;
      -webkit-transform: translate(-85%, -50%);
      -khtml-transform: translate(-85%, -50%);
      -moz-transform: translate(-85%, -50%);
      -ms-transform: translate(-85%, -50%);
      transform: translate(-85%, -50%); }
    #CSnews .pagination #CSNewsImgPrev, #CSnews .pagination #CSNewsImgNext {
      width: 15px;
      margin-right: 10px;
      margin-left: 10px; }
  #CSnews #secondblock, #CSnews #thirdblock, #CSnews #forthblock, #CSnews #fifthblock, #CSnews #sixthblock, #CSnews #seventhblock, #CSnews #eighthblock, #CSnews #ninethblock {
    display: none; }

.posthero {
  text-align: center; }
  @media print, screen and (max-width: 39.99875em) {
    .posthero {
      padding-top: 90px; } }
  .posthero .HeroPostImg {
    width: 100%; }
  .posthero .LetsMeetImg img {
    width: 100%; }
  .posthero .LetsMeetImg p {
    background-color: #f4f6f6;
    padding: 20px; }

.posthero {
  font-family: "Open Sans", sans-serif, Arial; }

.posttext {
  background-image: -ms-linear-gradient(top, bottom, #ffffff 0%, #e9f7f8 100%);
  /* Mozilla Firefox */
  background-image: -moz-linear-gradient(top bottom, #ffffff 0%, #e9f7f8 100%);
  /* Opera */
  background-image: -o-linear-gradient(top bottom, #ffffff 0%, #e9f7f8 100%);
  /* Webkit (Safari/Chrome 10) */
  background-image: -webkit-gradient(linear, top, bottom, color-stop(0, #ffffff), color-stop(100, #e9f7f8));
  /* Webkit (Chrome 11+) */
  background-image: -webkit-linear-gradient(top bottom, #ffffff 0%, #e9f7f8 100%);
  /* W3C Markup */
  background-image: linear-gradient(to bottom, #ffffff 0%, #e9f7f8 100%);
  /*h3 {
			@include breakpoint(large only) {
				font-size: 1.85rem;
			}

			padding-bottom:25px;
		}*/
  /*@media (max-width: 2000px) and (min-width: 1024px){
			p, li, a {
				font-size: 16px!important;
			}
			h3 {
				font-size: 18px;
			}
		}*/ }
  @media print, screen and (max-width: 63.99875em) {
    .posttext {
      background: #fefefe;
      padding-top: 90px; } }
  .posttext h1, .posttext h2, .posttext h3, .posttext h4, .posttext h5, .posttext h6 {
    font-family: "Libre Baskerville", serif;
    color: #595959;
    padding-bottom: 25px; }
  .posttext h1 {
    line-height: 1.3;
    padding: 25px 0;
    text-transform: capitalize; }
    @media print, screen and (max-width: 74.99875em) {
      .posttext h1 {
        font-size: 1.7rem;
        padding: 15px 0;
        margin-bottom: 0; } }
  @media (max-width: 2000px) and (min-width: 1199px) {
    .posttext h1 {
      font-size: 2rem !important; } }
  .posttext .sub {
    font-weight: 700;
    font-family: "Open Sans", sans-serif, Arial;
    color: #595959; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      .posttext .sub {
        font-size: 14px; } }
  .posttext p, .posttext li {
    color: #595959;
    line-height: 1.5;
    font-size: 18px;
    font-family: "Open Sans", sans-serif, Arial;
    /*@include breakpoint(medium down) {
			  font-size: 18px;
			}*/ }
  .posttext .topRow {
    font-weight: 700;
    margin-bottom: 0;
    padding: 100px 0 30px 0; }
    @media print, screen and (max-width: 39.99875em) {
      .posttext .topRow {
        padding-top: 50px; } }
  .posttext .ShareIcons {
    width: 50px;
    padding-left: 25px;
    transition: all 1s ease; }
    .posttext .ShareIcons:hover {
      transform: scale(1.1); }
  .posttext .ShareWrap .ShareIcons {
    padding-right: 25px;
    padding-bottom: 25px;
    padding-left: 0; }
  .posttext .blogtext {
    margin-bottom: 150px;
    margin-top: 50px; }
    @media print, screen and (max-width: 63.99875em) {
      .posttext .blogtext {
        margin-bottom: 50px; } }
  .posttext .boltnews {
    max-width: 50px;
    margin-bottom: 100px; }
    @media print, screen and (max-width: 63.99875em) {
      .posttext .boltnews {
        margin-bottom: 50px; } }
  .posttext .blogquot {
    font-size: 20px;
    font-weight: 700;
    font-family: "Libre Baskerville", serif;
    padding: 30px; }
  .posttext .pagination {
    position: relative;
    margin-bottom: 0;
    background: #28acbe;
    padding: 15px 0;
    font-family: "Open Sans", sans-serif, Arial;
    text-transform: uppercase;
    font-weight: 600; }
    @media print, screen and (max-width: 39.99875em) {
      .posttext .pagination {
        padding: 30px 0; } }
    .posttext .pagination a {
      font-size: 16px;
      color: #fefefe; }
      @media print, screen and (max-width: 74.99875em) {
        .posttext .pagination a {
          font-size: 14px; } }
      .posttext .pagination a::before, .posttext .pagination a::after {
        content: " "; }
      .posttext .pagination a:hover {
        background: transparent;
        color: #e6e04d; }
    .posttext .pagination .current {
      font-size: 16px;
      display: inline-block !important;
      background: transparent;
      color: #e6e04d; }
      @media print, screen and (max-width: 74.99875em) {
        .posttext .pagination .current {
          font-size: 14px; } }
    .posttext .pagination .portfolio img {
      width: 25px; }
    @media print, screen and (max-width: 39.99875em) {
      .posttext .pagination .portfolio {
        display: inline-block !important; } }
    .posttext .pagination .pagination li {
      display: inline-block !important; }
    .posttext .pagination .pagination-previous {
      position: absolute;
      top: 50%;
      left: 5%;
      margin-right: -5%;
      -webkit-transform: translate(-5%, -50%);
      -khtml-transform: translate(-5%, -50%);
      -moz-transform: translate(-5%, -50%);
      -ms-transform: translate(-5%, -50%);
      transform: translate(-5%, -50%); }
    .posttext .pagination .pagination-next {
      position: absolute;
      top: 50%;
      left: 95%;
      margin-right: -95%;
      -webkit-transform: translate(-95%, -50%);
      -khtml-transform: translate(-95%, -50%);
      -moz-transform: translate(-95%, -50%);
      -ms-transform: translate(-95%, -50%);
      transform: translate(-95%, -50%); }
    .posttext .pagination #BlogImgPrev, .posttext .pagination #BlogImgNext {
      width: 15px;
      margin-right: 10px;
      margin-left: 10px; }
  .posttext .Author {
    margin-bottom: 30px; }
    .posttext .Author span {
      text-transform: uppercase; }
  .posttext .MobileShare {
    margin-top: 30px; }
  .posttext .mobileArrow {
    width: 35px; }
  .posttext .Anotherlink {
    font-weight: bold;
    color: #595959; }
    .posttext .Anotherlink:hover {
      color: #fa4b13; }
  .posttext .postvideo {
    margin: 30px 0; }
  .posttext .LetsMeetImg img {
    width: 100%; }
  .posttext .LetsMeetImg p {
    background-color: #f4f6f6;
    padding: 20px; }
  .posttext .mypadding {
    padding-bottom: 80px; }
  .posttext .logo-bw {
    width: 350px;
    margin-bottom: 50px; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      .posttext .logo-bw {
        width: 250px; } }
    @media print, screen and (max-width: 63.99875em) {
      .posttext .logo-bw {
        width: 150px; } }
  .posttext .mypdf img {
    max-width: 30px; }
  @media print, screen and (max-width: 39.99875em) {
    .posttext.afterhero {
      padding-top: 0; } }
  .posttext .hoverphoto {
    position: relative;
    margin-bottom: 150px; }
    @media print, screen and (max-width: 63.99875em) {
      .posttext .hoverphoto {
        margin-bottom: 50px; } }
    @media (max-width: 1190px) {
      .posttext .hoverphoto .person {
        display: none; }
      .posttext .hoverphoto #key {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 5;
        width: 28%; }
      .posttext .hoverphoto .instructions {
        display: none; } }
    @media (min-width: 1191px) {
      .posttext .hoverphoto .person:hover, .posttext .hoverphoto .person:focus {
        opacity: .75; }
      .posttext .hoverphoto #key {
        display: none; } }
    .posttext .hoverphoto #chantelle {
      position: absolute;
      top: 565px;
      left: 768px;
      z-index: 3;
      cursor: pointer; }
    .posttext .hoverphoto #dasha {
      position: absolute;
      top: 578px;
      left: 548px;
      z-index: 3;
      cursor: pointer; }
    .posttext .hoverphoto #ruthie {
      position: absolute;
      top: 850px;
      left: 600px;
      z-index: 3;
      cursor: pointer; }
    .posttext .hoverphoto #rebecca {
      position: absolute;
      top: 900px;
      left: 397px;
      z-index: 4;
      cursor: pointer; }
    .posttext .hoverphoto #steve {
      position: absolute;
      top: 883px;
      left: 722px;
      z-index: 4;
      cursor: pointer; }
    .posttext .hoverphoto #beth {
      position: absolute;
      top: 798px;
      left: 195px;
      z-index: 4;
      cursor: pointer; }
    .posttext .hoverphoto #kyle {
      position: absolute;
      top: 809px;
      left: 725px;
      z-index: 3;
      cursor: pointer; }
    .posttext .hoverphoto #dave {
      position: absolute;
      top: 530px;
      left: 462px;
      z-index: 2;
      cursor: pointer; }
    .posttext .hoverphoto #mike {
      position: absolute;
      top: 516px;
      left: 330px;
      z-index: 2;
      cursor: pointer; }
    .posttext .hoverphoto #michael {
      position: absolute;
      top: 547px;
      left: 668px;
      z-index: 2;
      cursor: pointer; }
    .posttext .hoverphoto #malik {
      position: absolute;
      top: 822px;
      left: 389px;
      z-index: 3;
      cursor: pointer; }
    .posttext .hoverphoto #jennifer {
      position: absolute;
      top: 592px;
      left: 202px;
      z-index: 3;
      cursor: pointer; }

.privacy-policy {
  background-image: -ms-linear-gradient(top, bottom, #ffffff 0%, #e9f7f8 100%);
  /* Mozilla Firefox */
  background-image: -moz-linear-gradient(top bottom, #ffffff 0%, #e9f7f8 100%);
  /* Opera */
  background-image: -o-linear-gradient(top bottom, #ffffff 0%, #e9f7f8 100%);
  /* Webkit (Safari/Chrome 10) */
  background-image: -webkit-gradient(linear, top, bottom, color-stop(0, #ffffff), color-stop(100, #e9f7f8));
  /* Webkit (Chrome 11+) */
  background-image: -webkit-linear-gradient(top bottom, #ffffff 0%, #e9f7f8 100%);
  /* W3C Markup */
  background-image: linear-gradient(to bottom, #ffffff 0%, #e9f7f8 100%); }

#contact {
  background: #fa4b13;
  /*@media (max-width: 2000px) and (min-width: 1024px){
		p, a{
			font-size: 16px;
		}
	}*/
  /*  .wrapGoogle {
	  position: relative;
	  .Googleimg {
		  @include vertical-align(0%, -60%);
		  top: 10%;
	  }
  }*/ }
  @media print, screen and (max-width: 39.99875em) {
    #contact {
      padding-top: 90px; } }
  #contact h1, #contact h2, #contact h3, #contact h4, #contact h5, #contact h6 {
    font-family: "Libre Baskerville", serif; }
  #contact h1 {
    line-height: 1.3;
    padding: 30px 0;
    color: #fefefe;
    text-transform: capitalize; }
    @media print, screen and (max-width: 63.99875em) {
      #contact h1 {
        font-size: 2.4rem; } }
  @media (max-width: 2000px) and (min-width: 1250px) {
    #contact h1 {
      font-size: 2rem; } }
  @media (max-width: 1249px) and (min-width: 1024px) {
    #contact h1 {
      font-size: 1.8rem; } }
  #contact p {
    line-height: 1.3;
    font-size: 20px;
    font-family: "Open Sans", sans-serif, Arial;
    color: #fefefe; }
    @media print, screen and (max-width: 63.99875em) {
      #contact p {
        font-size: 18px; } }
  #contact a {
    color: #fefefe; }
    #contact a:hover {
      color: #fa4b13; }
  #contact .topRow {
    font-weight: 700;
    padding-bottom: 0; }
  #contact .ContacttextAlign {
    padding: 50px 0 0 0; }
    @media print, screen and (max-width: 63.99875em) {
      #contact .ContacttextAlign {
        padding: 50px 20px 30px 20px !important; } }
  #contact .row {
    position: relative; }
  #contact .Iconsocial {
    position: absolute;
    top: 95%;
    left: 2%;
    margin-right: -2%;
    -webkit-transform: translate(-2%, -95%);
    -khtml-transform: translate(-2%, -95%);
    -moz-transform: translate(-2%, -95%);
    -ms-transform: translate(-2%, -95%);
    transform: translate(-2%, -95%);
    z-index: 1;
    width: 65px; }
    #contact .Iconsocial img {
      transition: all 1s ease; }
      #contact .Iconsocial img:hover {
        transform: scale(1.1); }
  @media (max-width: 1920px) {
    #contact .Iconsocial li > a {
      padding: 0.4rem 1rem !important; } }
  #contact .contactcareers {
    margin: 30px 5% 30px 0;
    padding: 1rem;
    font-family: "Open Sans", sans-serif, Arial;
    color: #fefefe;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    border: 1.5px solid #fefefe;
    transition: all 0.5s ease; }
    @media print, screen and (max-width: 63.99875em) {
      #contact .contactcareers {
        margin: 10px 5% 10px 0; } }
    @media print, screen and (max-width: 89.99875em) {
      #contact .contactcareers {
        font-size: 14px; } }
    #contact .contactcareers:hover {
      background: #fefefe;
      color: #fa4b13; }
    @media (max-width: 1440px) {
      #contact .contactcareers .xlarge-title {
        display: none; } }
    @media (min-width: 1441px) {
      #contact .contactcareers .normal-title {
        display: none; } }
  @media (max-width: 2000px) and (min-width: 1441px) {
    #contact .contactcareers {
      font-size: 16px; } }
  #contact .Mypad {
    margin-top: 20px; }
  #contact #profitImg {
    padding-right: 10px; }
  @media print, screen and (max-width: 63.99875em) {
    #contact .ProfitRow {
      padding: 30px 20px 0 20px !important; } }
  @media print, screen and (max-width: 63.99875em) {
    #contact .GoogleRow {
      padding: 10px 20px 30px 20px !important;
      text-align: center; } }
  #contact #undermap {
    background: #595959;
    margin-top: -7px;
    padding: 3rem 3rem 2rem 3rem !important; }
    @media print, screen and (max-width: 63.99875em) {
      #contact #undermap {
        text-align: center;
        padding: 3rem 0 !important; } }
    @media print, screen and (max-width: 63.99875em) {
      #contact #undermap #CSaddress {
        line-height: 2.3; } }
  #contact #map {
    height: 600px; }
    @media print, screen and (max-width: 63.99875em) {
      #contact #map {
        height: 350px; } }

#mission {
  position: relative; }
  @media screen and (min-width: 75em) {
    #mission {
      background-image: url("../img/missionbkg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-attachment: fixed;
      background-position: bottom; } }
  @media print, screen and (max-width: 74.99875em) {
    #mission {
      background-image: url("../img/mission-mob.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center; } }
  @media screen and (min-width: 75em) {
    #mission .parallax {
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; } }
  #mission .fondo1 {
    background-image: url("../img/missionbkg1.png"); }
  #mission .fondo2 {
    background-image: url("../img/missionbkg2.png"); }
  #mission .fondo3 {
    background-image: url("../img/missionbkg3.png"); }
  #mission .fondo4 {
    background-image: url("../img/missionbkg4.png");
    padding-bottom: 150px; }
  #mission h1, #mission h2, #mission h3, #mission h4, #mission h5, #mission h6 {
    font-family: "Libre Baskerville", serif; }
  #mission h1 {
    line-height: 1.3;
    padding: 0;
    text-transform: none;
    color: #595959; }
    @media print, screen and (max-width: 63.99875em) {
      #mission h1 {
        font-size: 1.7rem; } }
  @media (max-width: 2000px) and (min-width: 1024px) {
    #mission h1 {
      font-size: 2rem !important; }
    #mission p {
      font-size: 16px !important; } }
  #mission p {
    line-height: 1.3;
    font-size: 18px;
    font-family: "Open Sans", sans-serif, Arial;
    color: #0a0a0a; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      #mission p {
        font-size: 18px; } }
    @media print, screen and (max-width: 63.99875em) {
      #mission p {
        font-size: 14px; } }
  #mission .MissiontextAlign {
    padding: 0 100px; }
    @media print, screen and (max-width: 63.99875em) {
      #mission .MissiontextAlign {
        padding: 0; } }
  #mission .MissionHeader {
    padding: 200px 30px 50px; }
    #mission .MissionHeader #OurMission {
      max-width: 450px; }
    @media print, screen and (max-width: 63.99875em) {
      #mission .MissionHeader {
        padding: 50px 0; } }

#OurOffice {
  background-image: url("../img/OurOffice.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position: left; }

.OurOffice {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url("../img/OurOffice.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position: left;
  z-index: 1;
  /*@media (max-width: 2000px) and (min-width: 1024px){
		h1{
			font-size: 2rem!important;
		}
		p{
			font-size: 16px!important;
		}
	}*/ }
  .OurOffice h1, .OurOffice h2, .OurOffice h3, .OurOffice h4, .OurOffice h5, .OurOffice h6 {
    font-family: "Libre Baskerville", serif; }
  .OurOffice h1 {
    line-height: 1.3;
    padding: 30px 0;
    color: #e6e04d;
    text-transform: capitalize; }
    @media print, screen and (max-width: 63.99875em) {
      .OurOffice h1 {
        font-size: 1.7rem; } }
  .OurOffice p {
    color: #fefefe;
    line-height: 1.3;
    font-size: 20px;
    font-family: "Open Sans", sans-serif, Arial; }
    @media print, screen and (max-width: 63.99875em) {
      .OurOffice p {
        font-size: 18px; } }
  .OurOffice .topRow {
    font-weight: 700;
    margin-bottom: 0; }
  .OurOffice .HerotextAlign {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
    -khtml-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .OurOffice .OurOfficescrollicon {
    position: absolute;
    top: 95%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -95%);
    -khtml-transform: translate(-50%, -95%);
    -moz-transform: translate(-50%, -95%);
    -ms-transform: translate(-50%, -95%);
    transform: translate(-50%, -95%);
    z-index: 5;
    position: absolute;
    width: 20px;
    -webkit-animation: fadeInOut 2s infinite; }

@-webkit-keyframes fadeInOut {
  from {
    opacity: 0.2; }
  50% {
    opacity: 1.0; }
  to {
    opacity: 0.2; } }
  .OurOffice .Iconsocial {
    position: absolute;
    top: 95%;
    left: 2%;
    margin-right: -2%;
    -webkit-transform: translate(-2%, -95%);
    -khtml-transform: translate(-2%, -95%);
    -moz-transform: translate(-2%, -95%);
    -ms-transform: translate(-2%, -95%);
    transform: translate(-2%, -95%);
    z-index: 1;
    width: 65px; }
    .OurOffice .Iconsocial img {
      transition: all 1s ease; }
      .OurOffice .Iconsocial img:hover {
        transform: scale(1.1); }
  @media (max-width: 1920px) {
    .OurOffice .Iconsocial li > a {
      padding: 0.4rem 1rem !important; } }

@media print, screen and (min-width: 40em) {
  #OfficeImages {
    padding-top: 80px;
    padding-bottom: 80px; }
    #OfficeImages .columns {
      padding: 20px 10px 0 10px !important; } }

@media print, screen and (max-width: 39.99875em) {
  #OfficeImages .columns {
    padding: 0 !important; } }

#CareersHero {
  background-image: url("../img/career_hero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position: bottom; }
  @media print, screen and (max-width: 63.99875em) {
    #CareersHero {
      background: #29acc0; } }

/*
.CareersHero {
	position: relative;
	height: 100%;
	width: 100%;
	@include cover-background('../img/career_hero.jpg');
	background-position: bottom;
	z-index:1;
	@include breakpoint(medium down) {
	  @include cover-background('../img/career_hero_m.jpg');
	}

	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.3;
		padding: 30px 0;
		color: #e6e04d;
		text-transform: capitalize;
		@include breakpoint(medium down) {
  		  font-size: 2rem;
	    }
	}
	p {
		color: $white;
		line-height: 1.3;
		font-size: 20px;
		font-family: $body-font-family;
		@include breakpoint(medium down) {
  		  font-size: 18px;
	    }
	}

	.HerotextAlign {
		@include vertical-align(50%, 40%);
	}
	//scrolling to the next section

    .CareersListicon {
 	   @include vertical-align(50%, 95%);
 	   z-index:5;
 	   position: absolute;
 	    width: 20px;
 	    -webkit-animation: fadeInOut 2s infinite;
    }
    @-webkit-keyframes fadeInOut {
 	 from { opacity: 0.2; }
 	 50% { opacity: 1.0; }
 	 to { opacity: 0.2; }
  }

	  //social icons
	  .Iconsocial {
		  @include vertical-align(2%, 95%);
		  z-index: 1;
		  width: 65px;
		  img {
			 transition: all 1s ease;
			  &:hover {
				  transform: scale(1.1);
			  }
		  }
	  }
	  @media (max-width: 1920px) {
		  .Iconsocial {

			  li > a {
				  padding: 0.4rem 1rem!important;
			  }
		  } //Iconsocial
	}
}
*/
#CareersList {
  /*@media (max-width: 2000px) and (min-width: 1024px){
		p, li {
			font-size: 16px!important;
		}
	}*/ }
  #CareersList h1, #CareersList h2, #CareersList h3, #CareersList h4, #CareersList h5, #CareersList h6 {
    font-family: "Libre Baskerville", serif;
    color: #595959; }
  #CareersList h1 {
    line-height: 1.3;
    padding: 25px 0;
    color: #fa4b13;
    text-transform: capitalize; }
    @media print, screen and (max-width: 74.99875em) {
      #CareersList h1 {
        font-size: 1.7rem;
        padding: 15px 0;
        margin-bottom: 0; } }
  @media (max-width: 2000px) and (min-width: 1199px) {
    #CareersList h1 {
      font-size: 2rem !important; } }
  #CareersList p, #CareersList li {
    color: #595959;
    line-height: 1.3;
    font-size: 20px;
    font-family: "Open Sans", sans-serif, Arial; }
    @media print, screen and (max-width: 63.99875em) {
      #CareersList p, #CareersList li {
        font-size: 18px; } }
  #CareersList ul.checklist {
    position: relative;
    list-style: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0; }
    #CareersList ul.checklist li {
      margin-left: 35px !important; }
      #CareersList ul.checklist li:before {
        content: '✓';
        position: absolute;
        left: 10px; }
  #CareersList li {
    margin: 0 0 10px 10px; }
    @media print, screen and (max-width: 63.99875em) {
      #CareersList li {
        margin: 0 0 10px 0; } }
  #CareersList .applylink {
    margin: 30px 0 0 0;
    padding: 1em 4em;
    font-family: "Open Sans", sans-serif, Arial;
    color: #fa4b13;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    border: 1.5px solid #fa4b13;
    transition: all 0.5s ease; }
    #CareersList .applylink:hover {
      background: #fa4b13;
      color: #fefefe; }
  #CareersList .emailus {
    color: #fa4b13;
    display: inline-block;
    transition: transform 0.3s ease; }
    #CareersList .emailus:hover {
      transform: scale(1.02); }
  #CareersList .ShareIcons {
    width: 50px;
    padding-left: 25px;
    transition: all 1s ease; }
    @media print, screen and (max-width: 63.99875em) {
      #CareersList .ShareIcons {
        margin-left: -25px; } }
    #CareersList .ShareIcons:hover {
      transform: scale(1.1); }
  #CareersList .careerblock {
    padding: 150px 0; }
    @media print, screen and (max-width: 63.99875em) {
      #CareersList .careerblock {
        padding: 50px 0; } }
  #CareersList .topgap {
    margin-top: 40px; }
  #CareersList .white {
    background-color: #fefefe; }
  #CareersList .gray {
    background-color: #f2f2f2; }
  #CareersList .mobileArrow {
    width: 35px; }

#OurNeighbourhood {
  background-image: url("../img/Neighbourhood.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  #OurNeighbourhood h1 span {
    white-space: nowrap; }

/*
#OurNeighbourhood {
	position: relative;
	height: 100%;
	width: 100%;
	//background-position: left;
	z-index:1;

	h1,h2,h3,h4,h5,h6 {
		font-family: $header-font-family;
	}

	h1 {
		line-height: 1.3;
		padding: 0px 0 30px;
		color: #e6e04d;
		text-transform: capitalize;
		@include breakpoint(medium down) {
  		  font-size: 1.7rem;
	    }
			span {
				white-space: nowrap;
			}
	}
	p {
		color: $white;
		line-height: 1.3;
		font-size: 20px;
		font-family: $body-font-family;

		@include breakpoint(medium down) {
  		  font-size:18px;
	    }

	}
	@media (max-width: 2000px) and (min-width: 1024px){
		h1{
			font-size: 2rem!important;
		}
		p{
			font-size: 16px!important;
		}
	}
	.topRow {
		font-weight: 700;
		margin-bottom: 0;
		@include breakpoint(large down) {
			font-size: 14px;
		}
		// padding-top: 80px;
	}

	.HerotextAlign {
		@include vertical-align(50%, 50%);
	}
	//scrolling to the next section

    .OurNbhscrollicon {
 	   @include vertical-align(50%, 95%);
 	   z-index:5;
 	   position: absolute;
 	    width: 20px;
 	    -webkit-animation: fadeInOut 2s infinite;
    }
    @-webkit-keyframes fadeInOut {
 	 from { opacity: 0.2; }
 	 50% { opacity: 1.0; }
 	 to { opacity: 0.2; }
  }

	  //social icons
	  .Iconsocial {
		  @include vertical-align(2%, 95%);
		  z-index: 1;
		  width: 65px;
		  img {
			 transition: all 1s ease;
			  &:hover {
				  transform: scale(1.1);
			  }
		  }
	  }
	  @media (max-width: 1920px) {
		  .Iconsocial {
			  // width: 60px;
			  li > a {
				  padding: 0.4rem 1rem!important;
			  }
		  } //Iconsocial
	}
}
*/
#NeighbourhoodImages {
  /*@media (max-width: 2000px) and (min-width: 1024px){
		p{
			font-size: 16px!important;
		}
	}*/ }
  #NeighbourhoodImages p {
    margin-bottom: 0;
    color: #595959;
    line-height: 1.3;
    font-size: 20px;
    font-family: "Open Sans", sans-serif, Arial; }
    @media print, screen and (max-width: 63.99875em) {
      #NeighbourhoodImages p {
        font-size: 18px; } }
  @media print, screen and (min-width: 40em) {
    #NeighbourhoodImages {
      margin-top: 80px; }
      #NeighbourhoodImages .columns {
        padding: 20px 10px 0 10px !important; } }
  @media print, screen and (max-width: 39.99875em) {
    #NeighbourhoodImages .columns {
      padding: 0 !important; }
      #NeighbourhoodImages .columns .MyDescription {
        margin: 0 auto;
        padding: 25px 0; } }
  #NeighbourhoodImages .MyDescription {
    margin: 50px auto; }

#workhero {
  background-image: url("../img/work.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  #workhero h1, #workhero p {
    color: #595959; }

.workhero {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  z-index: 1; }
  .workhero h1, .workhero h2, .workhero h3, .workhero h4, .workhero h5, .workhero h6 {
    font-family: "Libre Baskerville", serif; }
  .workhero h1 {
    line-height: 1.3;
    padding: 30px 0;
    color: #595959;
    text-transform: capitalize; }
    @media print, screen and (max-width: 63.99875em) {
      .workhero h1 {
        font-size: 1.7rem; } }
  .workhero p {
    color: #595959;
    line-height: 1.3;
    font-size: 20px;
    font-family: "Open Sans", sans-serif, Arial; }
    @media print, screen and (max-width: 63.99875em) {
      .workhero p {
        font-size: 18px; } }
  @media (max-width: 2000px) and (min-width: 1024px) {
    .workhero h1 {
      font-size: 2rem !important; }
    .workhero p {
      font-size: 16px !important; } }
  .workhero .HerotextAlign {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
    -khtml-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .workhero .scrollDown {
    position: absolute;
    top: 95%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -95%);
    -khtml-transform: translate(-50%, -95%);
    -moz-transform: translate(-50%, -95%);
    -ms-transform: translate(-50%, -95%);
    transform: translate(-50%, -95%);
    z-index: 5;
    position: absolute;
    width: 20px;
    -webkit-animation: fadeInOut 2s infinite; }

@-webkit-keyframes fadeInOut {
  from {
    opacity: 0.2; }
  50% {
    opacity: 1.0; }
  to {
    opacity: 0.2; } }
  .workhero .Iconsocial {
    position: absolute;
    top: 95%;
    left: 2%;
    margin-right: -2%;
    -webkit-transform: translate(-2%, -95%);
    -khtml-transform: translate(-2%, -95%);
    -moz-transform: translate(-2%, -95%);
    -ms-transform: translate(-2%, -95%);
    transform: translate(-2%, -95%);
    z-index: 1;
    width: 65px; }
    .workhero .Iconsocial img {
      transition: all 1s ease; }
      .workhero .Iconsocial img:hover {
        transform: scale(1.1); }
  @media (max-width: 1920px) {
    .workhero .Iconsocial li > a {
      padding: 0.4rem 1rem !important; } }

#works {
  overflow-x: hidden;
  /* Anchor will cover the whole item by default */
  /* For some effects it will show as a button */
  /*---------------*/
  /***** Bubba *****/
  /*---------------*/ }
  #works .figure {
    position: relative;
    text-align: center;
    overflow: hidden;
    cursor: pointer; }
  #works .figure img {
    position: relative;
    display: block; }
  #works .figure .figcaption {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }
    #works .figure .figcaption img {
      min-height: 100%;
      /* Safari only override */ }
      #works .figure .figcaption img _::-webkit-full-page-media, #works .figure .figcaption img _:future, #works .figure .figcaption img :root .safari_only {
        min-height: 50%; }
  #works .figure .figcaption::before,
  #works .figure .figcaption::after {
    pointer-events: none; }
  #works .figure .figcaption,
  #works .figure .figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  #works .figure .figcaption > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0; }
  #works .figure.effect-bubba {
    background: #000000; }
  #works .figure.effect-bubba img {
    opacity: 1;
    -webkit-transition: all 0.85s;
    transition: all 0.85s;
    transform: scale(1.02); }
  #works .figure.effect-bubba:hover img {
    opacity: 0.7;
    transform: scale(1); }
  #works .figure.effect-bubba .figcaption::before,
  #works .figure.effect-bubba .figcaption::after {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.85s, -webkit-transform 0.85s;
    transition: opacity 0.85s, transform 0.85s; }
  #works .figure.effect-bubba .figcaption::before {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1); }
  #works .figure.effect-bubba .figcaption::after {
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0); }
  #works .figure.effect-bubba:hover .figcaption::before,
  #works .figure.effect-bubba:hover .figcaption::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); }
  #works .notbuilt {
    cursor: default;
    background: #000000; }

.awards {
  position: absolute;
  top: 0;
  right: 60px;
  width: 150px;
  text-align: right; }
  .awards img {
    width: 50px;
    margin-left: 15px;
    display: inline-block !important;
    opacity: 1 !important; }

#airmaxhero {
  background-image: url("../img/work/individual/nike/AirMaxHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (min-width: 64em) {
    #airmaxhero {
      margin-bottom: 27px; } }

#hockeyhero {
  background-image: url("../img/work/individual/nike/HockeyHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (min-width: 64em) {
    #hockeyhero {
      margin-bottom: 27px; } }

#gastownhero {
  background-image: url("../img/work/individual/nike/GastownHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (min-width: 64em) {
    #gastownhero {
      margin-bottom: 27px; } }

#af1hero {
  background-image: url("../img/work/individual/nike/AF1Hero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (min-width: 64em) {
    #af1hero {
      margin-bottom: 27px; } }

#w15khero {
  background-image: url("../img/work/individual/nike/15KHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (min-width: 64em) {
    #w15khero {
      margin-bottom: 27px; } }

#brahaushero {
  background-image: url("../img/work/individual/nike/BraHausHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (min-width: 64em) {
    #brahaushero {
      margin-bottom: 27px; } }

#nbahero {
  background-image: url("../img/work/individual/nike/NBAHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (min-width: 64em) {
    #nbahero {
      margin-bottom: 27px; } }

#uncledrewhero {
  background-image: url("../img/work/individual/nike/UncleDrewHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (min-width: 64em) {
    #uncledrewhero {
      margin-bottom: 27px; } }

#carlinghero {
  background-image: url("../img/work/individual/molson/CarlingHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#madjackhero {
  background-image: url("../img/work/individual/molson/MadJackHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#madjackhero-new {
  background-image: url("../img/work/individual/molson/MadJackHero-new.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#belgianhero {
  background-image: url("../img/work/individual/molson/BelgianHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#bayhero {
  background-image: url("../img/work/individual/bay/BayHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#hudsonsbayhero {
  background-image: url("../img/work/individual/hudsonsbay/hudsonsbayHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#ikohero {
  background-image: url("../img/work/individual/iko/ikoHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#italpastahero {
  background-image: url("../img/work/individual/italpasta/italpastaHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#bluejayshero {
  background-image: url("../img/work/individual/bluejays/bluejaysHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#canadagoosehero {
  background-image: url("../img/work/individual/canadagoose/canadagooseHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#echelonhero {
  background-image: url("../img/work/individual/echelon/echelonHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position: left center; }

#reinharthero {
  background-image: url("../img/work/individual/reinhart/reinhartHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#saintelizabethhero {
  background-image: url("../img/work/individual/saintelizabeth/saintelizabethHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#lindthero {
  background-image: url("../img/work/individual/lindt/LindtHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#humberhero {
  background-image: url("../img/work/individual/humber/HumberHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#acanachero {
  background-image: url("../img/work/individual/acanac/AcanacHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  #acanachero #acanac-hero-logo {
    max-width: 70% !important; }

#distributelhero {
  background-image: url("../img/work/individual/distributel/DistributelHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  #distributelhero #distr-hero-logo {
    max-width: 75% !important; }

#arterrapridehero {
  background-image: url("../img/work/individual/arterra/ArterraPrideHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#arterrabaskhero {
  background-image: url("../img/work/individual/arterra/ArterraBaskHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#baruvidahero {
  background-image: url("../img/work/individual/baruvida/BaruvidaHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#diorhero {
  background-image: url("../img/work/individual/dior/DiorHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#hrhhero {
  background-image: url("../img/work/individual/humber/HRHHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#hrhfhero {
  background-image: url("../img/work/individual/humber/hrhfHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#kpmghero {
  background-image: url("../img/work/individual/kpmg/kpmgHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position: right center; }

#humbermeadowhero {
  background-image: url("../img/work/individual/humbermeadow/humbermeadowhero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#appficiencyhero {
  background-image: url("../img/work/individual/appficiency/appficiencyhero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

#buddhahero {
  background-image: url("../img/work/individual/buddha/buddhahero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  #buddhahero .client-logo {
    max-width: 395px !important; }

#deciemhero {
  background-image: url("../img/work/individual/deciem/deciemhero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  #deciemhero .client-logo {
    max-width: 460px !important; }

#twobearshero {
  background-image: url("../img/work/individual/twobears/twobearshero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  #twobearshero .client-logo {
    max-width: 340px !important; }

#millerhero {
  background-image: url("../img/work/individual/miller/millerhero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  #millerhero .client-logo {
    max-width: 390px !important; }

.case-study-hero {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1; }
  .case-study-hero h1, .case-study-hero h2, .case-study-hero h3, .case-study-hero h4, .case-study-hero h5, .case-study-hero h6 {
    font-family: "Libre Baskerville", serif; }
  .case-study-hero h1 {
    line-height: 1.3;
    padding: 30px 0;
    color: #595959;
    text-transform: capitalize; }
    @media print, screen and (max-width: 63.99875em) {
      .case-study-hero h1 {
        font-size: 1.7rem; } }
    @media screen and (min-width: 75em) and (max-width: 89.99875em) {
      .case-study-hero h1 {
        font-size: 2.9rem; } }
  .case-study-hero p {
    color: #595959;
    line-height: 1.3;
    font-size: 20px;
    font-family: "Open Sans", sans-serif, Arial; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      .case-study-hero p {
        font-size: 18px; } }
    @media print, screen and (max-width: 63.99875em) {
      .case-study-hero p {
        font-size: 14px; } }
  .case-study-hero .HerotextAlign {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
    -khtml-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    .case-study-hero .HerotextAlign .client-logo {
      width: 60vw;
      max-width: 570px; }
  .case-study-hero .scrollDown {
    position: absolute;
    top: 95%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -95%);
    -khtml-transform: translate(-50%, -95%);
    -moz-transform: translate(-50%, -95%);
    -ms-transform: translate(-50%, -95%);
    transform: translate(-50%, -95%);
    z-index: 5;
    position: absolute;
    width: 20px;
    -webkit-animation: fadeInOut 2s infinite; }

@-webkit-keyframes fadeInOut {
  from {
    opacity: 0.2; }
  50% {
    opacity: 1.0; }
  to {
    opacity: 0.2; } }
  .case-study-hero .Iconsocial {
    position: absolute;
    top: 95%;
    left: 2%;
    margin-right: -2%;
    -webkit-transform: translate(-2%, -95%);
    -khtml-transform: translate(-2%, -95%);
    -moz-transform: translate(-2%, -95%);
    -ms-transform: translate(-2%, -95%);
    transform: translate(-2%, -95%);
    z-index: 1;
    width: 65px; }
    .case-study-hero .Iconsocial img {
      transition: all 1s ease; }
      .case-study-hero .Iconsocial img:hover {
        transform: scale(1.1); }
  @media (max-width: 1920px) {
    .case-study-hero .Iconsocial li > a {
      padding: 0.4rem 1rem !important; } }

.case-study-list {
  /*---------------*/
  /***** Bubba *****/
  /*---------------*/ }
  .case-study-list h1, .case-study-list h2, .case-study-list h3, .case-study-list h4, .case-study-list h5, .case-study-list h6 {
    font-family: "Libre Baskerville", serif; }
  .case-study-list h1 {
    line-height: 1.3;
    padding: 30px 0;
    color: #fefefe;
    text-transform: capitalize; }
    @media print, screen and (max-width: 63.99875em) {
      .case-study-list h1 {
        font-size: 1.7rem; } }
    @media screen and (min-width: 75em) and (max-width: 89.99875em) {
      .case-study-list h1 {
        font-size: 2.9rem; } }
  .case-study-list p {
    color: #fefefe;
    line-height: 1.3;
    font-size: 20px;
    font-family: "Open Sans", sans-serif, Arial; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      .case-study-list p {
        font-size: 18px; } }
    @media print, screen and (max-width: 63.99875em) {
      .case-study-list p {
        font-size: 14px; } }
  .case-study-list .figure {
    position: relative;
    text-align: center;
    overflow: hidden;
    cursor: pointer; }
    @media print, screen and (max-width: 63.99875em) {
      .case-study-list .figure {
        max-height: 150px;
        width: 100%; } }
  .case-study-list .figure img {
    position: relative;
    display: block; }
    @media print, screen and (max-width: 63.99875em) {
      .case-study-list .figure img {
        margin-top: -15%; } }
  .case-study-list .figure .figcaption {
    padding: 2em;
    color: #fff;
    text-transform: capitalize;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }
    .case-study-list .figure .figcaption img {
      min-height: 100%;
      /* Safari only override */ }
      .case-study-list .figure .figcaption img _::-webkit-full-page-media, .case-study-list .figure .figcaption img _:future, .case-study-list .figure .figcaption img :root .safari_only {
        min-height: 50%; }
  .case-study-list .figure .figcaption::before,
  .case-study-list .figure .figcaption::after {
    pointer-events: none; }
  .case-study-list .figure .figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .case-study-list .figure.effect-bubba {
    background: #000000; }
    .case-study-list .figure.effect-bubba h1 {
      padding: 0 20px !important;
      line-height: 1.3;
      position: absolute;
      top: 35%;
      left: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -35%);
      -khtml-transform: translate(-50%, -35%);
      -moz-transform: translate(-50%, -35%);
      -ms-transform: translate(-50%, -35%);
      transform: translate(-50%, -35%); }
    @media (min-width: 1500px) and (max-width: 1920px) {
      .case-study-list .figure.effect-bubba h1 {
        font-size: 26px !important; } }
    @media (min-width: 1370px) and (max-width: 1499px) {
      .case-study-list .figure.effect-bubba h1 {
        font-size: 24px !important; } }
    @media (min-width: 1024px) and (max-width: 1369px) {
      .case-study-list .figure.effect-bubba h1 {
        font-size: 16px !important; } }
    @media (max-width: 1024px) {
      .case-study-list .figure.effect-bubba h1 {
        position: absolute;
        top: 45%;
        left: 50%;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -45%);
        -khtml-transform: translate(-50%, -45%);
        -moz-transform: translate(-50%, -45%);
        -ms-transform: translate(-50%, -45%);
        transform: translate(-50%, -45%); } }
    .case-study-list .figure.effect-bubba p {
      margin-bottom: 0;
      font-family: "Open Sans", sans-serif, Arial;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600; }
    @media (max-width: 2000px) and (min-width: 1800px) {
      .case-study-list .figure.effect-bubba p {
        font-size: 12px !important; } }
    @media (max-width: 1799px) and (min-width: 1000px) {
      .case-study-list .figure.effect-bubba p {
        font-size: 10px !important; }
      .case-study-list .figure.effect-bubba .worksview {
        padding: 0.7em 2em !important; } }
    .case-study-list .figure.effect-bubba .worksview {
      position: absolute;
      top: 65%;
      left: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -65%);
      -khtml-transform: translate(-50%, -65%);
      -moz-transform: translate(-50%, -65%);
      -ms-transform: translate(-50%, -65%);
      transform: translate(-50%, -65%);
      color: #fefefe;
      padding: 1em 3em;
      border: 1.5px solid #ffffff;
      transition: all 0.5s ease; }
      .case-study-list .figure.effect-bubba .worksview:hover {
        background: #ffffff;
        border: 1.5px solid #ffffff;
        color: #595959; }
  .case-study-list .figure.effect-bubba img {
    opacity: 0.3;
    -webkit-transition: all 0.85s;
    transition: all 0.85s;
    transform: scale(1.02); }
  .case-study-list .figure.effect-bubba:hover img {
    opacity: 0.7;
    transform: scale(1); }
  .case-study-list .figure.effect-bubba .figcaption::before,
  .case-study-list .figure.effect-bubba .figcaption::after {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.85s, -webkit-transform 0.85s;
    transition: opacity 0.85s, transform 0.85s; }
  .case-study-list .figure.effect-bubba .figcaption::before {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1); }
  .case-study-list .figure.effect-bubba .figcaption::after {
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0); }
  .case-study-list .figure.effect-bubba:hover .figcaption::before,
  .case-study-list .figure.effect-bubba:hover .figcaption::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); }
  @media print, screen and (max-width: 63.99875em) {
    .case-study-list .figure.effect-bubba:hover .figcaption::before,
    .case-study-list .figure.effect-bubba:hover .figcaption::after {
      opacity: 0; } }
  .case-study-list .active {
    cursor: default;
    background: #000000; }

@media print, screen and (min-width: 64em) {
  .nike-list .case-list-slider {
    margin-left: 0; }
  .nike-list .bx-wrapper .bx-pager, .nike-list .bx-wrapper .bx-controls-auto {
    bottom: 100% !important;
    left: 0 !important;
    z-index: 90 !important;
    background: #e6e04d !important; }
  .nike-list .bx-wrapper .bx-pager {
    padding: 0 0 7px 0 !important; }
  .nike-list .bx-wrapper .bx-pager.bx-default-pager a {
    border-color: #000000 !important; }
  .nike-list .bx-wrapper .bx-pager.bx-default-pager a:hover, .nike-list .bx-wrapper .bx-pager.bx-default-pager a.active {
    background-color: #000000 !important; } }

@media print, screen and (min-width: 64em) {
  #airmax-case {
    background-image: url("../img/work/individual/nike/AirMax.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #airmax-case {
    background-image: url("../img/work/individual/nike/AirMax_m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

#airmax-case p {
  color: #595959; }

@media print, screen and (min-width: 64em) {
  #hockey-case {
    background-image: url("../img/work/individual/nike/Hockey.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #hockey-case {
    background-image: url("../img/work/individual/nike/Hockey_m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

#hockey-case h1 {
  color: #d7271a; }

@media print, screen and (min-width: 64em) {
  #gastown-case {
    background-image: url("../img/work/individual/nike/Gastown.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #gastown-case {
    background-image: url("../img/work/individual/nike/Gastown_m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

#gastown-case h1, #gastown-case .topRow {
  color: #7a4b27; }

#gastown-case p {
  color: #595959; }

@media print, screen and (min-width: 64em) {
  #af1-case {
    background-image: url("../img/work/individual/nike/AF1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #af1-case {
    background-image: url("../img/work/individual/nike/AF1_m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (min-width: 64em) {
  #w15k-case {
    background-image: url("../img/work/individual/nike/15K.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #w15k-case {
    background-image: url("../img/work/individual/nike/15K_m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

#w15k-case h1 {
  color: #f6ff00; }

@media print, screen and (min-width: 64em) {
  #brahaus-case {
    background-image: url("../img/work/individual/nike/BraHaus.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #brahaus-case {
    background: #d5d5d5; } }

#brahaus-case h1 {
  color: #000000; }

#brahaus-case p {
  color: #000000; }

@media print, screen and (min-width: 64em) {
  #nba-case {
    background-image: url("../img/work/individual/nike/NBA.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #nba-case {
    background-image: url("../img/work/individual/nike/NBA.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

#nba-case h1 {
  color: #ff0000; }

@media print, screen and (min-width: 64em) {
  #uncledrew-case {
    background-image: url("../img/work/individual/nike/uncledrew.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #uncledrew-case {
    background: #f3efe7; } }

#uncledrew-case h1 {
  color: #f5823f; }

#uncledrew-case svg {
  fill: #024682;
  width: 30%; }

#uncledrew-case p {
  color: #595959; }

@media print, screen and (min-width: 64em) {
  #carling-case {
    background-image: url("../img/work/individual/molson/Carling.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #carling-case {
    background-image: url("../img/work/individual/molson/Carling_m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

#carling-case h1 {
  color: #ff140f; }

@media print, screen and (min-width: 64em) {
  #madjack-case {
    background-image: url("../img/work/individual/molson/MadJack.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #madjack-case {
    background-image: url("../img/work/individual/molson/MadJack_m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

#madjack-case h1 {
  color: #fcb13e; }

@media print, screen and (min-width: 64em) {
  #madjack-case-new {
    background-image: url("../img/work/individual/molson/MadJack-new.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #madjack-case-new {
    background-image: url("../img/work/individual/molson/MadJack-new_m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

#madjack-case-new h1 {
  color: #0a0a0a; }

#madjack-case-new p {
  color: #0a0a0a; }

#madjack-case-new .flavour-stamp {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: -5%;
  -webkit-transform: translate(-10%, -50%);
  -khtml-transform: translate(-10%, -50%);
  -ms-transform: translate(-10%, -50%);
  transform: translate(-10%, -50%); }

@media print, screen and (min-width: 64em) {
  #belgian-case {
    background-image: url("../img/work/individual/molson/Belgian.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #belgian-case {
    background-image: url("../img/work/individual/molson/Belgian_m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

#belgian-case h1 {
  color: #e0c37f; }

@media print, screen and (min-width: 64em) {
  #bay-case {
    background-image: url("../img/work/individual/bay/Bay.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #bay-case {
    background-image: url("../img/work/individual/bay/Bay_m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

#bay-case h1 {
  color: #e0c37f; }

#hudsonsbay-case {
  background-image: url("../img/work/individual/hudsonsbay/hudsonsbay.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (max-width: 63.99875em) {
    #hudsonsbay-case {
      background: #130042; } }

@media print, screen and (min-width: 64em) {
  #lindt-case {
    background-image: url("../img/work/individual/lindt/Lindt.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #lindt-case {
    background-image: url("../img/work/individual/lindt/Lindt_m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

#lindt-case h1 {
  color: #e0c37f; }

@media print, screen and (min-width: 64em) {
  #humber-case {
    background-image: url("../img/work/individual/humber/Humber.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #humber-case {
    background-image: url("../img/work/individual/humber/Humber_m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

#humber-case h1 {
  color: #033691; }

#humber-case p {
  color: #282828; }

#iko-case {
  background-image: url("../img/work/individual/iko/iko.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (max-width: 63.99875em) {
    #iko-case {
      background: #8b8a90; } }

#italpasta-case {
  background-image: url("../img/work/individual/italpasta/italpasta.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (max-width: 63.99875em) {
    #italpasta-case {
      background: #0a0a0a; } }

#bluejays-case {
  background-image: url("../img/work/individual/bluejays/bluejays.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (max-width: 63.99875em) {
    #bluejays-case {
      background: #0a0a0a; } }

#canadagoose-case {
  background-image: url("../img/work/individual/canadagoose/canadagoose.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (max-width: 63.99875em) {
    #canadagoose-case {
      background: #0a0a0a; } }

#saintelizabeth-case {
  background-image: url("../img/work/individual/saintelizabeth/saintelizabeth.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (max-width: 63.99875em) {
    #saintelizabeth-case {
      background: #fefefe; } }
  #saintelizabeth-case h1 {
    color: #595959; }
  #saintelizabeth-case p {
    color: #595959; }

#echelon-case {
  background-image: url("../img/work/individual/echelon/echelon.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (max-width: 63.99875em) {
    #echelon-case {
      background: #0a0a0a; } }

#reinhart-case {
  background-image: url("../img/work/individual/reinhart/reinhart.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (max-width: 63.99875em) {
    #reinhart-case {
      background: #225b88; } }

@media print, screen and (min-width: 64em) {
  #acanac-case {
    background-image: url("../img/work/individual/acanac/Acanac.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #acanac-case {
    background: #090909; } }

#acanac-case h1 {
  color: #f9e600; }

@media print, screen and (min-width: 64em) {
  #distributel-case {
    background-image: url("../img/work/individual/distributel/Distributel.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #distributel-case {
    background: #004c7b; } }

#distributel-case h1 {
  color: #f7a51c; }

@media print, screen and (max-width: 63.99875em) {
  #humber-case {
    height: 100vh !important; } }

@media print, screen and (min-width: 64em) {
  #arterra-pride {
    background-image: url("../img/work/individual/arterra/arterra-pride.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #f2eff1;
    background-size: auto 50vw;
    background-position: 90% center; } }

@media print, screen and (max-width: 63.99875em) {
  #arterra-pride {
    background: #f2eff1; } }

#arterra-pride h1, #arterra-pride .topRow {
  color: #939598; }

#arterra-pride p {
  color: #595959; }

@media print, screen and (min-width: 64em) {
  #arterra-bask {
    background-image: url("../img/work/individual/arterra/arterra-bask.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #f2eff1;
    background-size: auto 50vw;
    background-position: 105% center; } }

@media print, screen and (max-width: 63.99875em) {
  #arterra-bask {
    background: #f2eff1; } }

#arterra-bask h1, #arterra-bask .topRow {
  color: #939598; }

#arterra-bask p {
  color: #595959; }

@media print, screen and (min-width: 64em) {
  #baruvida-case {
    background-image: url("../img/work/individual/baruvida/Baruvida-Tree.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-position: 90% center;
    background-color: #ffffff;
    background-size: 50vw auto; } }

@media print, screen and (max-width: 63.99875em) {
  #baruvida-case {
    background: #ffffff; } }

#baruvida-case h1, #baruvida-case .topRow {
  color: #939598; }

#baruvida-case p {
  color: #595959; }

@media print, screen and (min-width: 64em) {
  #dior-case {
    background-image: url("../img/work/individual/dior/dior-case.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #dior-case {
    background: #e6e1d8; } }

#dior-case h1, #dior-case .topRow {
  color: #000001; }

#dior-case p {
  color: #595959; }

@media print, screen and (min-width: 64em) {
  #hrh-case {
    background-image: url("../img/work/individual/hrh/hrh-case.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-position: right center;
    background-color: #ffffff;
    background-size: 50vw auto; } }

@media print, screen and (max-width: 63.99875em) {
  #hrh-case {
    background: #ffffff; } }

#hrh-case h1, #hrh-case .topRow {
  color: #939598; }

#hrh-case p {
  color: #595959; }

@media print, screen and (min-width: 64em) {
  #hrhf-case {
    background-image: url("../img/work/individual/hrhf/hrhf-case.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #hrhf-case {
    background: #ededed; } }

#hrhf-case h1, #hrhf-case .topRow {
  color: #939598; }

#hrhf-case p {
  color: #595959; }

@media print, screen and (min-width: 64em) {
  #humbermeadow-case {
    background-image: url("../img/work/individual/humbermeadow/humbermeadow-case.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #humbermeadow-case {
    background: #ffffff; } }

#humbermeadow-case h1 {
  color: #878787; }

#humbermeadow-case p {
  color: #595959; }

#humbermeadow-case .img-to-idea {
  max-width: 258px; }

@media print, screen and (min-width: 64em) {
  #humbermeadow-prll {
    background-image: url("../img/work/individual/humbermeadow/humbermeadow-10.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100vw;
    background-attachment: fixed; } }

@media print, screen and (min-width: 64em) {
  #kpmg-case {
    background-image: url("../img/work/individual/kpmg/kpmg-case.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #kpmg-case {
    background: #ffffff; } }

#kpmg-case h1, #kpmg-case .topRow {
  background: -webkit-linear-gradient(180deg, #1e49e2, #7214ea);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: break-spaces; }

#kpmg-case p {
  color: #595959; }

#kpmg-case .img-to-idea {
  max-width: 225px; }

@media print, screen and (min-width: 64em) {
  #appficiency-case {
    background-image: url("../img/work/individual/appficiency/appficiency-case.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #appficiency-case {
    background: url(../img/work/individual/appficiency/appficiency-case.jpg) center no-repeat;
    background-size: cover; } }

#appficiency-case h1 {
  color: #969696;
  text-transform: uppercase; }

#appficiency-case p {
  color: #595959; }

.appficiency-prll {
  background-image: url("../img/work/individual/appficiency/appficiency-2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

@media print, screen and (min-width: 64em) {
  #buddha-case {
    background-image: url("../img/work/individual/buddha/buddha-case.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #buddha-case {
    background: #1ba359; } }

#buddha-case h1 {
  text-transform: uppercase; }

#buddha-case h1, #buddha-case p {
  color: #fefefe; }

#buddha-case .img-to-idea {
  max-height: 205px; }

.buddha-brands {
  max-width: 600px;
  width: 70% !important;
  margin: 6% auto; }

.buddha-icons {
  background: url(../img/work/individual/buddha/buddha-green-bkg.jpg) center 0 no-repeat #60c250;
  background-size: contain;
  padding: 30px 0; }
  .buddha-icons img {
    width: 70% !important;
    max-width: 230px;
    margin: 30px; }

@media print, screen and (min-width: 64em) {
  #deciem-case {
    background-image: url("../img/work/individual/deciem/deciem-case.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #deciem-case {
    background: #f2eff1; } }

#deciem-case h1 {
  color: #969696;
  text-transform: uppercase; }

#deciem-case p {
  color: #595959; }

@media print, screen and (min-width: 64em) {
  #twobears-case {
    background-image: url("../img/work/individual/twobears/twobears-case.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

@media print, screen and (max-width: 63.99875em) {
  #twobears-case {
    background: #e3f1f2; } }

#twobears-case h1 {
  color: #0a0a0a;
  text-transform: uppercase; }

#twobears-case p {
  color: #0a0a0a; }

#twobears-case .img-to-idea {
  max-height: 210px !important; }

@media print, screen and (min-width: 64em) {
  #miller-case {
    background-image: url("../img/work/individual/miller/miller-case.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; } }

#miller-case h1 {
  color: #8a8a8a;
  text-transform: uppercase; }

#miller-case p {
  color: #0a0a0a; }

#miller-case .img-to-idea {
  max-height: 210px !important; }

.miller-prll {
  background-image: url("../img/work/individual/miller/miller-2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

@media print, screen and (min-width: 64em) {
  .parrallel-bkg {
    height: 100vh;
    width: 100vw;
    background-attachment: fixed; } }

.casestudy {
  position: relative;
  height: 100vh;
  width: 100vw;
  /*@media (max-width: 2000px) and (min-width: 1024px){
		h1{
			font-size: 2rem!important;
		}
		p{
			font-size: 16px!important;
		}
	}*/ }
  @media screen and (min-width: 75em) {
    .casestudy {
      background-attachment: fixed; } }
  .casestudy h1, .casestudy h2, .casestudy h3, .casestudy h4, .casestudy h5, .casestudy h6 {
    font-family: "Libre Baskerville", serif; }
  .casestudy h1 {
    line-height: 1.3;
    padding: 60px 0 30px;
    color: #fefefe;
    text-transform: unset;
    font-size: 2.25rem; }
    @media print, screen and (min-width: 64em) {
      .casestudy h1 {
        white-space: nowrap; } }
    @media print, screen and (max-width: 63.99875em) {
      .casestudy h1 {
        font-size: 1.7rem; } }
  .casestudy p {
    line-height: 1.3;
    font-size: 18px;
    color: #fefefe;
    font-family: "Open Sans", sans-serif, Arial; }
    @media print, screen and (max-width: 63.99875em) {
      .casestudy p {
        font-size: 16px; } }
  .casestudy .topRow {
    font-weight: 700;
    color: #fefefe; }
  .casestudy .casestudytext {
    position: absolute;
    top: 50%;
    left: 10%;
    margin-right: -10%;
    -webkit-transform: translate(-10%, -50%);
    -khtml-transform: translate(-10%, -50%);
    -moz-transform: translate(-10%, -50%);
    -ms-transform: translate(-10%, -50%);
    transform: translate(-10%, -50%); }
    .casestudy .casestudytext span {
      font-weight: 700; }
  .casestudy .case-image {
    max-height: 40vh; }
  .casestudy .img-to-idea {
    max-height: 105px;
    max-width: 300px; }
    @media print, screen and (max-width: 39.99875em) {
      .casestudy .img-to-idea {
        max-width: 150px;
        max-height: 70px; } }
  .casestudy #bay-to-idea {
    width: 60%; }
  .casestudy .steliz-to-idea {
    width: 50%; }
  .casestudy .img-to-idea-h {
    width: 200px; }
  .casestudy .img-to-idea-h2 {
    width: 250px; }
  .casestudy .img-to-idea-h3 {
    width: 300px; }

.Mymasonry {
  border-top: 0.4px solid transparent; }
  .Mymasonry img {
    width: 100%; }
  @media print, screen and (max-width: 63.99875em) {
    .Mymasonry .madjack-social {
      padding: 5px; } }
  .Mymasonry #DistrBlock1 .flex-video, .Mymasonry #AcanacBlock1 .flex-video, .Mymasonry #AcanacBlock2 .flex-video, .Mymasonry #AcanacBlock1-mobile .flex-video, .Mymasonry #AcanacBlock2-mobile .flex-video, .Mymasonry #DistrBlock1-mobile .flex-video {
    margin-bottom: 0 !important; }
  .Mymasonry #AcanacBlock2 .responsive-embed.widescreen, .Mymasonry #AcanacBlock2 .flex-video.widescreen, .Mymasonry #AcanacBlock2-mobile .responsive-embed.widescreen, .Mymasonry #AcanacBlock2-mobile .flex-video.widescreen {
    padding-bottom: 73%; }
  @media print, screen and (max-width: 63.99875em) {
    .Mymasonry #AcanacBlock1, .Mymasonry #AcanacBlock2 {
      display: none; } }
  @media print, screen and (min-width: 64em) {
    .Mymasonry #AcanacBlock1-mobile, .Mymasonry #AcanacBlock2-mobile {
      display: none; } }
  @media print, screen and (max-width: 63.99875em) {
    .Mymasonry #DistrBlock1 {
      display: none; } }
  @media print, screen and (min-width: 64em) {
    .Mymasonry #DistrBlock1-mobile {
      display: none; } }
  @media print, screen and (max-width: 63.99875em) {
    .Mymasonry #MadJackBlock {
      display: none; } }
  @media print, screen and (min-width: 64em) {
    .Mymasonry #MadJackBlock-mobile {
      display: none; } }
  .Mymasonry #MadJackBlockV0_new {
    /*@include breakpoint(medium down) {
			display:none;
		}*/ }
  @media print, screen and (min-width: 64em) {
    .Mymasonry #MadJackBlock-mobile_new {
      display: none; } }
  .Mymasonry .myplay {
    border: 1px solid #fff;
    position: relative;
    cursor: pointer; }
    .Mymasonry .myplay .flex-video {
      margin-bottom: 0; }
    .Mymasonry .myplay .playbtn {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -50%);
      -khtml-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 150px;
      z-index: 10;
      opacity: 1;
      transition: all 1s ease; }
      @media print, screen and (max-width: 63.99875em) {
        .Mymasonry .myplay .playbtn {
          width: 80px; } }
  .Mymasonry .play_active .playbtn {
    opacity: 0; }
  .Mymasonry .active .playbtn {
    opacity: 0; }
  .Mymasonry .video-wrap {
    padding-top: 120px;
    padding-bottom: 120px; }
    @media print, screen and (max-width: 39.99875em) {
      .Mymasonry .video-wrap {
        padding-top: 60px;
        padding-bottom: 60px; } }
  .Mymasonry video {
    object-fit: inherit; }
  .Mymasonry #BelgianBlock1 .responsive-embed.widescreen, .Mymasonry #BelgianBlock1 .flex-video.widescreen, .Mymasonry #BelgianBlock2 .responsive-embed.widescreen, .Mymasonry #BelgianBlock2 .flex-video.widescreen, .Mymasonry #BelgianBlock31 .responsive-embed.widescreen, .Mymasonry #BelgianBlock31 .flex-video.widescreen, .Mymasonry #BelgianBlock4 .responsive-embed.widescreen, .Mymasonry #BelgianBlock4 .flex-video.widescreen, .Mymasonry #LindtBlock1 .responsive-embed.widescreen, .Mymasonry #LindtBlock1 .flex-video.widescreen, .Mymasonry #LindtBlock2 .responsive-embed.widescreen, .Mymasonry #LindtBlock2 .flex-video.widescreen, .Mymasonry #LindtBlock3 .responsive-embed.widescreen, .Mymasonry #LindtBlock3 .flex-video.widescreen, .Mymasonry #LindtBlock5 .responsive-embed.widescreen, .Mymasonry #LindtBlock5 .flex-video.widescreen, .Mymasonry #LindtBlock4 .responsive-embed.widescreen, .Mymasonry #LindtBlock4 .flex-video.widescreen, .Mymasonry #LindtBlock6 .responsive-embed.widescreen, .Mymasonry #LindtBlock6 .flex-video.widescreen, .Mymasonry #DistrBlock1 .responsive-embed.widescreen, .Mymasonry #DistrBlock1 .flex-video.widescreen, .Mymasonry #DistrBlock1-mobile .responsive-embed.widescreen, .Mymasonry #DistrBlock1-mobile .flex-video.widescreen {
    padding-bottom: 100%; }
  .Mymasonry #MadJackBlockV0_new .responsive-embed.widescreen, .Mymasonry #MadJackBlockV0_new .flex-video.widescreen, .Mymasonry #AF1Block .responsive-embed.widescreen, .Mymasonry #AF1Block .flex-video.widescreen {
    padding-bottom: 66.6%; }
  .Mymasonry #w15KBlock2 .responsive-embed.widescreen, .Mymasonry #w15KBlock2 .flex-video.widescreen {
    padding-bottom: 50.2%; }
  .Mymasonry #w15KBlock .responsive-embed.widescreen, .Mymasonry #w15KBlock .flex-video.widescreen {
    padding-bottom: 56.9%; }
  .Mymasonry #MadJackBlock-mobile_new .flex-video.widescreen {
    padding-bottom: 66.6%; }
  .Mymasonry #MadJackBlock01_new .responsive-embed.widescreen, .Mymasonry #MadJackBlock01_new .flex-video.widescreen, .Mymasonry #MadJackBlock2_new .responsive-embed.widescreen, .Mymasonry #MadJackBlock2_new .flex-video.widescreen, .Mymasonry #MadJackBlock3_new .responsive-embed.widescreen, .Mymasonry #MadJackBlock3_new .flex-video.widescreen, .Mymasonry #MadJackBlock4_new .responsive-embed.widescreen, .Mymasonry #MadJackBlock4_new .flex-video.widescreen, .Mymasonry #MadJackBlock5_new .responsive-embed.widescreen, .Mymasonry #MadJackBlock5_new .flex-video.widescreen {
    padding-bottom: 150%; }
  .Mymasonry #BelgianBlock1, .Mymasonry #BelgianBlock2, .Mymasonry #BelgianBlock31 {
    border: 0.5px solid #fefefe; }
  .Mymasonry #MadJackBlock2, .Mymasonry #MadJackBlock3, .Mymasonry #MadJackBlock4 {
    border: 0.5px solid #fefefe; }
  .Mymasonry .sidePadding {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .Mymasonry .topPadding {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  @media print, screen and (min-width: 64em) {
    .Mymasonry .centered-row {
      display: flex;
      justify-content: center;
      text-align: center; }
    .Mymasonry .myleft {
      width: 40%; }
    .Mymasonry .myright {
      width: 60%; }
    .Mymasonry .myleft2 {
      width: 42.9%; }
    .Mymasonry .myright2 {
      width: 57.1%; }
    .Mymasonry .myleft_h {
      width: 60.2%; }
    .Mymasonry .myright_h {
      width: 39.8%; }
    .Mymasonry .myleft_h2 {
      width: 42.9%; }
    .Mymasonry .myright_h2 {
      width: 57.1%; }
    .Mymasonry .myleft_h3 {
      width: 57.12%; }
    .Mymasonry .myright_h3 {
      width: 42.88%; }
    .Mymasonry .myleft_g {
      width: 39.82%; }
    .Mymasonry .myright_g {
      width: 60.18%; }
    .Mymasonry .myleft_g2 {
      width: 40.75%; }
    .Mymasonry .myright_g2 {
      width: 59.25%; }
    .Mymasonry .myleft_af {
      width: 57.15%; }
    .Mymasonry .myright_af {
      width: 42.85%; }
    .Mymasonry .myleft_af1 {
      width: 40%; }
    .Mymasonry .myright_af1 {
      width: 60%; }
    .Mymasonry .myleft_15k {
      width: 60%; }
    .Mymasonry .myright_15k {
      width: 40%; }
    .Mymasonry .myleft_15k2 {
      width: 42.8%; }
    .Mymasonry .myright_15k2 {
      width: 57.2%; }
    .Mymasonry .myleft_bra {
      width: 60%; }
    .Mymasonry .myright_bra {
      width: 40%; }
    .Mymasonry .myleft_bra1 {
      width: 42.77%; }
    .Mymasonry .myright_bra1 {
      width: 57.23%; }
    .Mymasonry .myleft_bra2 {
      width: 57.1%; }
    .Mymasonry .myright_bra2 {
      width: 42.9%; }
    .Mymasonry .myleft_nba {
      width: 42.77%; }
    .Mymasonry .myright_nba {
      width: 57.23%; }
    .Mymasonry .myleft_nba1 {
      width: 57.1%; }
    .Mymasonry .myright_nba1 {
      width: 42.9%; }
    .Mymasonry .myleft_nba2 {
      width: 37.56%; }
    .Mymasonry .myright_nba2 {
      width: 62.44%; }
    .Mymasonry .myleft_drew1 {
      width: 46.35%; }
    .Mymasonry .myright_drew1 {
      width: 53.65%; }
    .Mymasonry .myleft_c {
      width: 60%; }
    .Mymasonry .myright_c {
      width: 40%; }
    .Mymasonry .myleft_m {
      width: 36%; }
    .Mymasonry .myright_m {
      width: 64%; }
      .Mymasonry .myright_m .flex-video {
        margin-bottom: 0; }
    .Mymasonry .myleft_m2 {
      width: 50.89%; }
    .Mymasonry .myright_m2 {
      width: 49.11%; }
    .Mymasonry .myleft_m3 {
      width: 63.92%; }
    .Mymasonry .myright_m3 {
      width: 36.08%; }
    .Mymasonry .myleft_m4 {
      width: 49.98%; }
    .Mymasonry .myright_m4 {
      width: 49.98%; }
    .Mymasonry .myleft_b2 {
      width: 46.82%; }
    .Mymasonry .myright_b2 {
      width: 53.18%; }
    .Mymasonry .myleft_b3 {
      width: 63.3%; }
    .Mymasonry .myright_b3 {
      width: 36.7%; }
    .Mymasonry .myleft_b4 {
      width: 45.8%; }
    .Mymasonry .myright_b4 {
      width: 54.2%; }
    .Mymasonry .myleft_m5 {
      width: 52.94%; }
    .Mymasonry .myright_m5 {
      width: 23.53%; }
    .Mymasonry .myleft_bay {
      width: 67.35%; }
    .Mymasonry .myright_bay {
      width: 32.65%; }
    .Mymasonry .myleft_bay1 {
      width: 42.8%; }
    .Mymasonry .myright_bay1 {
      width: 57.2%; }
    .Mymasonry .myleft_bay2 {
      width: 59.8%; }
    .Mymasonry .myright_bay2 {
      width: 40.2%; }
    .Mymasonry .myleft_bay3 {
      width: 42.85%; }
    .Mymasonry .myright_bay3 {
      width: 57.15%; }
    .Mymasonry .myleft_bay4 {
      width: 67.35%; }
    .Mymasonry .myright_bay4 {
      width: 32.65%; }
    .Mymasonry .myleft_bay5 {
      width: 67.1%; }
    .Mymasonry .myright_bay5 {
      width: 32.9%; }
    .Mymasonry .myleft_iko {
      width: 67.55%; }
    .Mymasonry .myright_iko {
      width: 32.45%; }
    .Mymasonry .myleft_iko1 {
      width: 32.4%; }
    .Mymasonry .myright_iko1 {
      width: 67.6%; }
    .Mymasonry .myleft_lindt1 {
      width: 45.07%; }
    .Mymasonry .myright_lindt1 {
      width: 54.9%; }
    .Mymasonry .myleft_lindt2 {
      width: 47.45%; }
    .Mymasonry .myright_lindt2 {
      width: 52.55%; }
    .Mymasonry .myleft_lindt3 {
      width: 33%; }
    .Mymasonry .myright_lindt3 {
      width: 67%; }
    .Mymasonry .myleft_lindt4 {
      width: 46.8%; }
    .Mymasonry .myright_lindt4 {
      width: 53.2%; }
    .Mymasonry .myleft_humber1 {
      width: 33.38%; }
    .Mymasonry .myright_humber1 {
      width: 66.62%; }
    .Mymasonry .myleft_humber1-1, .Mymasonry .myright_humber1-1 {
      width: 33.35%; }
    .Mymasonry .mycenter_humber1-1 {
      width: 33.30%; }
    .Mymasonry .myleft_humber2 {
      width: 48.1%; }
    .Mymasonry .myright_humber2 {
      width: 51.9%; }
    .Mymasonry .myleft_humber3 {
      width: 32%; }
    .Mymasonry .mycenter_humber3 {
      width: 16.1%; }
    .Mymasonry .myright_humber3 {
      width: 51.9%; }
    .Mymasonry .myleft_humber4 {
      width: 74.3%; }
    .Mymasonry .myright_humber4 {
      width: 25.7%; }
    .Mymasonry .myleft_humber5 {
      width: 54.9%; }
    .Mymasonry .myright_humber5 {
      width: 45.1%; }
    .Mymasonry .myleft_dis1 {
      width: 49.65%; }
    .Mymasonry .myright_dis1 {
      width: 50.35%; }
    .Mymasonry .myleft_ac {
      width: 54.7%; }
    .Mymasonry .myright_ac {
      width: 45.3%; }
    .Mymasonry .myleft_ac1 {
      width: 57.70%; }
    .Mymasonry .myright_ac1 {
      width: 42.30%; }
    .Mymasonry .myleft_art {
      width: 27.7%; }
    .Mymasonry .myright_art {
      width: 72.3%; }
    .Mymasonry .myleft_hrh1 {
      width: 40.35%; }
    .Mymasonry .myright_hrh1 {
      width: 59.65%; }
    .Mymasonry .myleft_hrh2 {
      width: 44.8%; }
    .Mymasonry .myright_hrh2 {
      width: 55.2%; }
    .Mymasonry .myleft_kpmg {
      width: 37.55%; }
    .Mymasonry .mycenter_kpmg {
      width: 29.55%; }
    .Mymasonry .myright_kpmg {
      width: 32.9%; }
    .Mymasonry .myleft_deciem {
      width: 62.5%; }
    .Mymasonry .myright_deciem {
      width: 37.5%; } }
  .Mymasonry .pagination {
    position: relative;
    margin-bottom: 0;
    margin-top: -3px;
    background: #e6e04d;
    padding: 55px 0;
    font-family: "Open Sans", sans-serif, Arial;
    text-transform: uppercase;
    font-weight: 600; }
    @media print, screen and (max-width: 39.99875em) {
      .Mymasonry .pagination {
        padding: 30px 0; } }
    .Mymasonry .pagination a {
      font-size: 16px;
      color: #595959; }
      @media print, screen and (max-width: 74.99875em) {
        .Mymasonry .pagination a {
          font-size: 14px; } }
      .Mymasonry .pagination a::before, .Mymasonry .pagination a::after {
        content: " "; }
      .Mymasonry .pagination a:hover {
        background: transparent;
        color: #fa4b13; }
    .Mymasonry .pagination .current {
      font-size: 16px;
      display: inline-block !important;
      background: transparent;
      color: #fa4b13; }
      @media print, screen and (max-width: 74.99875em) {
        .Mymasonry .pagination .current {
          font-size: 14px; } }
    .Mymasonry .pagination .portfolio img {
      width: 45px; }
    @media print, screen and (max-width: 39.99875em) {
      .Mymasonry .pagination .portfolio {
        display: inline-block !important; } }
    .Mymasonry .pagination .pagination li {
      display: inline-block !important; }
    .Mymasonry .pagination .pagination-previous {
      position: absolute;
      top: 50%;
      left: 15%;
      margin-right: -15%;
      -webkit-transform: translate(-15%, -50%);
      -khtml-transform: translate(-15%, -50%);
      -moz-transform: translate(-15%, -50%);
      -ms-transform: translate(-15%, -50%);
      transform: translate(-15%, -50%); }
    .Mymasonry .pagination .pagination-next {
      position: absolute;
      top: 50%;
      left: 85%;
      margin-right: -85%;
      -webkit-transform: translate(-85%, -50%);
      -khtml-transform: translate(-85%, -50%);
      -moz-transform: translate(-85%, -50%);
      -ms-transform: translate(-85%, -50%);
      transform: translate(-85%, -50%); }
    .Mymasonry .pagination #CaseImgPrev, .Mymasonry .pagination #CaseImgNext {
      width: 15px;
      margin-right: 10px;
      margin-left: 10px; }
  .Mymasonry .molson_titles, .Mymasonry .bay_titles, .Mymasonry .lindt_titles, .Mymasonry .humber_titles, .Mymasonry .portfolio_titles {
    background-color: #e6e04d;
    padding: 20px; }
    .Mymasonry .molson_titles h1, .Mymasonry .molson_titles h2, .Mymasonry .molson_titles h3, .Mymasonry .molson_titles h4, .Mymasonry .molson_titles h5, .Mymasonry .molson_titles h6, .Mymasonry .bay_titles h1, .Mymasonry .bay_titles h2, .Mymasonry .bay_titles h3, .Mymasonry .bay_titles h4, .Mymasonry .bay_titles h5, .Mymasonry .bay_titles h6, .Mymasonry .lindt_titles h1, .Mymasonry .lindt_titles h2, .Mymasonry .lindt_titles h3, .Mymasonry .lindt_titles h4, .Mymasonry .lindt_titles h5, .Mymasonry .lindt_titles h6, .Mymasonry .humber_titles h1, .Mymasonry .humber_titles h2, .Mymasonry .humber_titles h3, .Mymasonry .humber_titles h4, .Mymasonry .humber_titles h5, .Mymasonry .humber_titles h6, .Mymasonry .portfolio_titles h1, .Mymasonry .portfolio_titles h2, .Mymasonry .portfolio_titles h3, .Mymasonry .portfolio_titles h4, .Mymasonry .portfolio_titles h5, .Mymasonry .portfolio_titles h6 {
      font-family: "Libre Baskerville", serif; }
    .Mymasonry .molson_titles h1, .Mymasonry .bay_titles h1, .Mymasonry .lindt_titles h1, .Mymasonry .humber_titles h1, .Mymasonry .portfolio_titles h1 {
      line-height: 1.3;
      padding: 30px 0;
      color: #595959;
      text-transform: capitalize; }
      @media print, screen and (max-width: 63.99875em) {
        .Mymasonry .molson_titles h1, .Mymasonry .bay_titles h1, .Mymasonry .lindt_titles h1, .Mymasonry .humber_titles h1, .Mymasonry .portfolio_titles h1 {
          font-size: 1.7rem; } }
      .Mymasonry .molson_titles h1 span, .Mymasonry .bay_titles h1 span, .Mymasonry .lindt_titles h1 span, .Mymasonry .humber_titles h1 span, .Mymasonry .portfolio_titles h1 span {
        text-transform: lowercase; }
    @media (max-width: 2000px) and (min-width: 1024px) {
      .Mymasonry .molson_titles h1, .Mymasonry .bay_titles h1, .Mymasonry .lindt_titles h1, .Mymasonry .humber_titles h1, .Mymasonry .portfolio_titles h1 {
        font-size: 2rem !important; } }
  .Mymasonry .humber_subtitles {
    background-color: #f05022;
    padding: 10px 0;
    margin-top: 1px; }
    .Mymasonry .humber_subtitles h1, .Mymasonry .humber_subtitles h2, .Mymasonry .humber_subtitles h3, .Mymasonry .humber_subtitles h4, .Mymasonry .humber_subtitles h5, .Mymasonry .humber_subtitles h6 {
      font-family: "Libre Baskerville", serif;
      color: #fefefe;
      margin-bottom: 0; }

#shophero {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url("../img/shop_hero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position: left;
  z-index: 1; }
  #shophero h1, #shophero h2, #shophero h3, #shophero h4, #shophero h5, #shophero h6 {
    font-family: "Libre Baskerville", serif; }
  #shophero h1 {
    line-height: 1.3;
    padding: 30px 0;
    color: #e6e04d;
    text-transform: capitalize; }
    @media print, screen and (max-width: 63.99875em) {
      #shophero h1 {
        font-size: 1.7rem; } }
  #shophero p {
    color: #fefefe;
    line-height: 1.3;
    font-size: 20px;
    font-family: "Open Sans", sans-serif, Arial; }
    @media print, screen and (max-width: 63.99875em) {
      #shophero p {
        font-size: 14px; } }
  @media (max-width: 2000px) and (min-width: 1024px) {
    #shophero h1 {
      font-size: 2rem !important; }
    #shophero p {
      font-size: 16px !important; } }
  #shophero .topRow {
    font-weight: 700;
    margin-bottom: 0; }
  #shophero .HerotextAlign {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
    -khtml-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  #shophero .Shopscrollicon {
    position: absolute;
    top: 95%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -95%);
    -khtml-transform: translate(-50%, -95%);
    -moz-transform: translate(-50%, -95%);
    -ms-transform: translate(-50%, -95%);
    transform: translate(-50%, -95%);
    z-index: 5;
    position: absolute;
    width: 20px;
    -webkit-animation: fadeInOut 2s infinite; }

@-webkit-keyframes fadeInOut {
  from {
    opacity: 0.2; }
  50% {
    opacity: 1.0; }
  to {
    opacity: 0.2; } }
  #shophero .Iconsocial {
    position: absolute;
    top: 95%;
    left: 2%;
    margin-right: -2%;
    -webkit-transform: translate(-2%, -95%);
    -khtml-transform: translate(-2%, -95%);
    -moz-transform: translate(-2%, -95%);
    -ms-transform: translate(-2%, -95%);
    transform: translate(-2%, -95%);
    z-index: 1;
    width: 65px; }
    #shophero .Iconsocial img {
      transition: all 1s ease; }
      #shophero .Iconsocial img:hover {
        transform: scale(1.1); }
  @media (max-width: 1920px) {
    #shophero .Iconsocial li > a {
      padding: 0.4rem 1rem !important; } }

#shopitems h1, #shopitems h2, #shopitems h3, #shopitems h4, #shopitems h5, #shopitems h6 {
  font-family: "Open Sans", sans-serif, Arial; }

#shopitems h2, #shopitems h3, #shopitems h4 {
  color: #fefefe;
  text-transform: capitalize; }

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  #shopitems h2 {
    font-size: 42px; } }

@media screen and (min-width: 75em) {
  #shopitems h2 {
    font-size: 42px; } }

@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  #shopitems h2 {
    font-size: 28px; } }

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  #shopitems h2 {
    font-size: 24px; } }

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  #shopitems h4 {
    font-size: 26px; } }

@media screen and (min-width: 75em) {
  #shopitems h4 {
    font-size: 26px; } }

@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  #shopitems h4 {
    font-size: 18px; } }

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  #shopitems h4 {
    font-size: 16px; } }

#shopitems p {
  color: #fefefe;
  line-height: 1.3;
  font-size: 20px;
  font-family: "Open Sans", sans-serif, Arial; }
  @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
    #shopitems p {
      font-size: 18px; } }
  @media print, screen and (max-width: 63.99875em) {
    #shopitems p {
      font-size: 14px; } }

#shopitems .columns {
  padding: 30px !important; }
  @media screen and (min-width: 75em) {
    #shopitems .columns {
      padding: 40px 30px 40px 30px !important; } }
  @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
    #shopitems .columns {
      padding: 25px 2.5px 25px 2.5px !important; } }
  @media print, screen and (max-width: 39.99875em) {
    #shopitems .columns {
      padding: 50px 0 0 0 !important; } }

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  #shopitems .main {
    padding-top: 5px !important; } }

@media print, screen and (max-width: 39.99875em) {
  #shopitems .main {
    padding-top: 0 !important; } }

#shopitems .bottom_item {
  position: relative; }
  #shopitems .bottom_item .myprice {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
    -khtml-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    #shopitems .bottom_item .myprice h2, #shopitems .bottom_item .myprice h3, #shopitems .bottom_item .myprice h4, #shopitems .bottom_item .myprice p {
      text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5); }
    @media screen and (min-width: 75em) {
      #shopitems .bottom_item .myprice .rightcol {
        margin-left: 30px; } }
    #shopitems .bottom_item .myprice .numberswrap {
      position: relative;
      margin-right: 25px; }
      #shopitems .bottom_item .myprice .numberswrap .number {
        position: relative; }
        #shopitems .bottom_item .myprice .numberswrap .number .dollarmark {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 36px;
          line-height: 1;
          font-weight: 600; }
          @media print, screen and (max-width: 74.99875em) {
            #shopitems .bottom_item .myprice .numberswrap .number .dollarmark {
              font-size: 22px; } }
        #shopitems .bottom_item .myprice .numberswrap .number .dollars {
          position: absolute;
          top: 0;
          left: 25px;
          font-size: 70px;
          line-height: 1;
          font-weight: 600; }
          @media print, screen and (max-width: 74.99875em) {
            #shopitems .bottom_item .myprice .numberswrap .number .dollars {
              font-size: 50px;
              left: 10px; } }
        #shopitems .bottom_item .myprice .numberswrap .number .cent {
          position: absolute;
          top: 0;
          left: 110px;
          font-size: 36px;
          line-height: 1;
          font-weight: 600; }
          @media print, screen and (max-width: 74.99875em) {
            #shopitems .bottom_item .myprice .numberswrap .number .cent {
              font-size: 22px;
              left: 70px; } }
        #shopitems .bottom_item .myprice .numberswrap .number .plus {
          position: absolute;
          top: 38px;
          left: 110px;
          font-size: 14px;
          line-height: 1;
          text-transform: uppercase; }
          @media print, screen and (max-width: 74.99875em) {
            #shopitems .bottom_item .myprice .numberswrap .number .plus {
              font-size: 10px;
              left: 70px;
              top: 22px; } }
        #shopitems .bottom_item .myprice .numberswrap .number .myvector {
          padding: 0 !important;
          position: absolute;
          top: 10%;
          left: 10%;
          max-width: 200%;
          width: 100%;
          z-index: 1000; }
          @media print, screen and (max-width: 74.99875em) {
            #shopitems .bottom_item .myprice .numberswrap .number .myvector {
              left: 0%; } }
          @media print, screen and (min-width: 64em) {
            #shopitems .bottom_item .myprice .numberswrap .number .myvector {
              top: 15%; } }
          @media print, screen and (max-width: 63.99875em) {
            #shopitems .bottom_item .myprice .numberswrap .number .myvector {
              width: 150%; } }
          @media screen and (min-width: 75em) {
            #shopitems .bottom_item .myprice .numberswrap .number .myvector {
              width: 120%; } }
        @media (max-height: 400px) {
          #shopitems .bottom_item .myprice .numberswrap .number .myvector {
            width: 100%; } }
    #shopitems .bottom_item .myprice .myorder .columns {
      padding: 10px 10px 0 10px !important; }
    #shopitems .bottom_item .myprice .myorder p {
      color: #0a0a0a;
      background: #fefefe;
      border-radius: 20px;
      padding: 5px;
      text-transform: uppercase;
      text-shadow: 4px 4px 6px rgba(0, 0, 0, 0);
      font-weight: 600;
      box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
      transition: all 0.5s ease; }
      @media screen and (min-width: 75em) {
        #shopitems .bottom_item .myprice .myorder p {
          font-size: 26px; } }
      @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
        #shopitems .bottom_item .myprice .myorder p {
          font-size: 16px; } }
      @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
        #shopitems .bottom_item .myprice .myorder p {
          font-size: 16px; } }
      #shopitems .bottom_item .myprice .myorder p:hover {
        color: #fefefe;
        background: #e7222d; }

#shopitems .Myitems {
  transition: all 0.5s ease-in-out; }
  #shopitems .Myitems:hover {
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3); }

#shopitems .legal p {
  color: #595959;
  font-size: 14px;
  padding: 0 30px; }
  @media print, screen and (max-width: 63.99875em) {
    #shopitems .legal p {
      padding: 30px 30px 0 30px; } }

footer {
  padding-top: 40px;
  /*position: relative;
	background-color: $white;
	@include breakpoint(large up) {
		padding: 40px 0;
	}
	padding: 20px 0;
	.row {
		@include breakpoint(large up) {
			margin: 0 auto 40px auto;
		}
		margin: 0 auto 20px auto;
	}*/ }
  footer h3 {
    color: #595959; }
    @media print, screen and (max-width: 63.99875em) {
      footer h3 {
        font-size: 1.1875rem; } }
  @media (max-width: 2000px) and (min-width: 1024px) {
    footer h1 {
      font-size: 2rem !important; }
    footer p {
      font-size: 16px !important; }
    footer h3 {
      font-size: 1.6rem !important; }
    footer h4 {
      font-size: 1.4rem !important; }
    footer h5 {
      font-size: 1.1rem !important; } }
  footer h4 {
    color: #fa4b13;
    font-weight: 700; }
    @media print, screen and (max-width: 63.99875em) {
      footer h4 {
        font-size: 1rem; } }
  footer p {
    font-family: "Open Sans", sans-serif, Arial;
    color: #fa4b13;
    font-weight: 600; }
    @media print, screen and (max-width: 63.99875em) {
      footer p {
        font-size: 14px; } }
  footer .menu {
    padding-top: 50px;
    text-transform: uppercase; }
    footer .menu li {
      transition: all 1s ease; }
      @media print, screen and (max-width: 39.99875em) {
        footer .menu li {
          display: block; } }
      @media screen and (min-width: 75em) {
        footer .menu li:hover {
          transform: scale(1.1); } }
  footer .Myemail {
    text-transform: uppercase;
    transition: all 1s ease;
    display: inline-block;
    color: #fa4b13;
    font-weight: 900;
    font-family: 'Open Sans', serif; }
    @media screen and (min-width: 75em) {
      footer .Myemail:hover {
        transform: scale(1.1); } }
  footer .IconsocialFooter img {
    z-index: 100;
    width: 40px;
    padding: 7px;
    transition: all 1s ease; }
    @media screen and (min-width: 75em) {
      footer .IconsocialFooter img:hover {
        transform: scale(1.1); } }
  footer .footer-arrow {
    width: 30px; }
    @media print, screen and (max-width: 89.99875em) {
      footer .footer-arrow {
        width: 23px; } }
  footer .Mytel {
    color: #595959;
    display: inline-block;
    transition: all 1s ease; }
    footer .Mytel:hover {
      color: #fa4b13; }
      @media screen and (min-width: 75em) {
        footer .Mytel:hover {
          transform: scale(1.1); } }
  footer .submit-btn {
    background-color: #fefefe;
    border: 1px solid #fa4b13;
    color: #fa4b13;
    text-transform: uppercase;
    margin: auto;
    display: block;
    font-size: 16px;
    font-weight: 800;
    width: 100%;
    padding: 0.66rem 0;
    transition: all 0.5s ease; }
    footer .submit-btn:hover {
      background: #fa4b13;
      color: #fefefe; }
    @media print, screen and (max-width: 39.99875em) {
      footer .submit-btn {
        font-size: 12px;
        padding: 0.5rem 0; } }
  footer #keep-in-touch {
    border: 1px solid #fa4b13;
    border-right-width: 0; }
    @media print, screen and (max-width: 39.99875em) {
      footer #keep-in-touch {
        width: 98%;
        margin-left: 2%;
        height: 1.9rem; } }
  footer #keep-in-touch::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */ }
    @media print, screen and (max-width: 39.99875em) {
      footer #keep-in-touch::-webkit-input-placeholder {
        font-size: 12px !important; } }
  footer #keep-in-touch::-moz-placeholder {
    /* Firefox 19+ */ }
    @media print, screen and (max-width: 39.99875em) {
      footer #keep-in-touch::-moz-placeholder {
        font-size: 12px !important; } }
  footer #keep-in-touch:-ms-input-placeholder {
    /* IE 10+ */ }
    @media print, screen and (max-width: 39.99875em) {
      footer #keep-in-touch:-ms-input-placeholder {
        font-size: 12px !important; } }
  footer #keep-in-touch:-moz-placeholder {
    /* Firefox 18- */ }
    @media print, screen and (max-width: 39.99875em) {
      footer #keep-in-touch:-moz-placeholder {
        font-size: 12px !important; } }

/* Get in Touch Form*/
#getinTouch {
  background: #929292;
  color: white;
  padding: 110px 0;
  font-weight: 600; }
  @media print, screen and (max-width: 74.99875em) {
    #getinTouch {
      padding: 60px 15px; } }
  #getinTouch .touchTitle, #getinTouch .cs-form-result-content {
    font-size: 1.2rem; }
  #getinTouch p {
    padding-top: 1rem;
    margin-bottom: 0; }
  #getinTouch .touchBtn {
    margin-top: .85rem; }
    #getinTouch .touchBtn .btnTwo {
      display: block;
      max-width: 210px;
      margin: 0 auto;
      text-align: center; }
      #getinTouch .touchBtn .btnTwo:hover {
        background: white;
        border-color: white; }
  #getinTouch .formWrap {
    font-size: .95rem;
    font-weight: 600; }
    #getinTouch .formWrap .fieldWrap {
      display: inline-block;
      padding-top: .85rem; }
    #getinTouch .formWrap p {
      color: #cccccc; }
    #getinTouch .formWrap a {
      color: #fefefe; }
      #getinTouch .formWrap a:hover {
        color: #cccccc; }
    #getinTouch .formWrap .checkwrap {
      position: relative;
      padding-left: 2rem;
      margin-bottom: 12px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      /* Hide the browser's default checkbox */
      /* Create a custom checkbox */
      /* On mouse-over, add a grey background color */
      /* When the checkbox is checked, add a blue background */ }
      #getinTouch .formWrap .checkwrap input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        left: 0;
        height: 0;
        width: 0; }
      #getinTouch .formWrap .checkwrap .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        /* Create the checkmark/indicator (hidden when not checked) */
        /* Style the checkmark/indicator */ }
        #getinTouch .formWrap .checkwrap .checkmark:after {
          content: "";
          position: absolute;
          display: none; }
        #getinTouch .formWrap .checkwrap .checkmark:after {
          left: 8px;
          top: 0;
          width: 10px;
          height: 20px;
          border: solid white;
          border-width: 0 5px 5px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg); }
      #getinTouch .formWrap .checkwrap:hover input ~ .checkmark {
        background-color: #ccc; }
      #getinTouch .formWrap .checkwrap input:checked ~ .checkmark {
        background-color: #fa4b13;
        /* Show the checkmark when checked */ }
        #getinTouch .formWrap .checkwrap input:checked ~ .checkmark:after {
          display: block; }
  #getinTouch .formBtm {
    background: #595959;
    color: white;
    width: 200px;
    padding: .7rem .25rem 2px;
    border: none;
    border-bottom: 1px solid white;
    margin-right: .25rem;
    color: #ddd;
    margin-bottom: .5rem; }
    #getinTouch .formBtm:focus {
      outline: none; }

#footer {
  padding: 130px 0 50px;
  color: #595959;
  font-size: 1rem; }
  @media print, screen and (max-width: 74.99875em) {
    #footer {
      padding: 70px 15px 30px; } }
  @media print, screen and (max-width: 39.99875em) {
    #footer {
      text-align: center;
      padding: 50px 0; } }
  #footer a:link, #footer a:visited {
    color: #595959; }
    #footer a:link:hover, #footer a:visited:hover {
      color: #fa4b13; }
  #footer .footerMenu {
    padding-top: 30px;
    max-width: 360px;
    font-weight: 500; }
    @media print, screen and (max-width: 39.99875em) {
      #footer .footerMenu {
        max-width: unset; } }
    #footer .footerMenu ul {
      margin: 0;
      padding: 0; }
    #footer .footerMenu li {
      list-style: None;
      display: inline-block;
      width: 49%;
      padding-bottom: 48px; }
  #footer .footRight {
    width: 300px;
    float: right; }
    @media print, screen and (max-width: 39.99875em) {
      #footer .footRight {
        width: auto;
        float: none; } }
  #footer .footerLogo {
    padding-bottom: 30px; }
    #footer .footerLogo img {
      width: 120px;
      height: 101px;
      display: inline-block; }
  #footer .socialWrap {
    width: 300px;
    float: right; }
    @media print, screen and (max-width: 39.99875em) {
      #footer .socialWrap {
        width: auto;
        float: none; } }
    #footer .socialWrap a {
      margin-right: 4.5rem; }
      @media (max-height: 420px) {
        #footer .socialWrap a {
          margin-right: 3.5rem; } }
      #footer .socialWrap a:last-child {
        margin-right: 0; }
    #footer .socialWrap .fab {
      font-size: 1.2rem; }
    @media print, screen and (max-width: 39.99875em) {
      #footer .socialWrap {
        padding-top: 1rem; } }
  #footer .footerTxt {
    font-weight: 500;
    padding-bottom: 40px; }
  #footer .copyright {
    font-size: .85rem; }

input:-internal-autofill-selected {
  background-color: #929292 !important;
  -webkit-text-fill-color: white;
  font-size: 1.2rem; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  border-bottom: 1px solid #fff;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #929292 inset !important;
  transition: background-color 5000s ease-in-out 0s;
  font-size: 1.2rem; }

/**
	* Make the field a flex-container, reverse the order so label is on top.
	*/
.btnTwo {
  display: inline-block;
  background: #fa4b13;
  padding: .85rem 1.5rem;
  border-radius: 0px;
  border: 1px solid #fa4b13;
  font-size: .9rem;
  color: white;
  cursor: pointer;
  font-weight: 500; }
  .btnTwo a {
    color: white; }
    .btnTwo a:hover {
      color: #fa4b13 !important; }
  .btnTwo:hover {
    background: white;
    color: #fa4b13 !important; }
  .btnTwo:focus {
    outline: none; }

label {
  letter-spacing: 0.05em;
  color: white;
  font-weight: 600; }

.cs-form-field {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 0;
  margin-right: 6px;
  /**
	* Add a transition to the label and input.
	* I'm not even sure that touch-action: manipulation works on
	* inputs, but hey, it's new and cool and could remove the
	* pesky delay.
	*/
  /**
	* Translate down and scale the label up to cover the placeholder,
	* when following an input (with placeholder-shown support).
	* Also make sure the label is only on one row, at max 2/3rds of the
	* field�to make sure it scales properly and doesn't wrap.
	*/ }
  .cs-form-field label, .cs-form-field input {
    transition: all 0.2s;
    touch-action: manipulation; }
  .cs-form-field input, .cs-form-field textarea {
    border: 0;
    border-bottom: 1px solid #ccc;
    font-family: inherit;
    -webkit-appearance: none;
    border-radius: 0;
    padding: .65rem 0;
    cursor: text;
    font-size: 1.2rem;
    background-color: transparent;
    color: #fff;
    box-shadow: none; }
    .cs-form-field input:focus, .cs-form-field textarea:focus {
      outline: 0;
      border: none;
      border-bottom: 1px solid #ff6d00;
      background: transparent;
      box-shadow: none; }
  .cs-form-field textarea {
    border: 1px solid #ccc;
    padding: .65rem; }
    .cs-form-field textarea:focus {
      border: 1px solid #ff6d00; }
  .cs-form-field input:placeholder-shown + label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 600;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 2.125rem) scale(1.05); }

/**
	* By default, the placeholder should be transparent. Also, it should
	* inherit the transition.
	*/
::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit; }

/**
	* Show the placeholder when the input is focused.
	*/
.cs-form-field input:focus::-webkit-input-placeholder {
  opacity: 1; }

/**
	* When the element is focused, remove the label transform.
	* Also, do this when the placeholder is _not_ shown, i.e. when
	* there's something in the input at all.
	*/
.cs-form-field input:not(:placeholder-shown) + label,
.cs-form-field input:focus + label {
  transform: translate(0, 0) scale(1);
  cursor: pointer; }

#Error404 {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url("../img/error_bkg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (min-width: 40em) {
    #Error404 .HerotextAlign {
      position: absolute;
      top: 41%;
      left: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -41%);
      -khtml-transform: translate(-50%, -41%);
      -moz-transform: translate(-50%, -41%);
      -ms-transform: translate(-50%, -41%);
      transform: translate(-50%, -41%); } }
  @media print, screen and (max-width: 39.99875em) {
    #Error404 .HerotextAlign {
      position: absolute;
      top: 5%;
      left: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -5%);
      -khtml-transform: translate(-50%, -5%);
      -moz-transform: translate(-50%, -5%);
      -ms-transform: translate(-50%, -5%);
      transform: translate(-50%, -5%); } }
  @media print, screen and (min-width: 40em) {
    #Error404 .HerotextAlignbtm {
      position: absolute;
      top: 98%;
      left: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -98%);
      -khtml-transform: translate(-50%, -98%);
      -moz-transform: translate(-50%, -98%);
      -ms-transform: translate(-50%, -98%);
      transform: translate(-50%, -98%); } }
  @media print, screen and (max-width: 39.99875em) {
    #Error404 .HerotextAlignbtm {
      position: absolute;
      top: 95%;
      left: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -95%);
      -khtml-transform: translate(-50%, -95%);
      -moz-transform: translate(-50%, -95%);
      -ms-transform: translate(-50%, -95%);
      transform: translate(-50%, -95%); } }
  #Error404 h1, #Error404 h2, #Error404 h3, #Error404 h4, #Error404 h5, #Error404 h6 {
    font-family: "Libre Baskerville", serif;
    color: #fefefe; }
  #Error404 h1 {
    line-height: 1; }
    @media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
      #Error404 h1 {
        font-size: 1.7rem; } }
    @media print, screen and (max-width: 39.99875em) {
      #Error404 h1 {
        font-size: 1.5rem; } }
  #Error404 p {
    font-family: "Open Sans", sans-serif, Arial;
    color: #fefefe;
    font-size: 20px;
    margin-bottom: 0; }
    @media print, screen and (min-width: 40em) {
      #Error404 p {
        padding: 20px 0; } }
    @media print, screen and (max-width: 63.99875em) {
      #Error404 p {
        font-size: 14px; } }
  @media print, screen and (min-width: 40em) {
    #Error404 .Iconsocial404 {
      padding-top: 20px; } }
  #Error404 .Iconsocial404 img {
    z-index: 100;
    width: 40px;
    padding: 7px;
    transition: all 1s ease; }
    #Error404 .Iconsocial404 img:hover {
      transform: scale(1.1); }
  #Error404 .Mytel {
    color: #fefefe;
    display: inline-block;
    transition: all 1s ease; }
    #Error404 .Mytel:hover {
      transform: scale(1.1); }
  #Error404 .errorbtns {
    margin: 30px 5% 30px 0;
    padding: 1rem;
    font-family: "Open Sans", sans-serif, Arial;
    color: #fefefe;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    border: 1.5px solid #fefefe;
    transition: all 0.5s ease; }
    @media print, screen and (max-width: 89.99875em) {
      #Error404 .errorbtns {
        font-size: 14px; } }
    @media print, screen and (max-width: 39.99875em) {
      #Error404 .errorbtns {
        margin: 10px auto 0 auto; } }
    #Error404 .errorbtns:hover {
      background: #fefefe;
      color: #fa4b13; }
  @media (max-width: 2000px) and (min-width: 1441px) {
    #Error404 .contactcareers {
      font-size: 16px; } }
  @media (max-width: 1260px) and (min-width: 1024px) {
    #Error404 .contactcareers {
      font-size: 10px; } }

#new-staff h1, #new-staff h2, #new-staff h3, #new-staff h4, #new-staff h5, #new-staff h6 {
  font-family: "Libre Baskerville", serif; }

#new-staff h1 {
  line-height: 1.3;
  padding: 30px 0;
  color: #595959;
  text-transform: capitalize; }
  @media print, screen and (max-width: 63.99875em) {
    #new-staff h1 {
      font-size: 2.4rem; } }

#new-staff p, #new-staff a {
  line-height: 1.3;
  font-size: 20px;
  font-family: "Open Sans", sans-serif, Arial;
  color: #595959; }
  @media print, screen and (max-width: 89.99875em) {
    #new-staff p, #new-staff a {
      font-size: 16px; } }

#new-staff .Anotherlink {
  line-height: 1.3;
  font-weight: bold;
  font-family: "Open Sans", sans-serif, Arial;
  color: #595959; }
  @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
    #new-staff .Anotherlink {
      font-size: 14px; } }
  #new-staff .Anotherlink:hover {
    color: #fa4b13; }

#new-staff .new-staff-logo {
  display: block;
  margin: 100px auto;
  width: 30%; }
  @media print, screen and (max-width: 39.99875em) {
    #new-staff .new-staff-logo {
      width: 60%; } }

#new-staff .hoverphoto {
  position: relative; }
  @media (max-width: 1190px) {
    #new-staff .hoverphoto .person {
      display: none; }
    #new-staff .hoverphoto #key {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 5;
      width: 28%; }
    #new-staff .hoverphoto .instructions {
      display: none; } }
  @media (min-width: 1191px) {
    #new-staff .hoverphoto .person:hover, #new-staff .hoverphoto .person:focus {
      opacity: .75; }
    #new-staff .hoverphoto #key {
      display: none; } }
  #new-staff .hoverphoto #chantelle {
    position: absolute;
    top: 565px;
    left: 768px;
    z-index: 3;
    cursor: pointer; }
  #new-staff .hoverphoto #dasha {
    position: absolute;
    top: 578px;
    left: 548px;
    z-index: 3;
    cursor: pointer; }
  #new-staff .hoverphoto #ruthie {
    position: absolute;
    top: 850px;
    left: 600px;
    z-index: 3;
    cursor: pointer; }
  #new-staff .hoverphoto #rebecca {
    position: absolute;
    top: 900px;
    left: 397px;
    z-index: 4;
    cursor: pointer; }
  #new-staff .hoverphoto #steve {
    position: absolute;
    top: 883px;
    left: 722px;
    z-index: 4;
    cursor: pointer; }
  #new-staff .hoverphoto #beth {
    position: absolute;
    top: 798px;
    left: 195px;
    z-index: 4;
    cursor: pointer; }
  #new-staff .hoverphoto #kyle {
    position: absolute;
    top: 809px;
    left: 725px;
    z-index: 3;
    cursor: pointer; }
  #new-staff .hoverphoto #dave {
    position: absolute;
    top: 530px;
    left: 462px;
    z-index: 2;
    cursor: pointer; }
  #new-staff .hoverphoto #mike {
    position: absolute;
    top: 516px;
    left: 330px;
    z-index: 2;
    cursor: pointer; }
  #new-staff .hoverphoto #michael {
    position: absolute;
    top: 547px;
    left: 668px;
    z-index: 2;
    cursor: pointer; }
  #new-staff .hoverphoto #malik {
    position: absolute;
    top: 822px;
    left: 389px;
    z-index: 3;
    cursor: pointer; }
  #new-staff .hoverphoto #jennifer {
    position: absolute;
    top: 592px;
    left: 202px;
    z-index: 3;
    cursor: pointer; }

#new-staff .directions {
  text-transform: uppercase;
  line-height: 1.3;
  font-size: 20px;
  width: 100%;
  text-align: center;
  color: #fb4c13;
  margin: 40px 10px; }
  @media print, screen and (max-width: 89.99875em) {
    #new-staff .directions {
      font-size: 16px; } }
  #new-staff .directions img {
    max-width: 3%;
    margin-top: -20px; }

@font-face {
  font-family: "Trade Gothic LT W04 Light";
  src: url("../fonts/1462027/ab88c69c-d345-4c07-bf49-c0cc984095fa.eot?#iefix");
  src: url("../fonts/1462027/ab88c69c-d345-4c07-bf49-c0cc984095fa.eot?#iefix") format("eot"), url("../fonts/1462027/6de16bd6-1e13-400f-80e8-8f5c69d8a2b8.woff2") format("woff2"), url("../fonts/1462027/73c7abdc-45a3-42a3-a1af-f07d8a348917.woff") format("woff"), url("../fonts/1462027/73494c38-422a-4bae-9e2c-b86e7703c1b3.ttf") format("truetype"), url("../fonts/1462027/b84e9482-10dd-4d9d-8ef2-539fbc5eb441.svg#b84e9482-10dd-4d9d-8ef2-539fbc5eb441") format("svg"); }

@font-face {
  font-family: "Trade Gothic LT W04 Bold";
  src: url("../fonts/1462035/519a7aea-4569-4f88-92ce-6545742efb3b.eot?#iefix");
  src: url("../fonts/1462035/519a7aea-4569-4f88-92ce-6545742efb3b.eot?#iefix") format("eot"), url("../fonts/1462035/7cf07212-d52a-4cf9-9983-4942ecc34b0d.woff2") format("woff2"), url("../fonts/1462035/81ca1fb3-a435-4db8-b09c-f74e270eec60.woff") format("woff"), url("../fonts/1462035/28a5f1a0-5ec3-4aae-a58e-2453166d7918.ttf") format("truetype"), url("../fonts/1462035/11be2b3f-f734-422c-bdb7-a4f797bfa3f3.svg#11be2b3f-f734-422c-bdb7-a4f797bfa3f3") format("svg"); }

@font-face {
  font-family: "Trade Gothic LT W04 Bd_1463660";
  src: url("../fonts/1463660/b435f232-793c-46e0-a59b-17cc94c45d36.eot?#iefix");
  src: url("../fonts/1463660/b435f232-793c-46e0-a59b-17cc94c45d36.eot?#iefix") format("eot"), url("../fonts/1463660/a577e9b9-e095-472c-a1b1-11b207e5e9fc.woff2") format("woff2"), url("../fonts/1463660/9043fcee-4909-4a14-877e-9fdca9788e08.woff") format("woff"), url("../fonts/1463660/6f9ae21c-4b61-4107-b9bb-f463027dbec3.ttf") format("truetype"), url("../fonts/1463660/3325cb6b-5146-426b-9c48-11fea7a7ea3a.svg#3325cb6b-5146-426b-9c48-11fea7a7ea3a") format("svg"); }

.sxsw-central {
  background: url(../img/newsletter-sxsw/orange-bkg.png);
  min-height: 100vh;
  background-size: cover; }
  @media print, screen and (max-width: 39.99875em) {
    .sxsw-central {
      height: auto; } }
  .sxsw-central .sxsw-title {
    width: 100%;
    font-size: 3rem;
    padding: 2rem 0;
    font-family: Trade Gothic LT W04 Bold;
    background: url(../img/newsletter-sxsw/white-title-bkg.png);
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    text-align: center;
    color: #fa4b13;
    position: fixed;
    z-index: 100; }
    @media print, screen and (max-width: 39.99875em) {
      .sxsw-central .sxsw-title {
        font-size: 1.5rem;
        padding: 1rem 0;
        position: relative; } }
    .sxsw-central .sxsw-title .blue {
      color: #00b5de; }
  .sxsw-central .sxsw-slider li {
    padding: 1em; }
  .sxsw-central .sxsw-box {
    max-width: 90rem;
    margin-top: 3rem;
    background: url(../img/newsletter-sxsw/box-black-bkg.png) right bottom no-repeat;
    color: white;
    position: relative; }
    @media print, screen and (max-width: 39.99875em) {
      .sxsw-central .sxsw-box {
        background: url(../img/newsletter-sxsw/box-black-bkg-v.png) 0 bottom no-repeat; } }
    .sxsw-central .sxsw-box .bx-controls-direction {
      display: none; }
    .sxsw-central .sxsw-box ul.ww-slider, .sxsw-central .sxsw-box ul.live-slider, .sxsw-central .sxsw-box ul.yt-slider {
      margin: 0; }
      .sxsw-central .sxsw-box ul.ww-slider li, .sxsw-central .sxsw-box ul.live-slider li, .sxsw-central .sxsw-box ul.yt-slider li {
        padding: 0; }
      .sxsw-central .sxsw-box ul.ww-slider img, .sxsw-central .sxsw-box ul.live-slider img, .sxsw-central .sxsw-box ul.yt-slider img {
        width: 100%; }
      .sxsw-central .sxsw-box ul.ww-slider .bx-controls-direction, .sxsw-central .sxsw-box ul.live-slider .bx-controls-direction, .sxsw-central .sxsw-box ul.yt-slider .bx-controls-direction {
        display: none; }
    .sxsw-central .sxsw-box .sxsw-img img {
      width: 100%; }
    .sxsw-central .sxsw-box .image-overlay-content .overlayText {
      top: 70%; }
    .sxsw-central .sxsw-box .sxsw-text {
      padding: 2rem 4rem; }
      @media print, screen and (max-width: 63.99875em) {
        .sxsw-central .sxsw-box .sxsw-text {
          padding: 2rem 1rem 2rem; } }
      .sxsw-central .sxsw-box .sxsw-text p {
        font-size: 1.15rem;
        line-height: 1.25; }
      .sxsw-central .sxsw-box .sxsw-text .yt-slider img, .sxsw-central .sxsw-box .sxsw-text .ww-slider img, .sxsw-central .sxsw-box .sxsw-text .live-slider img {
        width: 100%; }
    .sxsw-central .sxsw-box .bolt-btm {
      position: absolute;
      right: 60px;
      bottom: 50px; }
      @media print, screen and (max-width: 63.99875em) {
        .sxsw-central .sxsw-box .bolt-btm {
          display: none; } }
    .sxsw-central .sxsw-box .bolt-logo img {
      margin: 0 auto; }
  .sxsw-central .sxswwrap {
    padding-top: 14vh;
    width: 100vw;
    position: relative;
    overflow: hidden; }
    @media print, screen and (max-width: 39.99875em) {
      .sxsw-central .sxswwrap {
        padding-top: 0; } }
    @media print, screen and (max-width: 63.99875em) {
      .sxsw-central .sxswwrap .bx-wrapper .bx-pager {
        padding-top: 0; } }
    .sxsw-central .sxswwrap .bx-wrapper .bx-pager.bx-default-pager a {
      border: 1px solid #000 !important; }
      @media print, screen and (max-width: 63.99875em) {
        .sxsw-central .sxswwrap .bx-wrapper .bx-pager.bx-default-pager a {
          border: 1px solid #fff !important;
          margin: 0 8px; } }
    .sxsw-central .sxswwrap .bx-wrapper .bx-pager.bx-default-pager a.active,
    .sxsw-central .sxswwrap .bx-wrapper .bx-pager.bx-default-pager a:hover {
      background: #000 !important; }
      @media print, screen and (max-width: 63.99875em) {
        .sxsw-central .sxswwrap .bx-wrapper .bx-pager.bx-default-pager a.active,
        .sxsw-central .sxswwrap .bx-wrapper .bx-pager.bx-default-pager a:hover {
          background: #fff !important; } }
    .sxsw-central .sxswwrap .bx-wrapper .bx-controls-direction a {
      width: 42px;
      height: 72px; }
      @media print, screen and (max-width: 39.99875em) {
        .sxsw-central .sxswwrap .bx-wrapper .bx-controls-direction a {
          top: 30px;
          width: 25px;
          height: 50px;
          background-size: 100%; } }
    .sxsw-central .sxswwrap .bx-wrapper .bx-next {
      background: url(../img/newsletter-sxsw/controls_next.png) no-repeat; }
    .sxsw-central .sxswwrap .bx-wrapper .bx-prev {
      background: url(../img/newsletter-sxsw/controls_prev.png) no-repeat; }
    .sxsw-central .sxswwrap .overlay-fade-in .image-overlay-content {
      background: transparent; }
    .sxsw-central .sxswwrap .image-overlay-content {
      height: 100%; }
    .sxsw-central .sxswwrap .sxsw-coppy-center img {
      margin: 0 auto; }
    .sxsw-central .sxswwrap .sxsw-slider {
      margin-left: 0; }
      .sxsw-central .sxswwrap .sxsw-slider .sxsw-text .sxsw-header {
        padding-top: 5rem; }
        .sxsw-central .sxswwrap .sxsw-slider .sxsw-text .sxsw-header img {
          max-height: 60vh; }

.sxswwrap > .bx-wrapper > .bx-viewport {
  height: auto !important; }

.tattoo-central .square-left {
  position: absolute;
  width: 150px;
  height: 150px;
  background: #fefefe;
  top: 0;
  left: 2%;
  z-index: 1001; }
  @media print, screen and (max-width: 63.99875em) {
    .tattoo-central .square-left {
      width: 100%;
      height: 50px;
      left: 0; } }
  .tattoo-central .square-left .bolt-wrap {
    position: relative;
    height: 100%; }
    .tattoo-central .square-left .bolt-wrap .bolt {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -50%);
      -khtml-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      @media print, screen and (max-width: 63.99875em) {
        .tattoo-central .square-left .bolt-wrap .bolt {
          width: 50px; } }

@media (max-width: 2100px) and (min-width: 1800px) {
  .tattoo-central .square-left {
    width: 120px;
    height: 120px; } }

@media (max-width: 1800px) and (min-width: 1500px) {
  .tattoo-central .square-left {
    width: 100px;
    height: 100px; } }

@media (max-width: 1500px) and (min-width: 1024px) {
  .tattoo-central .square-left {
    width: 70px;
    height: 70px; }
  .tattoo-central .bolt {
    width: 60%; } }

.tattoo-central .tattoowrap {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: #d7d7d7;
  overflow: hidden; }
  .tattoo-central .tattoowrap .tattoo-slider {
    height: 100vh;
    margin-left: 0; }
    .tattoo-central .tattoowrap .tattoo-slider #dave-r-right-large {
      background-image: url(../img/newsletter-landing/DaveR_01.jpg); }
    .tattoo-central .tattoowrap .tattoo-slider #dave-r-right {
      background-image: url(../img/newsletter-landing/DaveR_02.jpg); }
    .tattoo-central .tattoowrap .tattoo-slider #dave-r-left {
      background-image: url(../img/newsletter-landing/DaveR_03.jpg); }
    .tattoo-central .tattoowrap .tattoo-slider #dave-m-right-large {
      background-image: url(../img/newsletter-landing/DaveM_01.jpg); }
    .tattoo-central .tattoowrap .tattoo-slider #dave-m-right {
      background-image: url(../img/newsletter-landing/DaveM_03.jpg); }
    .tattoo-central .tattoowrap .tattoo-slider #dave-m-left {
      background-image: url(../img/newsletter-landing/DaveM_02.jpg); }
    .tattoo-central .tattoowrap .tattoo-slider #steve-st-large {
      background-image: url(../img/newsletter-landing/Steve_01.jpg); }
    .tattoo-central .tattoowrap .tattoo-slider #steve-st {
      background-image: url(../img/newsletter-landing/Steve_02.jpg); }
    .tattoo-central .tattoowrap .tattoo-slider #kaitlyn-large {
      background-image: url(../img/newsletter-landing/Kaitlyn_01.jpg); }
    .tattoo-central .tattoowrap .tattoo-slider #kaitlyn {
      background-image: url(../img/newsletter-landing/Kaitlyn_02.jpg); }
    .tattoo-central .tattoowrap .tattoo-slider #kevin-large {
      background-image: url(../img/newsletter-landing/Kevin_01.jpg); }
    .tattoo-central .tattoowrap .tattoo-slider #kevin {
      background-image: url(../img/newsletter-landing/Kevin_02.jpg); }
    .tattoo-central .tattoowrap .tattoo-slider .tattoo-right-img {
      height: 100vh;
      width: 45vw;
      position: absolute;
      right: 0;
      top: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center; }
    .tattoo-central .tattoowrap .tattoo-slider .tattoo-text {
      height: 100vh;
      width: 55vw;
      position: absolute;
      left: 0;
      top: 0; }
      .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-copy {
        height: 50vh;
        position: relative; }
        .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-copy .tattoo-coppy-center {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-right: -50%;
          -webkit-transform: translate(-50%, -50%);
          -khtml-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
        .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-copy h1, .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-copy h2, .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-copy h3, .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-copy h4, .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-copy h5, .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-copy h6 {
          font-family: "Libre Baskerville", serif; }
        .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-copy h1 {
          line-height: 1.3;
          padding: 0 0 30px 0;
          color: #28acbe;
          text-transform: capitalize; }
          @media print, screen and (max-width: 74.99875em) {
            .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-copy h1 {
              font-size: 1.3rem;
              padding: 20px 0; } }
        .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-copy p {
          line-height: 1.3;
          font-size: 20px;
          font-family: "Open Sans", sans-serif, Arial;
          color: #595959; }
          @media print, screen and (max-width: 74.99875em) {
            .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-copy p {
              font-size: 12px; } }
        @media (max-width: 2000px) and (min-width: 1199px) {
          .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-copy h1 {
            font-size: 1.8rem;
            padding: 0 0 10px 0; }
          .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-copy p {
            font-size: 16px; } }
      .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-left-img {
        position: relative;
        height: 50vh; }
        .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-left-img .tattoo-right {
          height: 50vh;
          width: 50%;
          position: absolute;
          right: 0;
          top: 0;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top center; }
        .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-left-img .tattoo-left {
          height: 50vh;
          width: 50%;
          position: absolute;
          left: 0;
          top: 0;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top center; }
        .tattoo-central .tattoowrap .tattoo-slider .tattoo-text .tattoo-left-img .tattoo-left-one {
          height: 50vh;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top center; }
    .tattoo-central .tattoowrap .tattoo-slider li {
      height: 100vh !important; }
  .tattoo-central .tattoowrap .bx-wrapper, .tattoo-central .tattoowrap .bx-viewport {
    height: 100vh !important; }
  .tattoo-central .tattoowrap .outer-left {
    width: 1px;
    height: 100%;
    margin: 0 2%;
    position: absolute;
    overflow: hidden; }
  .tattoo-central .tattoowrap .inner-left {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: #fefefe;
    top: 0;
    /* box-shadow: 0px 0px 30px 20px grey; */
    z-index: 1000; }
  .tattoo-central .tattoowrap .outer-right {
    width: 1px;
    height: 100%;
    margin: 0 98%;
    position: absolute;
    overflow: hidden; }
  .tattoo-central .tattoowrap .inner-right {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: #fefefe;
    top: 0;
    /* box-shadow: 0px 0px 30px 20px grey; */
    z-index: 1000; }
  .tattoo-central .tattoowrap .square-right {
    position: absolute;
    width: 150px;
    height: 150px;
    background: #fefefe;
    top: 0;
    right: 2%;
    z-index: 1001; }
    .tattoo-central .tattoowrap .square-right .controls-wrap {
      position: relative;
      height: 100%; }
      .tattoo-central .tattoowrap .square-right .controls-wrap .tattoo-prev-cont {
        position: absolute;
        top: 35%;
        left: 15%;
        margin-right: -15%;
        -webkit-transform: translate(-15%, -35%);
        -khtml-transform: translate(-15%, -35%);
        -moz-transform: translate(-15%, -35%);
        -ms-transform: translate(-15%, -35%);
        transform: translate(-15%, -35%);
        cursor: pointer; }
      .tattoo-central .tattoowrap .square-right .controls-wrap .tattoo-next-cont {
        position: absolute;
        top: 35%;
        left: 85%;
        margin-right: -85%;
        -webkit-transform: translate(-85%, -35%);
        -khtml-transform: translate(-85%, -35%);
        -moz-transform: translate(-85%, -35%);
        -ms-transform: translate(-85%, -35%);
        transform: translate(-85%, -35%);
        cursor: pointer; }
      .tattoo-central .tattoowrap .square-right .controls-wrap #tattoo-pager {
        position: absolute;
        top: 75%;
        left: 50%;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -75%);
        -khtml-transform: translate(-50%, -75%);
        -moz-transform: translate(-50%, -75%);
        -ms-transform: translate(-50%, -75%);
        transform: translate(-50%, -75%); }
      .tattoo-central .tattoowrap .square-right .controls-wrap #tattoo-pager ul {
        list-style: none;
        margin-left: 0;
        margin-bottom: 0; }
      .tattoo-central .tattoowrap .square-right .controls-wrap #tattoo-pager li {
        display: inline-block; }
      .tattoo-central .tattoowrap .square-right .controls-wrap #tattoo-pager ul a {
        background: transparent;
        border: 1px solid #666666;
        text-indent: -9999px;
        display: block;
        width: 10px;
        height: 10px;
        margin: 0 3px;
        outline: 0;
        border-radius: 5px; }
        .tattoo-central .tattoowrap .square-right .controls-wrap #tattoo-pager ul a:hover {
          background-color: #28acbe;
          border: 1px solid #28acbe; }
      .tattoo-central .tattoowrap .square-right .controls-wrap .active {
        background-color: #28acbe !important;
        border: 1px solid #28acbe !important; }
  @media (max-width: 2100px) and (min-width: 1800px) {
    .tattoo-central .tattoowrap .square-right {
      width: 120px;
      height: 120px; } }
  @media (max-width: 1800px) and (min-width: 1500px) {
    .tattoo-central .tattoowrap .square-right {
      width: 100px;
      height: 100px; }
    .tattoo-central .tattoowrap .tattoo-next-cont, .tattoo-central .tattoowrap .tattoo-prev-cont {
      width: 30%; }
    .tattoo-central .tattoowrap #tattoo-pager ul a {
      margin: 0 2px !important; } }
  @media (max-width: 1500px) and (min-width: 1024px) {
    .tattoo-central .tattoowrap .square-right {
      width: 70px;
      height: 70px; }
    .tattoo-central .tattoowrap .tattoo-next-cont, .tattoo-central .tattoowrap .tattoo-prev-cont {
      width: 30%; }
    .tattoo-central .tattoowrap #tattoo-pager ul a {
      margin: 0 !important;
      width: 6px !important;
      height: 6px !important;
      border-radius: 4px !important;
      margin: 0 2px !important; } }

.tattoo-central .tattoowrap-mobile {
  background: #d7d7d7; }
  .tattoo-central .tattoowrap-mobile .tattoo-text-mobile {
    position: relative;
    padding-bottom: 20px;
    height: 200px; }
    .tattoo-central .tattoowrap-mobile .tattoo-text-mobile .tattoo-copy-mobile {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      -webkit-transform: translate(-50%, -50%);
      -khtml-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 5; }
    .tattoo-central .tattoowrap-mobile .tattoo-text-mobile .tattoo-mobile-prev {
      position: absolute;
      top: 50%;
      left: 5%;
      margin-right: -5%;
      -webkit-transform: translate(-5%, -50%);
      -khtml-transform: translate(-5%, -50%);
      -moz-transform: translate(-5%, -50%);
      -ms-transform: translate(-5%, -50%);
      transform: translate(-5%, -50%);
      cursor: pointer;
      width: 30px;
      margin-top: 30px;
      z-index: 6; }
    .tattoo-central .tattoowrap-mobile .tattoo-text-mobile .tattoo-mobile-next {
      position: absolute;
      top: 50%;
      left: 95%;
      margin-right: -95%;
      -webkit-transform: translate(-95%, -50%);
      -khtml-transform: translate(-95%, -50%);
      -moz-transform: translate(-95%, -50%);
      -ms-transform: translate(-95%, -50%);
      transform: translate(-95%, -50%);
      cursor: pointer;
      width: 30px;
      margin-top: 30px;
      z-index: 6; }
    .tattoo-central .tattoowrap-mobile .tattoo-text-mobile h1, .tattoo-central .tattoowrap-mobile .tattoo-text-mobile h2, .tattoo-central .tattoowrap-mobile .tattoo-text-mobile h3, .tattoo-central .tattoowrap-mobile .tattoo-text-mobile h4, .tattoo-central .tattoowrap-mobile .tattoo-text-mobile h5, .tattoo-central .tattoowrap-mobile .tattoo-text-mobile h6 {
      font-family: "Libre Baskerville", serif; }
    .tattoo-central .tattoowrap-mobile .tattoo-text-mobile h1 {
      padding: 80px 20% 20px 20%;
      color: #28acbe;
      font-size: 1.2rem;
      text-transform: capitalize; }
    .tattoo-central .tattoowrap-mobile .tattoo-text-mobile p {
      font-size: 14px;
      font-family: "Open Sans", sans-serif, Arial;
      color: #595959;
      padding: 0 20%;
      margin-bottom: 10px; }
  .tattoo-central .tattoowrap-mobile ul {
    margin-left: 0; }
    .tattoo-central .tattoowrap-mobile ul img {
      width: 100%; }
  .tattoo-central .tattoowrap-mobile .tattoo-descr-mobile {
    padding: 30px 5% 30px 5%; }
    .tattoo-central .tattoowrap-mobile .tattoo-descr-mobile p {
      margin-bottom: 0; }

#signup {
  margin: 0 auto;
  background-image: url("../img/newsletter-signup/background-signup-m.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media print, screen and (max-width: 74.99875em) {
    #signup {
      padding: 0 10px;
      background-image: url("../img/newsletter-signup/background-signup-m.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center; } }
  #signup .row {
    max-width: 45rem; }
  #signup #mc_embed_signup {
    background: transparent;
    clear: left;
    font-family: "Libre Baskerville", serif; }
  #signup #mc_embed_signup form {
    padding: 0; }
  #signup #mc_embed_signup .mc-field-group {
    width: 100%;
    padding-bottom: 0; }
  #signup .first-name {
    padding-right: 10px; }
    @media print, screen and (max-width: 74.99875em) {
      #signup .first-name {
        padding-right: 0; } }
  #signup .last-name {
    padding-left: 10px; }
    @media print, screen and (max-width: 74.99875em) {
      #signup .last-name {
        padding-left: 0; } }
  #signup input {
    margin: 0 0 5px;
    text-indent: 5px !important; }
  #signup #mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error {
    border: 2px solid #e85c41 !important; }
  #signup #mc_embed_signup .button {
    margin: 25px 0 0 0;
    background-color: #fa4b13;
    padding: 0;
    width: 100%;
    border: 1px solid #fa4b13 !important;
    font-family: "Open Sans", sans-serif, Arial;
    font-weight: 700;
    font-size: 12px; }
    #signup #mc_embed_signup .button:hover {
      color: #fa4b13;
      background-color: transparent;
      border: 1px solid #fa4b13 !important; }
  #signup #mc_embed_signup div#mce-responses {
    margin: 0 !important;
    padding: 0 !important; }
  #signup #mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
    padding: 5px 0 !important;
    font-size: 16px !important;
    background-color: transparent !important; }
  #signup #mc_embed_signup #mce-success-response, #signup #mc_embed_signup #mce-error-response {
    font-size: 22px !important;
    margin: 0 !important;
    padding: 5px 0 !important; }
  #signup .signup-introduction {
    padding: 0 0 10px 0 !important; }
  #signup .signup-header img {
    padding-top: 50px;
    width: 100%; }
  #signup .signup-header h6 {
    font-family: "Libre Baskerville", serif;
    font-weight: 600;
    padding-right: 10px; }
  #signup .signup-header .top-row {
    padding-top: 10px;
    padding-bottom: 10px; }
  #signup .cs-info {
    margin: 30px 0; }
    #signup .cs-info p {
      font-family: "Open Sans", sans-serif, Arial;
      font-weight: 700;
      font-size: 12px; }

.career-starts-here {
  padding-top: 60px; }
  @media print, screen and (max-width: 74.99875em) {
    .career-starts-here {
      padding-top: 0; } }
  .career-starts-here .main-row {
    max-width: 84rem; }
    @media print, screen and (max-width: 74.99875em) {
      .career-starts-here .main-row {
        padding: 20px; } }
  .career-starts-here .applied-arts {
    width: 653px; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      .career-starts-here .applied-arts {
        width: 480px; } }
    @media print, screen and (max-width: 63.99875em) {
      .career-starts-here .applied-arts {
        width: 268px; } }
  .career-starts-here h1, .career-starts-here h2, .career-starts-here h3, .career-starts-here h4, .career-starts-here h5, .career-starts-here h6 {
    font-family: "Libre Baskerville", serif;
    color: #595959;
    margin-bottom: 0; }
  .career-starts-here .my-header {
    line-height: 1.3;
    font-size: 3rem;
    font-weight: 900;
    padding: 25px 0; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      .career-starts-here .my-header {
        font-size: 2rem;
        padding: 15px 0;
        margin-bottom: 0; } }
    @media print, screen and (max-width: 63.99875em) {
      .career-starts-here .my-header {
        font-size: 1.4rem;
        padding: 15px 0;
        margin-bottom: 0; } }
  @media (max-width: 1300px) and (min-width: 1199px) {
    .career-starts-here .my-header {
      font-size: 2.6rem !important; }
    .career-starts-here h4 {
      font-size: 1.4rem !important; }
    .career-starts-here .sub-header, .career-starts-here .article-quot, .career-starts-here .sub-sub-header {
      font-size: 1.6rem !important; }
    .career-starts-here .applied-arts {
      width: 590px; } }
  @media print, screen and (max-width: 74.99875em) {
    .career-starts-here h4 {
      font-size: 1rem !important; } }
  .career-starts-here p, .career-starts-here a {
    color: #595959;
    line-height: 1.3;
    font-size: 20px;
    font-family: "Open Sans", sans-serif, Arial; }
    @media print, screen and (max-width: 63.99875em) {
      .career-starts-here p, .career-starts-here a {
        font-size: 14px; } }
  @media (max-width: 1300px) and (min-width: 1024px) {
    .career-starts-here p, .career-starts-here a {
      font-size: 16px; } }
  @media print, screen and (min-width: 64em) {
    .career-starts-here .columns {
      padding: 0 75px !important; } }
  @media print, screen and (min-width: 64em) {
    .career-starts-here .left-border {
      border-left: 2px solid #595959; } }
  @media print, screen and (min-width: 64em) {
    .career-starts-here .right-border {
      border-right: 2px solid #595959; } }
  .career-starts-here .sub-header {
    font-size: 2rem;
    font-weight: 900; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      .career-starts-here .sub-header {
        font-size: 1.6rem; } }
    @media print, screen and (max-width: 63.99875em) {
      .career-starts-here .sub-header {
        font-size: 1.2rem; } }
    @media print, screen and (min-width: 64em) {
      .career-starts-here .sub-header {
        padding: 0 0 60px 0;
        margin-bottom: 0; } }
  .career-starts-here .article-quot {
    font-size: 2rem;
    font-weight: 900;
    margin-top: 20px; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      .career-starts-here .article-quot {
        font-size: 1.6rem; } }
    @media print, screen and (max-width: 63.99875em) {
      .career-starts-here .article-quot {
        font-size: 1.2rem; } }
    @media print, screen and (min-width: 64em) {
      .career-starts-here .article-quot {
        padding: 60px 0 0 0;
        margin-bottom: 0; } }
  .career-starts-here .sub-sub-header {
    font-size: 2rem;
    font-weight: 900; }
    @media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
      .career-starts-here .sub-sub-header {
        font-size: 1.6rem; } }
    @media print, screen and (max-width: 63.99875em) {
      .career-starts-here .sub-sub-header {
        font-size: 1.2rem; } }
    @media print, screen and (min-width: 64em) {
      .career-starts-here .sub-sub-header {
        padding: 44px 0 60px 0;
        margin-bottom: 0; } }
  .career-starts-here .article-text-start {
    margin-top: 20px; }
    @media print, screen and (min-width: 64em) {
      .career-starts-here .article-text-start {
        margin-top: 60px; } }
  .career-starts-here .pagination {
    position: relative;
    margin-top: 100px;
    margin-bottom: 0;
    background: #28acbe;
    padding: 15px 0;
    font-family: "Open Sans", sans-serif, Arial;
    text-transform: uppercase;
    font-weight: 600; }
    @media print, screen and (max-width: 39.99875em) {
      .career-starts-here .pagination {
        padding: 30px 0; } }
    @media print, screen and (max-width: 63.99875em) {
      .career-starts-here .pagination {
        margin-top: 50px; } }
    .career-starts-here .pagination a {
      font-size: 16px;
      color: #fefefe; }
      @media print, screen and (max-width: 74.99875em) {
        .career-starts-here .pagination a {
          font-size: 14px; } }
      .career-starts-here .pagination a::before, .career-starts-here .pagination a::after {
        content: " "; }
      .career-starts-here .pagination a:hover {
        background: transparent;
        color: #e6e04d; }
    .career-starts-here .pagination .current {
      font-size: 16px;
      display: inline-block !important;
      background: transparent;
      color: #e6e04d; }
      @media print, screen and (max-width: 74.99875em) {
        .career-starts-here .pagination .current {
          font-size: 14px; } }
    .career-starts-here .pagination .portfolio img {
      width: 25px; }
    @media print, screen and (max-width: 39.99875em) {
      .career-starts-here .pagination .portfolio {
        display: inline-block !important; } }
    .career-starts-here .pagination .pagination li {
      display: inline-block !important; }
    .career-starts-here .pagination .pagination-previous {
      position: absolute;
      top: 50%;
      left: 15%;
      margin-right: -15%;
      -webkit-transform: translate(-15%, -50%);
      -khtml-transform: translate(-15%, -50%);
      -moz-transform: translate(-15%, -50%);
      -ms-transform: translate(-15%, -50%);
      transform: translate(-15%, -50%); }
    .career-starts-here .pagination .pagination-next {
      position: absolute;
      top: 50%;
      left: 85%;
      margin-right: -85%;
      -webkit-transform: translate(-85%, -50%);
      -khtml-transform: translate(-85%, -50%);
      -moz-transform: translate(-85%, -50%);
      -ms-transform: translate(-85%, -50%);
      transform: translate(-85%, -50%); }
    .career-starts-here .pagination #BlogImgPrev, .career-starts-here .pagination #BlogImgNext {
      width: 15px;
      margin-right: 10px;
      margin-left: 10px; }

#xmas-slider-wrap {
  position: relative; }
  #xmas-slider-wrap .xmas-bxslider {
    margin-left: 0; }
    #xmas-slider-wrap .xmas-bxslider .vertical {
      background: #0a0a0a; }
      #xmas-slider-wrap .xmas-bxslider .vertical img {
        height: 100vh;
        margin: auto; }
    #xmas-slider-wrap .xmas-bxslider .horizontal {
      min-height: 100vh;
      background-size: cover !important; }
  #xmas-slider-wrap .bx-wrapper .bx-viewport {
    left: 0;
    border: none;
    box-shadow: none; }
  #xmas-slider-wrap ul, #xmas-slider-wrap ul li {
    margin: 0;
    padding: 0; }
  #xmas-slider-wrap #xmas-next {
    position: absolute;
    top: 50%;
    left: 95%;
    margin-right: -95%;
    -webkit-transform: translate(-95%, -50%);
    -khtml-transform: translate(-95%, -50%);
    transform: translate(-95%, -50%);
    width: 60px;
    cursor: pointer;
    border: 2px solid #fa4b13;
    border-radius: 50%; }
  #xmas-slider-wrap #xmas-prev {
    position: absolute;
    top: 50%;
    left: 5%;
    margin-right: -5%;
    -webkit-transform: translate(-5%, -50%);
    -khtml-transform: translate(-5%, -50%);
    transform: translate(-5%, -50%);
    width: 60px;
    cursor: pointer;
    border: 2px solid #fa4b13;
    border-radius: 50%; }
  #xmas-slider-wrap .dowloadimg {
    color: #000000;
    background-color: #f04f23;
    position: absolute;
    transition: all 0.5s ease;
    border: 1px solid #f04f23; }
    @media print, screen and (max-width: 39.99875em) {
      #xmas-slider-wrap .dowloadimg {
        position: absolute;
        top: 95%;
        left: 50%;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -95%);
        -khtml-transform: translate(-50%, -95%);
        -moz-transform: translate(-50%, -95%);
        -ms-transform: translate(-50%, -95%);
        transform: translate(-50%, -95%); } }
    @media print, screen and (min-width: 40em) {
      #xmas-slider-wrap .dowloadimg {
        bottom: 20px;
        right: 20px; } }
    #xmas-slider-wrap .dowloadimg P {
      font-family: "Open Sans", sans-serif, Arial;
      margin-bottom: 0;
      padding: 10px 50px;
      font-weight: 600; }
    #xmas-slider-wrap .dowloadimg:hover {
      background-color: #ffffff;
      border-color: #000000; }

.holiday-window-displays {
  background: #fefefe;
  /*@media (max-width: 2000px) and (min-width: 1024px){
			p, li, a {
				font-size: 16px!important;
			}
			 h3 {
			 	font-size: 18px;
			 }
		}*/
  /*.ShareIcons {
			width:50px;
			padding-right: 25px;
			padding-bottom: 25px;
			transition: all 1s ease;
			&:hover {
				transform: scale(1.1);
			}
		}*/ }
  .holiday-window-displays h1, .holiday-window-displays h2, .holiday-window-displays h3, .holiday-window-displays h4, .holiday-window-displays h5, .holiday-window-displays h6 {
    font-family: "Libre Baskerville", serif;
    color: #595959;
    margin-bottom: 0;
    padding-bottom: 25px; }
  .holiday-window-displays h1 {
    line-height: 1.3;
    padding-top: 25px;
    font-weight: 900;
    text-transform: capitalize; }
    @media print, screen and (max-width: 74.99875em) {
      .holiday-window-displays h1 {
        font-size: 1.7rem;
        margin-bottom: 0; } }
  @media (max-width: 2000px) and (min-width: 1199px) {
    .holiday-window-displays h1 {
      font-size: 2rem !important; } }
  .holiday-window-displays .sub {
    font-weight: 700;
    font-family: "Open Sans", sans-serif, Arial;
    color: #595959;
    /*@include breakpoint(large only) {
				font-size: 14px;
			}*/ }
  .holiday-window-displays h3 {
    font-size: 26px;
    font-weight: 900;
    padding-top: 9px;
    margin-bottom: -16px; }
    @media print, screen and (max-width: 74.99875em) {
      .holiday-window-displays h3 {
        font-size: 1.1875rem; } }
    .holiday-window-displays h3 span {
      font-weight: 300; }
  .holiday-window-displays p, .holiday-window-displays li, .holiday-window-displays a {
    color: #595959;
    line-height: 1.5;
    font-size: 18px;
    font-family: "Open Sans", sans-serif, Arial;
    /*@include breakpoint(medium down) {
			  font-size: 16px;
			}*/ }
  .holiday-window-displays .blogtext {
    margin-bottom: 150px; }
    @media print, screen and (max-width: 63.99875em) {
      .holiday-window-displays .blogtext {
        margin-bottom: 50px; } }
  .holiday-window-displays .numbers {
    float: left;
    font-size: 5rem;
    line-height: 3rem;
    font-family: "Libre Baskerville", serif;
    padding-top: 16px;
    padding-right: 10px;
    font-weight: 900; }
  .holiday-window-displays .first-img {
    margin-bottom: 25px; }
  .holiday-window-displays .second-img {
    margin-bottom: 1rem; }
  .holiday-window-displays .Anotherlink {
    font-weight: bold;
    color: #595959; }
    .holiday-window-displays .Anotherlink:hover {
      color: #fa4b13; }
  .holiday-window-displays .font-type {
    font-family: "Libre Baskerville", serif;
    font-weight: 900 !important; }
  .holiday-window-displays .Saks-bxslider {
    margin-left: 0; }
    .holiday-window-displays .Saks-bxslider li {
      margin-right: 30px; }
  .holiday-window-displays .responsive-embed.widescreen, .holiday-window-displays .flex-video.widescreen {
    padding-bottom: 82%;
    margin-bottom: 0; }

.cs-exposes-its-neck {
  background: #fefefe; }
  .cs-exposes-its-neck h1, .cs-exposes-its-neck h2, .cs-exposes-its-neck h3, .cs-exposes-its-neck h4, .cs-exposes-its-neck h5, .cs-exposes-its-neck h6 {
    font-family: "Libre Baskerville", serif;
    color: #595959;
    margin-bottom: 0;
    padding-bottom: 25px; }
  .cs-exposes-its-neck h1 {
    line-height: 1.3;
    padding-top: 25px;
    font-weight: 900;
    text-transform: capitalize; }
    @media print, screen and (max-width: 74.99875em) {
      .cs-exposes-its-neck h1 {
        font-size: 1.7rem;
        margin-bottom: 0; } }
  @media (max-width: 2000px) and (min-width: 1199px) {
    .cs-exposes-its-neck h1 {
      font-size: 2rem !important; } }
  .cs-exposes-its-neck p {
    color: #595959;
    line-height: 1.3;
    font-size: 20px;
    font-family: "Open Sans", sans-serif, Arial; }
    @media print, screen and (max-width: 63.99875em) {
      .cs-exposes-its-neck p {
        font-size: 14px; } }
  @media (max-width: 2000px) and (min-width: 1024px) {
    .cs-exposes-its-neck p {
      font-size: 16px !important; } }
  .cs-exposes-its-neck .ShareIcons {
    width: 50px;
    padding-right: 25px;
    padding-bottom: 25px;
    transition: all 1s ease; }
    .cs-exposes-its-neck .ShareIcons:hover {
      transform: scale(1.1); }
  .cs-exposes-its-neck .blogtext {
    margin-bottom: 150px;
    margin-top: 50px; }
    @media print, screen and (max-width: 63.99875em) {
      .cs-exposes-its-neck .blogtext {
        margin-bottom: 50px; } }
  .cs-exposes-its-neck .boltnews {
    max-width: 50px; }
  .cs-exposes-its-neck .pagination {
    position: relative;
    margin-top: 100px;
    margin-bottom: 0;
    background: #28acbe;
    padding: 15px 0;
    font-family: "Open Sans", sans-serif, Arial;
    text-transform: uppercase;
    font-weight: 600; }
    @media print, screen and (max-width: 39.99875em) {
      .cs-exposes-its-neck .pagination {
        padding: 30px 0; } }
    @media print, screen and (max-width: 63.99875em) {
      .cs-exposes-its-neck .pagination {
        margin-top: 50px; } }
    .cs-exposes-its-neck .pagination a {
      font-size: 16px;
      color: #fefefe; }
      @media print, screen and (max-width: 74.99875em) {
        .cs-exposes-its-neck .pagination a {
          font-size: 14px; } }
      .cs-exposes-its-neck .pagination a::before, .cs-exposes-its-neck .pagination a::after {
        content: " "; }
      .cs-exposes-its-neck .pagination a:hover {
        background: transparent;
        color: #e6e04d; }
    .cs-exposes-its-neck .pagination .current {
      font-size: 16px;
      display: inline-block !important;
      background: transparent;
      color: #e6e04d; }
      @media print, screen and (max-width: 74.99875em) {
        .cs-exposes-its-neck .pagination .current {
          font-size: 14px; } }
    .cs-exposes-its-neck .pagination .pagination li {
      display: inline-block !important; }
    .cs-exposes-its-neck .pagination .pagination-previous {
      position: absolute;
      top: 50%;
      left: 15%;
      margin-right: -15%;
      -webkit-transform: translate(-15%, -50%);
      -khtml-transform: translate(-15%, -50%);
      -moz-transform: translate(-15%, -50%);
      -ms-transform: translate(-15%, -50%);
      transform: translate(-15%, -50%); }
    .cs-exposes-its-neck .pagination .pagination-next {
      position: absolute;
      top: 50%;
      left: 85%;
      margin-right: -85%;
      -webkit-transform: translate(-85%, -50%);
      -khtml-transform: translate(-85%, -50%);
      -moz-transform: translate(-85%, -50%);
      -ms-transform: translate(-85%, -50%);
      transform: translate(-85%, -50%); }
    .cs-exposes-its-neck .pagination #BlogImgPrev, .cs-exposes-its-neck .pagination #BlogImgNext {
      width: 15px;
      margin-right: 10px;
      margin-left: 10px; }
  .cs-exposes-its-neck .MobileShare {
    margin-top: 30px; }
  .cs-exposes-its-neck .gingerbread1 {
    padding-bottom: 2rem; }
  .cs-exposes-its-neck .gingerbread2 {
    padding-top: 1rem; }

#trialrun {
  background-image: url("../img/trialrun-hero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position: top;
  align-items: center;
  justify-content: center;
  min-height: 960px; }
  @media only screen and (min-width: 2000px) {
    #trialrun {
      min-height: 1260px; } }
  #trialrun .HerotextAlign {
    margin-top: 36%; }
    @media print, screen and (min-width: 40em) {
      #trialrun .HerotextAlign {
        margin-top: 15%; } }
  #trialrun h1 {
    color: #fefefe; }
  #trialrun .heroText {
    max-width: 600px;
    margin: 0 auto; }
    @media print, screen and (max-width: 63.99875em) {
      #trialrun .heroText p {
        font-size: 18px; } }
  @media print, screen and (max-width: 63.99875em) {
    #trialrun {
      min-height: auto; }
      #trialrun.pageHero {
        padding-bottom: 4rem; } }

#trialText {
  background-image: url("../img/trialrun-bkg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow-x: hidden;
  padding: 3rem 0 2.5rem; }
  @media screen and (min-width: 75em) {
    #trialText {
      background-attachment: fixed; } }
  #trialText .listWrap {
    max-width: 720px; }
  #trialText h1, #trialText h2, #trialText h3, #trialText h4, #trialText h5, #trialText h6 {
    font-family: "Libre Baskerville", serif; }
  #trialText h2 {
    line-height: 1.5;
    font-size: 2rem;
    padding: 0 0 30px;
    text-transform: capitalize;
    color: #fff85b; }
    @media print, screen and (max-width: 39.99875em) {
      #trialText h2 {
        font-size: 1.75rem; } }
  #trialText p, #trialText li {
    color: #fefefe;
    margin-bottom: .5rem; }

#howitWorks {
  padding: 3rem 0; }
  #howitWorks h3 {
    font-size: 1.25rem;
    color: #fa4b13;
    font-family: "Open Sans", sans-serif, Arial;
    font-weight: 600; }
  #howitWorks .workSteps {
    list-style: none;
    margin: 0;
    padding: 0; }
    #howitWorks .workSteps li {
      display: flex;
      align-items: stretch;
      margin: 1rem 0;
      padding: 0; }
      #howitWorks .workSteps li p {
        background: #f7f7f7;
        border-radius: 0 8px 8px 0;
        padding: 0 2rem 0 1rem;
        margin: 10px 0;
        width: 100%;
        display: flex;
        align-items: center; }
      #howitWorks .workSteps li img {
        width: 180px; }
      @media print, screen and (max-width: 39.99875em) {
        #howitWorks .workSteps li {
          align-items: flex-start; }
          #howitWorks .workSteps li p {
            padding: .5rem 1rem;
            margin: 3px 0; }
          #howitWorks .workSteps li img {
            width: 80px; } }

#readyText {
  background: #fa4b13;
  text-align: center;
  color: #fff85b;
  padding: 1.5rem; }
  #readyText h2 {
    font-size: 2rem; }
    @media print, screen and (max-width: 39.99875em) {
      #readyText h2 {
        font-size: 1.5rem; } }
